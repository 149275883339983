/* eslint-disable no-unused-vars */
import { formatMessage } from 'devextreme/localization'

export const lh = {
  getMessage,
}

export enum MessagesKeys {
  RetencionNroRequerido = 'Retencion-NroRequerido',
  RetencionNroIncorrecto = 'Retencion-NroIncorrecto',
  RetencionAutorizacionRequerida = 'Retencion-AutorizacionRequerida',
  RetencionAutorizacionIncorrecta = 'Retencion-AutorizacionIncorrecta',
  RetencionFormaPagoRequerida = 'Retencion-FormaPagoRequerida',
  RetencionSinDetalles = 'Retencion-SinDetalles',
  RetencionNroLoteIncorrecto = 'Retencion-NroLoteIncorrecto',
  RetencionDetalleTipoNoSeleccionado = 'Retencion-Detalle-TipoNoSeleccionado',
  RetencionDetalleBaseImponibleMayor = 'Retencion-Detalle-BaseImponibleMayor',
  RetencionDetalleBaseImponibleCero = 'Retencion-Detalle-BaseImponibleCero',
  ClienteTipoIdentificacionRequerida = 'Cliente-TipoIdentificacionRequerida',
  ClienteNumeroIdentificacionRequerido = 'Cliente-NumeroIdentificacionRequerido',
  ClienteNumeroIdentificacionIncorrecto = 'Cliente-NumeroIdentificacionIncorrecto',
  ClienteNumeroPasaporteRequerido = 'Cliente-NumeroPasaporteRequerido',
  ClienteTipoClienteRequerido = 'Cliente-TipoClienteRequerido',
  ClienteNumeroIdentificacionMax = 'Cliente-NumeroIdentificacionMax',
  ClienteLongitudCampoIncorrecto = 'Cliente-LongitudCampoIncorrecto',
  GlobalCampoRequerido = 'Global-CampoRequerido',
  GlobalFormatoCampoIncorrecto = 'Global-Formato-Campo-Incorrecto',
  FormConfirmExitForm = 'Form-Confirm-ExitForm',
  NotFoundRegisterSelec = 'NotFoundRegisterSelec',
  FormConfirmDeleteForm = 'Form-ConfirmDeleteForm',
  FormNotFoundChanges = 'Form-NotFoundChanges',
  FormConfirmChangeForm = 'Form-Confirm-ChangeForm',
  DataNotFoundByExport = 'DataNotFoundByExport',
  ConfirmEnumerarEntries = 'ConfirmEnumerarEntries',
  SelectSalesPoint = 'SelectSalesPoint',
  ConfigurarAutorizacionSRI = 'Configurar-AutorizacionSRI',
  GlobalSinDetalles = 'Global-Sin-Detallles',
  EstadoRequerido = 'EstadoRequerido',
  ProveedorTipoRequerida = 'ProveedorTipoRequerida',
  ProveedorClasificacionRequerida = 'ProveedorClasificacionRequerida',
  ProveedorUbicacionRequerida = 'ProveedorUbicacionRequerida',
  ProveedorTipoTelefonoRequerida = 'ProveedorTipoTelefonoRequerida',
  ProveedorTipoOperadoraRequerida = 'ProveedorTipoOperadoraRequerida',
  LiquidacionCompraTipoIdentificacionIncorrecta = 'LiquidacionCompra-TipoIdeProveedorIncorrecta',
  GlobalNumeroRegistrosEncontrados = 'Global-NumeroRegistrosEncontrados',
  GlobalNotSelected = 'Global-NotSelected',
  AuthEmpresaRequired = 'Auth-Empresa-Required',
  AuthLocalRequired = 'Auth-Local-Required',
  ClienteNumeroRucRequerido = 'Cliente-NumeroRucRequerido',
  ClienteNumeroRucIncorrecto = 'Cliente-NumeroRucIncorrecto',
  UpdateActionNotValid = 'UpdateActionNotValid',
  ConfirmDuplicar = 'ConfirmDuplicar',
  DataNotFoundTransactionExport = 'DataNotFoundTransactionExport',
  TransaccionSinDetalles = 'Transaccion-SinDetalles',
  GenericFormaPagoRequerida = 'Generic-FormaPagoRequerida',
  GenericProyectoRequerida = 'Generic-ProyectoRequerido',
  GenericLocalRequerida = 'Generic-LocalRequerido',
  GenericTipoCompraRequerida = 'Generic-TipoCompraRequerido',
  GenericSustentoTributarioRequerida = 'Generic-SustentoTributarioRequerido',
  GenericNumeroComprobanteRequerido = 'Generic-NumeroComprobanteRequerido',
  GenericAutorizacionComprobanteRequerido = 'Generic-AutorizacionComprobanteRequerido',
  GenericPtoEmisionRequerido = 'Generic-PtoEmisionRequerido',
  GenericEstablecimientoRequerido = 'Generic-AutorizacionComprobanteRequerido',
  GenericNumericInvalid = 'Generic-NumericInvalid',
  GenericSelectRequerid = 'Generic-SelectRequerid',
  GenericRequerid = 'Generic-Requerid',
  GenericUpdate = 'Generic-Update',
  GenericSuscriptionEnd = 'Generic-SuscriptionEnd',
  GenericPasDue = 'Generic-PasDue',
  GlobalCampoPlaca = 'Global-Campo-Planca',
  GlobalSaldoInfo = 'Global-Saldos-Info',
  GlobalCarteraInfo = 'Global-Cartera-Info',
  GlobalProveedorSelect = 'Global-Proveedor-Select',
  GlobalVerAsientoCreate = 'Global-VerAsiento-Create',
  GlobalAbonosInsert = 'Global-Abonas-Insert',
  GlobalAbonosLoad = 'Global-Abonas-Load',
  GlobalAbonosAddCuota = 'Global-Abonas-AddCuota',
  GlobalAbonosChose = 'Global-Abonas-Chose',
  GlobalAbonosCuotaRemove = 'Global-Abonas-CuotaRemove',
  GlobalFacturaSeeDetail = 'Global-Factura-SeeDetail',
  GlobalButtonSearchError = 'Global-Button-SearchError',
  GlobalPrintSearch = 'Global-Print-Search',
  NumberIncludingZeros = 'Number-Including-Zeros',
  CannotSelectPlan = 'Cannot-Select-Plan',
  CannotChangeLowPlan = 'Cannot-Change-LowPlan',
  CheckErrorsInForm = 'Check-Errors-In-Form',
  GlobalVerAbonosInfo = 'Global-VerAbonos-Info',
  InventarioLineasNuevoInfo = 'Inventario-LineasNuevo-Info',
  GlobalErrorSave = 'Global-Error-Save',
  CheckErrorsInPriceItem = 'Check-Errors-To-Add-ItemPrice',
  TypeCustomerStoreAlreadyExists = 'TypeCustomer-Store-Already-Exists',
  SelectRegToDelete = 'Select-Record-To-Delete',
  NoAllowedDeletePrice = 'No-Allowed-Delete-Price',
  ExpiredSession = 'SCLock-Expired-Session',
  SCLockAccountIrregular = 'SCLock-Account-Irregular',
  SCLockCredencialsIrregular = 'SCLock-Credencials-Irregular',
  SCLockAccountRegular = 'SCLock-Account-Regular',
  SCLockAccountNotFound = 'SCLock-Account-NotFound',
  GlobalInputMax = 'Global-Input-Max',
  GlobalInputMin = 'Global-Input-Min',
  ElectronicsNotificationsCant = 'Electronics-NotificationsCant',
  ElectronicsNotificationsResolve = 'Electronics-NotificationsResolve',
  ElectronicsNotificationsResolveSV = 'Electronics-NotificationsResolveSV',
  GenericConfirmAction = 'Generic-Confirm-Action',
  GenericPuntoVentaRequerido = 'Generic-PuntoVentaRequerido',
}

function getMessage(key: MessagesKeys | string, ...values: Array<string>) {
  return formatMessage(key, ...(values ?? []))
}
