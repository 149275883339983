import React, { memo } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import CustomCol from '../../../../../views/componentes/colContainer'
import TextBox from 'devextreme-react/text-box'
import ResultadoBusqueda from './resultadoBusqueda'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CForm,
  CLink,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { RadioGroup } from 'devextreme-react'
import { adminLocales } from '../../service/locales.service'
import { TModalAdminLocales } from '../../store/types'
import { ToastTypes } from '../../../../../store/types'
import { Local } from '../../../../shared/types/types'
import EstadosLookUp from '../../../estadosLookUp'

const clases: any = [
  {
    value: 0,
    label: 'Almacen',
  },
  {
    value: 1,
    label: 'Bodega general',
  },
  {
    value: 2,
    label: 'Bodega sucursales',
  },
]

interface IBuscarProps extends React.PropsWithChildren {
  onChanged: (data) => void
  onEdit: (data) => void
  onCancel?: () => void
  funcion: string
  resetFunction: () => void
  ejecutarBusqueda: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
  seleccion: Local
  currentTab: string
}

type infoPaginationType = {
  desde: number,
  hasta: number,
  paginaActual: number,
  totalPaginas: number,
  totalRegistros: number
}

type TFindLocales = {
  clase: any | null,
  nombre: string,
  estado: any | null
}

const infoPaginationInit = {
  desde: 1,
  hasta: 10,
  paginaActual: 0,
  totalPaginas: 0,
  totalRegistros: 0
}

const initDataFind = {
  clase: clases[0],
  nombre: '',
  estado: null,
}

const Buscar = (props: IBuscarProps) => {

  const { onChanged, funcion, setToast, resetFunction, playLoader, stopLoader, onEdit, seleccion, currentTab } = props

  const formSearchRef = React.useRef<any>()

  const [dataSearch, setDataSearch] = React.useState<TFindLocales>(initDataFind);
  const [infoPagination, ] = React.useState<infoPaginationType>(infoPaginationInit);
  const [items, setItems] = React.useState<Array<any>>([])

  const onValueChangedClase = React.useCallback((data: any) => {
    if (!data.event) { return }
    setDataSearch({
      ...dataSearch,
      clase: data.value,
    });
  }, [dataSearch]);

  const onValueChangedNombre = React.useCallback((data: any) => {
    if (!data.event) { return }
    setDataSearch({
      ...dataSearch,
      nombre: data.value,
    });
  }, [dataSearch]);

  const onValueChangeEstado = React.useCallback((data: any) => {
    setDataSearch({
      ...dataSearch,
      estado: data,
    });
  }, [dataSearch])

  const onLoad = React.useCallback(async () => {
    try {
      playLoader();
      const data = await adminLocales.getAdminLocales({
        estado: 1,
        mensaje: ''
      });
      stopLoader()

      if (data?.error) {
        stopLoader();
        setToast(data?.message, ToastTypes.Danger)
        return;
      }

      if (data.auto?.length) {
        setToast('Consulta realizada correctamente.', ToastTypes.Success);

        const items: Local[] = data.auto.map(function (item: any): Local {
          return {
            codigo: parseInt(item?.localCodigo ?? 0),
            clase: parseInt(item?.localClase ?? 0),
            nombre: String(item?.localNombre ?? ''),
            siglas: String(item?.localSiglas ?? ''),
            ciudad: {
              codigo: parseInt(item?.localCiudad ?? 0),
              descripcion: String(item?.localCiudadNombre ?? ''),
            },
            direccion: String(item?.localDireccion ?? ''),
            telefono: String(item?.localTelefono ?? ''),
            gerente: String(item?.localGerente ?? ''),
            tipo: {
              value: parseInt(item?.localTipo ?? 0),
              label: String(item?.localTipoDescripcion ?? ''),
            },
            pertenece: {
              pertenece: parseInt(item?.localPertenece ?? 0),
              perteneceDescripcion: String(item?.localPerteneceDescripcion ?? ''),
            },
            kardex: parseInt(item?.localKardex ?? 0),
            estado: {
              codigo: parseInt(item?.localEstado ?? 0),
              descripcion: String(item?.localEstadoDescripcion ?? ''),
            },
            siglasLocal: String(item?.localSiglas ?? ''),
            siglasBodega: String(item?.localSiglasLocal ?? ''),
            web: parseInt(item?.localWEB ?? 0),
            uaf: String(item?.localUAF ?? ''),
            descuento: parseInt(item?.localDescuento ?? 0),
            contrato: parseInt(item?.localContrato ?? 0),
            observaciones: String(item?.localObservaciones ?? ''),
            usuarioDokan: String(item?.localUsuarioDokan ?? ''),
            passwordDokan: String(item?.localPasswordDokan ?? ''),
            uloTipo: parseInt(item?.ULOTipo ?? 0),
            logo: String(item?.localLogo ?? ''),
            listaPrecios: parseInt(item?.localListaPrecios ?? 0),
            establecimiento: String(item?.localEstablecimiento ?? ''),
          }
        });

        setItems(items);
      }
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }
  }, [playLoader, setToast, stopLoader])

  // React.useEffect(() => {
  //   if (currentTab === 'Buscar') {
  //     onLoad();
  //   }
  // }, [])

  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>
          <CCard>
            <CCardHeader
              onClick={() => { }}
              className="d-flex justify-content-between"
            >
              <strong> {'Buscar'} </strong>
              <div className="card-header-actions">
                <CLink className="card-header-action">
                  <CIcon icon={CCollapse ? cilChevronBottom : cilChevronTop} />
                </CLink>
              </div>
            </CCardHeader>

            <CCardBody>
              <CForm className="row">
                <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                  {'Clase:'}
                </div>
                <CustomCol className="dx-field-value mt-1">
                  <CCol lg="9" md="6" sm="12">
                    <RadioGroup
                      name="clase"
                      dataSource={clases}
                      defaultValue={dataSearch?.clase}
                      value={dataSearch?.clase}
                      layout="horizontal"
                      displayExpr="label"
                      onValueChanged={onValueChangedClase}
                    />
                  </CCol>
                </CustomCol>
              </CForm>

              <CForm className="row">
                <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                  {'Nombre:'}
                </div>
                <CustomCol className="dx-field-value" xs="12" md="4">
                  <CCol md="6">
                    <TextBox
                      name="nombre"
                      value={dataSearch?.nombre}
                      onValueChanged={onValueChangedNombre}
                    ></TextBox>
                  </CCol>
                </CustomCol>
              </CForm>
              <CForm className="row">
                <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                  {'Estado:'}
                </div>
                <CustomCol className="dx-field-value mt-1">
                  <CCol lg="9" md="6" sm="12">
                    <EstadosLookUp
                      onChanged={onValueChangeEstado}
                      onChangedOptions={() => {}}
                      selected={dataSearch?.estado}
                    >
                    </EstadosLookUp>
                  </CCol>
                </CustomCol>
              </CForm>

              <ResultadoBusqueda
                funcion={funcion}
                name="tableItems"
                data={items ?? []}
                filaSeleccionada={seleccion}
                pagination={infoPagination}
                dataSearch={dataSearch}
                resetFunction={() => {
                  resetFunction()
                }}
                setDataItems={(data: any) => { setItems(data) }}
                onChanged={onChanged}
                onEdit={onEdit}
                setToast={setToast}
              />
            </CCardBody>
          </CCard>
        </ValidationGroup>
      </fieldset>
    </>
  )
}
export default memo(Buscar)
