import { RequestHelper } from '../helpers/requestHelper'
import { utilidades } from '../helpers/utilidades'
import { PaisOption } from '../modulos/componentes/paisesLookUp/store/type'
import {
  CapitalizeMode,
  IdentificacionSriValidationResult,
} from '../store/types'
import { SesionService } from './sesion.service'

export const GeneralService = {
  validarIndentificacion,
  validarIndentificacionSri,
  deleteFileServer,
  validaIndentificacionGetData,
  getCountries,
  getVersionApp,
}

async function validarIndentificacion(
  id: string,
): Promise<IdentificacionSriValidationResult> {
  const apiQuery = {
    id,
  }
  const apiResult = await RequestHelper.get<any>(
    'validIdLocal',
    '',
    '',
    apiQuery,
  )

  const result: IdentificacionSriValidationResult = {
    isValid: false,
  }
  if (apiResult.isValid) {
    result.isValid = true
    result.nombre = apiResult?.razonSocial ?? ''
    result.razonComercial = apiResult?.razonComercial ?? ''
    result.email = apiResult?.email ?? ''
    result.telefono = apiResult?.telefono ?? ''
    result.direccion = apiResult?.direccion ?? ''
    result.actividadEconomica = apiResult?.actividadEconomica ?? ''
    result.nrc = apiResult?.nrc ?? ''
  } else {
    result.motivo = apiResult.motivo
  }

  return result
}

async function validarIndentificacionSri(
  id: string,
): Promise<IdentificacionSriValidationResult> {
  const apiQuery = {
    id,
  }
  const apiResult = await RequestHelper.get<any>('validId', '', '', apiQuery)
  const result: IdentificacionSriValidationResult = {
    isValid: false,
  }
  if (apiResult.isValid) {
    result.isValid = true
    result.nombre = apiResult.nombre
    result.razonComercial = apiResult.razonComercial
    result.direccion = apiResult.direccion
  }
  return result
}

function deleteFileServer(id) {
  const sesion = SesionService.getCurrentSesion()
  const apiQuery = {
    id: id,
    ruc: sesion.empresa.ruc,
  }
  const data = RequestHelper.deleteRequestAll('pdf', 'delete', '', apiQuery)
  return data
}

async function validaIndentificacionGetData(apiQuery): Promise<any> {
  const apiResult = await RequestHelper.getAllNoSession(
    'public',
    'getDataUser',
    '',
    apiQuery,
  )
  return apiResult
}

async function getCountries(): Promise<any> {
  const data: any = {}
  const cacheKey = 'countriesApp' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return { error: false, auto: JSON.parse(cached) }
  } else {
    const apiResult = await RequestHelper.getAllNoSession(
      'public',
      'getCountries',
      '',
      '',
    )
    const opt: Array<PaisOption> = []
    if (apiResult?.auto && apiResult?.auto.length > 0) {
      for (const x of apiResult.auto) {
        opt.push({
          codigo: parseInt(x.codigo) ?? -1,
          nombre: utilidades.capText(x?.nombre, CapitalizeMode.upper) ?? '',
          uaf: x?.uaf ?? '',
        })
      }
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(opt))
    apiResult.auto = opt ?? []
    return apiResult
  }
}

async function getVersionApp(): Promise<string> {
  const apiResult = await RequestHelper.getAllNoSession(
    'public',
    'version',
    '',
    '',
  )
  console.log('apiResult', apiResult)
  return apiResult?.auto || '' // Return version or empty string
}
