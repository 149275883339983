import { ItemInventarioPlan, Plan } from '../../../../store/types'
import { TCategory } from './store/type'

export const PlansHelper = { plansToTypes, categoryPlansToTypes }

function categoryPlansToTypes(categories: Array<any>): Array<TCategory> {
  const categoriesAll: Array<TCategory> = []
  if (categories) {
    for (const x of categories) {
      if (x?.estado === true) {
        categoriesAll.push({
          codigo: x?.codigo,
          descripcion: x?.descripcion,
          estado: x?.estado,
          activoWeb: false,
        })
      }
    }
  }
  return categoriesAll
}

const toType = (item) => {
  const itemDet: ItemInventarioPlan = {
    itemCodigo: item?.itemCodigo ?? 0,
    itemCodigoUsuario: item?.itemCodigoUsuario ?? '',
    itemBarras: item?.itemBarras ?? '',
    itemDescripcion: item?.itemDescripcion ?? '',
    itemTipo: item?.itemTipo ?? 0,
    itemTipoDescripcion: item?.itemTipoDescripcion ?? '',
    itemDescuentos: item?.itemDescuentos ?? '',
    itemPVD: item?.itemPVD ?? 0,
    itemPVDIVA: item?.itemPVDIVA ?? 0,
    itemPVP: item?.itemPVP ?? 0,
    itemPVPIVA: item?.itemPVPIVA ?? 0,
  }
  return itemDet
}

function plansToTypes(plans: Array<any>): Array<Plan> {
  const planesAll: Array<Plan> = []
  if (plans) {
    let i = 0
    for (const x of plans) {
      let comprobanteItem: ItemInventarioPlan = null
      let usuarioItem: ItemInventarioPlan = null

      if (x.detalleItemComprobante && x.detalleItemComprobante.length > 0) {
        comprobanteItem = x.detalleItemComprobante[0]
        x.detalleItemComprobante = comprobanteItem
      }
      if (x.detalleItemUsuario && x.detalleItemUsuario.length > 0) {
        usuarioItem = x.detalleItemUsuario[0]
        x.detalleItemUsuario = usuarioItem
      }

      const det = x.detalle?.slice(0) ?? []

      if (x.detalleItemComprobante && x.detalleItemComprobante.length > 0) {
        for (const item of x.detalleItemComprobante) {
          const itemDet: ItemInventarioPlan = toType(item)
          comprobanteItem = itemDet
        }
      }

      if (x.detalleItemUsuario && x.detalleItemUsuario.length > 0) {
        for (const item of x.detalleItemUsuario) {
          const itemDet: ItemInventarioPlan = toType(item)
          usuarioItem = itemDet
        }
      }

      if (x.maxComprobantes > 0) {
        det.push({
          codigo: -1,
          descripcion: `Comprobantes (${x.maxComprobantes})`,
          estado: 1,
          planId: 1,
        })
      }

      planesAll.push({
        id: Number(x.id) ?? 0,
        plan: String(x.plan) ?? '',
        cadena: String(x.cadena) ?? '',
        estado: x.estado ?? false,
        detalle: det ?? [],
        precio: Number(x.precio) ?? 0,
        tituloDetalle: String(x.tituloDetalle) ?? '',
        iva: Number(x.iva) ?? 0,
        valorPlanConIva: Number(x.valorConIva) ?? 0,
        codPeriodo: Number(x.codPeriodo) ?? 0,
        maxComprobantes: Number(x.maxComprobantes) ?? 0,
        usuariosBase:
          x?.usuarios_base !== undefined ? Number(x?.usuarios_base) : null,
        usuariosExtras:
          x?.usuarios_extra !== undefined ? Number(x?.usuarios_extra) : null,
        usuariosMax:
          x?.maxUsuarios !== undefined ? Number(x?.maxUsuarios) : null,
        codItemComprobantes:
          x?.codItemComprobantes !== undefined ||
          x?.codItemComprobantes !== null
            ? x?.codItemComprobantes
            : null,
        detalleItemComprobante: x?.detalleItemComprobante ?? null,
        codItemUsuarios:
          x?.codItemUsuarios !== undefined || x?.codItemUsuarios !== null
            ? x?.codItemUsuarios
            : null,
        detalleItemUsuario: x?.detalleItemUsuario ?? null,
        isHidden: false,
        visibleWeb: Number(x?.visibleWeb) ?? 0,
        categoria: {
          codigo: Number(x?.categoriaCodigo) ?? 0,
          descripcion: x?.categoriaDescripcion ?? '',
          estado: Number(x?.categoriaEstado) ?? 0,
        },
      })
      i = i + 1
    }
  }
  return planesAll
}
