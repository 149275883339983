import { ItemPedido } from '../modulos/ventas/pages/ordenes/pages/ordenPedido/type/types'
import { consoleService } from '../services/console.service'
import { TiposComprobantesSLV } from '../store/types'
import { utilidades } from './utilidades'

export interface IProdDetail {
  codigo: number
  codigoInterno: string
  codigoBarras: string
  descripcion: string
  tieneIva: boolean
  porcentajeiva: number
  imagenes: string[] // Supongo que las imágenes son URLs o rutas a archivos de imágenes
  fecha: string
  _iva: number
  _subtotal: number
  _subtotal0: number
  _subtotalIva: number
  _total: number
  _cantidad: number
  _precioUnitario: number
  _precioIva: number
  _descuento: number
  _porcentajeDescuento: number
  codigoImpuesto?: number
  _exenta?: number
  _noSujeto?: number
  _gravada?: number
}

export type IProdDetailSV = {
  numItem: number
  tipoItem: number
  numeroDocumento: string | null
  cantidad: number
  codigo: string
  codigoBarras?: string
  codTributo: string | null
  uniMedida: number
  descripcion: string
  precioUni: number
  montoDescu: number
  ventaNoSuj: number
  ventaExenta: number
  ventaGravada: number
  tributos: any[] | null // Puedes definir una interfaz específica para los tributos si es necesario
  ivaItem?: number | null
  psv?: number
  noGravado?: number
  codigoImpuesto?: number
}
export interface IProdDetailReteSV {
  numItem: number
  tipoDte: string
  tipoDoc: number
  numDocumento: string
  fechaEmision: string
  montoSujetoGrav: number
  codigoRetencionMH: string
  ivaRetenido: number
  descripcion: string
}

export const FormatProductsElectronicInvoiceSVCCF = (
  IProdDetail: IProdDetail[],
  precision = 2,
): IProdDetailSV[] => {
  const products: IProdDetailSV[] = []
  IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      _cantidad,
      _precioUnitario,
      _descuento,
      _iva,
      codigoImpuesto,
      _exenta,
      _noSujeto,
      _gravada,
    } = product
    const esExentoIva = Number(_iva) <= 0

    const itemProd = {
      codigoImpuesto: codigoImpuesto,
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion.slice(0, 1000),
      precioUni: parseFloat(_precioUnitario.toFixed(precision)),
      montoDescu: parseFloat(_descuento.toFixed(precision)),
      ventaNoSuj: _noSujeto,
      ventaExenta: _exenta,
      ventaGravada: _gravada,
      tributos: !esExentoIva ? ['20'] : null,
      ivaItem: parseFloat(_iva.toFixed(precision)),
      psv: 0,
      noGravado: 0,
    }

    products.push(itemProd)
  })
  return products
}

export const FormatProductsElectronicInvoiceSVRetencion = (
  tipoDoc: TiposComprobantesSLV,
  numDoc: string,
  fechaEmision: string,
  precision = 2,
  detalles,
): IProdDetailReteSV[] => {
  return detalles.map((detalle, index) => {
    //fecha actual sv
    const { baseImponible, concepto } = detalle
    let { porcentaje } = detalle
    porcentaje = porcentaje / 100

    const ivaRete = utilidades.formatDecimal(
      baseImponible * porcentaje,
      precision,
    )
    ///"2023-11-20", "23/02/2024"
    const [dia, mes, anio] = fechaEmision.split('/')

    return {
      numItem: index + 1,
      tipoDte: tipoDoc == '14' ? null : tipoDoc,
      tipoDoc: tipoDoc == '14' ? 1 : 2,
      numDocumento: tipoDoc == '14' ? null : numDoc,
      fechaEmision: tipoDoc == '14' ? null : `${anio}-${mes}-${dia}`,
      montoSujetoGrav: baseImponible,
      codigoRetencionMH: concepto.codigo,
      ivaRetenido: ivaRete,
      descripcion: concepto.concepto,
    }
  })
}

export const FormatProductsElectronicInvoiceSVNotaCredito = (
  IProdDetail: IProdDetail[],
  precision = 4,
): IProdDetailSV[] => {
  const products: IProdDetailSV[] = IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      _cantidad,
      _precioUnitario,
      _descuento,
      _iva,
      codigoImpuesto,
      _exenta,
      _noSujeto,
      _gravada,
    } = product

    const esExentoIva = Number(_iva) <= 0

    return {
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion,
      precioUni: parseFloat(_precioUnitario.toFixed(precision)),
      montoDescu: parseFloat(_descuento.toFixed(precision)),
      ventaNoSuj: _noSujeto,
      ventaExenta: _exenta,
      ventaGravada: _gravada,
      tributos: !esExentoIva ? ['20'] : null,
      psv: 0,
      noGravado: 0,
      ivaItem: parseFloat(_iva.toFixed(precision)),
      codigoImpuesto: codigoImpuesto,
    }
  })
  return products
}

export const FormatProductsElectronicInvoiceSV = (
  IProdDetail: IProdDetail[],
  // eslint-disable-next-line no-unused-vars
  precision = 2,
): IProdDetailSV[] => {
  const products = []
  IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      _cantidad,
      _descuento,
      _iva,
      codigoImpuesto,
      _exenta,
      _noSujeto,
      _gravada,
      porcentajeiva,
    } = product

    const itemProd = {
      codigoImpuesto: codigoImpuesto,
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion.slice(0, 1000),
      precioUni: parseFloat(
        (
          (_gravada +
            _exenta +
            _noSujeto +
            _descuento +
            (_gravada + _exenta + _noSujeto + _descuento) * porcentajeiva) /
          _cantidad
        ).toFixed(8),
      ),
      montoDescu: parseFloat((_descuento * (1 + porcentajeiva)).toFixed(8)),
      ventaNoSuj: _noSujeto,
      ventaExenta: _exenta,
      ventaGravada: _gravada + _iva,
      tributos: null,
      psv: 0,
      noGravado: 0,
      ivaItem: parseFloat(
        (((_gravada + _iva) / (1 + porcentajeiva)) * porcentajeiva).toFixed(8),
      ),
    }
    products.push(itemProd)
  })

  return products
}

export const FormatProductsElectronicSujetoExcluido = (
  IProdDetail: IProdDetail[],
  precision = 2,
): IProdDetailSV[] => {
  const products = []
  IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      _cantidad,
      _precioIva,
      _descuento,
      _iva,
      codigoImpuesto,
      _exenta,
      _noSujeto,
      _gravada,
      porcentajeiva,
    } = product

    const itemProd = {
      codigoImpuesto: codigoImpuesto,
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion,
      precioUni: parseFloat(_precioIva.toFixed(precision)),
      montoDescu: parseFloat(
        (_descuento * (1 + porcentajeiva)).toFixed(precision),
      ),
      ventaNoSuj: _noSujeto,
      ventaExenta: _exenta,
      ventaGravada: _gravada + _iva,
      tributos: null,
      psv: 0,
      noGravado: 0,
      ivaItem: parseFloat(_iva.toFixed(precision)),
    }
    products.push(itemProd)
  })

  return products
}

export const FormatProductsElectronicInvoiceSVPrueba = (
  IProdDetail: ItemPedido[],
  precision = 2,
): IProdDetailSV[] => {
  try {
    const products: IProdDetailSV[] = IProdDetail.map(
      (product: ItemPedido, index) => {
        const {
          codigo,
          codigoBarras,
          descripcion,
          pvd: _precioUnitario,
          pvp,
          impuestoValor: _iva,
          codigoImpuesto,
        } = product

        const _cantidad = 1
        const _descuento = 0
        const esExentoIva = Number(_iva) <= 0

        const iva2 = 13
        const cantidad = _cantidad

        const productoPrecioSinIva = _precioUnitario > 0 ? _precioUnitario : pvp

        // Calcular producto con IVA
        const productoPrecioConIva = utilidades.formatDecimal(
          productoPrecioSinIva * (1 + iva2 / 100),
          precision,
        )

        const precioSinIva = utilidades.formatDecimal(
          productoPrecioSinIva,
          precision,
        )

        // Calcular el subtotal (sin IVA)
        const subtotalSinIva = utilidades.formatDecimal(
          cantidad * productoPrecioSinIva,
          precision,
        )
        const descu = utilidades.formatDecimal(_descuento, precision)

        // Calcular el valor total del IVA
        let ventaGravada =
          Number(productoPrecioConIva) * Number(cantidad) - Number(descu)
        ventaGravada = utilidades.formatDecimal(ventaGravada, precision)

        let ventaExenta = esExentoIva ? subtotalSinIva - Number(descu) : 0
        ventaExenta = utilidades.formatDecimal(ventaExenta, precision)
        const ivaTotal = utilidades.formatDecimal(
          ventaGravada - ventaGravada / (1 + iva2 / 100),
          precision,
        )

        return {
          numItem: index + 1,
          tipoItem: 1,
          numeroDocumento: null,
          cantidad: _cantidad,
          codigo: codigo.toString(),
          codigoBarras: codigoBarras + '',
          codTributo: null,
          uniMedida: 1,
          descripcion: descripcion,
          precioUni: utilidades.formatDecimal(
            esExentoIva ? precioSinIva : productoPrecioConIva,
            precision,
          ),
          montoDescu: Number(descu),
          ventaNoSuj: 0,
          ventaExenta: ventaExenta,
          ventaGravada: !esExentoIva
            ? utilidades.formatDecimal(ventaGravada, precision)
            : 0,
          tributos: null,
          psv: 0,
          noGravado: 0,
          ivaItem: esExentoIva ? 0 : ivaTotal,
          codigoImpuesto: codigoImpuesto,
        }
      },
    )
    return products
  } catch (e) {
    return []
    consoleService.error(e)
  }
}

export const FormatProductsElectronicInvoiceSVCCFPrueba = (
  IProdDetail: ItemPedido[],
  precision = 2,
): IProdDetailSV[] => {
  const products: IProdDetailSV[] = IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      pvp: _subtotal0,
      impuestoValor: _iva,
      codigoImpuesto,
    } = product

    const esExentoIva = Number(_iva) <= 0

    const iva2 = 13
    const _cantidad = 1
    const cantidad = _cantidad
    const _descuento = 0

    const _precioUnitario = _subtotal0
    const productoPrecioSinIva = _precioUnitario

    // Calcular el valor total del IVA
    const ivaTotal = utilidades.formatDecimal(
      (productoPrecioSinIva * iva2) / 100,
      precision,
    )

    // Calcular el total (con IVA)
    const total = _subtotal0 * cantidad - _descuento

    const resumen = {
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion,
      precioUni: utilidades.formatDecimal(_subtotal0, precision),
      montoDescu: utilidades.formatDecimal(_descuento, precision),
      ventaNoSuj: 0,
      ventaExenta: esExentoIva
        ? utilidades.formatDecimal(_subtotal0, precision)
        : 0,
      ventaGravada: !esExentoIva
        ? utilidades.formatDecimal(Number(total), precision)
        : 0,
      tributos: !esExentoIva ? ['20'] : null,
      psv: 0,
      noGravado: 0,
      ivaItem: !esExentoIva ? ivaTotal : 0,
      codigoImpuesto: codigoImpuesto,
    }

    return resumen
  })

  return products
}

export const FormatProductsElectronicInvoiceExportSV = (
  IProdDetail: IProdDetail[],
  precision = 2,
): IProdDetailSV[] => {
  const products: any[] = IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      _cantidad,
      _precioUnitario,
      _descuento,
      codigoImpuesto,
    } = product

    const gravado = utilidades.formatDecimal(
      _precioUnitario * _cantidad,
      precision,
    )

    // Calcular el valor total del IVA
    const descuento = utilidades.formatDecimal(_descuento, precision)

    const noGravado = 0
    let ventaGravada = 0

    ventaGravada = utilidades.formatDecimal(gravado - descuento, precision)

    return {
      numItem: index + 1,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      descripcion: descripcion.slice(0, 1000),
      cantidad: _cantidad,
      uniMedida: 99,
      precioUni: utilidades.formatDecimal(_precioUnitario, precision),
      montoDescu: descuento,
      ventaGravada: ventaGravada,
      tributos: ['C3'],
      noGravado: utilidades.formatDecimal(noGravado, precision),
      codigoImpuesto: codigoImpuesto,
    }
  })

  return products
}

export const FormatProductsElectronicInvoiceExportSVPruebas = (
  IProdDetail: ItemPedido[],
  precision = 2,
): IProdDetailSV[] => {
  const products: any[] = IProdDetail.map((product, index) => {
    const {
      codigo,
      descripcion,
      pvp,
      pvd: _precioUnitario,
      codigoBarras,
    } = product

    const _descuento = 0
    const _cantidad = 1
    const cantidad = _cantidad

    const productoPrecioSinIva = utilidades.formatDecimal(
      _precioUnitario > 0 ? _precioUnitario : pvp,
      precision,
    )

    // Calcular el valor total del IVA
    const precioUni = utilidades.formatDecimal(productoPrecioSinIva, precision)
    const descuento = utilidades.formatDecimal(_descuento, precision)

    const ventaGravada = utilidades.formatDecimal(
      precioUni * cantidad - descuento,
      precision,
    )

    // Calcular el total (con IVA)

    return {
      numItem: index + 1,
      codigo: codigo + '',
      codigoBarras: codigoBarras + '',
      descripcion: descripcion,
      cantidad: _cantidad,
      uniMedida: 99,
      precioUni: utilidades.formatDecimal(productoPrecioSinIva, precision),
      montoDescu: descuento,
      ventaGravada: utilidades.formatDecimal(ventaGravada, precision),
      tributos: ['C3'],
      noGravado: 0,
    }
  })
  return products
}
