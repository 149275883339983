import React from 'react'
import {
  DataGrid,
  LoadPanel,
  Button as DatagridButton,
} from 'devextreme-react/data-grid'
import { ClientesService } from '../../../../clientes/pages/clientes/services/clientes.service'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import CustomModalDevx from '../../../../../views/componentes/modal/Modal'
import { CBadge, CCard, CCardBody } from '@coreui/react-pro'
import { Column } from 'devextreme-react/data-grid'
import { useModalConvertSize } from '../../../../../hooks/useModalSize'
interface ISucursalesProps {
  show: boolean
  onChange: (newData: any) => void
  onCancel: () => void
  clienteCodigo: number
}
const CSucursalesListado: React.FunctionComponent<ISucursalesProps> = (
  props,
) => {
  const { clienteCodigo, show, onCancel, onChange } = props
  const [modalSize] = useModalConvertSize('lg')

  const [sucursales, setSucursales] = React.useState<any>([])

  const setData = React.useCallback(async (clienteCodigo) => {
    const clienteSucursales = await ClientesService.getSucursales(clienteCodigo)
    setSucursales(clienteSucursales ?? [])
  }, [])

  const onRowDoubleClick = React.useCallback(
    (e) => {
      const data = { ...e.data }
      onChange(data)
    },
    [onChange],
  )

  React.useEffect(() => {
    setData(clienteCodigo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteCodigo])

  return (
    <CustomModalDevx
      size={modalSize}
      id="Sucursales"
      show={show}
      title="Sucursales"
      onClose={onCancel}
      height={'auto'}
    >
      <CCard>
        <CCardBody>
          <RowContainer>
            <CustomCol xs="12" className="mt-2 mb-2">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <div>
                  <CBadge color="info">{`${
                    sucursales && sucursales.length
                  } REGISTROS`}</CBadge>
                </div>
              </div>
            </CustomCol>
          </RowContainer>
          <RowContainer className="mt-2">
            <CustomCol xs="12" md="12">
              <DataGrid
                keyExpr="codigo"
                repaintChangesOnly
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                dataSource={sucursales ?? []}
                showBorders={true}
                showRowLines={true}
                onRowDblClick={onRowDoubleClick}
              >
                <LoadPanel enabled={true} />
                <Column type="buttons" width="10%">
                  <DatagridButton
                    icon="arrowleft"
                    visible={true}
                    hint="Seleccionar"
                    onClick={(e) => {
                      onChange(e.row.data)
                    }}
                  />
                </Column>
                <Column
                  dataType="string"
                  dataField="ciudadNombre"
                  caption="Ciudad"
                  width="30%"
                />
                <Column
                  dataType="string"
                  dataField="direccion"
                  caption="Dirección"
                  width="60%"
                />
                <Column
                  dataType="string"
                  dataField="email"
                  caption="Email"
                  width="35%"
                />
                <Column
                  dataField="descripcionZona"
                  caption="Ruta"
                  width="60%"
                />
                <Column
                  dataField="telefonoNumero"
                  caption="Telefono"
                  width="30%"
                />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCardBody>
      </CCard>
    </CustomModalDevx>
  )
}
export default React.memo(CSucursalesListado)
