import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Buscar from './componentes/buscar'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import LoadPanel from 'devextreme-react/load-panel';
import config from '../../../config/config'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { addToast } from '../../../store/toasterReducer'
import Nuevo from './componentes/nuevo'
import Modalform from '../../../views/componentes/modalform'
import { RootState } from '../../../store/store'
import { changeLoader } from '../../../store/reducers'
import { VendedorBusqueda } from '../../shared/components/buscarVendedorLookMemoUp/type/types'
import { getTittles } from '../lookUpTittles/helper/helper'
import Dialog from '../../../views/componentes/librerias/bootstrap-dialog'
import { MessagesKeys, lh } from '../../../helpers/localizationHelper'
interface IAdminModalVendedoresProps extends React.PropsWithChildren {
  vendedor?: VendedorBusqueda | null
  onChanged: (dataProveedor: any) => void
  onCancel: () => void
  show: boolean
}

export const AdminModalVendedores: React.FC<IAdminModalVendedoresProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const dialogRef = React.useRef<any>(null);

  const { show, onCancel, onChanged, vendedor } = props
  const loader = useSelector((state: RootState) => state.global.loader);

  const botones = config['menuDefecto'].map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const [currentTab, setCurrentTab] = React.useState<any>("Buscar");
  const [funcion, setFuncion] = React.useState<string>("");
  const [seleccion, setSeleccion] = React.useState<VendedorBusqueda | null>(null);

  const onUpdateConfirm = React.useCallback((tittle?: string) => {
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body:
        <>
          <strong style={{ fontSize: "14px" }}>{tittle}</strong>
          <br />
        </>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            setCurrentTab("Editar")
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, []);

  const onUpdateDelete = React.useCallback((tittle?: string) => {
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body:
        <>
          <strong style={{ fontSize: "14px" }}>{tittle}</strong>
          <br />
        </>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            setFuncion(ButtonTypes.delete)
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, []);

  const resetSearched = React.useCallback(() => {
    setSeleccion(null)
  }, []);

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const setToast = React.useCallback((texto, type: ToastTypes) => {
    dispatch(addToast({
      id: '',
      autoHide: 5000,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: type
    }));
  }, [dispatch]);

  const setActionUpdate = () => {
    onUpdateConfirm(lh.getMessage(MessagesKeys.GenericConfirmAction, "actualizar", "vendedor"));
  }

  const setActionDelete = () => {
    onUpdateDelete(lh.getMessage(MessagesKeys.GenericConfirmAction, "eliminar", "vendedor"));
  }

  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.find:
        setSeleccion(null)
        if (currentTab == "Buscar") {
          setFuncion(ButtonTypes.find)
          return false;
        } else if (currentTab === "Editar") {
          resetSearched()
          setCurrentTab("Buscar")
        } else {
          setCurrentTab("Buscar")
          setFuncion(ButtonTypes.undo)
        }
        break;
      case ButtonTypes.new:
        setCurrentTab("Nuevo")
        setFuncion("")
        break;
      case ButtonTypes.save:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.save)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.save)
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", ToastTypes.Info)
        }
        break;
      case ButtonTypes.undo:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.undo)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.undo)
          return false;
        }
        break;
      case ButtonTypes.edit:
        if (seleccion === null) {
          setToast("No se encuentra un registro seleccionado, Por favor seleccione un Item para editarlo.", ToastTypes.Info)
          return false;
        } else {
          setActionUpdate()
          return false;
        }
        break;
      case ButtonTypes.delete:
        if (seleccion !== null) {
          setActionDelete();
          return false;
        } else {
          setToast("No se encuentra seleccionado un vendedor, por favor seleccione un vendedor para eliminarlo.", ToastTypes.Info)
          return false;
        }
        break;
      default:
        break;
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.find:
        return false;
      case ButtonTypes.undo:
        return currentTab !== "Nuevo" && currentTab !== "Editar";
      case ButtonTypes.new: {
        return currentTab !== "Buscar" || seleccion !== null;
      }
      case ButtonTypes.delete:
      case ButtonTypes.edit: {
        return (currentTab !== "Buscar" || seleccion === null);
      }
      case ButtonTypes.save: {
        return currentTab === "Buscar";
      }
      default:
        return true;
    }
  }, [currentTab, seleccion]);

  // initialize providers dependence modules
  const loadProviders = React.useCallback(async () => {
    await getTittles('Elija una opción');
  }, []);

  React.useEffect(() => {
    if (funcion !== "") {
      setFuncion("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  React.useEffect(() => {
    if (show) {
      if (vendedor === null) {
        setSeleccion(null);
        setCurrentTab("Nuevo");
        setFuncion("");
      } else if (vendedor) {
        setSeleccion(vendedor);
        setCurrentTab("Editar");
        setFuncion(ButtonTypes.undo);
      }
    }
  }, [show, vendedor]);

  React.useEffect(() => {
    loadProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const botonesModal = () => {
    return (
      <RowContainer>
        <CustomCol xs='12' md='4'>
          <GlobalMenu
            acciones={acciones}
            onClick={returnAction}
            getButtonDisabled={getButtonDisabled}
          />
        </CustomCol>
      </RowContainer>
    );
  }

  const bodyModal = () => {
    return (
      <RowContainer>
        <CustomCol xs='12' md='12'>
          {(currentTab === "Nuevo" || currentTab === "Editar") && (
            <Nuevo
              funcion={funcion}
              setCurrenTab={(tab) => setCurrentTab(tab)}
              currentTab={currentTab}
              resetFunction={() => setFuncion('')}
              onChanged={onChanged}
              setToast={(sms, type) => setToast(sms, type)}
              playLoader={playLoader}
              stopLoader={stopLoader}
              selection={seleccion}
            />
          )}
          {currentTab === "Buscar" && (
            <Buscar
              funcion={funcion}
              resetFunction={() => setFuncion("")}
              onChanged={(data) => {
                setSeleccion(data)
                onChanged(data)
              }}
              onEdit={(data) => {
                setSeleccion(data)
              }}
              ejecutarBusqueda={() => setFuncion("Buscar")}
              currentTab={currentTab}
              seleccion={seleccion}
              setToast={setToast}
            />
          )}
        </CustomCol>
      </RowContainer>
    );
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <Modalform
        name='popupVendedores'
        headerTitle={'Vendedores'}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={onCancel}
        centered={true}
        size='lg'
      />
    </>

  )
}

