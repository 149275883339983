import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, Pager, Paging, LoadPanel, Button as DatagridButton } from 'devextreme-react/data-grid';
import React from 'react';
import { ToastTypes } from '../../../../../store/types';
import CustomCol from '../../../../../views/componentes/colContainer';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import {ButtonTypes} from "../../../../../views/componentes/globalMenu/types";
import {adminLocales} from "../../service/locales.service";
import {Local} from "../../../../shared/types/types";
import {changeLoader} from "../../../../../store/reducers";
import {useDispatch} from "react-redux";
import {TModalAdminLocales} from "../../store/types";

interface IResultadoBusquedaProps extends React.PropsWithChildren {
  funcion: string
  name: string
  data: any
  filaSeleccionada: Local
  pagination: TInfoPagination
  dataSearch: any
  resetFunction: () => void,
  setDataItems: (data) => void
  onChanged: (data) => void;
  onEdit: (any: any) => void;
  setToast: (sms: string, type: ToastTypes) => void
}

type TInfoPagination = {
  desde: number,
  hasta: number,
  paginaActual: number,
  totalPaginas: number,
  totalRegistros: number
}

type TFindLocales = {
  clase?: any | null,
  nombre?: string,
  estado?: any | null
}

const ResultadoBusqueda: React.FunctionComponent<IResultadoBusquedaProps> = (props) => {

  const {
    funcion,
    name,
    data,
    filaSeleccionada,
    pagination,
    dataSearch,
    resetFunction,
    setDataItems,
    onChanged,
    onEdit,
    setToast,
  } = props
  const dispatch = useDispatch();

  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const [items, setItems] = React.useState<Array<any> | []>([])
  const [pageSize, ] = React.useState<number>(10);
  const [pageIndex, setPageIndex] = React.useState<number>(10);

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const onSearch = React.useCallback(async (dataSearch: any) => {
    const findLocales: TFindLocales = {
      ...(dataSearch?.clase?.value && { clase: dataSearch.clase.value }),
      ...(dataSearch?.nombre && { nombre: dataSearch.nombre }),
      ...(dataSearch?.estado?.codigo
        ? { estado: dataSearch.estado.codigo > 0 ? dataSearch.estado.codigo : 1 }
        : { estado: 1 }),
    };

    try {
      playLoader();
      const data = await adminLocales.getAdminLocales(findLocales);
      stopLoader();

      if (data?.error) {
        stopLoader();
        setToast(data?.message, ToastTypes.Danger)
        return;
      }

      if (data.auto?.length) {
        const items: Local[] = data.auto.map(function (item: any): Local {
          return {
            codigo: parseInt(item?.localCodigo ?? 0),
            clase: parseInt(item?.localClase ?? 0),
            nombre: String(item?.localNombre ?? ''),
            siglas: String(item?.localSiglas ?? ''),
            ciudad: {
              codigo: parseInt(item?.localCiudad ?? 0),
              descripcion: String(item?.localCiudadNombre ?? ''),
            },
            direccion: String(item?.localDireccion ?? ''),
            telefono: String(item?.localTelefono ?? ''),
            gerente: String(item?.localGerente ?? ''),
            tipo: {
              value: parseInt(item?.localTipo ?? 0),
              label: String(item?.localTipoDescripcion ?? ''),
            },
            pertenece: {
              pertenece: parseInt(item?.localPertenece ?? 0),
              perteneceDescripcion: String(item?.localPerteneceDescripcion ?? ''),
            },
            kardex: parseInt(item?.localKardex ?? 0),
            estado: {
              codigo: parseInt(item?.localEstado ?? 0),
              descripcion: String(item?.localEstadoDescripcion ?? ''),
            },
            siglasLocal: String(item?.localSiglas ?? ''),
            siglasBodega: String(item?.localSiglasLocal ?? ''),
            web: parseInt(item?.localWEB ?? 0),
            uaf: String(item?.localUAF ?? ''),
            descuento: parseInt(item?.localDescuento ?? 0),
            contrato: parseInt(item?.localContrato ?? 0),
            observaciones: String(item?.localObservaciones ?? ''),
            usuarioDokan: String(item?.localUsuarioDokan ?? ''),
            passwordDokan: String(item?.localPasswordDokan ?? ''),
            uloTipo: parseInt(item?.ULOTipo ?? 0),
            logo: String(item?.localLogo ?? ''),
            listaPrecios: parseInt(item?.localListaPrecios ?? 0),
            establecimiento: String(item?.localEstablecimiento ?? ''),
          }
        });

        setItems(items);
        setDataItems(items);
      }
    } catch (error) {
      stopLoader()
      setToast(JSON.stringify(error), ToastTypes.Danger)
    }

  }, [playLoader, setDataItems, setToast, stopLoader]);

  const selectionChanged = React.useCallback((data: any) => {
    if (data['selectedRowsData'] && data['selectedRowsData'].length > 0) {
      onEdit(data['selectedRowsData'][0]);
    }
  }, [onEdit]);

  const onDelete = React.useCallback(async () => {
    const response: any = await adminLocales.deleteLocal(filaSeleccionada.codigo);

    if (!response) {
      return;
    }

    if (!response.error) {
      setToast(response.message, ToastTypes.Success);
      onSearch(dataSearch);
      onEdit(null);
      return;
    }

    setToast(response.message, ToastTypes.Danger);
  }, [filaSeleccionada, dataSearch, setToast, onSearch, onEdit]);

  const onResetTab = React.useCallback(() => {
    setPageIndex(0)
  }, []);

  React.useEffect(() => {
    if (funcion === ButtonTypes.undo) {
      setItems([])
    } else if (funcion === ButtonTypes.find) {
      onResetTab();
      resetFunction()
      onSearch(dataSearch)
    } else if (funcion === ButtonTypes.delete) {
      onResetTab();
      resetFunction()
      onDelete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion]);

  React.useEffect(() => {
    setItems(data);
  }, [data])

  return (

    <RowContainer className='mt-2 '>
      <CustomCol>
        <DataGrid
          ref={tablaRef}
          keyExpr='codigo'
          dataSource={items ?? []}
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          showBorders={true}
          showRowLines={true}
          allowColumnReordering={true}
          allowColumnResizing
          columnResizingMode='widget'
          columnAutoWidth={true}
          activeStateEnabled={true}
          className='border'
          remoteOperations={true}
          onSelectionChanged={selectionChanged}
          width={'100%'}
        >
          <Paging
            pageIndex={pageIndex}
            enabled={true}
            defaultPageSize={10}
            pageSize={pageSize}
          />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={[8, 12, 20]}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <LoadPanel enabled={true} />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Column type='buttons' width='50px' >
            <DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
              onClick={(e: any) => {
                onChanged(e.row.data)
              }} />
          </Column>
          <Column dataField='nombre' caption='Local' width='200px' allowEditing={false} />
          <Column dataField='tipo.label' caption='Tipo' width='80px' allowEditing={false} />
          <Column dataField='ciudad.descripcion' caption='Ciudad' width='100px' allowEditing={false} />
          <Column dataField='direccion' caption='Dirección' width='400px' allowEditing={false} />
          <Column dataField='telefono' caption='Teléfono ' width='100px' allowEditing={false} />
        </DataGrid>
      </CustomCol>
    </RowContainer>

  );
}
export default ResultadoBusqueda;

