import React from 'react';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../views/componentes/colContainer';
import SelectBox from 'devextreme-react/select-box';
// import { ModalAdminProveedores } from '../modalAdminProveedores/ModalAdminProveedores';
import Button from 'devextreme-react/button';
import { TLocal } from './type/types';
import { LocalService } from './service/local.service';


interface IBuscarLocalLookUpProps extends React.PropsWithChildren {
  selected: TLocal | null,
  onChanged: (newValue: TLocal | null) => void,
  allowAdd?: boolean,
  allowClear?: boolean,
  allowEdit?: boolean,
  disabled?: boolean,
  onEnterKey?: any
}

const localSearchExpresion = ['nombre', 'codigo'];


export const BuscarLocalLookUp: React.FC<IBuscarLocalLookUpProps> = (props) => {
  const { selected, onChanged, disabled, allowAdd, allowClear, allowEdit, onEnterKey } = props;
  const [mostrarEdicion, setMostrarEdicion] = React.useState(false);
  const [vendedorEditando, setVendedorEditando] = React.useState<TLocal | null>(null);
  const selectRef = React.useRef<any>();
  const [entidadLocal, setEntidadLocal] = React.useState<TLocal>();

  const datasource = React.useMemo(() => {
    return LocalService.getLocalesDatasource();
  }, []);


  // const onModificarProveedorChanged = React.useCallback(async (identificacion) => {
  //   setMostrarEdicion(false);
  //   setVendedorEditando(null);
  //   if (selected) {
  //     await datasource.reload();
  //     if (selectRef) {
  //       selectRef.current.instance.repaint();
  //     }
  //   } else {
  //     const nuevo = await VendedoresService.getVendedor(identificacion);
  //     onChanged(nuevo);
  //   }
  //   //onChanged(nuevo)
  // }, [selected, onChanged, datasource]);

  const onModificarProveedorCancel = React.useCallback(() => {
    setVendedorEditando(null);
    setMostrarEdicion(false);
  }, []);

  const getLocalDisplayExpr = React.useCallback((vendedor: TLocal) => {
    return vendedor ? `${vendedor.nombre}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback(async ({ value, previousValue }: any) => {

    console.log("onLookUpValueChanged", value)
    if (typeof (value) == 'string' || value?.codigo === entidadLocal?.codigo) return;

    onChanged(value);
  }, [onChanged, entidadLocal]);



  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])


  const onEditarVendedorCLick = React.useCallback(() => {
    setVendedorEditando(selected);
    setMostrarEdicion(true);
  }, [selected])

  const onCrearVendedorCLick = React.useCallback(() => {
    setVendedorEditando(null);
    setMostrarEdicion(true);
  }, [])

  const onQuitarVendedorClick = React.useCallback(() => {
    onChanged(null);
  }, [onChanged])

  React.useEffect(() => {
    console.log('BuscarLocalLookUp cambio onChanged');
  }, [onChanged])

  React.useEffect(() => {
    setEntidadLocal(selected ?? undefined)
  }, [selected])

  React.useEffect(() => {
    if (selectRef.current) {
      selectRef.current.instance.repaint();
    }
  }, [entidadLocal, datasource])

  if (allowAdd || allowEdit || allowClear) {
    return (
      <>
        {/* <ModalAdminProveedores
          codigoProveedor={proveedorEditando ? proveedorEditando.codigo : null}
          onChanged={onModificarProveedorChanged}
          onCancel={onModificarProveedorCancel}
          show={mostrarEdicion}
          esTransportista={esTransportista ?? false}
        /> */}
        <RowContainer   >
          <CustomCol xs='12' md='9'>
            <SelectBox
              ref={selectRef}
              dataSource={datasource}
              searchEnabled
              displayExpr={getLocalDisplayExpr}
              searchExpr={localSearchExpresion}
              searchTimeout={500}
              value={entidadLocal}
              onValueChanged={onLookUpValueChanged}
              onFocusIn={onSelectBoxFocusIn}
              disabled={disabled}
              onEnterKey={onEnterKey}
            >
              {props.children}
            </SelectBox>
          </CustomCol>
          <CustomCol md='3' xs='6'  >
            <div style={{ display: 'flex' }}>
              {allowEdit && selected &&

                <div style={{ marginLeft: '1px' }} >
                  <Button
                    stylingMode='contained'
                    type='default'
                    icon='edit'
                    onClick={() => {
                      setVendedorEditando(selected);
                      setMostrarEdicion(true);
                    }} >

                  </Button>
                </div>

              }
              {(allowAdd && !disabled) &&

                <div style={{ marginLeft: '1px' }} >
                  <Button
                    stylingMode='contained'
                    type='default'
                    icon='add'
                    onClick={() => {
                      setVendedorEditando(null);
                      setMostrarEdicion(true);
                    }} >

                  </Button>
                </div>

              }
              {!disabled && allowClear && selected &&

                <div style={{ marginLeft: '1px' }} >
                  <Button
                    stylingMode='contained'
                    type='danger'
                    icon='undo'
                    onClick={() => {
                      onChanged(null);
                    }} >
                  </Button>
                </div>

              }
            </div>
          </CustomCol>
        </RowContainer>
      </>
    );
  }


  return (
    <SelectBox
      ref={selectRef}
      dataSource={datasource}
      searchEnabled
      displayExpr={getLocalDisplayExpr}
      searchExpr={localSearchExpresion}
      searchTimeout={500}
      //onEnterKey={(e) => { console.log(e) }}
      value={entidadLocal}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
      onEnterKey={onEnterKey}
    >
      {props.children}
    </SelectBox>
  )
}

