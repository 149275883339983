import React from 'react'
import { QRCodeSVG } from 'qrcode.react'
interface IQrcodeProps extends React.PropsWithChildren {
  text: string
  width?: number
  fontSize?: number
  height?: number
}

const QrCode: React.FunctionComponent<IQrcodeProps> = (props) => {
  const { fontSize, height, width, text } = props
  const canvasRef = React.useRef(null)

  return (
    <div style={{ height: 'auto', margin: '0 auto', width: '100%' }}>
      <QRCodeSVG value={text} />
    </div>
  )
}
export default QrCode
