import { CAlert } from '@coreui/react-pro'
import React from 'react'
import CustomModalDevx from '../../../../../views/componentes/modal/Modal'

interface Props extends React.PropsWithChildren {
  titulo: string
  mensaje: string | string[]
  content?: any
  onOk: () => void
  type?: 'danger' | 'success' | 'warning' | 'info'
}

const VisualizaError = (props: Props) => {
  const [showPopup, setShowPopup] = React.useState(false)

  React.useEffect(() => {
    setShowPopup(true)
  }, [])

  if (props.content) {
    return (
      <CustomModalDevx
        show={showPopup}
        size="sm"
        onClose={() => {
          setShowPopup(false)
          props.onOk()
        }}
        title={props.titulo ? `Acatha - ${props.titulo}` : 'Acatha'}
      >
        {props.content}
      </CustomModalDevx>
    )
  }

  return (
    <CustomModalDevx
      show={showPopup}
      size="sm"
      onClose={() => {
        setShowPopup(false)
        props.onOk()
      }}
      title={props.titulo ? `Acatha - ${props.titulo}` : 'Acatha'}
    >
      <CAlert color={props?.type ?? 'danger'}>{props.mensaje}</CAlert>
    </CustomModalDevx>
  )
}

export default VisualizaError
