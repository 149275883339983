import {combineReducers} from "redux";
import {menuReducer} from "./menu.reducer";
import {tabsReducer} from "./tabs.reducer";
import {dataEditionReducer} from "./edit.reducer";
import {searchReducer} from "./search.reducer";
import {TVendedores} from "./types";

export const ParametrosVendedoresReducer = combineReducers<TVendedores>({
  menu: menuReducer,
  tabs: tabsReducer,
  editData: dataEditionReducer,
  search: searchReducer,
});
