import { RolesUsuariosState } from '../rolesUsuario/store/types'
import { UsuarioState } from '../cuenta/usuario/store/types'
import { DesarrolloState } from '../desarrollador/store/types'
import { AdministracionCuentaState } from '../cuenta/store/types'
import { CertificadoState } from '../backOffice/certificadosDigitales/store/types'
import { TipoRecurrenciasState } from '../backOffice/tipoRecurrencias/store/types'
import { NotificationState } from '../backOffice/notificaciones/types/types'
import { ConfiguracionesState } from '../configuracion/store/types'
import { CatalogosState } from '../catalogos/store/types'
import { TPlanes as PlanesState } from '../planes/store/types'
import { Reducers as PlanesMenuState } from '../planesMenu/store/types'
import { TBotones as BotonesState } from '../botones/store/types'
import { TMenuBotones as MenuBotonesState } from '../menuBotones/store/types'

export type ModuloAdminState = {
  rolesUsuarios: RolesUsuariosState
  usuario: UsuarioState
  desarrollo: DesarrolloState
  cuenta: AdministracionCuentaState
  certificados: CertificadoState
  tipoRecurrencias: TipoRecurrenciasState
  notificaciones: NotificationState
  configuraciones: ConfiguracionesState
  catalagos: CatalogosState
  planes: PlanesState
  planesMenu: PlanesMenuState
  botones: BotonesState
  menuBotones: MenuBotonesState
}

export enum EConfModulos {
  Plan = 'Plan',
  Planes = 'Planes',
  PlanMenu = 'PlanMenu',
  PlanesMenu = 'PlanesMenu',
  Boton = 'Boton',
  Botones = 'Botones',
  MenuBoton = 'MenuBoton',
  MenusBotones = 'MenusBotones',
}

export enum Aplicacion {
  notificaciones = 'notificaciones',
}

export type EdicionPayload<T> = {
  key: number | string
  data: T
}
