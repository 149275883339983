import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  TVendedoresListado,
  TFiltroBusquedaVendedoresState,
} from './types';
import {FETCH_STATUS} from "../../../../../../store/types";
import {VendedoresService} from "../services/vendedores.service";
import {TConfigSearchState} from "../../types/types";
import {RootState} from "../../../../../../store/store";

const initialState: TConfigSearchState<TVendedoresListado> = {
  filter: {
    codigo: 0,
    localCodigo: '',
    usuarioNombres: '',
    usuarioApellidos: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchVendedores = createAsyncThunk<
  TVendedoresListado[],
  TFiltroBusquedaVendedoresState
>('clientes/sellers/getAll', async (filtro) => {
  try {
    return await VendedoresService.getCollection(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
});

const searchConfSlice = createSlice({
  name: 'VendedoresSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaVendedoresState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<TVendedoresListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVendedores.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(fetchVendedores.fulfilled, (state, { payload }: {payload:any}) => {
      state.resultados = payload.auto
      state.status = FETCH_STATUS.SUCCESS
      state.error = payload.error
      state.mensaje = payload.message
    })
    builder.addCase(fetchVendedores.rejected, (state, { payload }: {payload:any}) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.error = payload.error
      state.mensaje = payload.message
    })
  }
});

export const selectFiltroBusquedaVendedores = (state: RootState) => {
  return state.ventas.parametros.vendedores.search.filter
}

export const selectResultadosBusquedaVendedores = (state: RootState) => {
  return state.ventas.parametros.vendedores.search.resultados
}

export const selectEstadoBusquedaVendedores = (state: RootState) => {
  return state.ventas.parametros.vendedores.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
