import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TUsuarioLocalListado,
  TFiltroBusquedaUsuarioLocalState, TInitialState
} from './types';
import {LocalOption} from "../../../../componentes/localesLookUp/store/type";
import {OptionTiposPersonas} from "../../../../componentes/tiposPersonasLookUp/types/types";

const initialState: TInitialState = {
  filter: {
    empresaCodigo: null,
    localCodigo: null,
    usuarioTipo: null,
  },
  tipoUsuario: null,
  local: null,
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  availableUsers: [],
  assignedUsers: [],
  selectedItem: null,
  error: false,
}

const searchConfSlice = createSlice({
  name: 'UsuarioLocalSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaUsuarioLocalState>) {
      state.filter = action.payload
    },
    setCleanAvailableUsers(state) {
      state.availableUsers = []
    },
    setCleanAssignedUsers(state) {
      state.assignedUsers = []
    },
    setAvailableUsers(state, action: PayloadAction<Array<TUsuarioLocalListado> | []>) {
      state.availableUsers = action.payload
    },
    setAssignedUsers(state, action: PayloadAction<Array<TUsuarioLocalListado> | []>) {
      state.assignedUsers = action.payload
    },
    updateLocal(state, action: PayloadAction<LocalOption>) {
      state.local = action.payload
    },
    updateTipoUsuario(state, action: PayloadAction<OptionTiposPersonas>) {
      state.tipoUsuario = action.payload
    },
  },
});

export const {
  resetState,
  changeFilter,
  setCleanAvailableUsers,
  setCleanAssignedUsers,
  setAvailableUsers,
  setAssignedUsers,
  updateLocal,
  updateTipoUsuario,
} = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
