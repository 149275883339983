import React, { useMemo } from 'react'
import { Cliente } from '../../types/types'
import { ClientesService } from '../../services/clientes.service'
import { CTooltip } from '@coreui/react-pro'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../views/componentes/colContainer'
import ModificarCliente from '../clientes/modificarCliente'
import config from '../../../../config/config'
import SelectBox from 'devextreme-react/select-box'
import Button from 'devextreme-react/button'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import { ECountry } from '../../../../store/enum/countries'
import ModificarClienteSV from '../clientes/modificarClienteSV'
import { TiposComprobantesSLV } from '../../../../store/types'
import { DatosSucursales } from '../../../clientes/pages/clientes/types/types'
import CSucursalesListado from './../clientes/sucursales/sucursalesListado'

interface IBuscarClienteLookUpProps extends React.PropsWithChildren {
  selected: Cliente | null
  onChanged: (newValue: Cliente | null) => void
  onSucursalChanged?: (newValue: DatosSucursales | null) => void
  allowEdit?: boolean
  allowClear?: boolean
  allowAdd?: boolean
  disabled?: boolean
  children?: React.ReactNode
  onEnterKey?: any
  tipoComrpobante?: TiposComprobantesSLV
  allowSucursal?: boolean
}

const ClienteSearchExpresion = ['nombres', 'identificacion']

const BuscarClienteLookUp: React.FC<IBuscarClienteLookUpProps> = (props) => {
  const {
    selected,
    onChanged,
    allowAdd,
    allowClear,
    allowEdit,
    disabled,
    onEnterKey,
    tipoComrpobante,
    allowSucursal,
    onSucursalChanged,
  } = props
  const [clienteLocal, setClienteLocal] = React.useState<Cliente>()
  const [mostrarEdicion, setMostrarEdicion] = React.useState(false)
  const [mostrarSucursales, setMostrarSucursales] = React.useState(false)
  const [clienteEditando, setClienteEditando] = React.useState<Cliente | null>(
    null,
  )
  const selectRef = React.useRef<any>()

  const empresa = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )

  const datasource = React.useMemo(() => {
    return ClientesService.getClientesDatasource()
  }, [])

  const onLookUpValueChanged = React.useCallback(
    async (value) => {
      if (typeof value == 'string' || value?.codigo === clienteLocal?.codigo)
        return
      setClienteLocal(value)
      //const cliente = await  datasource.store().byKey(value);
      await onChanged(value)
    },
    [onChanged, clienteLocal],
  )

  const onModificarClienteChanged = React.useCallback(
    async (cliente: Cliente, isNew) => {
      setMostrarEdicion(false)
      setClienteEditando(null)
      if (isNew) {
        await onChanged(cliente)
        return
      }
      if (selected && selected.codigo === cliente.codigo) {
        await datasource.reload()
        if (selectRef) {
          //const nuevo = await ClientesService.getCliente(cliente);
          await onChanged(cliente)
          //onChanged()
          //selectRef.current.instance.repaint();
        }
      } else {
        //const nuevo = await ClientesService.getCliente(cliente);
        await onChanged(cliente)
      }
      //onChanged(identificacion);
    },
    [selected, datasource, onChanged],
  )
  const onModificarClienteCancel = React.useCallback(() => {
    setClienteEditando(null)
    setMostrarEdicion(false)
  }, [])

  const modificarCliente = useMemo(() => {
    if (empresa?.codigoPais === ECountry.ElSalvador) {
      return (
        <ModificarClienteSV
          tipoComprobante={tipoComrpobante}
          identificacion={clienteEditando?.identificacion ?? ''}
          show={mostrarEdicion}
          onChanged={onModificarClienteChanged}
          onCancel={onModificarClienteCancel}
        />
      )
    } else {
      return (
        <ModificarCliente
          identificacion={clienteEditando?.identificacion ?? ''}
          show={mostrarEdicion}
          onChanged={onModificarClienteChanged}
          onCancel={onModificarClienteCancel}
        />
      )
    }
  }, [
    empresa?.codigoPais,
    tipoComrpobante,
    clienteEditando?.identificacion,
    mostrarEdicion,
    onModificarClienteChanged,
    onModificarClienteCancel,
  ])

  const getClienteDisplayExpr = React.useCallback((cliente: Cliente) => {
    return cliente ? `${cliente.identificacion} - ${cliente.nombres}` : ''
  }, [])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const onEditarClienteCLick = React.useCallback(() => {
    setClienteEditando(selected)
    setMostrarEdicion(true)
  }, [selected])

  const onCrearClienteCLick = React.useCallback(() => {
    setClienteEditando(null)
    setMostrarEdicion(true)
  }, [])

  const onQuitarClienteClick = React.useCallback(() => {
    onChanged(null)
  }, [onChanged])

  const onChangeSuc = React.useCallback(() => {
    setMostrarSucursales(true)
  }, [])

  React.useEffect(() => {
    console.log('BuscarClienteLookUp cambio onChanged')
  }, [onChanged])

  React.useEffect(() => {
    setClienteLocal(selected ?? undefined)
  }, [selected])

  React.useEffect(() => {
    if (selectRef.current) {
      selectRef.current.instance.repaint()
    }
  }, [clienteLocal, datasource])

  if (allowAdd || allowEdit || allowClear) {
    return (
      <>
        {modificarCliente}
        {mostrarSucursales && (
          <CSucursalesListado
            clienteCodigo={selected.codigo}
            show={mostrarSucursales}
            onChange={(d) => {
              onSucursalChanged(d)
              setMostrarSucursales(false)
            }}
            onCancel={() => setMostrarSucursales(false)}
          />
        )}
        <RowContainer>
          <CustomCol xs="12" md="9" className="pe-0">
            <SelectBox
              ref={selectRef}
              dataSource={datasource}
              searchEnabled
              displayExpr={getClienteDisplayExpr}
              searchExpr={ClienteSearchExpresion}
              searchTimeout={500}
              value={clienteLocal}
              onValueChange={onLookUpValueChanged}
              onFocusIn={onSelectBoxFocusIn}
              disabled={disabled}
              onEnterKey={onEnterKey}
              width={'100%'}
            >
              {props.children}
            </SelectBox>
          </CustomCol>
          <CustomCol md="3" xs="6" className="m-0 p-0">
            <div className="d-flex flex-row align-items-start m-0 p-0">
              {allowEdit &&
                selected &&
                selected.identificacion !== config.rucConsumidorFinal && (
                  <CTooltip content="Editar Cliente">
                    <div style={{ marginLeft: '1px' }}>
                      <Button
                        stylingMode="contained"
                        type="default"
                        icon="edit"
                        onClick={onEditarClienteCLick}
                      />
                    </div>
                  </CTooltip>
                )}
              {!disabled && allowAdd && (
                <CTooltip content="Crear Cliente">
                  <div style={{ marginLeft: '1px' }}>
                    <Button
                      stylingMode="contained"
                      type="default"
                      icon="add"
                      onClick={onCrearClienteCLick}
                      elementAttr={{
                        id: 'btnAddClient',
                      }}
                    />
                  </div>
                </CTooltip>
              )}
              {!disabled && allowClear && selected && (
                <CTooltip content="Quitar cliente seleccionado">
                  <div style={{ marginLeft: '1px' }}>
                    <Button
                      stylingMode="contained"
                      type="danger"
                      icon="undo"
                      onClick={onQuitarClienteClick}
                    />
                  </div>
                </CTooltip>
              )}
              {!disabled && allowSucursal && (
                <CTooltip content="Cambiar sucursal">
                  <div style={{ marginLeft: '1px' }}>
                    <Button
                      stylingMode="contained"
                      type="normal"
                      icon="more"
                      onClick={onChangeSuc}
                    />
                  </div>
                </CTooltip>
              )}
            </div>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  return (
    <SelectBox
      ref={selectRef}
      dataSource={datasource}
      searchEnabled
      displayExpr={getClienteDisplayExpr}
      searchExpr={ClienteSearchExpresion}
      searchTimeout={500}
      value={clienteLocal}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
      onEnterKey={onEnterKey}
    >
      {props.children}
    </SelectBox>
  )
}

export default React.memo(BuscarClienteLookUp)
