import { RequestHelper } from "../../../../../helpers/requestHelper";
import { SesionService } from "../../../../../services/sesion.service";
import { TLocal } from "../type/types";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { PagedResult, PaginationInfo } from "../../../../../store/types";

const optionDefault = 'Elije un local o cc';

export const LocalService = {
  getLocales, getLocalesDatasource, getLocalByCode
}

function getLocalesDatasource() {
  const regex = new RegExp("^[0-9]{3,13}$");
  const customCache: any = {};

  const store = new CustomStore({
    key: "codigo",
    byKey: async (key) => {

      if (customCache[key]) {
        return customCache[key];
      } else {
        const vendedor = await getLocalByCode(key);
        customCache[key] = vendedor;
        return vendedor;
      }

    },
    load: async (loadOptions) => {
      console.log("loadOptions", loadOptions);

      if (loadOptions.filter && loadOptions.filter.length === 3) {
        //Si hay filtro devolvemos null y ya
        const filter = loadOptions.filter;
        if (filter[0] === "codigo") {
          //Recuperar el vendedor por codigo
          if (filter[2] === 0) return {
            data: [],
            totalCount: 0,
          }; //no existe el vendedor con codigo 0
          const vendedor = await getLocalByCode(filter[2])
          return {
            data: vendedor,
            totalCount: 1,
          };
        }
        return {
          data: [],
          totalCount: 0,
        };
      }

      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<TLocal>;
      if (regex.test(loadOptions.searchValue)) {
        pagedResult = await consultarLOcalesPaged(
          "",
          loadOptions.searchValue.toString(),
          paginInfo
        );
      } else {
        pagedResult = await consultarLOcalesPaged(
          loadOptions.searchValue,
          "",
          paginInfo
        );
      }
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,


    store: store,
    key: "codigo",
  });
  return source;
}

async function consultarLOcalesPaged(
  nombre: string,
  identificacion: string,
  paginInfo: PaginationInfo
): Promise<PagedResult<TLocal>> {

  return consultarLocalesPagedApiPhp(
    nombre,
    identificacion,
    paginInfo
  );

}

async function consultarLocalesPagedApiPhp(
  nombre: string,
  identificacion: string,
  pagination: PaginationInfo
): Promise<PagedResult<TLocal>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    codigoempresa: sesion.empresa.codigo,
    page: pagination.current,
    limit: pagination.size,
    isPaged: true,
  };

  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion;
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre;
  }

  query.codigoLocal = sesion.local.codigo ?? -1;
  query.mensaje = optionDefault;

  const pagedApiResult = await RequestHelper.get<any>(
    "local",
    "cboxLoad",
    "",
    query
  );
  const proveedoresApi = parseApiItemsData(pagedApiResult.auto);

  const pagedResult: PagedResult<TLocal> = {
    count: pagedApiResult.totalRegistros,
    currentPage: pagedApiResult.paginaActual,
    from: pagedApiResult.desde,
    result: proveedoresApi,
    to: pagedApiResult.hasta,
  };
  return pagedResult;
}

async function getLocales(
  nombre: string,
  identificacion: string
): Promise<Array<TLocal>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    codigoempresa: sesion.empresa.codigo,
    mensaje: optionDefault
  };

  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion;
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre;
  }
  const proveedoresApi = await RequestHelper.get<Array<TLocal>>(
    "local",
    "cboxLoad",
    "",
    query
  );
  return proveedoresApi;
}
async function getLocalByCode(
  codigo: string
): Promise<TLocal | null> {
  const query: any = {
    codigo: codigo,
  };
  const proveedor = await RequestHelper.get<TLocal>(
    "inventario/multialmacen",
    "local/get",
    "",
    query
  );
  return proveedor;
}

function parseApiItemsData(apiresult: Array<any>): Array<TLocal> {
  const items = apiresult.map(x => {
    const item: TLocal = {
      codigo: parseInt(x.localCodigo) ?? 0,//  LOC_CODIGO;
      ciudad: parseInt(x.localCiudad) ?? 0,//  LOC_CIUDAD;
      ciudadNombre: x.localCiudadNombre ?? "", // LOC_CIUDAD_NOM;
      nombre: x.localNombre ?? "", // LOC_NOMBRE;
      direccion: x.localDireccion ?? "", // LOC_DIRECCION;
      telefono: x.localTelefono ?? "", // LOC_TELEFONO;
      gerente: x.localGerente ?? "", // LOC_GERENTE;
      tipo: parseInt(x.localTipo) ?? 0,//  LOC_TIPO;
      tipoDescripcion: x.localTipoDescripcion ?? "", // LOC_TIPO_DES;
      clase: parseInt(x.localClase) ?? 0,//  LOC_CLASE;
      claseDescripcion: x.localClaseDescripcion ?? "", // LOC_CLASE_DES;
      pertenece: parseInt(x.localPertenece) ?? -1,//  LOC_PERTENECE;
      perteneceDescripcion: x.localPerteneceDescripcion ?? "", // LOC_PERTENECE_DES;
      estado: parseInt(x.Estado) ?? 0,//  LOC_ESTADO;
      estadoDescripcion: x.localEstadoDescripcion ?? "", // LOC_ESTADO_DES;
      kardex: parseInt(x.localKardex) ?? 0,//  LOC_KARDEX;
      siglas: x.localSiglas ?? "", // LOC_SIGLAS;
      siglasLocal: x.localSiglasLocal ?? "", // LOC_SIGLASBOD;
      wEB: parseInt(x.localWEB) ?? 0,//  LOC_WEB;
      uAF: x.localUAF ?? "", // LOC_UAF;
      descuento: parseInt(x.localDescuento) ?? 0, // LOC_DESCUENTO;
      contrato: parseInt(x.localContrato) ?? 0, // LOC_CONTRATO;
      observaciones: x.localObservaciones ?? "", // LOC_OBSERVACIONES;
      usuarioDokan: x.localUsuarioDokan ?? "", // LOC_USUARIODOKAN;
      passwordDokan: x.localPasswordDokan ?? "", // LOC_PASSWORDDOKAN;
      uLOTipo: x.ULOTipo ?? "", // ULO_TIPO;
    };
    return item;
  });
  return items;
}
