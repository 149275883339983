/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CAlert, CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import { ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'

import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../../../store/genericTabTypes'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../../../store/types'
import { RootState } from '../../../../../../store/store'
import {
  TVendedoresDatosEdicion,
  TVendedoresData,
} from '../store/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { PopupContent } from '../../../../../../views/componentes/popupContent'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../store/tabs.reducer'
import { StatesEdition } from '../../../../../../store/enums'
import { setDatosEdicion } from '../store/edit.reducer'
import InputTextDE from '../../../../../../views/componentes/inputText-DE/inputText-DE'
import { VendedoresService } from '../services/vendedores.service'
import LocalesLookUp from "../../../../../componentes/localesLookUp";
import NumberBox from "devextreme-react/number-box";
import TipoEstadoLookUp from "../../../../../componentes/tipoEstado";
import {defaultDatosEdicionVendedores} from "../store/dataEdicion";

interface IMantenimientoProps extends React.PropsWithChildren {
  info: DocumentInfo<TVendedoresData>
  tabId: string
  tab: TabState<TVendedoresData>
}

export type TAtributos = {
  valor: string
  atributo: string
  idAtributo: number
  idAtributoValor: number
  nueva: boolean
}

const MantenimientoRegimen: React.FunctionComponent<IMantenimientoProps> = (
  props,
) => {
  const { info, tabId, tab } = props

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()

  const localState = useSelector(
    (state: RootState) => state?.global?.session?.local,
  )
  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const usuarioState = useSelector(
    (state: RootState) => state.global.session?.usuario,
  )
  const ingresoState = useSelector(
    (state: RootState) => state.global.session?.ingreso,
  )
  const tabs = useSelector(
    (state: RootState) => state.ventas.parametros.vendedores.tabs,
  )
  const dataEdicion = useSelector(
    (state: RootState) =>
      state.ventas.parametros.vendedores.editData[tabId],
  )
  const loading = useSelector(
    (state: RootState) =>
      state.ventas.parametros.vendedores.editData[tabId].loading,
  )

  const dispatch = useDispatch()

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loader, setLoader] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })
  const [datosEdicion2, setDatosEdicion2] = React.useState<any>([])

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      let time: number = 3000
      if (texto.length < 50) {
        time = 5000
      } else if (texto.length > 50) {
        time = 9000
      }
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const parseVendedorData = React.useCallback(
    async (response: any): Promise<TVendedoresDatosEdicion> => {
      return {
        edition: false,
        codigo: response?.codigo ?? 0,
        usuarioCodigo: response?.usuarioCodigo ?? 0,
        comision: response?.comision ?? 0,
        estadoCodigo: {
          codigo: parseInt(response?.estadoCodigo)
        } ?? null,
        localCodigo: {
          codigo: parseInt(response?.localCodigo)
        } ?? null,
        siglas: response?.siglas ?? "",
        usuarioApellidos: response?.usuarioApellidos ?? "",
        usuarioEmail: response?.usuarioEmail ?? "",
        usuarioIdentificacion: response?.usuarioIdentificacion ?? "",
        usuarioNombres: response?.usuarioNombres ?? "",
        usuarioTelefono: response?.usuarioTelefono ?? "",
        zona: response?.zona ?? "",
        loader: {
          show: false,
          mensaje: '',
        },
        loading: false,
        tieneError: false,
        mensajeError: ''
      }
    },
    [],
  )

  const parseApiVendedorData = React.useCallback(
    async (response: any): Promise<TVendedoresDatosEdicion> => {
      return {
        edition: false,
        codigo: response?.vendedorCodigo ?? 0,
        usuarioCodigo: response?.usuarioCodigo ?? 0,
        comision: response?.comision ?? 0,
        estadoCodigo: {
          codigo: parseInt(response?.estado)
        } ?? null,
        localCodigo: {
          codigo: parseInt(response?.localCodigo)
        } ?? null,
        siglas: response?.siglas ?? "",
        usuarioApellidos: response?.usuarioApellidos ?? "",
        usuarioEmail: response?.usuarioEmail ?? "",
        usuarioIdentificacion: response?.usuarioIdentificacion ?? "",
        usuarioNombres: response?.usuarioNombres ?? "",
        usuarioTelefono: response?.usuarioTelefono ?? "",
        zona: response?.vendedorZona ?? "",
        loader: {
          show: false,
          mensaje: '',
        },
        loading: false,
        tieneError: false,
        mensajeError: ''
      }
    },
    [],
  )

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      const data = { ...defaultEditionRecintoFiscal }
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...data },
        }),
      )
    },
    [dispatch, tabId],
  )

  const modoEdicion = React.useCallback(
    async (reload: boolean = false, dataEdicion: TVendedoresData) => {
      setLoader({ show: true, message: 'Espere, por favor...' })

      try {
        let dataApi: any = []
        let infoParse: TVendedoresDatosEdicion = {
          ...defaultDatosEdicionVendedores
        }

        infoParse.edition = false

        dataApi = dataEdicion

        infoParse = await parseVendedorData(dataEdicion)

        if (reload) {
          dataApi = await VendedoresService.getResource(
            dataEdicion?.codigo ?? 0,
          )

          infoParse = await parseApiVendedorData(dataApi)
        }

        setDatosEdicion2(infoParse)

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...infoParse },
          }),
        )

        setLoader({ show: false, message: '' })
      } catch (error) {
        setLoader({ show: false, message: JSON.stringify(error) })
      }
    },
    [dispatch, parseVendedorData, parseApiVendedorData],
  )

  const playLoader = React.useCallback(async () => {
    setLoader({ show: true, message: 'Espere, por favor...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoader({ show: false, message: '' })
  }, [])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px', margin: '0px', padding: '0px' }}>
            {tittle}
          </strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...dataEdicion,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, dataEdicion, tabId],
  )

  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()

    playLoader()

    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)
      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)
      stopLoader()
      onSetButtonAction(undefined)
      return
    }

    setShowErrorPopup(false)
    setShowErrorMessages([])
    onSetButtonAction(ButtonTypes.save)

    const bodyRequest: any = {
      codigo: dataEdicion?.codigo ?? 0,
      usuarioCodigo: dataEdicion?.usuarioCodigo ?? 0,
      comision: dataEdicion?.comision ?? 0,
      estadoCodigo: dataEdicion?.estadoCodigo.codigo ?? 0,
      localCodigo: dataEdicion?.localCodigo.codigo ?? 0,
      siglas: dataEdicion?.siglas ?? "",
      usuarioApellidos: dataEdicion?.usuarioApellidos ?? "",
      usuarioEmail: dataEdicion?.usuarioEmail ?? "",
      usuarioIdentificacion: dataEdicion?.usuarioIdentificacion ?? "",
      usuarioNombres: dataEdicion?.usuarioNombres ?? "",
      usuarioTelefono: dataEdicion?.usuarioTelefono ?? "",
      zona: dataEdicion?.zona ?? "",
    }

    try {
      const data = await VendedoresService.postResource(bodyRequest)

      stopLoader()

      if (data['error'] === true) {
        setToast(data['message'], ToastTypes.Warning)
        stopLoader()
        onSetButtonAction(undefined)
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EVendedoresButtons,
              Guardar: true,
              Imprimir: true,
            },
          }),
        )
        return
      }

      if (data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        stopLoader()
        onSetButtonAction(undefined)
      }

      setDatosEdicion2(bodyRequest)
      if (bodyRequest.codigo === 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EVendedoresButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      if (bodyRequest.codigo !== 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EVendedoresButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      dispatch(
        setNameTab({
          key: tabId,
          nombre: bodyRequest?.usuarioNombres ?? '',
          codigo: data?.auto?.vendedorCodigo ?? 0,
        }),
      )
    } catch (error) {
      onSetButtonAction(undefined)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...EVendedoresButtons,
            Guardar: true,
            Imprimir: true,
          },
        }),
      )
      stopLoader()
      setToast(error, ToastTypes.Danger)
    }
  }, [
    dataEdicion,
    playLoader,
    setToast,
    stopLoader,
    dispatch,
    onErrorConfirm,
    modoEdicion,
    empresaState,
    ingresoState,
  ])

  const handleButtonClick = React.useCallback(
    async (buttonAction: ButtonTypes) => {
      //let ciudaddselect=null;
      switch (buttonAction) {
        case ButtonTypes.undo:
          // eslint-disable-next-line no-case-declarations
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: { ...datosEdicion2 },
            }),
          )
          break
        case ButtonTypes.save:
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          if (tabs.current === tabId) handleSubmit()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [tabId, tabs, info, modoNuevo, dispatch, modoEdicion, onSetButtonAction],
  )

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(true, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <div>
        <div>
          <Dialog ref={dialogRef} />
        </div>
        {showErrorPopup && (
          <PopupContent
            show={showErrorPopup}
            title={'Acatha'}
            subTitle={'Por favor revise las siguientes advertencias:'}
            onClose={() => {
              setShowErrorPopup(false)
              setShowErrorMessages([])
            }}
            size={'sm'}
            canClose={true}
            height={'auto'}
          >
            <CAlert color="danger">
              <ul style={{ listStyle: 'none', margin: '0px', padding: '0px' }}>
                {showErrorMessages.map(function (item, id) {
                  return <li key={id}>{item['message']}</li>
                })}
              </ul>
            </CAlert>
          </PopupContent>
        )}

        <div id="configLocal">
          <BlockUi
            tag="div"
            loader={LoadingIndicator}
            blocking={loader?.show ?? false}
            message={loader?.message ?? ''}
          >
            <fieldset disabled={loader.show}>
              <ValidationGroup ref={validationGroupRef} className="">
                <CCard style={{ border: 1 }} className="m-0">
                  <CCardBody>
                    <ValidationSummary className="mb-2" />

                    <CRow>
                      <CCol md="6">
                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Email(*):'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <InputTextDE
                                    value={dataEdicion?.usuarioEmail ?? ''}
                                    placeholder=""
                                    onValueChange={(e) => {
                                      onChangeValue(e, 'usuarioEmail')
                                    }}
                                    showClearButton={true}
                                    width="100%"
                                  >
                                    <Validator>
                                      <RequiredRule message="- Email: Este campo es requerido" />
                                      <StringLengthRule
                                        min="5"
                                        message="- Email: Este campo debe tener al menos 5 caracteres"
                                      />
                                      <StringLengthRule
                                        max="250"
                                        message="- Email: Este campo no puede tener mas de 250 caracteres"
                                      />
                                    </Validator>
                                  </InputTextDE>
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Nombres(*):'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <InputTextDE
                                    value={dataEdicion?.usuarioNombres ?? ''}
                                    placeholder=""
                                    onValueChange={(e) => {
                                      onChangeValue(e, 'usuarioNombres')
                                    }}
                                    showClearButton={true}
                                    width="100%"
                                  >
                                    <Validator>
                                      <RequiredRule message="- Nombres: Este campo es requerido" />
                                      <StringLengthRule
                                        min="1"
                                        message="- Nombres: Este campo debe tener al menos 1 caracteres"
                                      />
                                      <StringLengthRule
                                        max="250"
                                        message="- Nombres: Este campo no puede tener mas de 250 caracteres"
                                      />
                                    </Validator>
                                  </InputTextDE>
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Apellidos(*):'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <InputTextDE
                                    value={dataEdicion?.usuarioApellidos ?? ''}
                                    placeholder=""
                                    onValueChange={(e) => {
                                      onChangeValue(e, 'usuarioApellidos')
                                    }}
                                    showClearButton={true}
                                    width="100%"
                                  >
                                    <Validator>
                                      <RequiredRule message="- Apellidos: Este campo es requerido" />
                                      <StringLengthRule
                                        min="1"
                                        message="- Apellidos: Este campo debe tener al menos 1 caracteres"
                                      />
                                      <StringLengthRule
                                        max="250"
                                        message="- Apellidos: Este campo no puede tener mas de 250 caracteres"
                                      />
                                    </Validator>
                                  </InputTextDE>
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Identificación(*):'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <InputTextDE
                                    value={dataEdicion?.usuarioIdentificacion ?? ''}
                                    placeholder=""
                                    onValueChange={(e) => {
                                      onChangeValue(e, 'usuarioIdentificacion')
                                    }}
                                    showClearButton={true}
                                    width="100%"
                                  >
                                    <Validator>
                                      <RequiredRule message="- Identificación: Este campo es requerido" />
                                      <StringLengthRule
                                        min="1"
                                        message="- Identificación: Este campo debe tener al menos 1 caracteres"
                                      />
                                      <StringLengthRule
                                        max="250"
                                        message="- Identificación: Este campo no puede tener mas de 250 caracteres"
                                      />
                                    </Validator>
                                  </InputTextDE>
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Teléfono(*):'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <InputTextDE
                                    value={dataEdicion?.usuarioTelefono ?? ''}
                                    placeholder=""
                                    onValueChange={(e) => {
                                      onChangeValue(e, 'usuarioTelefono')
                                    }}
                                    showClearButton={true}
                                    width="100%"
                                  >
                                    <Validator>
                                      <RequiredRule message="- Teléfono: Este campo es requerido" />
                                      <StringLengthRule
                                        min="1"
                                        message="- Teléfono: Este campo debe tener al menos 1 caracteres"
                                      />
                                      <StringLengthRule
                                        max="250"
                                        message="- Teléfono: Este campo no puede tener mas de 250 caracteres"
                                      />
                                    </Validator>
                                  </InputTextDE>
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>
                      </CCol>

                      <CCol md="6">
                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Locales:'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <LocalesLookUp
                                    selected={dataEdicion?.localCodigo ?? null}
                                    onChanged={(data) => {
                                      onChangeValue(data, 'localCodigo')
                                    }}
                                    provider={[]}
                                  />
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'% de Comision:'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <NumberBox
                                    value={dataEdicion?.comision ?? 0}
                                    onValueChange={(e) => {
                                      onChangeValue(e, 'comision')
                                    }}
                                    showClearButton={true}
                                    width="100%"
                                  />
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Zona:'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <InputTextDE
                                    value={dataEdicion?.zona ?? ''}
                                    placeholder=""
                                    onValueChange={(e) => {
                                      onChangeValue(e, 'zona')
                                    }}
                                    showClearButton={true}
                                    width="100%"
                                  />
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Siglas:'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <InputTextDE
                                    value={dataEdicion?.siglas ?? ''}
                                    placeholder=""
                                    onValueChange={(e) => {
                                      onChangeValue(e, 'siglas')
                                    }}
                                    showClearButton={true}
                                    width="100%"
                                  />
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol className=" d-flex " md="12">
                            <div className="dx-field" style={{ width: '100%' }}>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <div className="dx-field-label" style={{ width: '100%' }}>
                                    {'Estado:'}
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol lg="12" md="12" sm="12">
                                  <TipoEstadoLookUp
                                    provider={[]}
                                    selected={dataEdicion?.estadoCodigo ?? null}
                                    onChanged={(e) => {
                                      if (e !== null) {
                                        onChangeValue(e, 'estadoCodigo')
                                      }
                                    }}
                                  >
                                    <Validator>
                                      <RequiredRule message="- Estado: Este campo es requerido" />
                                    </Validator>
                                  </TipoEstadoLookUp>
                                </CCol>
                              </CRow>
                            </div>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </ValidationGroup>
            </fieldset>
          </BlockUi>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MantenimientoRegimen)

export const EVendedoresButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
  Eliminar: false,
}

export const defaultEditionRecintoFiscal: TVendedoresDatosEdicion = {
  codigo: 0,
  comision: 0,
  estadoCodigo: null,
  localCodigo: null,
  siglas: "",
  usuarioApellidos: "",
  usuarioCodigo: 0,
  usuarioEmail: "",
  usuarioIdentificacion: "",
  usuarioNombres: "",
  usuarioTelefono: "",
  zona: "",
  edition: false,
  loader: null,
  loading: false,
  tieneError: false,
  mensajeError: '',
}
