import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo, Puntoventa } from '../../../../../../../store/types'
import { CustomDictionary } from '../../../../../types/generics'
import {
  ccDetalle,
  CuadresCajaDatosEdicion,
  CuadresCajaDatosEdicionPayload,
  ComprobantesCuadresCajaListado,
  formasPagoCuadre,
  LocalOption,
  AnticiposCuadresCajaListado,
  AbonosCuadresCajaListado,
  ComprasCuadresCajaListado,
  AbonoComprasCuadresCajaListado,
  ResumenListado,
  ChequesCuadresCajaListado,
  VendedoresListado,
  LetrasCuadresCajaListado,
  BovedaCuadresCajaListado,
  IngresosCuadresCajaListado,
  DenominacionListado,
  TCuadreDocumento,
  RangoInicialFinal,
  formasPago,
  TObjBilletes,
  TObjMonedas,
} from '../../../types/types'
import { cuadresClearDatosEdicion } from '../../../store/reducers'

const initialState: CustomDictionary<CuadresCajaDatosEdicion> = {}

const datosEdicionCuadreCajaSlice = createSlice({
  name: 'cuadresCajaDatosEdicion',
  initialState: initialState,
  reducers: {
    cuadresClearDatosEdicion,
    setFechaRetencion(
      state,
      action: PayloadAction<{ key: number | string; fecha: string }>,
    ) {
      state[action.payload.key].fechaCierre = action.payload.fecha
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: number | string
        data: CuadresCajaDatosEdicion
      }>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<
        CuadresCajaDatosEdicionPayload<CuadresCajaDatosEdicion>
      >,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    updatePuntoVentaOnly(
      state,
      action: PayloadAction<{ key: number | string; puntoventa: Puntoventa }>,
    ) {
      state[action.payload.key].puntoVenta = action.payload.puntoventa
    },
    updateHoraCierreOnly(
      state,
      action: PayloadAction<{ key: number | string; horaCierre: number }>,
    ) {
      state[action.payload.key].horaCierre = action.payload.horaCierre
    },
    updateMinutoCierreOnly(
      state,
      action: PayloadAction<{ key: number | string; minutoCierre: number }>,
    ) {
      state[action.payload.key].minutoCierre = action.payload.minutoCierre
    },
    updateFechaCierreOnly(
      state,
      action: PayloadAction<{ key: number | string; fechaCierre: string }>,
    ) {
      state[action.payload.key].fechaCierre = action.payload.fechaCierre
    },
    updateLocalOnly(
      state,
      action: PayloadAction<{ key: number | string; local: LocalOption }>,
    ) {
      state[action.payload.key].local = action.payload.local
    },
    update1VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial1 = action.payload.rango.inicial
      state[action.payload.key].venFinal1 = action.payload.rango.final
    },
    update2VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial2 = action.payload.rango.inicial
      state[action.payload.key].venFinal2 = action.payload.rango.final
    },
    update3VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial3 = action.payload.rango.inicial
      state[action.payload.key].venFinal3 = action.payload.rango.final
    },
    update4VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial4 = action.payload.rango.inicial
      state[action.payload.key].venFinal4 = action.payload.rango.final
    },
    update5VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial5 = action.payload.rango.inicial
      state[action.payload.key].venFinal5 = action.payload.rango.final
    },
    update7VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial7 = action.payload.rango.inicial
      state[action.payload.key].venFinal7 = action.payload.rango.final
    },
    update8VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial8 = action.payload.rango.inicial
      state[action.payload.key].venFinal8 = action.payload.rango.final
    },
    update9VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial9 = action.payload.rango.inicial
      state[action.payload.key].venFinal9 = action.payload.rango.final
    },
    update10VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial10 = action.payload.rango.inicial
      state[action.payload.key].venFinal10 = action.payload.rango.final
    },
    update11VenInicialFinal(
      state,
      action: PayloadAction<{ key: number | string; rango: RangoInicialFinal }>,
    ) {
      state[action.payload.key].venInicial11 = action.payload.rango.inicial
      state[action.payload.key].venFinal11 = action.payload.rango.final
    },
    updateTotalinicialb_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalinicialb_ti: number }>,
    ) {
      state[action.payload.key].totalinicialb_ti =
        action.payload.totalinicialb_ti
    },
    updateTotalinicial_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalinicial_ti: number }>,
    ) {
      state[action.payload.key].totalinicial_ti = action.payload.totalinicial_ti
    },
    updateTotalesaldosi_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalesaldosi_ti: number }>,
    ) {
      state[action.payload.key].totalesaldosi_ti =
        action.payload.totalesaldosi_ti
    },
    updateCcaCod1Only(
      state,
      action: PayloadAction<{ key: number | string; ccaCod1: number }>,
    ) {
      state[action.payload.key].ccaCod1 = action.payload.ccaCod1
    },
    updateGenerarCuadreOnly(
      state,
      action: PayloadAction<{ key: number | string; generarCuadre: boolean }>,
    ) {
      state[action.payload.key].generarCuadre = action.payload.generarCuadre
    },
    updateFacturasCuadreOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre1: Array<ComprobantesCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre1 = action.payload.dpcierre1
    },
    updateFormasPagoCuadreCuadreOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales1: Array<formasPagoCuadre> | []
      }>,
    ) {
      state[action.payload.key].dptotales1 = action.payload.dptotales1
    },
    updateAnuladasCierre1Only(
      state,
      action: PayloadAction<{
        key: number | string
        anuladascierre1_ti: number
      }>,
    ) {
      state[action.payload.key].anuladascierre1_ti =
        action.payload.anuladascierre1_ti
    },
    updateTotal1_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; total1_ti: number }>,
    ) {
      state[action.payload.key].total1_ti = action.payload.total1_ti
    },
    updateDptotalesOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotales = action.payload.dptotales
    },
    updateDptotalesFacturasOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalesFacturas: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotalesFacturas =
        action.payload.dptotalesFacturas
    },
    updateDptotalesAnticiposOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalesAnticipos: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotalesAnticipos =
        action.payload.dptotalesAnticipos
    },
    updateDptotalesCobrosCarteraOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalesCobrosCartera: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotalesCobrosCartera =
        action.payload.dptotalesCobrosCartera
    },
    updateDptotalesRetencionesOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalesRetenciones: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotalesRetenciones =
        action.payload.dptotalesRetenciones
    },
    updateDptotalesNotasCreditoOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalesNotasCredito: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotalesNotasCredito =
        action.payload.dptotalesNotasCredito
    },
    updateDptotalesComprasCajaOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalesComprasCaja: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotalesComprasCaja =
        action.payload.dptotalesComprasCaja
    },
    updateDptotalesPagosTesoreriaOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalesPagosTesoreria: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotalesPagosTesoreria =
        action.payload.dptotalesPagosTesoreria
    },
    updateDptotalesAnticiposProveedoresOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalesAnticiposProveedores: Array<ccDetalle> | []
      }>,
    ) {
      state[action.payload.key].dptotalesAnticiposProveedores =
        action.payload.dptotalesAnticiposProveedores
    },
    updateTotalCaja_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalcaja_ti: number }>,
    ) {
      state[action.payload.key].totalcaja_ti = action.payload.totalcaja_ti
    },
    updateTotalEgresos_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalegresos_ti: number }>,
    ) {
      state[action.payload.key].totalegresos_ti = action.payload.totalegresos_ti
    },
    updateValorTotal_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; valortotal_ti: number }>,
    ) {
      state[action.payload.key].valortotal_ti = action.payload.valortotal_ti
    },
    updateTotalCajab_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalcajab_ti: number }>,
    ) {
      state[action.payload.key].totalcajab_ti = action.payload.totalcajab_ti
    },
    updateTotalEgreso_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalegreso_ti: number }>,
    ) {
      state[action.payload.key].totalegreso_ti = action.payload.totalegreso_ti
    },
    updateDescuadre_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; descuadre_ti: number }>,
    ) {
      state[action.payload.key].descuadre_ti = action.payload.descuadre_ti
    },
    updateDescuadredes_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; descuadredes_ti: string }>,
    ) {
      state[action.payload.key].descuadredes_ti = action.payload.descuadredes_ti
    },
    updateTotalEgresosb_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalegresosb_ti: number }>,
    ) {
      state[action.payload.key].totalegresosb_ti =
        action.payload.totalegresosb_ti
    },
    updateValorTotalb_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; valortotalb_ti: number }>,
    ) {
      state[action.payload.key].valortotalb_ti = action.payload.valortotalb_ti
    },
    updateDpegresosBovedaOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dpegresosboveda: Array<any> | []
      }>,
    ) {
      state[action.payload.key].dpegresosboveda = action.payload.dpegresosboveda
    },
    updateDpCierre2Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre2: Array<ComprobantesCuadresCajaListado> | []
          loaddpcierre2: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre2 = action.payload.data.dpcierre2
      state[action.payload.key].loaddpcierre2 =
        action.payload.data.loaddpcierre2
    },
    updateDpCierre2Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre2: Array<ComprobantesCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre2 = action.payload.dpcierre2
    },
    updateDptotales2Only(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales2: Array<formasPagoCuadre> | []
      }>,
    ) {
      state[action.payload.key].dptotales2 = action.payload.dptotales2
    },
    updateAnuladasCierre2_tiOnly(
      state,
      action: PayloadAction<{
        key: number | string
        anuladascierre2_ti: number
      }>,
    ) {
      state[action.payload.key].anuladascierre2_ti =
        action.payload.anuladascierre2_ti
    },
    updateTotal2_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; total2_ti: number }>,
    ) {
      state[action.payload.key].total2_ti = action.payload.total2_ti
    },
    updateDpCierre3Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre3: Array<ComprobantesCuadresCajaListado> | []
          loaddpcierre3: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre3 = action.payload.data.dpcierre3
      state[action.payload.key].loaddpcierre3 =
        action.payload.data.loaddpcierre3
    },
    updateDpCierre3Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre3: Array<ComprobantesCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre3 = action.payload.dpcierre3
    },
    updateDptotales3Only(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales3: Array<formasPagoCuadre> | []
      }>,
    ) {
      state[action.payload.key].dptotales3 = action.payload.dptotales3
    },
    updateAnuladasCierre3_tiOnly(
      state,
      action: PayloadAction<{
        key: number | string
        anuladascierre3_ti: number
      }>,
    ) {
      state[action.payload.key].anuladascierre3_ti =
        action.payload.anuladascierre3_ti
    },
    updateTotal3_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; total3_ti: number }>,
    ) {
      state[action.payload.key].total3_ti = action.payload.total3_ti
    },
    updateDpCierre4Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre4: Array<ChequesCuadresCajaListado> | []
          loaddpcierre4: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre4 = action.payload.data.dpcierre4
      state[action.payload.key].loaddpcierre4 =
        action.payload.data.loaddpcierre4
    },
    updateDpCierre4Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre4: Array<ChequesCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre4 = action.payload.dpcierre4
    },
    updateDpCierre5Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre5: Array<LetrasCuadresCajaListado> | []
          loaddpcierre5: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre5 = action.payload.data.dpcierre5
      state[action.payload.key].loaddpcierre5 =
        action.payload.data.loaddpcierre5
    },
    updateDpCierre5Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre5: Array<LetrasCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre5 = action.payload.dpcierre5
    },
    updateDpCierre7Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre7: Array<AnticiposCuadresCajaListado> | []
          loaddpcierre7: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre7 = action.payload.data.dpcierre7
      state[action.payload.key].loaddpcierre7 =
        action.payload.data.loaddpcierre7
    },
    updateDpCierre7Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre7: Array<AnticiposCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre7 = action.payload.dpcierre7
    },
    updateDptotales7Only(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales7: Array<formasPagoCuadre> | []
      }>,
    ) {
      state[action.payload.key].dptotales7 = action.payload.dptotales7
    },
    updateTotal7_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; total7_ti: number }>,
    ) {
      state[action.payload.key].total7_ti = action.payload.total7_ti
    },
    updateDpCierre8Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre8: Array<AbonosCuadresCajaListado> | []
          loaddpcierre8: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre8 = action.payload.data.dpcierre8
      state[action.payload.key].loaddpcierre8 =
        action.payload.data.loaddpcierre8
    },
    updateDpCierre8Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre8: Array<AbonosCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre8 = action.payload.dpcierre8
    },
    updateDptotales8Only(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales8: Array<formasPagoCuadre> | []
      }>,
    ) {
      state[action.payload.key].dptotales8 = action.payload.dptotales8
    },
    updateTotal8_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; total8_ti: number }>,
    ) {
      state[action.payload.key].total8_ti = action.payload.total8_ti
    },
    updateDpCierre9Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre9: Array<ComprasCuadresCajaListado> | []
          loaddpcierre9: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre9 = action.payload.data.dpcierre9
      state[action.payload.key].loaddpcierre9 =
        action.payload.data.loaddpcierre9
    },
    updateDpCierre9Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre9: Array<ComprasCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre9 = action.payload.dpcierre9
    },
    updateDptotales9Only(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales9: Array<formasPagoCuadre> | []
      }>,
    ) {
      state[action.payload.key].dptotales9 = action.payload.dptotales9
    },
    updateTotalCom_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalcom_ti: number }>,
    ) {
      state[action.payload.key].totalcom_ti = action.payload.totalcom_ti
    },
    updateDpCierre10Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre10: Array<AbonoComprasCuadresCajaListado> | []
          loaddpcierre10: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre10 = action.payload.data.dpcierre10
      state[action.payload.key].loaddpcierre10 =
        action.payload.data.loaddpcierre10
    },
    updateDpCierre10Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre10: Array<AbonoComprasCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre10 = action.payload.dpcierre10
    },
    updateDpCierre1Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre1: Array<ComprobantesCuadresCajaListado> | []
          loaddpcierre1: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre1 = action.payload.data.dpcierre1
      state[action.payload.key].loaddpcierre1 =
        action.payload.data.loaddpcierre1
    },
    updateDpCierre1Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre1: Array<ComprobantesCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre1 = action.payload.dpcierre1
    },
    updateLoadDpCierre1Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre1: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre1 = action.payload.loaddpcierre1
    },
    updateDptotales10Only(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales10: Array<formasPagoCuadre> | []
      }>,
    ) {
      state[action.payload.key].dptotales10 = action.payload.dptotales10
    },
    updateTotalTesoreria_tiOnly(
      state,
      action: PayloadAction<{
        key: number | string
        totaltesoreria_ti: number
      }>,
    ) {
      state[action.payload.key].totaltesoreria_ti =
        action.payload.totaltesoreria_ti
    },
    updateDpCierre11Load(
      state,
      action: PayloadAction<{
        key: number | string
        data: {
          dpcierre11: Array<AnticiposCuadresCajaListado> | []
          loaddpcierre11: boolean
        }
      }>,
    ) {
      state[action.payload.key].dpcierre11 = action.payload.data.dpcierre11
      state[action.payload.key].loaddpcierre11 =
        action.payload.data.loaddpcierre11
    },
    updateDpCierre11Only(
      state,
      action: PayloadAction<{
        key: number | string
        dpcierre11: Array<AnticiposCuadresCajaListado> | []
      }>,
    ) {
      state[action.payload.key].dpcierre11 = action.payload.dpcierre11
    },
    updateDptotales11Only(
      state,
      action: PayloadAction<{
        key: number | string
        dptotales11: Array<formasPagoCuadre> | []
      }>,
    ) {
      state[action.payload.key].dptotales11 = action.payload.dptotales11
    },
    updateTotal11_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; total11_ti: number }>,
    ) {
      state[action.payload.key].total11_ti = action.payload.total11_ti
    },

    updateDpTotalResumenOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dptotalresumen: Array<ResumenListado>
      }>,
    ) {
      state[action.payload.key].dptotalresumen = action.payload.dptotalresumen
    },

    updateDpVendedoresOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dpvendedores: Array<VendedoresListado>
      }>,
    ) {
      state[action.payload.key].dpvendedores = action.payload.dpvendedores
    },
    updateVendedorOnly(
      state,
      action: PayloadAction<{
        key: number | string
        vendedor: VendedoresListado
      }>,
    ) {
      state[action.payload.key].vendedor = action.payload.vendedor
    },

    updateIngresosBovedaOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dpingresosboveda: Array<BovedaCuadresCajaListado>
      }>,
    ) {
      state[action.payload.key].dpingresosboveda =
        action.payload.dpingresosboveda
    },
    updateTotalSaldoi_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalsaldoi_ti: number }>,
    ) {
      state[action.payload.key].totalsaldoi_ti = action.payload.totalsaldoi_ti
    },
    updateTotalBovedai_tiOnly(
      state,
      action: PayloadAction<{ key: number | string; totalbovedai_ti: number }>,
    ) {
      state[action.payload.key].totalbovedai_ti = action.payload.totalbovedai_ti
    },
    updateTotalIngresosb_tiOnly(
      state,
      action: PayloadAction<{
        key: number | string
        totalingresosb_ti: number
      }>,
    ) {
      state[action.payload.key].totalingresosb_ti =
        action.payload.totalingresosb_ti
    },
    updateValorTotalBoveda_tiOnly(
      state,
      action: PayloadAction<{
        key: number | string
        valortotalboveda_ti: number
      }>,
    ) {
      state[action.payload.key].valortotalboveda_ti =
        action.payload.valortotalboveda_ti
    },
    updateDpIngresosOnly(
      state,
      action: PayloadAction<{
        key: number | string
        dpingresos: Array<IngresosCuadresCajaListado>
      }>,
    ) {
      state[action.payload.key].dpingresos = action.payload.dpingresos
    },

    updateDenominacionOnly(
      state,
      action: PayloadAction<{
        key: number | string
        denominacion: Array<DenominacionListado>
      }>,
    ) {
      state[action.payload.key].denominacion = action.payload.denominacion
    },
    updateFechaOnly(
      state,
      action: PayloadAction<{ key: number | string; fecha: string }>,
    ) {
      state[action.payload.key].fecha = action.payload.fecha
    },

    updateCcaCodigoOnly(
      state,
      action: PayloadAction<{ key: number | string; ccaCodigo: number }>,
    ) {
      state[action.payload.key].ccaCodigo = action.payload.ccaCodigo
    },
    updateUsuarioNombreOnly(
      state,
      action: PayloadAction<{ key: number | string; usuarioNombre: string }>,
    ) {
      state[action.payload.key].usuarioNombre = action.payload.usuarioNombre
    },
    updateUsuarioCodigoOnly(
      state,
      action: PayloadAction<{ key: number | string; usuarioCodigo: number }>,
    ) {
      state[action.payload.key].usuarioCodigo = action.payload.usuarioCodigo
    },
    updateCuadreTotalOnly(
      state,
      action: PayloadAction<{ key: number | string; cuadreTotal: number }>,
    ) {
      state[action.payload.key].cuadreTotal = action.payload.cuadreTotal
    },
    updateGenerarCuadresGeneral(
      state,
      action: PayloadAction<{ key: number | string; cuadre: TCuadreDocumento }>,
    ) {
      state[action.payload.key].dpcierre1 = action.payload.cuadre.facturas
      state[action.payload.key].dpcierre7 =
        action.payload.cuadre.anticiposClientes
      state[action.payload.key].dpcierre8 = action.payload.cuadre.cobrosCartera
      state[action.payload.key].dpcierre3 = action.payload.cuadre.retenciones
      state[action.payload.key].dpcierre2 = action.payload.cuadre.notasCredito
      state[action.payload.key].dpcierre9 = action.payload.cuadre.compras
      state[action.payload.key].dpcierre10 = action.payload.cuadre.pagos
      state[action.payload.key].dpcierre11 =
        action.payload.cuadre.anticiposProveedores
      state[action.payload.key].dpcierre4 = action.payload.cuadre.cheques
      state[action.payload.key].dpcierre5 = action.payload.cuadre.letras
    },
    updateLoadDpCierre2Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre2: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre2 = action.payload.loaddpcierre2
    },
    updateLoadDpCierre3Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre3: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre3 = action.payload.loaddpcierre3
    },
    updateLoadDpCierre4Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre4: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre4 = action.payload.loaddpcierre4
    },
    updateLoadDpCierre5Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre5: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre5 = action.payload.loaddpcierre5
    },
    updateLoadDpCierre7Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre7: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre7 = action.payload.loaddpcierre7
    },
    updateLoadDpCierre8Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre8: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre8 = action.payload.loaddpcierre8
    },
    updateLoadDpCierre9Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre9: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre9 = action.payload.loaddpcierre9
    },
    updateLoadDpCierre10Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre10: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre10 = action.payload.loaddpcierre10
    },
    updateLoadDpCierre11Only(
      state,
      action: PayloadAction<{
        key: number | string
        loaddpcierre11: boolean
      }>,
    ) {
      state[action.payload.key].loaddpcierre11 = action.payload.loaddpcierre11
    },
    updateDpFormasPagoOnly(
      state,
      action: PayloadAction<{
        key: number | string
        formasPago: Array<formasPago> | []
      }>,
    ) {
      state[action.payload.key].dpfpagos = action.payload.formasPago
    },
    updateObjBilletes(
      state,
      action: PayloadAction<{
        key: number | string
        objetoBilletes: TObjBilletes[]
      }>,
    ) {
      state[action.payload.key].objetoBilletes = action.payload.objetoBilletes
    },
    updateObjMonedas(
      state,
      action: PayloadAction<{
        key: number | string
        objetoMonedas: TObjMonedas[]
      }>,
    ) {
      state[action.payload.key].objetoMonedas = action.payload.objetoMonedas
    },
    updateObjDinero(
      state,
      action: PayloadAction<{
        key: number | string
        monedas: TObjMonedas[]
        billetes: TObjBilletes[]
      }>,
    ) {
      state[action.payload.key].objetoMonedas = action.payload.monedas
      state[action.payload.key].objetoBilletes = action.payload.billetes
    },
    updateEditableInput(
      state,
      action: PayloadAction<{
        key: number | string
        editableInput: boolean
      }>,
    ) {
      state[action.payload.key].editableInput = action.payload.editableInput
    },
  },
})

export const {
  updateEditableInput,
  updateObjDinero,
  updateObjBilletes,
  updateObjMonedas,
  updateDpFormasPagoOnly,
  updateGenerarCuadresGeneral,
  setDatosEdicion,
  setEditLoader,
  setMuestraError,
  setFechaRetencion,
  initDatosEdicion,
  cuadresClearDatosEdicion: clearDatosEdicion,
  updatePuntoVentaOnly,
  updateMinutoCierreOnly,
  updateHoraCierreOnly,
  updateFechaCierreOnly,
  updateLocalOnly,
  updateTotalinicialb_tiOnly,
  updateTotalinicial_tiOnly,
  updateTotalesaldosi_tiOnly,
  updateCcaCod1Only,
  updateGenerarCuadreOnly,
  updateFacturasCuadreOnly,
  updateFormasPagoCuadreCuadreOnly,
  updateAnuladasCierre1Only,
  updateTotal1_tiOnly,

  updateDptotalesOnly,
  updateDptotalesFacturasOnly,
  updateDptotalesAnticiposOnly,
  updateDptotalesCobrosCarteraOnly,
  updateDptotalesRetencionesOnly,
  updateDptotalesNotasCreditoOnly,
  updateDptotalesComprasCajaOnly,
  updateDptotalesPagosTesoreriaOnly,
  updateDptotalesAnticiposProveedoresOnly,

  updateTotalCaja_tiOnly,
  updateTotalEgresos_tiOnly,
  updateValorTotal_tiOnly,
  updateTotalCajab_tiOnly,
  updateTotalEgresosb_tiOnly,
  updateValorTotalb_tiOnly,
  updateDpegresosBovedaOnly,
  updateDpCierre2Load,
  updateDpCierre2Only,
  updateDptotales2Only,
  updateAnuladasCierre2_tiOnly,
  updateTotal2_tiOnly,
  updateDpCierre3Load,
  updateDpCierre3Only,
  updateDptotales3Only,
  updateAnuladasCierre3_tiOnly,
  updateTotal3_tiOnly,
  updateDpCierre7Only,
  updateDpCierre7Load,
  updateDptotales7Only,
  updateTotal7_tiOnly,
  updateDpCierre8Load,
  updateDpCierre8Only,
  updateDptotales8Only,
  updateTotal8_tiOnly,
  updateDpCierre9Load,
  updateDpCierre9Only,
  updateTotalCom_tiOnly,
  updateDptotales9Only,
  updateDpCierre10Load,
  updateDpCierre10Only,
  updateDpCierre1Only,
  updateDpCierre1Load,
  updateLoadDpCierre1Only,
  updateLoadDpCierre2Only,
  updateLoadDpCierre3Only,
  updateLoadDpCierre4Only,
  updateLoadDpCierre5Only,
  updateLoadDpCierre7Only,
  updateLoadDpCierre8Only,
  updateLoadDpCierre9Only,
  updateLoadDpCierre10Only,
  updateLoadDpCierre11Only,
  updateDptotales10Only,
  updateTotalTesoreria_tiOnly,
  updateDpCierre11Load,
  updateDpCierre11Only,
  updateDptotales11Only,
  updateTotal11_tiOnly,
  updateDpTotalResumenOnly,
  updateDpCierre4Load,
  updateDpCierre4Only,
  updateDpVendedoresOnly,
  updateVendedorOnly,
  updateDpCierre5Load,
  updateDpCierre5Only,
  updateIngresosBovedaOnly,
  updateTotalSaldoi_tiOnly,
  updateTotalBovedai_tiOnly,
  updateTotalIngresosb_tiOnly,
  updateValorTotalBoveda_tiOnly,
  updateDpIngresosOnly,
  updateDenominacionOnly,
  updateFechaOnly,
  updateCcaCodigoOnly,
  updateUsuarioNombreOnly,
  updateUsuarioCodigoOnly,
  updateCuadreTotalOnly,
  updateTotalEgreso_tiOnly,
  updateDescuadre_tiOnly,
  updateDescuadredes_tiOnly,
  update7VenInicialFinal,
  update8VenInicialFinal,
  update9VenInicialFinal,
  update10VenInicialFinal,
  update11VenInicialFinal,
  update1VenInicialFinal,
  update2VenInicialFinal,
  update3VenInicialFinal,
  update4VenInicialFinal,
  update5VenInicialFinal,
} = datosEdicionCuadreCajaSlice.actions
export const datosEdicionReducer = datosEdicionCuadreCajaSlice.reducer
