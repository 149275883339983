import { PlansHelper } from '../../../../containers/component/sharedProviders/plans/helper'
import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../helpers/requestHelper'

export const PlanesCategoriaService = {
  getCategoryPlans,
}

async function getCategoryPlans(obj): Promise<any> {
  try {
    const data: any = {}
    data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'categoryPlans' + JSON.stringify(data)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const data = await RequestHelper.getAllNoSession(
        'public',
        'getCategoryPlans',
        '',
        obj,
      )
      if (data.auto) {
        const allCategory = PlansHelper.categoryPlansToTypes(data.auto)
        data.auto = allCategory ?? []
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(data.auto))

      return data
    }
  } catch (e) {
    return e
  }
}
