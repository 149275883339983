import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { projetsService } from '../projets.services'
import { Local, Project } from '../../../../../modulos/shared/types/types'
import { SesionService } from '../../../../../services/sesion.service'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Button from 'devextreme-react/button'
import {AdminModalLocales} from "../../../../../modulos/componentes/modalAdminLocales/modalAdminLocales";

interface TLocsLookUpProps extends React.PropsWithChildren {
  selected: Local | null
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: Local | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions: (newValue: Array<Local> | []) => void
  disabled?: boolean
  provider?: Array<Local> | []

  allowAdd?: boolean
  allowClear?: boolean
  allowEdit?: boolean
}

const LocsLookUp: React.FC<TLocsLookUpProps> = (props) => {
  const {
    provider,
    selected,
    onChanged,
    disabled,
    allowAdd,
    allowClear,
    allowEdit,
  } = props
  const sesion = SesionService.getCurrentSesion()
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<Local> | []>([])
  const [showModalLocales, setShowModalLocales] = React.useState<boolean>(false);
  const [localCopy, setLocalCopy] = React.useState<Local | null>(null);
  const [localEditando, setLocalEditando] = React.useState<Local | null>(null);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      let marcarOption: Local = provider[0]
      if (selected !== null) {
        const selectedIndex = provider.findIndex((select: Local) => {
          return select.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          marcarOption = provider[selectedIndex] ?? provider[0]
        }
      }
      onChanged(marcarOption)
    } else {
      const locs = await projetsService.getLocs(sesion.empresa.codigo, '')
      if (locs.auto && locs.auto.length > 0) {
        // const opt = ProjectsHelper.onParseLocs(locs.auto, KeyLoc);
        const opt = locs.auto
        setOptions(opt ?? [])

        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        } else {
          onChanged(opt[0])
        }
      }
    }
  }, [onChanged, selected, sesion, provider])

  const getItemDisplayExpr = React.useCallback((item: Project) => {
    return item ? `${item.nombre}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      onChanged(evt)
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: Local) => {
        return Number(option.codigo) === Number(selected.codigo)
      })
      let marcarOption: Local = options[0]
      if (selectedIndex > -1) {
        marcarOption = options[selectedIndex] ?? options[0]
      }
      onChanged(marcarOption)
    }
    selectRef.current.instance.repaint()
  }, [onChanged, options, selected])

  const onEditarClick = React.useCallback(() => {
    setLocalEditando(selected);
    setShowModalLocales(true);
  }, [selected])

  const onCrearClick = React.useCallback(() => {
    setLocalEditando(null);
    setShowModalLocales(true);
  }, [selected])

  const onQuitarClick = React.useCallback(() => {
    setLocalEditando(null);
    onChanged(null)
  }, [onChanged])

  const onCancel = React.useCallback(() => {
    setLocalEditando(null)
    setShowModalLocales(false);
  }, []);

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (provider.length > 0) {
      loadDatos()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <>
      <AdminModalLocales
        local={localEditando}
        onChanged={(data) => {
          onChanged(data);
          setLocalCopy(data);
          setShowModalLocales(false);
        }}
        onCancel={onCancel}
        show={showModalLocales}
        modulo={0}
      />
      <RowContainer>
        <CustomCol xs="12" md="9" className="pe-0">
          <SelectBox
            id="localSelect"
            ref={selectRef}
            items={options ?? []}
            searchEnabled
            displayExpr={getItemDisplayExpr}
            searchExpr={['nombre']}
            searchTimeout={500}
            value={selected}
            onValueChange={onLookUpValueChanged}
            disabled={disabled}
            onFocusIn={onSelectBoxFocusIn}
          >
            {props.children}
          </SelectBox>
        </CustomCol>
        <CustomCol md="3" xs="6" className="m-0 p-0">
          <div className="d-flex flex-row align-items-start m-0 p-0 ">
            {allowEdit && selected && (
              <div style={{ marginLeft: '1px' }}>
                <Button
                  stylingMode="contained"
                  type="default"
                  icon="edit"
                  onClick={onEditarClick}
                />
              </div>
            )}
            {allowAdd && !disabled && (
              <div style={{ marginLeft: '1px' }}>
                <Button
                  stylingMode="contained"
                  type="default"
                  icon="add"
                  onClick={onCrearClick}
                />
              </div>
            )}
            {!disabled && allowClear && selected && (
              <div style={{ marginLeft: '1px' }}>
                <Button
                  stylingMode="contained"
                  type="danger"
                  icon="undo"
                  onClick={onQuitarClick}
                />
              </div>
            )}
          </div>
        </CustomCol>
      </RowContainer>
    </>
  )
}
export default React.memo(LocsLookUp)
