import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import LoadPanel from 'devextreme-react/load-panel';
import config from '../../../config/config'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import { addToast } from '../../../store/toasterReducer'
import { RootState } from '../../../store/store'
import Buscar from './components/buscar'
import { changeLoader } from '../../../store/reducers'
import Nuevo from './components/nuevo'
import Modalform from '../../../views/componentes/modalform'
import Dialog from "../../../views/componentes/librerias/bootstrap-dialog";
import {lh, MessagesKeys} from "../../../helpers/localizationHelper";
import {Local} from "../../shared/types/types";

interface IAdminModalLocalesProps extends React.PropsWithChildren {
  local?: Local | null;
  onChanged: (dataLocal: any) => void
  onCancel: () => void
  show: boolean
  modulo: number
}

export const AdminModalLocales: React.FC<IAdminModalLocalesProps> = (
  props
) => {
  const dispatch = useDispatch();
  const dialogRef = React.useRef<any>(null);

  const { show, onCancel, onChanged, modulo, local } = props
  const loader = useSelector((state: RootState) => state.global.loader);

  const botones = config['menuDefecto'].map(acc => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon,//as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null
    }
    return boton;
  });

  const [acciones,] = React.useState<Array<AccionMenu>>(botones);
  const [currentTab, setCurrentTab] = React.useState<any>("Buscar");
  const [funcion, setFuncion] = React.useState<string>("");
  const [seleccion, setSeleccion] = React.useState<Local | null>(null);

  const onUpdateConfirm = React.useCallback((tittle?: string) => {
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body:
        <>
          <strong style={{ fontSize: "14px" }}>{tittle}</strong>
          <br />
        </>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            setCurrentTab("Editar")
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, []);

  const onUpdateDelete = React.useCallback((tittle?: string) => {
    dialogRef.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body:
        <>
          <strong style={{ fontSize: "14px" }}>{tittle}</strong>
          <br />
        </>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            setFuncion(ButtonTypes.delete)
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, []);

  const setToast = React.useCallback((texto: string, type: ToastTypes) => {
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: type
    }));
  }, [dispatch]);

  const setActionUpdate = () => {
    onUpdateConfirm(lh.getMessage(MessagesKeys.GenericConfirmAction, "actualizar", "local"));
  }

  const setActionDelete = () => {
    onUpdateDelete(lh.getMessage(MessagesKeys.GenericConfirmAction, "eliminar", "local"));
  }

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.find:
        setSeleccion(null);
        if (currentTab == "Buscar") {
          setFuncion(ButtonTypes.find)
          return false;
        } else if (currentTab === "Editar") {
          setCurrentTab("Buscar")
        } else {
          setCurrentTab("Buscar")
          setFuncion(ButtonTypes.undo)
        }
        break;
      case ButtonTypes.new:
        setCurrentTab("Nuevo")
        setFuncion("")
        break;
      case ButtonTypes.save:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.save)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.save)
          return false;
        } else {
          setToast("No se encuentra cambios que realizar.", ToastTypes.Info)
        }
        break;
      case ButtonTypes.undo:
        if (currentTab == "Nuevo") {
          setFuncion(ButtonTypes.undo)
          return false;
        } else if (currentTab == "Editar") {
          setFuncion(ButtonTypes.undo)
          return false;
        }
        break;
      case ButtonTypes.edit:
        if (seleccion === null) {
          setToast("No se encuentra un registro seleccionado, Por favor seleccione un Item para editarlo.", ToastTypes.Info)
          return false;
        } else {
          setActionUpdate();
          return false;
        }
        break;
      case ButtonTypes.delete:
        if (seleccion !== null) {
          setActionDelete();
          return false;
        } else {
          setToast("No se encuentra seleccionado un local, por favor seleccione un local para eliminarlo.", ToastTypes.Info)
          return false;
        }
        break;
      default:
        break;
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.find:
        return false;
      case ButtonTypes.undo:
        return currentTab !== "Nuevo" && currentTab !== "Editar";
      case ButtonTypes.new: {
        return currentTab !== "Buscar" || seleccion !== null;
      }
      case ButtonTypes.delete:
      case ButtonTypes.edit: {
        return (currentTab !== "Buscar" || seleccion === null);
      }
      case ButtonTypes.save: {
        return currentTab === "Buscar";
      }
      default:
        return true;
    }
  }, [currentTab, seleccion]);

  React.useEffect(() => {
    if (show) {
      if (local === null) {
        setSeleccion(null);
        setCurrentTab("Nuevo");
        setFuncion("");
      } else if (local) {
        setSeleccion(local);
        setCurrentTab("Editar");
        setFuncion(ButtonTypes.undo);
      }
    }
  }, [show, local]);

  const botonesModal = () => {
    return (
      <GlobalMenu
        acciones={acciones}
        onClick={returnAction}
        getButtonDisabled={getButtonDisabled}
      />
    );
  }

  const bodyModal = () => {
    return (
      <RowContainer>
        <CustomCol xs='12' md='12'>
          {(currentTab === "Nuevo" || currentTab === "Editar") && (
            <Nuevo
              funcion={funcion}
              setCurrenTab={(tab) => setCurrentTab(tab)}
              currentTab={currentTab}
              resetFunction={() => setFuncion('')}
              onChanged={onChanged}
              setToast={setToast}
              playLoader={playLoader}
              stopLoader={stopLoader}
              modulo={modulo}
              selection={seleccion}
            />
          )}
          {currentTab === "Buscar" && (
            <Buscar
              funcion={funcion}
              resetFunction={() => setFuncion('')}
              onChanged={(data) => {
                setSeleccion(data);
                onChanged(data);
              }}
              onEdit={(data) => {
                setSeleccion(data);
              }}
              ejecutarBusqueda={() => setFuncion("Buscar")}
              setToast={setToast}
              playLoader={playLoader}
              stopLoader={stopLoader}
              seleccion={seleccion}
              currentTab={currentTab}
            />
          )}
        </CustomCol>
      </RowContainer>
    );
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>
          {botonesModal()}
        </CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
            closeOnOutsideClick={false}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <>
        <Dialog ref={dialogRef} />
      </>
      <Modalform
        name={'popupLocales'}
        key={'popupLocales'}
        headerTitle={"Local"}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={() => onCancel()}
        centered={true}
        size='lg'
      />
    </>
  )
}

