import { RequestHelper } from "../../../../helpers/requestHelper";

export const adminLocales = {
  getAdminLocales,
  getLocal,
  deleteLocal,
  actualizarDatosLocal,
};

async function getAdminLocales(tipo): Promise<any> {
  try {
    const data: any = {
      ...tipo
    };
    const res = await RequestHelper.getAll<any>('local', 'getAllBase', '', data);
    return res;
  } catch (error) {
    return error;
  }
}

async function getLocal(codigoLocal): Promise<any> {
  try {
    const data: any = {
      codigo: codigoLocal
    };
    const res = await RequestHelper.getAll<any>('inventario/multialmacen', 'local/get', '', data);
    return res;
  } catch (error) {
    return error;
  }
}

async function deleteLocal(codigo: any): Promise<any> {
  try {
    const obj = {
      codigo: codigo,
    }

    const data = await RequestHelper.deleteRequestAll<any>(
      'inventario/multialmacen',
      'local/delete',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function actualizarDatosLocal(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        ...objeto,
      },
    }
    const data = await RequestHelper.postAll<any>(
      'inventario/multialmacen',
      'local/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}
