import ColContainer from '../../../../views/componentes/colContainer'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import DataGrid, {
  Button as DatagridButton,
  Column,
  ColumnChooser,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/data-grid'
import { IDetallePlan } from '../store/types'
import React from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import InputTextDE from '../../../../views/componentes/inputText-DE/inputText-DE'
import { Button, Validator } from 'devextreme-react'
import CheckBox from 'devextreme-react/check-box'
import { ToastTypes } from '../../../../store/types'
import { addToast } from '../../../../store/toasterReducer'
import {useDispatch} from "react-redux";

interface IDetallesPlanesProps extends React.PropsWithChildren {
  planId: number
  validationGroupRef: any
  items: IDetallePlan[]
  onChange: (data: IDetallePlan[]) => void
}

const pageSizes = [10, 25, 50, 100, 200]

const DetallesPlanes: React.FunctionComponent<IDetallesPlanesProps> = (
  props,
) => {
  const { planId, validationGroupRef, items, onChange } = props

  const dispatch = useDispatch()

  const dialogRef = React.useRef<any>(null)

  const [selectedLocal, setSelectedLocal] = React.useState(null)
  const [isEditing, setIsEditing] = React.useState(false)
  const [dataDetalle, setDataDetalle] = React.useState<IDetallePlan>({
    codigo: 0,
    planId: planId,
    descripcion: '',
    estado: 1,
    isNew: true,
  })

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      let time: number = 3000
      if (texto.length < 50) {
        time = 5000
      } else if (texto.length > 50) {
        time = 9000
      }
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const resetForm = React.useCallback(() => {
    setDataDetalle({
      codigo: 0,
      planId: planId,
      descripcion: '',
      estado: 1,
      isNew: true,
    })
    setIsEditing(false)
    setSelectedLocal(null)
  }, [planId])

  const handleSubmit = React.useCallback(() => {
    const result = validationGroupRef.current.instance.validate()

    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)
      return
    }

    let newItems: IDetallePlan[]

    if (isEditing) {
      newItems = items.map((item) =>
        item.codigo === dataDetalle.codigo ? dataDetalle : item,
      )
    } else {
      newItems = [
        ...items,
        {
          ...dataDetalle,
          codigo: items.length + 1,
          planId: planId,
        },
      ]
    }

    onChange(newItems)
    validationGroupRef.current.instance.reset()
    resetForm()
  }, [
    dataDetalle,
    isEditing,
    items,
    onChange,
    planId,
    resetForm,
    validationGroupRef,
  ])

  const handleEdit = React.useCallback((data: IDetallePlan) => {
    setDataDetalle(data)
    setIsEditing(true)
    setSelectedLocal(data.codigo)
  }, [])

  const handleDelete = React.useCallback(
    (codigo: number) => {
      const newItems = items.filter((item) => item.codigo !== codigo)
      onChange(newItems)
      resetForm()
    },
    [items, onChange],
  )

  return (
    <>
      <RowContainer>
        <ColContainer className="d-flex" md="12">
          <span className="h6">Detalles del Plan</span>
        </ColContainer>
      </RowContainer>
      <RowContainer>
        <ColContainer className="d-flex" md="3">
          <RowContainer>
            <CCol className="d-flex" md="12">
              <div className="dx-field" style={{ width: '100%' }}>
                <CRow>
                  <CCol lg="12" md="12" sm="12">
                    <div className="dx-field-label" style={{ width: '100%' }}>
                      {'Código:'}
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="12" md="12" sm="12">
                    <InputTextDE
                      value={String(dataDetalle?.codigo)}
                      placeholder=""
                      onValueChange={() => {}}
                      width="100%"
                      readOnly={true}
                    />
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol className="d-flex" md="12">
              <div className="dx-field" style={{ width: '100%' }}>
                <CRow>
                  <CCol lg="12" md="12" sm="12">
                    <div className="dx-field-label" style={{ width: '100%' }}>
                      {'Plan ID:'}
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="12" md="12" sm="12">
                    <InputTextDE
                      value={String(planId)}
                      placeholder=""
                      onValueChange={() => {}}
                      width="100%"
                      readOnly={true}
                    ></InputTextDE>
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol className="d-flex" md="12">
              <div className="dx-field" style={{ width: '100%' }}>
                <CRow>
                  <CCol lg="12" md="12" sm="12">
                    <div className="dx-field-label" style={{ width: '100%' }}>
                      {'Descripción(*):'}
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="12" md="12" sm="12">
                    <InputTextDE
                      value={dataDetalle?.descripcion}
                      placeholder="Ingrese una descripción"
                      onValueChange={(value) => {
                        setDataDetalle({
                          ...dataDetalle,
                          descripcion: value,
                        })
                      }}
                      showClearButton={true}
                      width="100%"
                    >
                      <Validator>
                        <RequiredRule message="- Descripción: Este campo es requerido" />
                        <StringLengthRule
                          min="1"
                          message="- Descripción: Este campo debe tener al menos 1 caracteres"
                        />
                        <StringLengthRule
                          max="250"
                          message="- Descripción: Este campo no puede tener mas de 250 caracteres"
                        />
                      </Validator>
                    </InputTextDE>
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol className="d-flex" md="12">
              <div className="dx-field" style={{ width: '100%' }}>
                <CRow>
                  <CCol lg="12" md="12" sm="12">
                    <div className="dx-field-label" style={{ width: '100%' }}>
                      {'Estado:'}
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="12" md="12" sm="12">
                    <CheckBox
                      value={dataDetalle?.estado === 1}
                      onValueChange={(e) => {
                        const newValue = e ? 1 : 0
                        setDataDetalle({
                          ...dataDetalle,
                          estado: newValue,
                        })
                      }}
                      text={dataDetalle?.estado === 1 ? 'Activo' : 'Inactivo'}
                    />
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol className="d-flex" md="12">
              <div className="mt-3" style={{ width: '100%' }}>
                <Button
                  text={isEditing ? 'Actualizar' : 'Guardar'}
                  stylingMode="contained"
                  type="success"
                  icon="save"
                  height={30}
                  width="100%"
                  onClick={handleSubmit}
                />
              </div>
            </CCol>

            {isEditing && (
              <CCol className="d-flex" md="12">
                <div className="mt-2" style={{ width: '100%' }}>
                  <Button
                    text="Cancelar"
                    stylingMode="outlined"
                    type="normal"
                    icon="close"
                    height={30}
                    width="100%"
                    onClick={resetForm}
                  />
                </div>
              </CCol>
            )}
          </RowContainer>
        </ColContainer>

        <ColContainer className="d-flex flex-grow-1" md="9">
          <div className="w-full">
            <DataGrid
              ref={dialogRef}
              selection={{ mode: 'single' }}
              allowColumnResizing={true}
              columnResizingMode="nextColumn"
              columnAutoWidth={true}
              focusedRowEnabled={true}
              focusedRowKey={selectedLocal}
              keyExpr="codigo"
              width="100%"
              height="100%"
              dataSource={items ?? []}
              showBorders={true}
            >
              <ColumnChooser enabled={true} mode="select" />
              <HeaderFilter visible={true} />

              <Column
                type="buttons"
                width={100}
                fixed={true}
                fixedPosition="left"
              >
                <DatagridButton
                  icon="edit"
                  hint="Editar"
                  onClick={async (e) => {
                    const data: IDetallePlan = e.row.data
                    handleEdit(data)
                  }}
                />
                <DatagridButton
                  icon="trash"
                  hint="Eliminar Ítem"
                  onClick={async (e) => {
                    const data: IDetallePlan = e.row.data
                    handleDelete(data.codigo)
                  }}
                />
              </Column>

              <Column
                dataField="codigo"
                caption="Código"
                width={100}
                fixed={true}
                fixedPosition="left"
                allowEditing={false}
                allowFiltering={true}
              />

              <Column
                dataField="descripcion"
                caption="Descripción"
                minWidth={200}
                allowEditing={false}
                allowFiltering={true}
              />

              <Column
                dataField="estado"
                caption="Estado"
                width={120}
                allowEditing={false}
                allowSearch={false}
                allowFiltering={true}
                calculateDisplayValue={(rowData: any) =>
                  rowData.estado === 1 ? 'ACTIVO' : 'INACTIVO'
                }
                alignment="left"
              />

              <Column
                dataField="planId"
                caption="Plan ID"
                width={120}
                allowEditing={false}
                allowFiltering={true}
              />

              <Pager
                allowedPageSizes={pageSizes}
                showPageSizeSelector={true}
                showInfo={true}
              />
              <Paging defaultPageSize={10} />
            </DataGrid>
          </div>
        </ColContainer>
      </RowContainer>
    </>
  )
}

export default DetallesPlanes
