import { CCol, CRow } from '@coreui/react-pro'
import React from 'react'
import { RootState } from '../../../../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import DataGrid, {
  Column,
  HeaderFilter,
  Paging,
  Pager,
  Export,
} from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../../views/componentes/colContainer'
import { utilidades } from '../../../../../../../../../helpers/utilidades'
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize'
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper'
import { LetrasCuadresCajaListado } from '../../../../../types/types'
import {
  update5VenInicialFinal,
  updateLoadDpCierre5Only,
} from '../../../store/editDataReducer'
import { exportFormats } from '../facturas/facturas'

interface ILetrasProps extends React.PropsWithChildren {
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
  onErrorConfirm: (sms, title) => void
  onConfirmarRegistro: (sms, method) => void
  tabId: string
  statusLoaded: (load) => void
}

const Letras: React.FunctionComponent<ILetrasProps> = (props) => {
  const { tabId, statusLoaded } = props
  const dataGrid = React.useRef<any>()

  const dispatch = useDispatch()
  const [heigth] = useGridResultadosSize()

  const dpcierre5 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre5
  })
  const generarCuadre = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre
  })
  const loaddpcierre5 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loaddpcierre5
  })

  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [data, setData] = React.useState<any>([])

  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting()
    dataGrid.current.instance.clearFilter()
  }, [])

  const llenarData = React.useCallback(
    (data) => {
      clearFilter()
      const item: any = []
      let contar: number = 0
      try {
        data.map(function (key, i) {
          let itemAdd: any = {}
          itemAdd = {
            ...key,
          }
          contar = contar + 1
          item.push(itemAdd)
        })
        setData(item.map((x) => utilidades.unfreeze(x)))
      } catch (error) {
        console.error('on llenarData', error)
      }
    },
    [clearFilter],
  )

  const resultadoCuadresLetras = React.useCallback(
    async (dpCierre: Array<LetrasCuadresCajaListado> | []) => {
      if (dpCierre.length > 0) {
        const VEN_INICIAL5 = dpCierre[0].codigo
        const VEN_FINAL5 = dpCierre[dpCierre.length - 1].codigo

        dispatch(
          update5VenInicialFinal({
            rango: {
              inicial: VEN_INICIAL5,
              final: VEN_FINAL5,
            },
            key: tabId,
          }),
        )
      }
    },
    [dispatch, tabId],
  )

  const getLetrasCuadres = React.useCallback(async () => {
    let dpCierreRet = []
    if (dpcierre5) {
      dpCierreRet = dpcierre5
    }
    await resultadoCuadresLetras(dpCierreRet)

    dispatch(
      updateLoadDpCierre5Only({
        loaddpcierre5: false,
        key: tabId,
      }),
    )
  }, [dispatch, tabId, dpcierre5, resultadoCuadresLetras])

  const onLoadClose = React.useCallback(async () => {
    statusLoaded(true)
  }, [statusLoaded])

  React.useEffect(() => {
    if (generarCuadre === true) {
      onLoadClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generarCuadre])

  React.useEffect(() => {
    if (loaddpcierre5 === true) {
      getLetrasCuadres()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaddpcierre5])

  React.useEffect(() => {
    llenarData(dpcierre5)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpcierre5])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">
          <RowContainer>
            <CustomCol xs="12" md="12">
              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr="codigo"
                dataSource={data}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode="widget"
                height={heigth}
                ref={dataGrid}
              >
                <Export enabled={true} formats={exportFormats} />

                <Paging defaultPageSize={20} />
                <Pager
                  visible={data.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(data)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column
                  dataField="letra"
                  dataType="string"
                  caption="Letra"
                  width="25%"
                  allowEditing={false}
                  allowSearch={false}
                  allowFiltering
                />
                <Column
                  dataField="cuota"
                  caption="Cuota"
                  width="20%"
                  allowEditing={false}
                  visible={true}
                />
                <Column
                  dataField="interes"
                  caption="Interes"
                  width="15%"
                  allowEditing={true}
                  visible={true}
                />
                <Column
                  dataField="saldo"
                  caption="Saldo"
                  width="15%"
                  allowEditing={false}
                  visible={true}
                />
                <Column
                  dataField="estadoDescripcion"
                  caption="Estado"
                  width="10%"
                  allowEditing={false}
                  visible={true}
                />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCol>
      </CRow>
    </>
  )
}
export default Letras
