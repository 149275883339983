export class CCDETALLE {
  ccdCodigo: number
  ccaCodigo: string
  subValor: string
  ccdValor: number
  subCodigo: number

  private items: CCDETALLE[] = []

  constructor(
    ccdCodigo: number = 0,
    ccaCodigo: string = '',
    subValor: string = '',
    ccdValor: number = 0,
    subCodigo: number = 0,
  ) {
    this.ccdCodigo = ccdCodigo
    this.ccaCodigo = ccaCodigo
    this.subValor = subValor
    this.ccdValor = ccdValor
    this.subCodigo = subCodigo
  }

  addItem(detalle: CCDETALLE): void {
    this.items.push(detalle)
  }

  getItemAt(index: number): CCDETALLE | null {
    return this.items[index] || null
  }

  getAll(): CCDETALLE[] {
    return this.items
  }
}
