import CryptoJS from 'crypto-js'
import { DateUtils } from './dateUtils'
import config from 'devextreme/core/config'
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button'
import { consoleService } from '../services/console.service'
import { isMobile } from 'react-device-detect'
const key_code = '86758576376418652952657157128997'

function testHelper(date: string) {
  // your logic
  return date
}

/**
 * Función para encriptar un texto plano utilizando una clave.
 * @param plain_text El texto plano a encriptar.
 * @param keyP Clave opcional. Si no se proporciona, se utiliza una clave predeterminada.
 * @returns Un objeto JSON que contiene el texto encriptado, la sal y el vector de inicialización.
 */
const encriptarConKey = (plain_text, keyP = '') => {
  let auxKey = key_code
  if (keyP !== '') {
    auxKey = keyP
  }
  const salt = CryptoJS.lib.WordArray.random(256)
  const iv = CryptoJS.lib.WordArray.random(16)
  const key = CryptoJS.PBKDF2(auxKey, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: 64 / 8,
    iterations: 999,
  })
  const encrypted = CryptoJS.AES.encrypt(plain_text, key, { iv: iv })
  const jsonCrypto = {
    ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
    salt: CryptoJS.enc.Hex.stringify(salt),
    iv: CryptoJS.enc.Hex.stringify(iv),
  }
  return jsonCrypto
}

function encriptarAes(encriptarDatos: any) {
  const key = CryptoJS.enc.Utf8.parse(key_code)

  const iv = CryptoJS.enc.Utf8.parse('')
  const encrypted = CryptoJS.AES.encrypt(encriptarDatos, key, {
    iv: iv,
  })

  const codificado = encrypted.ciphertext.toString(CryptoJS.enc.Base64)
  return codificado
}

function desencriptarAes(datoParaDesencriptar: any) {
  const key = CryptoJS.enc.Utf8.parse(key_code)
  const iv = CryptoJS.enc.Utf8.parse('')
  const plainText = CryptoJS.AES.decrypt(datoParaDesencriptar, key, {
    iv: iv,
  })
  const originalText = plainText.toString(CryptoJS.enc.Utf8)

  const str = originalText.split('"').join('')
  return str
}

function encriptarJson(encriptarDatos: any) {
  const ciphertext = CryptoJS.AES.encrypt(encriptarDatos, key_code).toString()
  return ciphertext
}

function desencriptarJson(encriptarDatos: any) {
  const bytes = CryptoJS.AES.decrypt(encriptarDatos, key_code)
  const originalText = bytes.toString(CryptoJS.enc.Utf8)
  return originalText
}

function getAuthJsonSelect() {
  const authSelect = sessionStorage.getItem('auth_selec')
  const encripted = authSelect
  const setEncript = desencriptarJson(encripted)
  return setEncript
}

function getAuthJson() {
  const authRes = sessionStorage.getItem('auth_res')
  const encripted = authRes
  const setEncript = desencriptarJson(encripted)
  return setEncript
}

function formatMoney(num: number, x = 2, s = '.', c = ',') {
  try {
    const p = num.toFixed(x).split(s)
    return (
      p[0]
        .split('')
        .reverse()
        .reduce(function (acc, num, i) {
          if ((num === '-') === false) {
            return num === '-' ? acc : num + (i && !(i % 3) ? c : '') + acc
          } else {
            return num + (i && !(i % 3) ? '' : '') + acc
          }
        }, '') +
      s +
      p[1]
    )
  } catch (e) {
    consoleService.error(e)
  }
}

function dateFormat(fecha: any) {
  const dayInicio =
    fecha.getDate() < 10 ? '0' + fecha.getDate() : fecha.getDate()
  const monthInicio =
    fecha.getMonth() + 1 < 10
      ? '0' + (fecha.getMonth() + 1)
      : fecha.getMonth() + 1
  const fechaFinal = dayInicio + '/' + monthInicio + '/' + fecha.getFullYear()
  return fechaFinal
}

//  buscar en un objeto Objeto, Key, val
const findOnObject = (obj: any, key: any, val: any) => {
  let objects: any = []
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue
    if (typeof obj[i] === 'object') {
      objects = objects.concat(findOnObject(obj[i], key, val))
    }
    //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
    else if ((i === key && obj[i] === val) || (i === key && val === '')) {
      //
      objects.push(obj)
    } else if (obj[i] === val && key === '') {
      //only add if the object is not already in the array
      if (objects.lastIndexOf(obj) === -1) {
        objects.push(obj)
      }
    }
  }
  return objects
}

export const allowedPageSizes = [
  5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 250, 500,
]

const getAllowedPageSizes = (data: any) => {
  const tableSizes = allowedPageSizes.filter(
    (tableSize) => tableSize <= data.length,
  )
  if (
    tableSizes[tableSizes.length - 1] < data.length &&
    data.length < allowedPageSizes[allowedPageSizes.length - 1]
  ) {
    tableSizes.push(allowedPageSizes[tableSizes.length])
  }
  return tableSizes
}
//  function restaFechas necesita la fecha formato 'dd/MM/yyyy' => '23/03/2023'
//   el parametro fechaSolicitud tomo la fecha menor
const restaFechas = (
  fechaSolicitud: string | 'dd/MM/yyyy',
  fechaLimite: string | 'dd/MM/yyyy',
) => {
  try {
    const day1 = DateUtils.strDateToDate(fechaSolicitud, 'dd/MM/yyyy') // "23/03/2023"
    const day2 = DateUtils.strDateToDate(fechaLimite, 'dd/MM/yyyy') // "24/03/2023"

    let diasDiferencia: number = 0
    if (day2.getTime()) {
      const difference = day2.getTime() - day1.getTime()

      const day = 1000 * 60 * 60 * 24
      diasDiferencia = difference / day
    }

    return diasDiferencia
  } catch (error) {
    consoleService.error(error)
    return ''
  }
}

function restarFechasReturnMeses(fecha1: string, fecha2: string): number {
  const partesFecha1 = fecha1.split('/')
  const partesFecha2 = fecha2.split('/')

  const fecha1Obj = new Date(
    Number(partesFecha1[2]),
    Number(partesFecha1[1]) - 1,
    Number(partesFecha1[0]),
  )
  const fecha2Obj = new Date(
    Number(partesFecha2[2]),
    Number(partesFecha2[1]) - 1,
    Number(partesFecha2[0]),
  )

  const aniosDiferencia = fecha1Obj.getFullYear() - fecha2Obj.getFullYear()
  const mesesDiferencia =
    aniosDiferencia * 12 + fecha1Obj.getMonth() - fecha2Obj.getMonth()

  return mesesDiferencia
}

// elimina el objeto de un array
function eliminarObjeto(json: Array<any>, cadena: Array<string>, key: string) {
  const jsonData = json.slice()
  for (let i = jsonData.length - 1; i >= 0; i--) {
    const objeto: any = jsonData[i]
    for (let j = 0; j < cadena.length; j++) {
      const texto: any = String(cadena[j]).toUpperCase()
      const verificador = String(objeto[key]).toUpperCase()
      if (verificador.includes(texto)) {
        jsonData.splice(i, 1)
        break
      }
    }
  }
  return jsonData
}

/**
 * Cambia la posición de un componente SpeedDialAction.
 * Esta función modifica la configuración de posición de un componente SpeedDialAction,
 * específicamente ajusta su dirección y posición en la interfaz de usuario.
 * @function changePositionSpeedDialAction
 * @returns {void}
 * @example
 * changePositionSpeedDialAction();
 */
function changePositionSpeedDialAction() {
  config({
    floatingActionButtonConfig: {
      direction: 'up',
      position: {
        my: 'left bottom',
        at: 'left bottom',
        offset: '8 -60',
      },
    },
  })
  repaintFloatingActionButton()
}

/**
 * Establece el foco en el componente que se le pase como referencia.
 * @function atFocus
 * @returns {void}
 * @param const ref = React.useRef<any>()
 * @example atFocus(ref);
 */
function atFocus(ref) {
  ref.current.instance.focus()
}

// formato debe ser 'yyyy-mm-dd hh:mm:ss'
function validarFormatoFecha(fecha: string): boolean {
  const formatoRegExp = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
  return formatoRegExp.test(fecha)
}

function convertirFecha(fecha: Date): string {
  const year = fecha.getFullYear()
  const month = ('0' + (fecha.getMonth() + 1)).slice(-2) // Agrega un cero inicial si el mes es menor que 10
  const day = ('0' + fecha.getDate()).slice(-2) // Agrega un cero inicial si el día es menor que 10
  const hours = ('0' + fecha.getHours()).slice(-2) // Agrega un cero inicial si la hora es menor que 10
  const minutes = ('0' + fecha.getMinutes()).slice(-2) // Agrega un cero inicial si los minutos son menores que 10
  const seconds = ('0' + fecha.getSeconds()).slice(-2) // Agrega un cero inicial si los segundos son menores que 10

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function isMayor24Horas(fechaEmision: string): boolean {
  const day1 = new Date(fechaEmision)
  const day2 = new Date()

  const diferenciaEnMilisegundosTiempos = Math.abs(
    day1.getTime() - day2.getTime(),
  )

  const dias = Math.floor(
    diferenciaEnMilisegundosTiempos / (1000 * 60 * 60 * 24),
  )
  const horas = Math.floor(
    (diferenciaEnMilisegundosTiempos % (1000 * 60 * 60 * 24)) /
      (1000 * 60 * 60),
  )
  const minutos = Math.floor(
    (diferenciaEnMilisegundosTiempos % (1000 * 60 * 60)) / (1000 * 60),
  )
  const segundos = Math.floor(
    (diferenciaEnMilisegundosTiempos % (1000 * 60)) / 1000,
  )

  console.log(
    'diferecia : ',
    `${dias} días, ${horas} horas, ${minutos} minutos y ${segundos} segundos`,
  )

  const fechaEmisionUTC = new Date(fechaEmision).getTime() // Convertir la fecha de emisión a milisegundos desde el 1 de enero de 1970
  const fechaActualUTC = Date.now() // Obtener la fecha actual en milisegundos desde el 1 de enero de 1970

  const horasTranscurridas =
    (fechaActualUTC - fechaEmisionUTC) / (1000 * 60 * 60) // Calcular las horas transcurridas

  return horasTranscurridas >= 24 // Devolver true si han pasado al menos 24 horas
}

function eliminarElementosBloqueoDom() {
  try {
    // Selecciona todos los elementos con las clases "sidebar-backdrop fade"
    const elementos = document?.querySelectorAll('.sidebar-backdrop.fade')
    // Itera sobre los elementos y los elimina del DOM
    if (elementos && isMobile) {
      elementos?.forEach((elemento) => {
        // eslint-disable-next-line no-console
        console.log('Intentando eliminar elemento del DOM')
        try {
          if (elemento && elemento?.parentNode) {
            elemento?.classList?.remove('sidebar-backdrop', 'fade')
            console.log('Clases eliminadas correctamente')
          }
        } catch (error) {
          // Maneja errores individuales sin afectar el resto de los elementos
          // eslint-disable-next-line no-console
          console.error('Error al eliminar elemento individual:', error)
        }
      })
    }
  } catch (error) {
    // Captura cualquier error inesperado que ocurra en la función
    // eslint-disable-next-line no-console
    console.error('Error general al eliminar elementos del DOM:', error)
  }
}

// Now you have to export each function you want
export {
  atFocus,
  dateFormat,
  formatMoney,
  testHelper,
  desencriptarAes,
  encriptarAes,
  encriptarJson,
  desencriptarJson,
  getAuthJsonSelect,
  getAuthJson,
  findOnObject,
  getAllowedPageSizes,
  restaFechas,
  restarFechasReturnMeses,
  eliminarObjeto,
  encriptarConKey,
  changePositionSpeedDialAction,
  isMayor24Horas,
  validarFormatoFecha,
  eliminarElementosBloqueoDom,
}
