import { CCol, CRow } from '@coreui/react-pro'
import React from 'react'
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  update2VenInicialFinal,
  updateAnuladasCierre2_tiOnly,
  updateDptotales2Only,
  updateDptotalesNotasCreditoOnly,
  updateLoadDpCierre2Only,
  updateTotal2_tiOnly,
} from '../../../store/editDataReducer'
import DataGrid, {
  Column,
  HeaderFilter,
  Paging,
  Pager,
  Export,
} from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../../views/componentes/colContainer'
import { utilidades } from '../../../../../../../../../helpers/utilidades'
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize'
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper'
import {
  ComprobantesCuadresCajaListado,
  formasPagoCuadre,
} from '../../../../../types/types'
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago'
import { GlobalLabel } from '../../../../../../../../../views/componentes/globalLabel/globalLabel'
import ButtonsInfo from '../shared/ButtonsInfo'
import { exportFormats } from '../facturas/facturas'
import { CCDETALLE } from '../../../types/clases/ccdetalles'

interface INotasCreditoProps extends React.PropsWithChildren {
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
  onErrorConfirm: (sms, title) => void
  onConfirmarRegistro: (sms, method) => void
  tabId: string
  statusLoaded: (load) => void
}

const NotasCredito: React.FunctionComponent<INotasCreditoProps> = (props) => {
  const { tabId, statusLoaded } = props
  const dataGrid = React.useRef<any>()

  const dispatch = useDispatch()
  const [heigth] = useGridResultadosSize()

  const generarCuadre = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre
  })
  const loaddpcierre2 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loaddpcierre2
  })

  const anuladascierre2_ti = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId]
      .anuladascierre2_ti
  })
  const total2_ti = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].total2_ti
  })
  const dptotales2 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales2
  })
  const dpfpagos = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos
  })
  const dpcierre2 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre2
  })

  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showPageSizeSelector] = React.useState<boolean>(true)

  const resultadoCierreCajaNC = React.useCallback(
    async (dpCierreFacturasVar: Array<ComprobantesCuadresCajaListado> | []) => {
      let valor: number = 0
      const dptotales2Local: Array<formasPagoCuadre> = []
      if (dpCierreFacturasVar.length === 0) {
        for (const fpg of dpfpagos) {
          dptotales2Local.push({
            subCodigo: Number(fpg.codigo) ?? -1,
            subValor: String(fpg.descripcion) ?? '',
            ccdValor: valor,
          })
        }

        dispatch(
          updateDptotales2Only({
            dptotales2: dptotales2Local,
            key: tabId,
          }),
        )
      } else {
        let anuladas = 0
        let i = 0
        for (let j = 0; j <= dpfpagos.length; j++) {
          valor = 0
          for (i = 0; i < dpCierreFacturasVar.length; i++) {
            if (
              dpCierreFacturasVar[i]?.ventaFormaPagoDescripcion ===
              dpfpagos[j]?.descripcion
            ) {
              valor =
                valor + Number(Number(dpCierreFacturasVar[i].valor).toFixed(2))
            }
          }
          if (dpfpagos[j] && dpfpagos[j]?.codigo) {
            dptotales2Local.push({
              subCodigo: Number(dpfpagos[j]?.codigo),
              subValor: String(dpfpagos[j]?.descripcion),
              ccdValor: Number(Number(valor).toFixed(2)),
            })
          }
        }

        let k = 0

        for (k = 0; k < dpCierreFacturasVar.length; k++) {
          if (dpCierreFacturasVar[k].ventaEstado === '*') {
            anuladas += Number(dpCierreFacturasVar[k].ventaTotal)
          }
        }

        dispatch(
          updateAnuladasCierre2_tiOnly({
            anuladascierre2_ti: anuladas,
            key: tabId,
          }),
        )

        const VEN_INICIAL2 = dpCierreFacturasVar[0].ventaCodigo
        const VEN_FINAL2 =
          dpCierreFacturasVar[dpCierreFacturasVar.length - 1].ventaCodigo

        dispatch(
          update2VenInicialFinal({
            rango: {
              inicial: VEN_INICIAL2,
              final: VEN_FINAL2,
            },
            key: tabId,
          }),
        )

        let total: number = 0
        for (k = 0; k < dptotales2Local.length; k++) {
          total = total + dptotales2Local[k].ccdValor
        }
        total = Number(Number(total).toFixed(2))
        dispatch(
          updateTotal2_tiOnly({
            total2_ti: total,
            key: tabId,
          }),
        )

        dispatch(
          updateDptotales2Only({
            dptotales2: dptotales2Local,
            key: tabId,
          }),
        )

        const dptotalesNotasCredito: CCDETALLE[] = []

        for (i = 0; i < dpfpagos.length; i++) {
          let x: CCDETALLE | null = null
          try {
            x = dptotales2Local[i] as CCDETALLE
          } catch (ex) {
            x = null
          }
          if (x == null) {
            const detalle = new CCDETALLE()
            detalle.ccdValor = Number(
              (Number(dptotales2Local[i].ccdValor) * -1).toFixed(2),
            )
            detalle.subCodigo = dpfpagos[i].codigo
            detalle.subValor = dpfpagos[i].descripcion
            dptotalesNotasCredito.push(detalle)
          } else {
            const y: number =
              Number(x?.ccdValor ?? 0) -
              Number(dptotales2Local[i]?.ccdValor ?? 0)

            const nuevoDetalle = new CCDETALLE(
              x?.ccdCodigo ?? 0,
              x?.ccaCodigo ?? '',
              x?.subValor ?? '',
              y,
              x?.subCodigo ?? 0,
            )

            dptotalesNotasCredito[i] = nuevoDetalle
          }
        }

        const detallesTotales = []
        dptotalesNotasCredito.forEach((item) => {
          detallesTotales.push({ ...item })
        })

        dispatch(
          updateDptotalesNotasCreditoOnly({
            dptotalesNotasCredito: detallesTotales,
            key: tabId,
          }),
        )
      }
    },
    [dispatch, tabId, dpfpagos],
  )

  const getCierreCajaNC = React.useCallback(async () => {
    let dpCierre = []
    if (dpcierre2) {
      dpCierre = dpcierre2
    }
    await resultadoCierreCajaNC(dpCierre)
    dispatch(
      updateLoadDpCierre2Only({
        loaddpcierre2: false,
        key: tabId,
      }),
    )
  }, [dispatch, tabId, dpcierre2, resultadoCierreCajaNC])

  const onLoadClose = React.useCallback(async () => {
    statusLoaded(true)
  }, [statusLoaded])

  React.useEffect(() => {
    if (generarCuadre === true) {
      onLoadClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generarCuadre])

  React.useEffect(() => {
    if (loaddpcierre2 === true) {
      getCierreCajaNC()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaddpcierre2])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">
          <RowContainer>
            <CustomCol xs="12" md="12">
              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr="ventaCodigo"
                dataSource={dpcierre2}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode="widget"
                height={heigth}
                ref={dataGrid}
              >
                <Export enabled={true} formats={exportFormats} />

                <Paging defaultPageSize={20} />
                <Pager
                  visible={dpcierre2.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre2)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column
                  dataField="ventaTipoDocumentoDescripcion"
                  dataType="string"
                  caption="Comprobante"
                  width="15%"
                  allowEditing={false}
                  allowSearch={false}
                  allowFiltering
                />
                <Column
                  dataField="ventaNumero"
                  caption="Número"
                  width="20%"
                  allowEditing={false}
                />
                <Column
                  dataField="clienteNombre"
                  caption="Cliente"
                  width="20%"
                  allowEditing={false}
                />
                <Column
                  dataField="ventaTotal"
                  caption="Total"
                  width="15%"
                  allowEditing={false}
                />
                <Column
                  dataField="valor"
                  caption="Abonado"
                  allowEditing={false}
                  width={'15%'}
                />
                <Column
                  dataField="saldo"
                  caption="Saldo"
                  width="15%"
                  allowEditing={false}
                />
                <Column
                  dataField="ventaFormaPagoDescripcion"
                  caption="F. Pago"
                  width="15%"
                  allowEditing={false}
                />
                <Column
                  dataField="ventaEstado"
                  caption="E"
                  width="20%"
                  allowEditing={false}
                />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol
          lg="12"
          md="12"
          sm="12"
          className="d-flex justify-content-end  align-items-center"
        >
          <CCol
            lg="3"
            md="3"
            sm="12"
            className="d-flex justify-content-end  align-items-center"
          >
            <TabFormasPago tabId={tabId} dptotales={dptotales2} />
          </CCol>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol
          lg="3"
          md="3"
          sm="12"
          className="d-flex justify-content-start  align-items-center"
        >
          <ButtonsInfo />
        </CCol>
        <CCol
          lg="9"
          md="9"
          sm="12"
          className="d-flex justify-content-end  align-items-center"
        >
          <CCol lg="3" md="3" xs="12" className="me-1">
            <GlobalLabel htmlFor="anuladas" className="col-form-label-md">
              <strong> {'Anuladas'} </strong>
            </GlobalLabel>
            <CRow>
              <CCol md="12">
                <NumberBox
                  readOnly
                  width="100%"
                  value={anuladascierre2_ti}
                  placeholder=""
                  format="#,##0.00"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol lg="3" md="3" xs="12">
            <GlobalLabel htmlFor="total" className="col-form-label-md">
              <strong> {'Total'} </strong>
            </GlobalLabel>
            <CRow>
              <CCol md="12">
                <NumberBox
                  readOnly
                  width="100%"
                  value={total2_ti}
                  placeholder=""
                  format="#,##0.00"
                />
              </CCol>
            </CRow>
          </CCol>
        </CCol>
      </CRow>
    </>
  )
}
export default NotasCredito
