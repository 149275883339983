import { RequestHelper } from '../../../../helpers/requestHelper'
import { TFiltroBusquedaPlanesState, TPlanesListado } from '../store/types'
import { PaginationInfo } from '../../../../store/types'

export const PlanesService = {
  getCollection,
  getResource,
  postResource,
  deleteResource,
}

async function getCollection(filtro: TFiltroBusquedaPlanesState): Promise<any> {
  try {
    const query = buildQuery(filtro)
    const pagedResponse = await RequestHelper.getAll<any>(
      'public',
      'getAllPlans',
      '',
      query,
    )
    pagedResponse.auto = pagedResponse.auto
      ? parseResponse(pagedResponse.auto)
      : []
    return pagedResponse
  } catch (error) {
    return error
  }
}

async function getResource(id: any): Promise<any> {
  try {
    // const query = {
    //   codigo: id,
    // };
    // return await RequestHelper.get<any>('province', 'getProvince', '', query);
    return new Promise((resolve, reject) => {})
  } catch (error) {
    return error
  }
}

async function postResource(plan: any, detalles: any): Promise<any> {
  try {
    const body = {
      plan: {
        ...plan,
      },
      items: {
        ...detalles,
      },
    }

    return await RequestHelper.postAll<any>('admin/planes', 'save', body)
  } catch (error) {
    return error
  }
}

async function deleteResource(id: any): Promise<any> {
  try {
    const query = {
      codigo: id,
    }

    return await RequestHelper.deleteRequestAll<any>(
      'admin/planes',
      'inactive',
      '',
      query,
    )
  } catch (error) {
    return error
  }
}

// Private
function buildQuery(
  filtro: TFiltroBusquedaPlanesState,
  pagination?: PaginationInfo,
): any {
  const query: any = {
    ...(filtro.nombre && { nombre: filtro?.nombre }),
    ...(filtro.paisCodigo && { pai_codigo: filtro?.paisCodigo?.codigo }),
    estado: filtro?.estado,
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['pais_codigo']

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query
}

function parseResponse(dataApi: any[]): Array<TPlanesListado> {
  return dataApi.map((item) => ({
    id: item.id,
    plan: item.plan,
    cadena: item.cadena,
    precio: item.precio,
    pupular: item.pupular,
    cod_base: item.cod_base,
    name_web: item.name_web,
    usuarios_base: item.usuarios_base,
    usuarios_extra: item.usuarios_extra,
    cod_item: item.cod_item,
    maxUsuarios: item.maxUsuarios,
    detalle: item.detalle.map((det) => ({
      codigo: det.codigo,
      descripcion: det.descripcion,
      estado: det.estado,
      planId: det.planId,
      isNew: false,
    })),
    iva: item.iva,
    maxComprobantes: item.maxComprobantes,
    tituloAlternativo: item.tituloAlternativo,
    planOrden: item.planOrden,
    tituloDetalle: item.tituloDetalle,
    visibleWeb: item.visibleWeb,
    categoriaCodigo: item.categoriaCodigo,
    categoriaDescripcion: item.categoriaDescripcion,
    categoriaEstado: item.categoriaEstado,
    valorConIva: item.valorConIva,
    codPeriodo: item.codPeriodo,
    codItemUsuarios: item.codItemUsuarios,
    codItemComprobantes: item.codItemComprobantes,
    controlaSesiones: item.controlaSesiones,
    movil: item.movil,
    pc: item.pc,
    tablet: item.tablet,
    detalleItemUsuario: item.detalleItemUsuario,
    detalleItemComprobante: item.detalleItemComprobante,
    seleccionado: item.seleccionado,
    estado: item.estado,
  }))
}
