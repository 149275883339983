import { combineReducers } from '@reduxjs/toolkit'
import { ventasReducer } from '../pages/ventas/store/ventasReducer'
import { notasCreditoReducer } from '../pages/notasCredito/store/notasCreditoReducer'
import { notasDebitoReducer } from '../pages/notasDebito/store/notasDebitoReducer'
import { guiasRemisionReducer } from '../pages/guiasRemision/store/guiasReducer'
import { ModuloVentasState } from '../types/types'
import { retencionesVentasReducer } from '../pages/retenciones/store/retencionesVentasReducer'
import { liquidacionesReducer } from '../pages/liquidaciones/store/liquidacionesReducer'
import { cuadresReducer } from '../pages/cuadres/store/cuadresReducer'
import { ordenesReducer } from '../pages/ordenes/store/ordenesReducer'
import { proformaReducer } from '../pages/proformas/store/proformasReducer'
import { moduloVentasSVReducer } from '../pages/ventas/facturacionSV/store/moduloVentasSVReducer'
import { parametrosCombineReducers } from "../pages/parametros/store/parametros.combine.reducers";

export const moduloVentasReducer = combineReducers<ModuloVentasState>({
  ventas: ventasReducer,
  ventasSV: moduloVentasSVReducer,
  notasCredito: notasCreditoReducer,
  notasDebito: notasDebitoReducer,
  guiasRemision: guiasRemisionReducer,
  retenciones: retencionesVentasReducer,
  liquidaciones: liquidacionesReducer,
  cuadres: cuadresReducer,
  ordenes: ordenesReducer,
  proformas: proformaReducer,
  parametros: parametrosCombineReducers,
})
