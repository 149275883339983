import {TVendedoresDatosEdicion} from "./types";

export const defaultDatosEdicionVendedores: TVendedoresDatosEdicion = {
  codigo: 0,
  comision: 0,
  estadoCodigo: null,
  localCodigo: null,
  siglas: "",
  usuarioApellidos: "",
  usuarioCodigo: 0,
  usuarioEmail: "",
  usuarioIdentificacion: "",
  usuarioNombres: "",
  usuarioTelefono: "",
  zona: "",
  edition: false,
  loader: {
    mensaje: 'Cargando...',
    show: true,
  },
  loading: true,
  tieneError: false,
  mensajeError: ''
}
