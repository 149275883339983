import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_STATUS } from '../../../../store/types'
import {
  TFiltroBusquedaPlanesMenuState,
  TInitialState,
  TFlattendMenu,
} from './types'
import { PlanFacturacion } from '../../../componentes/tiposPlanesLookUp/types/types'

const initialState: TInitialState = {
  filter: {
    codigoPlan: 0,
  },
  plan: null,
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  availableMenus: [],
  assignedMenus: [],
  selectedItem: null,
  error: false,
}

const searchConfSlice = createSlice({
  name: 'PlanesMenuSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaPlanesMenuState>) {
      state.filter = action.payload
    },
    setCleanAvailableMenus(state) {
      state.availableMenus = []
    },
    setCleanAssignedMenus(state) {
      state.assignedMenus = []
    },
    setAvailableMenus(state, action: PayloadAction<Array<TFlattendMenu> | []>) {
      state.availableMenus = action.payload
    },
    setAssignedMenus(state, action: PayloadAction<Array<TFlattendMenu> | []>) {
      state.assignedMenus = action.payload
    },
    updatePlan(state, action: PayloadAction<PlanFacturacion>) {
      state.plan = action.payload
    },
  },
})

export const {
  resetState,
  changeFilter,
  setCleanAvailableMenus,
  setCleanAssignedMenus,
  setAvailableMenus,
  setAssignedMenus,
  updatePlan,
} = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
