import { CLink } from '@coreui/react-pro'
import React from 'react'
import QrCode from '../qrcode/qrcode'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup'
interface QrPopoverProps {
  texto: string
  label?: string
  showEnlace?: boolean
  onClose: () => void
}

const QrPopover: React.FC<QrPopoverProps> = (props) => {
  const { texto, showEnlace, onClose } = props

  const [enlace, setEnlace] = React.useState<string>('')

  const configQrDocumento = React.useCallback(() => {
    setEnlace(texto)
  }, [texto])

  React.useEffect(() => {
    configQrDocumento()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Popup
        visible={showEnlace}
        onHiding={() => {
          onClose()
        }}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={false}
        container=".dx-viewport"
        width={'auto'}
        height={'auto'}
      >
        <Position at="bottom" my="center" of="#btnAddClient" collision="fit" />
        {enlace !== '' && <QrCode text={enlace} />}
        {enlace && (
          <CLink href={enlace} target="_blank">
            {'enlace'}
          </CLink>
        )}
      </Popup>
    </>
  )
}

export default QrPopover
