import { CButton, CCol, CContainer, CRow, CTooltip } from '@coreui/react-pro'
import React from 'react'
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { CuadresService } from '../../../../../services/cuadres.service'
import {
  DateUtils,
  formatoFechasApi,
} from '../../../../../../../../../helpers/dateUtils'
import {
  setDatosEdicion,
  updateDenominacionOnly,
  updateDescuadredes_tiOnly,
  updateDescuadre_tiOnly,
  updateDpIngresosOnly,
  updateGenerarCuadreOnly,
  updateTotalesaldosi_tiOnly,
  updateTotalinicialb_tiOnly,
  updateTotalinicial_tiOnly,
  updateDpCierre1Load,
  updateDpCierre7Load,
  updateDpCierre8Load,
  updateDpCierre3Load,
  updateDpCierre2Load,
  updateDpCierre9Load,
  updateDpCierre10Load,
  updateDpCierre11Load,
  updateDpCierre4Load,
  updateDpCierre5Load,
  updateObjBilletes,
  updateObjMonedas,
  updateObjDinero,
  updateEditableInput,
} from '../../../store/editDataReducer'
import {
  ccDetalle,
  CuadresCajaListado,
  DenominacionListado,
  ResumenListado,
  TabStateCuadre,
} from '../../../../../types/types'
import { StatesEdition } from '../../../../../../../types/enums'
import Dialog from '../../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import {
  getAbonosComprasCHelper,
  getAbonosCuadres,
  getChequesCuadresHelper,
  getCierreCajaNCHelper,
  getCierreCajaRet,
  getCierreCuadreActicipos,
  getCierreCuadreActiciposProveedoresHelper,
  getDatosFacturas,
  getLetrasCuadresHelper,
  getPagosCuadresHelper,
} from '../../../../../services/helper.cuadreCaja'
import { consoleService } from '../../../../../../../../../services/console.service'
interface IDenominacionProps extends React.PropsWithChildren {
  // currentTab: string,
  tab: TabStateCuadre<CuadresCajaListado>
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
  onErrorConfirm: (sms, title) => void
  onConfirmarRegistro: (sms, method) => void
  tabId: string
  statusLoaded: (load) => void
}

export const objetoBilletes = [
  { descripcion: 'CIEN', billeteValor: 100, cantidad: 0, total: 0.0, grupo: 1 },
  {
    descripcion: 'CINCUENTA',
    billeteValor: 50,
    cantidad: 0,
    total: 0.0,
    grupo: 1,
  },
  {
    descripcion: 'VEINTE',
    billeteValor: 20,
    cantidad: 0,
    total: 0.0,
    grupo: 1,
  },
  { descripcion: 'DIEZ', billeteValor: 10, cantidad: 0, total: 0.0, grupo: 1 },
  { descripcion: 'CINCO', billeteValor: 5, cantidad: 0, total: 0.0, grupo: 1 },
  { descripcion: 'UNO', billeteValor: 1, cantidad: 0, total: 0.0, grupo: 1 },
]

export const objetoMonedas = [
  { descripcion: 'UND', monedaValor: 1, cantidad: 0, total: 0.0, grupo: 2 },
  {
    descripcion: 'CINCUENTA',
    monedaValor: 0.5,
    cantidad: 0,
    total: 0.0,
    grupo: 2,
  },
  {
    descripcion: 'VEINTECINCO',
    monedaValor: 0.25,
    cantidad: 0,
    total: 0.0,
    grupo: 2,
  },
  { descripcion: 'DIEZ', monedaValor: 0.1, cantidad: 0, total: 0.0, grupo: 2 },
  {
    descripcion: 'CINCO',
    monedaValor: 0.05,
    cantidad: 0,
    total: 0.0,
    grupo: 2,
  },
  { descripcion: 'UNC', monedaValor: 0.01, cantidad: 0, total: 0.0, grupo: 2 },
]

const Denominacion: React.FunctionComponent<IDenominacionProps> = (props) => {
  const { tabId, onErrorConfirm, onConfirmarRegistro, tab, statusLoaded } =
    props
  const dialogRefPrint = React.useRef<any>(null)

  const dispatch = useDispatch()

  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId]
  })

  const {
    objetoBilletes: objBilletes,
    objetoMonedas: objMonedas,
    dptotalresumen,
    venFinal5,
    venInicial5,
    venFinal4,
    venInicial4,
    venFinal11,
    venInicial11,
    venFinal10,
    venInicial10,
    venFinal9,
    venInicial1,
    venFinal1,
    venInicial7,
    venFinal7,
    venInicial8,
    venFinal8,
    venInicial3,
    venFinal3,
    venInicial2,
    venFinal2,
    venInicial9,
    horaCierre,
    minutoCierre,
    fechaCierre,
    totalegreso_ti,
    descuadre_ti,
    descuadredes_ti,
    local,
    puntoVenta,
    denominacion,
    editableInput,
  } = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId]
  })

  const updateDenominacion = React.useCallback(() => {
    const denominacionVar: Array<DenominacionListado> = []
    objBilletes.forEach(function (item: any) {
      denominacionVar.push({
        descripcion: item.descripcion,
        valor: item.total,
        grupo: 1,
      })
    })
    objMonedas.forEach(function (item: any) {
      denominacionVar.push({
        descripcion: item.descripcion,
        valor: item.total,
        grupo: 2,
      })
    })
    // setDenominacion(denominacionVar)

    dispatch(
      updateDenominacionOnly({
        denominacion: denominacionVar,
        key: tabId,
      }),
    )
  }, [objBilletes, objMonedas, dispatch, tabId])

  const aplicaNuevoValorBilletes = React.useCallback(
    (data, i) => {
      const rowsDataUpdate = objBilletes?.slice()
      if (rowsDataUpdate[i]) {
        const updatedRow = { ...rowsDataUpdate[i] }

        updatedRow['cantidad'] = data
        updatedRow['total'] =
          Number(updatedRow['cantidad']) * Number(updatedRow['billeteValor'])

        const updatedRows = [...rowsDataUpdate]
        updatedRows[i] = updatedRow

        dispatch(
          updateObjBilletes({
            key: tabId,
            objetoBilletes: updatedRows,
          }),
        )
        updateDenominacion()
      }
    },
    [objBilletes, updateDenominacion, tabId, dispatch],
  )

  const aplicaNuevoValorMonedas = React.useCallback(
    (data, i) => {
      const rowsDataUpdate = objMonedas?.slice()
      if (rowsDataUpdate[i]) {
        const updatedRow = { ...rowsDataUpdate[i] }

        updatedRow[i]['cantidad'] = data
        updatedRow[i]['total'] =
          Number(updatedRow[i]['cantidad']) *
          Number(updatedRow[i]['monedaValor'])

        const updatedRows = [...rowsDataUpdate]
        updatedRows[i] = updatedRow

        dispatch(
          updateObjMonedas({
            key: tabId,
            objetoMonedas: updatedRows,
          }),
        )
        updateDenominacion()
      }
    },
    [objMonedas, updateDenominacion, tabId, dispatch],
  )

  const getValueEfectivo = React.useCallback(() => {
    let valorBilletes: number = 0
    let valorMonedas: number = 0
    let total: number = 0
    objBilletes.forEach(function (item: any) {
      valorBilletes = valorBilletes + item.total
    })
    objMonedas.forEach(function (item: any) {
      valorMonedas = valorMonedas + item.total
    })
    total = Number(valorBilletes) + Number(valorMonedas)
    return total
  }, [objMonedas, objBilletes])

  const validarFormulario = React.useCallback(async () => {
    const errors: any = []
    if (
      (puntoVenta && puntoVenta.codigoPuntoVenta < 0) ||
      (local && local.codigo < 0)
    ) {
      errors.push('Debe elegir un local o punto de venta.')
    }
    if (denominacion.length === 0) {
      errors.push('No existe registro de efectivo.')
    }
    if (minutoCierre === null || horaCierre === null) {
      errors.push('Ingrese la hora y minuto(s) del cuadre.')
    }

    if (fechaCierre === null || fechaCierre === '') {
      errors.push('Ingrese la fecha del cierre.')
    }

    if (errors.length > 0) {
      return {
        errors: true,
        messages: errors,
      }
    } else {
      return {
        errors: false,
        messages: errors,
      }
    }
  }, [puntoVenta, local, denominacion, minutoCierre, horaCierre, fechaCierre])

  // request cierreCaja2
  const getDatosFacturasCierre = React.useCallback(async () => {
    const resDpfacturasCuadre: any = await getDatosFacturas(
      fechaCierre,
      local,
      puntoVenta,
      venInicial1,
      venFinal1,
    )
    return resDpfacturasCuadre
  }, [fechaCierre, local, puntoVenta, venFinal1, venInicial1])

  // request CuadreCaja
  const getDatosActicipos = React.useCallback(async () => {
    const resDpCierreRet: any = await getCierreCuadreActicipos(
      fechaCierre,
      local,
      puntoVenta,
      venInicial7,
      venFinal7,
    )
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venFinal7, venInicial7])

  // request VerAbonosCuadres
  const getCuadresAbonos = React.useCallback(async () => {
    const resDpCierreRet: any = await getAbonosCuadres(
      fechaCierre,
      local,
      puntoVenta,
      venInicial8,
      venFinal8,
    )
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venFinal8, venInicial8])

  // request cierreCaja
  const getCajaRetCierre = React.useCallback(async () => {
    const resDpCierreRet: any = await getCierreCajaRet(
      fechaCierre,
      local,
      puntoVenta,
      venInicial3,
      venFinal3,
    )
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venFinal3, venInicial3])

  const getCierreCajaNC = React.useCallback(async () => {
    const resDpCierre: any = await getCierreCajaNCHelper(
      fechaCierre,
      local,
      puntoVenta,
      venInicial2,
      venFinal2,
    )
    return resDpCierre
  }, [fechaCierre, local, puntoVenta, venInicial2, venFinal2])

  const getCuadresCompras = React.useCallback(async () => {
    const resDpCierreRet: any = await getAbonosComprasCHelper(
      fechaCierre,
      local,
      puntoVenta,
      venInicial9,
      venFinal9,
    )
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venInicial9, venFinal9])

  const getPagosCuadres = React.useCallback(async () => {
    const resDpCierreRet: any = await getPagosCuadresHelper(
      fechaCierre,
      local,
      puntoVenta,
      venInicial10,
      venFinal10,
    )
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venInicial10, venFinal10])

  const getCuadreActiciposProv = React.useCallback(async () => {
    const resDpCierreRet: any = await getCierreCuadreActiciposProveedoresHelper(
      fechaCierre,
      local,
      puntoVenta,
      venInicial11,
      venFinal11,
    )
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venInicial11, venFinal11])

  const getChequesCuadres = React.useCallback(async () => {
    const resDpCierreRet: any = await getChequesCuadresHelper(
      fechaCierre,
      local,
      puntoVenta,
      venInicial4,
      venFinal4,
    )
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venInicial4, venFinal4])

  const getLetrasCuadres = React.useCallback(async () => {
    const resDpCierreRet: any = await getLetrasCuadresHelper(
      fechaCierre,
      local,
      puntoVenta,
      venInicial5,
      venFinal5,
    )
    return resDpCierreRet
  }, [fechaCierre, local, puntoVenta, venInicial5, venFinal5])

  const generarCuadre = React.useCallback(async () => {
    let dpfacturasCuadre = []
    let dpAnticiposClientes = []
    let dpCobrosCartera = []
    let dpRetenciones = []
    let dpNotasCredito = []
    let dpCompras = []
    let dpPagos = []
    let dpAnticiposProveedores = []
    let dpCheques = []
    let dpLetras = []

    dpfacturasCuadre = await getDatosFacturasCierre()
    if (dpfacturasCuadre.length > 0) {
      dispatch(
        updateDpCierre1Load({
          data: {
            loaddpcierre1: true,
            dpcierre1: dpfacturasCuadre,
          },
          key: tabId,
        }),
      )
    }
    dpAnticiposClientes = await getDatosActicipos()
    if (dpAnticiposClientes.length > 0) {
      dispatch(
        updateDpCierre7Load({
          data: {
            loaddpcierre7: true,
            dpcierre7: dpAnticiposClientes,
          },
          key: tabId,
        }),
      )
    }
    dpCobrosCartera = await getCuadresAbonos()
    if (dpCobrosCartera.length > 0) {
      dispatch(
        updateDpCierre8Load({
          data: {
            loaddpcierre8: true,
            dpcierre8: dpCobrosCartera,
          },
          key: tabId,
        }),
      )
    }
    dpRetenciones = await getCajaRetCierre()
    if (dpRetenciones.length > 0) {
      dispatch(
        updateDpCierre3Load({
          data: {
            loaddpcierre3: true,
            dpcierre3: dpRetenciones,
          },
          key: tabId,
        }),
      )
    }
    dpNotasCredito = await getCierreCajaNC()
    if (dpNotasCredito.length > 0) {
      dispatch(
        updateDpCierre2Load({
          data: {
            loaddpcierre2: true,
            dpcierre2: dpNotasCredito,
          },
          key: tabId,
        }),
      )
    }
    dpCompras = await getCuadresCompras()
    if (dpCompras.length > 0) {
      dispatch(
        updateDpCierre9Load({
          data: {
            loaddpcierre9: true,
            dpcierre9: dpCompras,
          },
          key: tabId,
        }),
      )
    }
    dpPagos = await getPagosCuadres()
    if (dpPagos.length > 0) {
      dispatch(
        updateDpCierre10Load({
          data: {
            loaddpcierre10: true,
            dpcierre10: dpPagos,
          },
          key: tabId,
        }),
      )
    }
    dpAnticiposProveedores = await getCuadreActiciposProv()
    if (dpAnticiposProveedores.length > 0) {
      dispatch(
        updateDpCierre11Load({
          data: {
            loaddpcierre11: true,
            dpcierre11: dpAnticiposProveedores,
          },
          key: tabId,
        }),
      )
    }
    dpCheques = await getChequesCuadres()
    if (dpCheques.length > 0) {
      dispatch(
        updateDpCierre4Load({
          data: {
            loaddpcierre4: true,
            dpcierre4: dpCheques,
          },
          key: tabId,
        }),
      )
    }
    dpLetras = await getLetrasCuadres()
    if (dpLetras.length > 0) {
      dispatch(
        updateDpCierre5Load({
          data: {
            loaddpcierre5: true,
            dpcierre5: dpLetras,
          },
          key: tabId,
        }),
      )
    }

    dispatch(
      updateGenerarCuadreOnly({
        generarCuadre: true,
        key: tabId,
      }),
    )
  }, [
    dispatch,
    tabId,
    getDatosFacturasCierre,
    getDatosActicipos,
    getCajaRetCierre,
    getCuadresAbonos,
    getCierreCajaNC,
    getCuadresCompras,
    getPagosCuadres,
    getCuadreActiciposProv,
    getChequesCuadres,
    getLetrasCuadres,
  ])

  const resultadoEspecial = React.useCallback(async () => {
    try {
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            editableInput: true,
            loader: {
              show: true,
              mensaje: 'Por favor espere...',
            },
          },
          key: tabId,
        }),
      )

      // setEditableInput(true)
      const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
      const dpingresos = await CuadresService.getEspecial(
        String(fechaFilter),
        98,
      )

      let saldoinicial: number = 0
      let saldoboveda: number = 0

      if (dpingresos.length > 0) {
        for (const cuadreItem of dpingresos) {
          if (cuadreItem.puntoVentaEspecial === 'BOVEDA') {
            saldoboveda = saldoboveda + cuadreItem.ventaTotal
          } else {
            saldoinicial = saldoinicial + cuadreItem.ventaTotal
          }
        }

        dispatch(
          updateTotalinicial_tiOnly({
            totalinicial_ti: saldoinicial,
            key: tabId,
          }),
        )

        dispatch(
          updateTotalinicialb_tiOnly({
            totalinicialb_ti: saldoboveda,
            key: tabId,
          }),
        )

        let totalesaldosi_ti: number = 0
        if (saldoboveda > 0) {
          totalesaldosi_ti = Number(saldoboveda) - Number(saldoinicial)
        } else {
          totalesaldosi_ti = Number(saldoinicial)
        }

        dispatch(
          updateTotalesaldosi_tiOnly({
            totalesaldosi_ti: totalesaldosi_ti,
            key: tabId,
          }),
        )
      }

      dispatch(
        updateDpIngresosOnly({
          dpingresos: dpingresos,
          key: tabId,
        }),
      )

      await generarCuadre()
      statusLoaded(true)
    } catch (ex) {
      statusLoaded(true)
    } finally {
      statusLoaded(true)
    }
  }, [datosEdicion, fechaCierre, tabId, dispatch, generarCuadre, statusLoaded])

  const handleClickConfirmar = React.useCallback(async () => {
    const res = await validarFormulario()
    if (res.errors === true) {
      onErrorConfirm(res.messages, 'Antes de continuar revise lo siguiente:')
    } else {
      const sms: string =
        'Esta a punto de registrar el cuadre, confirma la fecha ' +
        fechaCierre +
        ' y hora ' +
        horaCierre.toString() +
        ':' +
        minutoCierre.toString()
      onConfirmarRegistro(sms, resultadoEspecial)
    }
  }, [
    validarFormulario,
    onErrorConfirm,
    onConfirmarRegistro,
    resultadoEspecial,
    horaCierre,
    minutoCierre,
    fechaCierre,
  ])

  const sortJSON = (data, key, orden) => {
    return data.sort(function (a: number, b: number) {
      const x = a[key],
        y = b[key]

      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0
      }

      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0
      }
    })
  }

  const verificarDescuadre = React.useCallback(
    (denominacion, totalresumen) => {
      consoleService.log('verificarDescuadre', totalresumen)
      consoleService.log('denominacion', denominacion)
      let descuadre: number = 0
      let totalDenominacion: number = 0
      let textoDescuadre: string = 'Sobrante'
      denominacion.forEach(function (det) {
        totalDenominacion = Number(totalDenominacion) + Number(det.valor)
      })

      let ccdValor = 0
      if (totalresumen && totalresumen.length > 0 && totalresumen?.[0]?.total) {
        ccdValor = Number(totalresumen?.[0]?.total ?? 0)
      }
      consoleService.log('ccdValor', ccdValor)

      descuadre = Number(totalDenominacion - Number(ccdValor))
      descuadre = Number(descuadre.toFixed(2))
      consoleService.log('descuadre', descuadre)

      if (descuadre < 0) {
        textoDescuadre = 'Faltante'
      } else {
        textoDescuadre = 'Sobrante'
      }
      dispatch(
        updateDescuadre_tiOnly({
          descuadre_ti: descuadre,
          key: tabId,
        }),
      )
      dispatch(
        updateDescuadredes_tiOnly({
          descuadredes_ti: textoDescuadre,
          key: tabId,
        }),
      )
    },
    [dispatch, tabId],
  )

  const getTotalVentas = React.useCallback(() => {
    consoleService.log('dptotalresumen', dptotalresumen)
    let totalVentas: number = 0
    const totalventas_ti: number = datosEdicion?.totalventas_ti
    if (dptotalresumen.length > 0) {
      const dptotalresumenEfectivo = dptotalresumen.find(
        //EFECTIVO
        (x: any) => String(x?.valor) === 'EFECTIVO',
      )
      consoleService.log('dptotalresumenEfectivo', dptotalresumenEfectivo)

      if (
        dptotalresumenEfectivo !== undefined &&
        dptotalresumenEfectivo?.total
      ) {
        totalVentas =
          Number(totalventas_ti) + Number(dptotalresumenEfectivo?.total)
      }
    }
    return totalVentas
  }, [datosEdicion, dptotalresumen])

  const initForm = React.useCallback(
    (denominacion, edicion: boolean) => {
      let rowsObjetoBilletes: Array<any> = []
      let rowsObjetoMonedas: Array<any> = []

      if (edicion === false) {
        // setEditableInput(false)
        dispatch(
          updateEditableInput({
            key: tabId,
            editableInput: false,
          }),
        )

        rowsObjetoBilletes = [
          { descripcion: 'CIEN', billeteValor: 100, cantidad: 0, total: 0.0 },
          {
            descripcion: 'CINCUENTA',
            billeteValor: 50,
            cantidad: 0,
            total: 0.0,
          },
          { descripcion: 'VEINTE', billeteValor: 20, cantidad: 0, total: 0.0 },
          { descripcion: 'DIEZ', billeteValor: 10, cantidad: 0, total: 0.0 },
          { descripcion: 'CINCO', billeteValor: 5, cantidad: 0, total: 0.0 },
          { descripcion: 'UNO', billeteValor: 1, cantidad: 0, total: 0.0 },
        ]

        rowsObjetoMonedas = [
          { descripcion: 'UND', monedaValor: 1, cantidad: 0, total: 0.0 },
          {
            descripcion: 'CINCUENTA',
            monedaValor: 0.5,
            cantidad: 0,
            total: 0.0,
          },
          {
            descripcion: 'VEINTECINCO',
            monedaValor: 0.25,
            cantidad: 0,
            total: 0.0,
          },
          { descripcion: 'DIEZ', monedaValor: 0.1, cantidad: 0, total: 0.0 },
          { descripcion: 'CINCO', monedaValor: 0.05, cantidad: 0, total: 0.0 },
          { descripcion: 'UNC', monedaValor: 0.01, cantidad: 0, total: 0.0 },
        ]
      } else {
        // setEditableInput(true)
        dispatch(
          updateEditableInput({
            key: tabId,
            editableInput: true,
          }),
        )

        denominacion.forEach(function (det) {
          if (det.grupo === 0) {
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'CIEN'
            ) {
              rowsObjetoBilletes.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                billeteValor: 100,
                cantidad: Number(det.cantidad),
                tipo: 'billete',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'CINCUENTA'
            ) {
              rowsObjetoBilletes.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                billeteValor: 50,
                cantidad: Number(det.cantidad),
                tipo: 'billete',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'VEINTE'
            ) {
              rowsObjetoBilletes.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                billeteValor: 20,
                cantidad: Number(det.cantidad),
                tipo: 'billete',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'DIEZ'
            ) {
              rowsObjetoBilletes.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                billeteValor: 10,
                cantidad: Number(det.cantidad),
                tipo: 'billete',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'CINCO'
            ) {
              rowsObjetoBilletes.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                billeteValor: 5,
                cantidad: Number(det.cantidad),
                tipo: 'billete',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'UNO'
            ) {
              rowsObjetoBilletes.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                billeteValor: 1,
                cantidad: Number(det.cantidad),
                tipo: 'billete',
              })
            }
          }
        })
        rowsObjetoBilletes = sortJSON(
          rowsObjetoBilletes,
          'billeteValor',
          'desc',
        )

        denominacion.forEach(function (det) {
          if (det.grupo === 1) {
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'UND'
            ) {
              rowsObjetoMonedas.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                monedaValor: 1,
                cantidad: Number(det.cantidad),
                tipo: 'moneda',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'CINCUENTA'
            ) {
              rowsObjetoMonedas.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                monedaValor: 0.5,
                cantidad: Number(det.cantidad),
                tipo: 'moneda',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'VEINTECINCO'
            ) {
              rowsObjetoMonedas.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                monedaValor: 0.25,
                cantidad: Number(det.cantidad),
                tipo: 'moneda',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'DIEZ'
            ) {
              rowsObjetoMonedas.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                monedaValor: 0.1,
                cantidad: Number(det.cantidad),
                tipo: 'moneda',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'CINCO'
            ) {
              rowsObjetoMonedas.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                monedaValor: 0.05,
                cantidad: Number(det.cantidad),
                tipo: 'moneda',
              })
            }
            if (
              det.descripcion &&
              String(det.descripcion).toUpperCase() === 'UNC'
            ) {
              rowsObjetoMonedas.push({
                ...det,
                descripcion: det.descripcion,
                total: Number(det.total),
                monedaValor: 0.01,
                cantidad: Number(det.cantidad),
                tipo: 'moneda',
              })
            }
          }
        })
        rowsObjetoMonedas = sortJSON(rowsObjetoMonedas, 'monedaValor', 'desc')
      }

      dispatch(
        updateObjDinero({
          key: tabId,
          billetes: rowsObjetoBilletes,
          monedas: rowsObjetoMonedas,
        }),
      )
    },
    [dispatch, tabId],
  )

  React.useEffect(() => {
    initForm([], false)
  }, [puntoVenta, local, initForm])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.save && datosEdicion?.denominacion) {
      initForm(datosEdicion?.denominacion, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosEdicion?.denominacion])

  React.useEffect(() => {
    verificarDescuadre(datosEdicion?.denominacion, dptotalresumen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosEdicion?.denominacion, dptotalresumen])

  return (
    <>
      <Dialog ref={dialogRefPrint} />

      <CRow className={'m-2'}>
        <CCol lg="6" xl="6" md="6" sm="12">
          <CRow className={'mt-2 mp-2 d-flex justify-content-start'}>
            <CCol lg="12" xl="12" sm="12">
              <strong>{'BILLETES'}</strong>
            </CCol>
          </CRow>

          {objBilletes.map((billete, i) => {
            return (
              <CRow
                key={i}
                className={
                  'm-2 d-flex justify-content-center align-items-center'
                }
              >
                <CCol
                  xl="2"
                  md="1"
                  sm="1"
                  className={'d-flex justify-content-end'}
                >
                  <strong>{billete.billeteValor}</strong>
                </CCol>
                <CCol xl="0" md="1" sm="1">
                  <strong>{'X'}</strong>
                </CCol>
                <CCol xl="3" md="3" sm="2">
                  <NumberBox
                    readOnly={editableInput}
                    format="#0"
                    min={0}
                    placeholder=""
                    value={billete.cantidad}
                    showSpinButtons={true}
                    onValueChange={(data) => aplicaNuevoValorBilletes(data, i)}
                  />
                </CCol>
                <CCol xl="0" md="1" sm="1">
                  <strong>{'='}</strong>
                </CCol>
                <CCol xl="3" md="1" sm="1">
                  <strong>{Number(billete.total).toFixed(2)}</strong>
                </CCol>
              </CRow>
            )
          })}
        </CCol>
        <CCol lg="6" xl="6" md="6" sm="12">
          <CRow className={'mt-2 mp-2 d-flex justify-content-start'}>
            <CCol lg="12" xl="12" sm="12">
              <strong>{'MONEDAS'}</strong>
            </CCol>
          </CRow>

          {objMonedas.map((moneda, i) => {
            return (
              <CRow
                key={i}
                className={
                  'm-2 d-flex justify-content-center align-items-center'
                }
              >
                <CCol
                  xl="2"
                  md="1"
                  sm="1"
                  className={'d-flex justify-content-end'}
                >
                  <strong>{moneda.monedaValor}</strong>
                </CCol>
                <CCol xl="0" md="1" sm="1">
                  <strong>{'X'}</strong>
                </CCol>
                <CCol xl="3" md="3" sm="2">
                  <NumberBox
                    readOnly={editableInput}
                    format="#0"
                    min={0}
                    placeholder=""
                    value={moneda.cantidad}
                    showSpinButtons={true}
                    onValueChange={(data) => aplicaNuevoValorMonedas(data, i)}
                  />
                </CCol>
                <CCol xl="0" md="1" sm="1">
                  <strong>{'='}</strong>
                </CCol>
                <CCol xl="3" md="1" sm="1">
                  <strong>{Number(moneda.total).toFixed(2)}</strong>
                </CCol>
              </CRow>
            )
          })}
        </CCol>
      </CRow>

      <CContainer>
        <div className="row mt-4 mp-2 d-flex justify-content-center align-items-center">
          <CCol
            md={{ span: 8, offset: 0 }}
            className="  d-flex justify-content-center align-items-center"
          >
            <strong style={{ fontSize: '20px', fontWeight: 400 }}>
              {'TOTAL EFECTIVO : '}
            </strong>
            <strong style={{ fontSize: '20px', fontWeight: 600 }}>
              {getValueEfectivo().toFixed(2)}
            </strong>
          </CCol>

          <CCol
            md={{ span: 8, offset: 0 }}
            className="  d-flex justify-content-center align-items-center"
          >
            <CTooltip
              key={'tool-plus-account'}
              placement="top"
              content={'CONFIRMAR'}
            >
              <CButton
                id="btnConfirmar"
                color="secondary"
                className="mt-4 mb-4"
                size="sm"
                onClick={() => handleClickConfirmar()}
              >
                {'CONFIRMAR'}
              </CButton>
            </CTooltip>
          </CCol>

          <CCol
            md={{ span: 8, offset: 0 }}
            className="  d-flex justify-content-center align-items-center"
          >
            <strong style={{ fontSize: '20px', fontWeight: 400 }}>
              {'VENTA REGISTRADA : '}
            </strong>
            <strong style={{ fontSize: '20px', fontWeight: 600 }}>
              {getTotalVentas().toFixed(2)}
            </strong>
          </CCol>

          <CCol
            md={{ span: 8, offset: 0 }}
            className="  d-flex justify-content-center align-items-center"
          >
            <strong style={{ fontSize: '20px', fontWeight: 400 }}>
              {'TOTAL EGRESOS : '}
            </strong>
            <strong style={{ fontSize: '20px', fontWeight: 600 }}>
              {Number(Number(totalegreso_ti).toFixed(2))}
            </strong>
          </CCol>

          <CCol
            md={{ span: 8, offset: 0 }}
            className="  d-flex justify-content-center align-items-center"
          >
            <strong
              className={''}
              style={{ fontSize: '20px', fontWeight: 400 }}
            >
              {'DESCUADRE : '}
            </strong>
            <strong
              style={{
                fontSize: '20px',
                color: descuadredes_ti === 'Faltante' ? '#A52A2A' : '',
              }}
            >
              {Number(Number(descuadre_ti).toFixed(2))} {' ' + descuadredes_ti}
            </strong>
          </CCol>
        </div>
      </CContainer>
    </>
  )
}
export default Denominacion
