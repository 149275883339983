import React, { memo } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import TextBox from 'devextreme-react/text-box'
import { CCard, CCardBody, CCardHeader, CLink } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import { useSelector } from 'react-redux'

import CustomCol from '../../../../../views/componentes/colContainer'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import ResultadoBusqueda from './resultadoBusqueda'
import { ToastTypes } from '../../../../../store/types'
import { RootState } from '../../../../../store/store'
import { OrdenProformaListado } from '../../store/types'
import DateBox from 'devextreme-react/date-box'
import { utilidades } from '../../../../../helpers/utilidades'
import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils'
import { BuscarLocalLookUp } from '../../../../shared/components/buscarLocalesLookMemoUp/buscarLocalLookUp'
import BuscarClienteLookUp from '../../../../ventas/components/busquedaCliente/busquedaCliente'
import BuscarItemPedidoMemoLookUp from '../../../../ventas/pages/proformas/pages/ordenProforma/components/busquedaProformas/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import { EModulosAcatha } from '../../../../../store/enumsAplication'
import { FiltroBusquedaOrdenProformaState } from '../../../../ventas/pages/proformas/type/types'

interface TBuscarItemsProps extends React.PropsWithChildren {
  onChanged: (data) => void
  onEdit: (data) => void
  onCancel?: () => void
  funcion: string
  resetFunction: () => void
  ejecutarBusqueda: () => void
  seleccion: OrdenProformaListado
  currentTab: string
  setToast: (texto: string, type: ToastTypes) => void
  playLoader?: () => void
  stopLoader?: () => void
}

type infoPaginationType = {
  desde: number
  hasta: number
  paginaActual: number
  totalPaginas: number
  totalRegistros: number
}

const infoPaginationInit = {
  desde: 1,
  hasta: 10,
  paginaActual: 0,
  totalPaginas: 0,
  totalRegistros: 0,
}

type TFindOrdenProforma = FiltroBusquedaOrdenProformaState & {}

const initDataFind = {
  fechaInicio: DateUtils.getCurrentDateAsString(),
  fechaFin: DateUtils.getCurrentDateAsString(),
  numero: '',
  cliente: null,
  local: null,
  item: null,
}

const Buscar = (props: TBuscarItemsProps) => {
  const {
    setToast,
    ejecutarBusqueda,
    currentTab,
    funcion,
    seleccion,
    resetFunction,
    onChanged,
    onEdit,
    playLoader,
    stopLoader,
  } = props

  const formSearchRef = React.useRef<any>()

  const [collapsed, setCollapsed] = React.useState(true)
  const [dataSearch, setDataSearch] =
    React.useState<TFindOrdenProforma>(initDataFind)
  const [infoPagination, setInfoPagination] =
    React.useState<infoPaginationType>(infoPaginationInit)
  const [items, setItems] = React.useState<any>([])

  return (
    <>
      <fieldset>
        <ValidationGroup ref={formSearchRef}>
          <CCard>
            <CCardHeader
              onClick={() => {}}
              className="d-flex justify-content-between"
            >
              <strong> {'Buscar'} </strong>
              <div className="card-header-actions">
                <CLink className="card-header-action">
                  <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
                </CLink>
              </div>
            </CCardHeader>

            <CCardBody>
              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label="Fecha Desde">
                    <DateBox
                      disabled={dataSearch.numero.length > 0}
                      value={dataSearch.fechaInicio}
                      onValueChanged={({ value }: any) => {
                        if (value) {
                          setDataSearch({
                            ...dataSearch,
                            fechaInicio: value,
                          })
                        }
                      }}
                      onEnterKey={() => ejecutarBusqueda()}
                    ></DateBox>
                  </Labeled>
                </CustomCol>

                <CustomCol xs="12" md="4">
                  <Labeled label="Fecha Hasta">
                    <DateBox
                      disabled={dataSearch.numero.length > 0}
                      value={dataSearch.fechaFin}
                      onValueChanged={({ value }: any) => {
                        if (value) {
                          setDataSearch({
                            ...dataSearch,
                            fechaFin: value,
                          })
                        }
                      }}
                      onEnterKey={() => ejecutarBusqueda()}
                    ></DateBox>
                  </Labeled>
                </CustomCol>

                <CustomCol xs="6" sm="8" md="4">
                  <Labeled label="# Proforma">
                    <TextBox
                      value={dataSearch.numero}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                      onValueChanged={(e) => {
                        setDataSearch({
                          ...dataSearch,
                          numero: e.value ?? '',
                        })
                      }}
                      showClearButton={true}
                      onEnterKey={() => ejecutarBusqueda()}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12" md="4">
                  <Labeled label="Local">
                    <BuscarLocalLookUp
                      allowClear
                      selected={dataSearch.local}
                      onChanged={(value) => {
                        setDataSearch({
                          ...dataSearch,
                          local: value,
                        })
                      }}
                      disabled={dataSearch.numero.length > 0}
                      onEnterKey={() => ejecutarBusqueda()}
                    />
                  </Labeled>
                </CustomCol>

                <CustomCol xs="12" md="4">
                  <Labeled label="Cliente">
                    <BuscarClienteLookUp
                      allowClear
                      selected={dataSearch.cliente}
                      onChanged={(value) => {
                        setDataSearch({
                          ...dataSearch,
                          cliente: value,
                        })
                      }}
                      disabled={dataSearch.numero.length > 0}
                      onEnterKey={() => ejecutarBusqueda()}
                    />
                  </Labeled>
                </CustomCol>

                <CustomCol xs="12" md="8">
                  <Labeled label="Item">
                    <BuscarItemPedidoMemoLookUp
                      selected={dataSearch.item}
                      onChanged={(value) => {
                        setDataSearch({
                          ...dataSearch,
                          item: value,
                        })
                      }}
                      cliente={null}
                      fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                      allowClear={true}
                      modulo={EModulosAcatha.Proformas}
                      onEnterKey={() => ejecutarBusqueda()}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CCardBody>
          </CCard>

          <RowContainer>
            <CustomCol>
              <ResultadoBusqueda
                funcion={funcion}
                data={items}
                dataSearch={dataSearch}
                resetFunction={() => {
                  resetFunction()
                }}
                setDataItems={(data: any) => {
                  setItems(data)
                }}
                onChanged={onChanged}
                playLoader={playLoader}
                stopLoader={stopLoader}
              />
            </CustomCol>
          </RowContainer>
        </ValidationGroup>
      </fieldset>
    </>
  )
}
export default memo(Buscar)
