import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import React from 'react'
import ReactDOM from 'react-dom'
import Popup from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import { dxPopupToolbarItem } from 'devextreme/ui/popup'
import { isMobile, isMobileOnly } from 'react-device-detect'

interface ICustomModalProps extends React.PropsWithChildren {
  show: boolean
  onClose: () => void
  title: string
  size: 'sm' | 'lg' | 'xl'
  portalElementId?: string
  canClose?: boolean
}

export const CustomModal: React.FC<ICustomModalProps> = (props) => {
  const portalTarget = React.useMemo(() => {
    const element = document.querySelector('#modal')
    return element
  }, [])

  const popup = React.useMemo(() => {
    return <Modal {...props} />
  }, [props])

  if (props.show && portalTarget) {
    return ReactDOM.createPortal(popup, portalTarget)
  }
  return null
}

const Modal: React.FC<ICustomModalProps> = (props) => {
  return (
    <CModal
      size={props.size}
      backdrop="static"
      visible={props.show}
      onClose={props.onClose}
    >
      <ScrollView width="100%" height="100%">
        {props.title && (
          <CModalHeader>
            <CModalTitle className="ms-3">{props.title}</CModalTitle>
            <button
              className="close me-0"
              disabled={props.canClose === undefined ? false : !props.canClose}
              onClick={props.onClose}
            >
              x
            </button>
          </CModalHeader>
        )}
        <CModalBody>{props.children}</CModalBody>
      </ScrollView>
    </CModal>
  )
}

interface IModelDevProps extends React.PropsWithChildren {
  show: boolean
  onClose: () => void
  title: string
  width?: string | number
  height?: string | number
  toolbarItems?: Array<dxPopupToolbarItem>
  toolbarLocation?: string
  size?: 'sm' | 'md' | 'lg'
  position?:
    | 'bottom'
    | 'center'
    | 'left'
    | 'left bottom'
    | 'left top'
    | 'right'
    | 'right bottom'
    | 'right top'
    | 'top'
  id?: string
  showCloseButton?: boolean
}

const CustomModalDevx: React.FC<IModelDevProps> = (props) => {
  const portalTarget = React.useMemo(() => {
    const element = document.querySelector('#modal')
    return element
  }, [])

  const popup = React.useMemo(() => {
    return <ModalDevx {...props} />
  }, [props])

  if (portalTarget) {
    return ReactDOM.createPortal(popup, portalTarget)
  }
  return null
}

const ModalDevx: React.FC<IModelDevProps> = (props) => {
  const { width, height, size, position } = props

  const getWidth = React.useCallback(
    (
      size: 'sm' | 'md' | 'lg' | undefined,
      width: string | number | undefined,
    ): string | number | undefined => {
      if (width) {
        return width
      }
      if (size) {
        if (isMobileOnly) {
          return window.innerWidth / 1.1
        }
        switch (size) {
          case 'sm':
            return '30%'
          case 'md':
            return '60%'
          case 'lg':
            return '80%'
        }
      }
      return undefined
    },
    [],
  )

  const getHeight = React.useCallback(
    (
      size: 'sm' | 'md' | 'lg' | undefined,
      height: string | number | undefined,
    ): string | number | undefined => {
      if (height) {
        return height
      }
      return undefined
    },
    [],
  )

  return (
    <Popup
      title={props.title}
      width={!isMobile ? getWidth(size, width) : '80%'}
      height={!isMobile ? getHeight(size, height) : '80%'}
      visible={props.show}
      showTitle={props.title !== ''}
      closeOnOutsideClick={false}
      showCloseButton={props?.showCloseButton ?? true}
      onHiding={props.onClose}
      toolbarItems={props.toolbarItems}
      shadingColor="rgba(0,0,0,0.4)"
      position={position ?? 'center'}
      accessKey={props.id}
      id={props.id}
    >
      {!isMobile && (
        <ScrollView width={'100%'} height={'100%'}>
          <React.Fragment key="popupContent">{props.children}</React.Fragment>
        </ScrollView>
      )}
      {isMobile && (
        <ScrollView
          width="100%"
          height="85%"
          direction="both"
          showScrollbar="always"
          bounceEnabled={false}
        >
          <div style={{ width: '100%', height: '85%' }}>
            <React.Fragment key="popupContent">{props.children}</React.Fragment>
          </div>
        </ScrollView>
      )}
    </Popup>
  )
}
export default React.memo(CustomModalDevx)
