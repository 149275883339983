import React from 'react'
import { PlanesCategoriaService } from './services/planesCategoria.service'
import { v4 as uuidv4 } from 'uuid'
import { SelectBoxDE } from '../../../views/componentes/selectBox-DE/selectBox-DE'

export type TPlanesCategoria = {
  codigo: number
  descripcion: string
  estado: boolean
}

interface PlanesCategoriasLookup extends React.PropsWithChildren {
  selected: TPlanesCategoria
  onChange: (event: any) => void
  disabled?: boolean
  provider?: TPlanesCategoria[] | []
  id?: string
}

const PlanesCategoriasLookup: React.FC<PlanesCategoriasLookup> = (props) => {
  const { id, provider, selected, onChange, disabled } = props
  const selectRef = React.useRef<any>(null)
  const [options, setOptions] = React.useState<TPlanesCategoria[]>([])

  const init = React.useCallback(async () => {
    if (provider?.length) {
      setOptions(provider)
      return
    }

    const { error, auto } = await PlanesCategoriaService.getCategoryPlans({})
    if (!error && auto.length) {
      const opt: TPlanesCategoria[] = auto.map((x: any) => ({
        codigo: x.codigo,
        descripcion: x.descripcion,
        estado: x.estado,
      }))
      setOptions(opt)
    }
  }, [provider])

  React.useEffect(() => {
    if (!selected && options.length > 0) {
      const defaultOption = options.find((opt) => opt.codigo === 1)
      if (defaultOption) {
        onChange(defaultOption)
      }
    }
  }, [selected, options, onChange])

  React.useEffect(() => {
    init()
  }, [])

  return (
    <SelectBoxDE
      id={id ?? 'planesCategoriasSelect'}
      key={uuidv4()}
      ref={selectRef}
      items={options}
      displayExpr="descripcion"
      searchExpr={['descripcion']}
      value={options.find((opt) => opt.codigo === selected?.codigo) ?? null}
      onValueChange={onChange}
      disabled={disabled}
      onFocusIn={(e: any) =>
        e.element.querySelector('input.dx-texteditor-input').select()
      }
    >
      {props.children}
    </SelectBoxDE>
  )
}

export default React.memo(PlanesCategoriasLookup)
