import {
  CustomDictionary,
  OptionGrupos,
  Seleccionado,
  TipoAgente,
  TipoNegocio,
} from '../../../../../store/types'
import { Proveedor } from '../../../store/types'

import { OptionCiudad } from '../../../../../containers/component/formLocal/types/types'
import { FETCH_STATUS, LoaderInfo, TEstado } from '../../../../../store/types'
import { TipoIdentificacion } from '../../../../componentes/tipoIdentificacion/store/types'
import { MenuState, TipoProveedor } from '../../../../ventas/types/types'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  TOptionGenerico,
  TRetencionesIva,
  TelefonoCliente,
} from '../../../../clientes/pages/clientes/types/types'
import { ClasificacionOption } from '../../../../componentes/lookUpClasificacionAdicional/store/type'
import { EspecialidadPrv } from '../../../../componentes/especialidadProveedor/store/types'
import { TFormasPago } from '../../../../componentes/formasPagoLookUp/store/type'
import { SutentosTributarios } from '../../../../componentes/sustentosTributarioLookUp/types/types'
import { TiposEmpresasOption } from '../../../../componentes/tiposEmpresasLookUp/store/type'
import { TabsState } from '../../../../../store/genericTabTypes'

export enum TIPOS_BOTONES {
  // eslint-disable-next-line no-unused-vars
  BOTON_NUEVO = 'Nuevo',
  // eslint-disable-next-line no-unused-vars
  BOTON_GUARDAR = 'Guardar',
  // eslint-disable-next-line no-unused-vars
  BOTON_EDITAR = 'Editar',
  // eslint-disable-next-line no-unused-vars
  BOTON_ELIMINAR = 'Eliminar',
  // eslint-disable-next-line no-unused-vars
  BOTON_BUSCAR = 'Buscar',
  // eslint-disable-next-line no-unused-vars
  BOTON_LIMPIAR = 'Limpiar',
  // eslint-disable-next-line no-unused-vars
  BOTON_REFRESCAR = 'Refrescar',
  // eslint-disable-next-line no-unused-vars
  BOTON_EXPORTAR = 'Exportar',
  // eslint-disable-next-line no-unused-vars
  BOTON_IMPORTAR = 'Importar',
  // eslint-disable-next-line no-unused-vars
  BOTON_IMPRIMIR = 'Imprimir',
  // eslint-disable-next-line no-unused-vars
  BOTON_DESHACER = 'Deshacer',
  // eslint-disable-next-line no-unused-vars
  BOTON_SALIR = 'Salir',
}

export type BotonMenu = {
  id: TIPOS_BOTONES
  text: string
  icon: IconDefinition | null
}

export type ModalProveedoresState = {
  modulo: string
  currentAction: string
  registros: Array<Proveedor>
  seleccionado: Seleccionado
  tipos: Array<OptionGrupos>
  estados: Array<OptionGrupos>
}

export type ConfiguracionesProveedor = {
  modulo: number
}

export type ProveedoresListado = {
  codigo: number
  identificacion: string
  razonComercial: string
}

export type SuppliersList = {
  codigo: number
  tipoIdentificacion: string
  tipoIdDescripcion: string
  identificacion: string
  nombre: string
  razonComercial: string
  direccion: string
  email: string
  telefono: string
  limiteCredito: number
  diasCredito: number
  personaAtencionCompras: string
  personaAtencionPagos: string
  intervalo: number
  diaReposicion: string
  ciudadNombre: string
  saldo: number
  nrc?: string
  tipoNegocioDescripcion?: string
  tipoNegocioCodigo?: number
}

export type FiltrosProveedoresState = {
  identificacion: string
  nombre: string
  razonComercial: string
  tipoIdentificacion: TipoIdentificacion | null
  ciudad: OptionCiudad | null
  estado: boolean
  importar: boolean
}

export type SearchState<T> = {
  filter: FiltrosProveedoresState
  status: FETCH_STATUS
  mensaje: string
  resultados: Array<T>
  editarRegistro: boolean
  verRegistro: boolean
  seleccionado: Seleccionado
  eliminarItem: boolean
}

export type WindowProveedoresState<T, K> = {
  menu: MenuState
  tabs: TabsState<T>
  search: SearchState<T>
  editData: CustomDictionary<K> // Array<K>
  modalProveedores: ModalProveedoresState
  configuraciones: ConfiguracionesProveedor
}

export type DatosEdicionContribuyente = {
  codigo: number
  nombre: string
  razonComercial: string
}

export type TabProveedorState = {
  contribuyente: DatosEdicionContribuyente
  generales: DatosEdicionGenerales
  compras: DatosEdicionCompra
  historicos: DatosEdicionHistorico
  adicionales: DatosEdicionAdicionales
}

export type TMainEdition = {
  imprimir: boolean
  loader: LoaderInfo
  tieneError: boolean
  mensajeError: string
  loading: boolean
  desdePlantilla: boolean
  codigoProveedor: number | null
  mensajesErrors: Array<string>
}

export type ProveedoresDatosEdicion = {
  imprimir: boolean
  loader: LoaderInfo
  tieneError: boolean
  mensajeError: string
  mensajesErrors: Array<string>
  loading: boolean
  desdePlantilla: boolean
  codigoProveedor: number | null
  tabs: TabProveedorState
}

export type DatosEdicionGenerales = {
  tipoIdentificacion: TipoIdentificacion | null
  tiposIdentificacion: Array<TipoIdentificacion>
  identificacion: string
  dui?: string
  proveedorSeguro: boolean
  artesanoCalificado: boolean
  direccion: string
  email: string
  estado: TEstado | null
  estados: Array<TEstado> | []
  telefonos: Array<TelefonoCliente>
  ciudad: OptionCiudad | null
  tipoProveedor: TipoProveedor | null
  tiposProveedor: Array<TipoProveedor> | []
  clasificacion: ClasificacionOption | null
  clasificacionOptions: Array<ClasificacionOption> | []
  parteRelacionada: TOptionGenerico | null
  transportista: boolean
  nrc?: string
  actividadEconomica?: TipoNegocio
  tiposAgente?: TipoAgente[] | []
  tipoAgente?: TipoAgente | null
}

export type DatosEdicionCompra = {
  diasCredito: number
  limiteCredito: number
  descuento: number
  saldo: number
  sustentoTri: SutentosTributarios | null
  sustentoTriOptions: Array<SutentosTributarios> | []
  atencionCompras: string
  atencionPagos: string
  retenciones: Array<TRetencionesIva> | []
  formasPago: Array<TFormasPago> | []
}

export type DatosEdicionHistorico = {
  limiteCreditoFecha: string
  comprasAnuales: number
  ultimoPago: { fecha: string; documento: string; monto: number }
  ultimaCompra: { fecha: string; documento: string; monto: number }
}

export type DatosEdicionAdicionales = {
  observaciones: string
  sitioweb: string
  tipoEmpresa: TiposEmpresasOption | null
  tiposEmpresaDp: Array<TiposEmpresasOption> | []
  especialidad: EspecialidadPrv | null
  especialidadOptions: Array<EspecialidadPrv> | []
  reposicion: number
  diaVisita: TOptionGenerico | null
}

export type SupplierssState = WindowProveedoresState<
  ProveedoresListado,
  ProveedoresDatosEdicion
> & {
  // configuraciones: ConfiguracionesVentas;
}
