// regex.ts
// empresiones regulares para el salvador

export const regexPatterns = {
  // Validación de correo electrónico
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

  // Validación de número de teléfono (10 dígitos, por ejemplo para USA)
  phoneNumber: /^\d{10}$/,

  // Validación de código postal (5 dígitos)
  zipCode: /^\d{5}$/,

  // Validación de URL
  url: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,

  // Validación de fecha en formato YYYY-MM-DD
  date: /^\d{4}-\d{2}-\d{2}$/,

  // Validación de contraseñas (mínimo 8 caracteres, al menos una letra y un número)
  password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,

  // Validación de nombres (solo letras y espacios, sin números o caracteres especiales)
  name: /^[a-zA-Z\s]+$/,

  // Validación de identificador de usuario (alfanumérico, guiones y guiones bajos)
  username: /^[a-zA-Z0-9_-]{3,16}$/,

  // Validación de texto alfanumérico
  alphanumeric: /^[a-zA-Z0-9]+$/,

  // Validación de dirección IPv4
  ipv4: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,

  // Validación de dirección MAC
  macAddress: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
  // Validación de Codigo de generacion
  codGeneracion:
    /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/,
  // Validación de Codigo de generacion
  numeroControl: /^DTE-[0-9]{2}-[A-Z0-9]{8}-[0-9]{15}$/,
  // Validación de Sello de recepcion
  selloRecepcion: /^[A-Z0-9]{20,40}$/,
  nit: /^([0-9]{14}|[0-9]{9})$/,
  nrc: /^[0-9]{1,8}$/,
  // Valida el codigo de generacion
  codigoGeneracion:
    /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/,
  dui: /^[0-9]{8}-[0-9]{1}$/,
}

// Puedes exportar funciones que usen estas expresiones para validaciones si lo prefieres
export const validate = {
  email: (value: string) => regexPatterns.email.test(value),
  phoneNumber: (value: string) => regexPatterns.phoneNumber.test(value),
  zipCode: (value: string) => regexPatterns.zipCode.test(value),
  url: (value: string) => regexPatterns.url.test(value),
  date: (value: string) => regexPatterns.date.test(value),
  password: (value: string) => regexPatterns.password.test(value),
  name: (value: string) => regexPatterns.name.test(value),
  username: (value: string) => regexPatterns.username.test(value),
  alphanumeric: (value: string) => regexPatterns.alphanumeric.test(value),
  ipv4: (value: string) => regexPatterns.ipv4.test(value),
  macAddress: (value: string) => regexPatterns.macAddress.test(value),
  codGeneracion: (value: string) => regexPatterns.codGeneracion.test(value),
  numeroControl: (value: string) => regexPatterns.numeroControl.test(value),
  selloRecepcion: (value: string) => regexPatterns.selloRecepcion.test(value),
  nit: (value: string) => regexPatterns.nit.test(value),
  nrc: (value: string) => regexPatterns.nrc.test(value),
}
