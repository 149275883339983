import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store/store'
import { FETCH_STATUS } from '../../../../store/types'
import { TBotonListado, TFiltroBusquedaBotonesState } from './types'
import { BotonesService } from '../services/botones.services'
import { TConfigSearchState } from '../../configuracion/store/types'

const initialState: TConfigSearchState<TBotonListado> = {
  filter: {
    descripcion: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchPlanes = createAsyncThunk<
  Array<TBotonListado>,
  TFiltroBusquedaBotonesState
>('admin/botones', async (filtro) => {
  try {
    return await BotonesService.getCollection(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchConfSlice = createSlice({
  name: 'BotonesSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(state, action: PayloadAction<TFiltroBusquedaBotonesState>) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(state, action: PayloadAction<Array<TBotonListado> | []>) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlanes.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(
      fetchPlanes.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.resultados = payload.auto
        state.status = FETCH_STATUS.SUCCESS
        state.error = payload.error
        state.mensaje = payload.message
      },
    )
    builder.addCase(
      fetchPlanes.rejected,
      (state, { payload }: { payload: any }) => {
        state.resultados = []
        state.status = FETCH_STATUS.FAILED
        state.error = payload.error
        state.mensaje = payload.message
      },
    )
  },
})

export const selectFiltroBusquedaBotones = (state: RootState) => {
  return state.administracion.botones.search.filter
}

export const selectResultadosBusquedaBotones = (state: RootState) => {
  return state.administracion.botones.search.resultados
}

export const selectEstadoBusquedaBotones = (state: RootState) => {
  return state.administracion.botones.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } =
  searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
