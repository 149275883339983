import { CCol, CRow } from '@coreui/react-pro'
import React from 'react'
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  update11VenInicialFinal,
  updateDptotales11Only,
  updateDptotalesAnticiposProveedoresOnly,
  updateLoadDpCierre11Only,
  updateTotal11_tiOnly,
} from '../../../store/editDataReducer'
import DataGrid, {
  Column,
  HeaderFilter,
  Paging,
  Pager,
  Export,
} from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../../views/componentes/colContainer'
import { utilidades } from '../../../../../../../../../helpers/utilidades'
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize'
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper'
import {
  AnticiposCuadresCajaListado,
  ccDetalle,
  formasPagoCuadre,
} from '../../../../../types/types'
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago'
import { GlobalLabel } from '../../../../../../../../../views/componentes/globalLabel/globalLabel'
import ButtonsInfo from '../shared/ButtonsInfo'
import { exportFormats } from '../facturas/facturas'

interface IAnticiposProveedoresProps extends React.PropsWithChildren {
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
  onErrorConfirm: (sms, title) => void
  onConfirmarRegistro: (sms, method) => void
  tabId: string
  statusLoaded: (load) => void
}

const AnticiposProveedores: React.FunctionComponent<
  IAnticiposProveedoresProps
> = (props) => {
  const { tabId, statusLoaded } = props
  const dataGrid = React.useRef<any>()

  const dispatch = useDispatch()
  const [heigth] = useGridResultadosSize()

  const generarCuadre = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre
  })
  const loaddpcierre11 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loaddpcierre11
  })

  const total11_ti = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].total11_ti
  })
  const dptotales11 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales11
  })
  const dpfpagos = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos
  })
  const dptotales = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales
  })
  const dpcierre11 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre11
  })

  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showPageSizeSelector] = React.useState<boolean>(true)

  const resultadoCierreCajaRet = React.useCallback(
    async (dpCierreFacturasVar: Array<AnticiposCuadresCajaListado> | []) => {
      const detalle = {
        ccdCodigo: 0,
        ccaCodigo: '',
        subValor: '',
        ccdValor: 0,
        subCodigo: 0,
      }

      let valor: number = 0
      const dptotales11Local: Array<formasPagoCuadre> = []
      if (dpCierreFacturasVar.length === 0) {
        for (const fpg of dpfpagos) {
          dptotales11Local.push({
            subCodigo: Number(fpg.codigo) ?? -1,
            subValor: String(fpg.descripcion) ?? '',
            ccdValor: valor,
          })
        }

        dispatch(
          updateDptotales11Only({
            dptotales11: dptotales11Local,
            key: tabId,
          }),
        )
      } else {
        let i = 0

        for (let j = 0; j <= dpfpagos.length - 1; j++) {
          valor = 0
          for (i = 0; i < dpCierreFacturasVar.length; i++) {
            if (
              dpCierreFacturasVar[i].formaPagoDescripcion ===
              dpfpagos[j].descripcion
            ) {
              valor =
                valor + Number(Number(dpCierreFacturasVar[i].valor).toFixed(2))
            }
          }

          dptotales11Local.push({
            subCodigo: Number(dpfpagos[j].codigo),
            subValor: String(dpfpagos[j].descripcion),
            ccdValor: Number(Number(valor).toFixed(2)),
          })
        }

        dispatch(
          updateDptotales11Only({
            dptotales11: dptotales11Local,
            key: tabId,
          }),
        )

        let k = 0

        let total: number = 0
        for (k = 0; k < dptotales11Local.length; k++) {
          total = total + dptotales11Local[k].ccdValor
        }
        total = Number(Number(total).toFixed(2))
        dispatch(
          updateTotal11_tiOnly({
            total11_ti: total,
            key: tabId,
          }),
        )

        const VEN_INICIAL11 = dpCierreFacturasVar[0].codigo
        const VEN_FINAL11 =
          dpCierreFacturasVar[dpCierreFacturasVar.length - 1].codigo

        dispatch(
          update11VenInicialFinal({
            rango: {
              inicial: VEN_INICIAL11,
              final: VEN_FINAL11,
            },
            key: tabId,
          }),
        )

        const dptotalesLocal: any = dptotales.slice() ?? []
        if (dptotales11Local.length > 0) {
          for (i = 0; i < dpfpagos.length; i++) {
            let x: ccDetalle | null = null
            try {
              x = dptotales[i]
            } catch (ex) {
              x = null
            }
            if (x == null) {
              const addDetallTotales: ccDetalle = {
                ...detalle,
                ccdValor: Number(
                  Number(dptotales11Local[i].ccdValor * -1).toFixed(2),
                ),
                subCodigo: dpfpagos[i].codigo,
                subValor: dpfpagos[i].descripcion,
              }
              dptotalesLocal.push({ ...addDetallTotales })
            } else {
              const y: number =
                Number(x?.ccdValor ?? 0) +
                Number(dptotales11Local[i]?.ccdValor ?? 0)

              dptotalesLocal[i] = {
                ...x,
                ccdValor: y ?? 0,
              }
            }
          }

          dispatch(
            updateDptotalesAnticiposProveedoresOnly({
              dptotalesAnticiposProveedores: dptotalesLocal,
              key: tabId,
            }),
          )
        }
      }
    },
    [dispatch, tabId, dpfpagos, dptotales],
  )

  const getCierreCuadreActicipos = React.useCallback(async () => {
    let dpCierreRet = []
    if (dpcierre11) {
      dpCierreRet = dpcierre11
    }
    await resultadoCierreCajaRet(dpCierreRet)
    dispatch(
      updateLoadDpCierre11Only({
        loaddpcierre11: false,
        key: tabId,
      }),
    )
  }, [dpcierre11, dispatch, tabId, resultadoCierreCajaRet])

  const onLoadClose = React.useCallback(async () => {
    statusLoaded(true)
  }, [statusLoaded])

  React.useEffect(() => {
    if (loaddpcierre11 === true) {
      getCierreCuadreActicipos()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaddpcierre11])

  React.useEffect(() => {
    if (generarCuadre === true) {
      onLoadClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generarCuadre])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">
          <RowContainer>
            <CustomCol xs="12" md="12">
              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr="codigo"
                dataSource={dpcierre11}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode="widget"
                height={heigth}
                ref={dataGrid}
              >
                <Export enabled={true} formats={exportFormats} />
                <Paging defaultPageSize={20} />
                <Pager
                  visible={dpcierre11.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre11)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column
                  dataField="numero"
                  caption="Número"
                  width="20%"
                  allowEditing={false}
                />
                <Column
                  dataField="registro"
                  caption="Fecha"
                  width="20%"
                  allowEditing={false}
                />
                <Column
                  dataField="asociadoIdentificacion"
                  caption="Ced/Ruc"
                  width="15%"
                  allowEditing={false}
                />
                <Column
                  dataField="asociadoDescripcion"
                  caption="Cliente"
                  allowEditing={false}
                  width={'15%'}
                />
                <Column
                  dataField="concepto"
                  caption="Concepto"
                  width="15%"
                  allowEditing={false}
                />
                <Column
                  dataField="documento"
                  caption="Documento"
                  width="15%"
                  allowEditing={false}
                />
                <Column
                  dataField="valor"
                  caption="Valor"
                  width="20%"
                  allowEditing={false}
                />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol
          lg="12"
          md="12"
          sm="12"
          className="d-flex justify-content-end  align-items-center"
        >
          <CCol
            lg="3"
            md="3"
            sm="12"
            className="d-flex justify-content-end  align-items-center"
          >
            <TabFormasPago tabId={tabId} dptotales={dptotales11} />
          </CCol>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol
          lg="3"
          md="3"
          sm="12"
          className="d-flex justify-content-start  align-items-center"
        >
          <ButtonsInfo />
        </CCol>
        <CCol
          lg="9"
          md="9"
          sm="12"
          className="d-flex justify-content-end  align-items-center"
        >
          <CCol lg="2" md="2" xs="12">
            <GlobalLabel htmlFor="total" className="col-form-label-md">
              <strong> {'Total'} </strong>
            </GlobalLabel>
            <CRow>
              <CCol md="12">
                <NumberBox
                  readOnly
                  width="100%"
                  value={total11_ti}
                  placeholder=""
                  format="#,##0.00"
                />
              </CCol>
            </CRow>
          </CCol>
        </CCol>
      </CRow>
    </>
  )
}
export default AnticiposProveedores
