import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {CustomDictionary} from "../../../../types/generics";
import { TVendedoresDatosEdicion } from './types'
import {TConfEdicionPayload} from "../../types/types";
import {ventasClearDatosEdicion} from "../../../../store/reducers";

const initialState: CustomDictionary<TVendedoresDatosEdicion> = {}

const datosEdicionConfSlice = createSlice({
  name: 'VendedoresDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<TVendedoresDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<TConfEdicionPayload<TVendedoresDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const {
  ventasClearDatosEdicion: clearDatosEdicion,
  initDatosEdicion,
  setDatosEdicion,
} = datosEdicionConfSlice.actions
export const dataEditionReducer = datosEdicionConfSlice.reducer
