import { DateRange } from '../../../../store/types'
import { RequestHelper } from '../../../../helpers/requestHelper'
import { ItemDetalleProforma, OrdenProformaListado } from '../store/types'

export const ProformaService = {
  getOrdenes,
}

async function getOrdenes(
  rangoFechas: DateRange,
  cod: string = '',
  filtro: any,
) {
  const queryData: any = {}
  if (rangoFechas.inicio) {
    queryData.fechaDesde = rangoFechas.inicio
  }
  if (rangoFechas.fin) {
    queryData.fechaHasta = rangoFechas.fin
  }
  if (filtro.item) {
    queryData.item = filtro.item.codigoBarras ?? 0
  }
  queryData.tipo = 'PRO'
  queryData.estado = ''
  queryData.salonCodigo = ''

  if (filtro.numero) {
    queryData.ordNumero = filtro.numero
  }

  if (filtro?.cliente && filtro?.cliente?.codigo) {
    queryData.idCliente = filtro?.cliente?.identificacion
  }

  if (filtro?.local && filtro?.local?.codigo) {
    queryData.localCodigo = filtro?.local?.codigo
  }

  const apiData = await RequestHelper.get<any>(
    'compras/documentos/general',
    'orders/getAll',
    '',
    queryData,
  )
  const orden: Array<OrdenProformaListado> = []
  try {
    for (const ite of apiData) {
      orden.push({
        codigo: Number(ite?.codigo) ?? 0,
        anio: String(ite?.anio) ?? '',
        numero: String(ite?.numero) ?? '',
        fecha: String(ite?.fecha) ?? '',
        registro: String(ite?.registro) ?? '',
        ventaCodigo: Number(ite?.ventaCodigo) ?? 0,
        ventaNumero: ite?.ventaNumero,
        vehiculoCodigo: Number(ite?.vehiculoCodigo) ?? 0,
        vehiculoPlaca: ite?.vehiculoPlaca,
        vehiculoModelo: ite?.vehiculoModelo,
        vehiculoColor: ite?.vehiculoColor,
        vehiculoKilometraje: Number(ite?.vehiculoKilometraje) ?? 0,
        fechaEntrega: String(ite?.fechaEntrega) ?? '',
        horaEntrega: String(ite?.fechaEntrega) ?? '',
        clienteNombre: String(ite?.clienteNombre) ?? '',
        clienteCodigo: Number(ite?.clienteCodigo) ?? 0,
        clienteIdentificacion: String(ite?.clienteIdentificacion) ?? '',
        clienteDireccion: String(ite?.clienteDireccion) ?? '',
        clienteComercial: String(ite?.clienteComercial) ?? '',
        clienteEmail: String(ite?.clienteEmail) ?? '',
        clienteTelefono: ite?.clienteTelefono
          ? String(ite.clienteTelefono)
          : '',
        garantia: Number(ite?.garantia) ?? 0,
        abono: Number(ite?.abono) ?? 0,
        saldo: Number(ite?.saldo) ?? 0,
        total: Number(ite?.total) ?? 0,
        porciones: Number(ite?.porciones) ?? 0,
        observaciones: String(ite?.observaciones) ?? '',
        vendedor: ite?.vendedorData ?? null,
        localCodigo: Number(ite?.localCodigo) ?? 0,
        localNombre: String(ite?.localNombre) ?? '',
        localColor: String(ite?.localColor) ?? '',
        ordenDetalle: Array.isArray(ite?.detalleOrden)
          ? ite.detalleOrden.map((key: ItemDetalleProforma) => ({
              ordenDetalleCodigo: Number(key?.ordenDetalleCodigo) ?? 0,
              ordenCodigo: Number(key?.ordenCodigo) ?? 0,
              codigoInterno: String(key?.codigoInterno) ?? '',
              itemCodigo: String(key?.itemCodigo) ?? '',
              itemBarras: String(key?.itemBarras) ?? '',
              ordenDetalleCantidad: Number(key?.ordenDetalleCantidad) ?? 0,
              itemDescripcion: String(key?.itemDescripcion) ?? '',
              itemUnidadCompraDescripcion:
                String(key?.itemUnidadCompraDescripcion) ?? '',
              ordenDetalleImpuesto: Number(key?.ordenDetalleImpuesto) ?? 0,
              ordenDetallePorcentaje: Number(key?.ordenDetallePorcentaje) ?? 0,
              ordenDetalleDescuento: Number(key?.ordenDetalleDescuento) ?? 0,
              ordenDetallePrecio: Number(key?.ordenDetallePrecio) ?? 0,
              ordenDetalleValor: Number(key?.ordenDetalleValor) ?? 0,
              ordenDetallePrecioIVA: Number(key?.ordenDetallePrecioIVA) ?? 0,
              itemImagen: String(key?.itemImagen) ?? '',
            }))
          : [],
        documentoGenerar: String(ite?.documentoGenerar) ?? '',
        estado: Number(ite?.estado) ?? 0,
        estadoDescripcion: String(ite?.estadoDescripcion) ?? '',
        tipo: String(ite?.tipo) ?? '',
        descripcion: String(ite?.descripcion) ?? '',
        sucursalCodigo: Number(ite?.sucursalCodigo) ?? 0,
        tipoClienteDescripcion: String(ite?.tipoClienteDescripcion) ?? '',
        origen: String(ite?.origen) ?? '',
        numeroItems: Number(ite?.numeroItems) ?? 0,
        usuarioCodigo: Number(ite?.usuarioCodigo) ?? 0,
        usuarioNombre: ite?.usuarioNombre,
        cono: Number(ite?.cono) ?? 0,
        trabajo: String(ite?.trabajo) ?? '',
        recepcion: String(ite?.recepcion) ?? '',
        proforma: String(ite?.proforma) ?? '',
        ventaCodigoOrdenesDetalle: Number(ite?.ventaCodigoOrdenesDetalle) ?? 0,
        terminado: ite?.terminado,
        seguimiento: ite?.seguimiento,
        atencion: String(ite?.atencion) ?? '',
        valorPendiente: ite?.valorPendiente,
        mesNumero: Number(ite?.mesNumero) ?? 0,
        mesComensales: Number(ite?.mesComensales) ?? 0,
        salonCodigo: Number(ite?.salonCodigo) ?? 0,
        salonNombre: ite?.salonNombre,
        cobros: Number(ite?.cobros) ?? 0,
        progreso: String(ite?.progreso) ?? '',
        unidadItem: Number(ite?.unidadItem) ?? 0,
        numeroMesa: ite?.numeroMesa,
        descuento: Number(ite?.descuento) ?? 0,
      })
    }
  } catch (error) {
    console.error(error)
  }

  return orden
}
