import React from 'react'
import TabContactos, {
  ETipoAsociado,
} from '../../../../../../../proveedores/components/modalAdminProveedores/tabContactos/tabContactos'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { CCard, CCardBody, CCol, CLink, CRow } from '@coreui/react-pro'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import {
  NumberBox,
  RadioGroup,
  Switch,
  TextBox,
  Validator,
} from 'devextreme-react'
import {
  DatosEdicionDatosGenerales,
  TDatosPersona,
  TelefonoCliente,
} from '../../../../types/types'
import { RootState } from '../../../../../../../../store/store'
import { useSelector, useDispatch } from 'react-redux'
import { TipoTransaccion } from '../../../../../../../componentes/tipoIdentificacion/store/types'
import TipoIdentificacionLookUp from '../../../../../../../componentes/tipoIdentificacion/tipoIdentificacion'
import LocalidadesLookUp from '../../../../../../../componentes/localidadesLookUp/localidadesLookUp'
import TipoAgenteLookUp from '../../../../../../../componentes/tipoAgente/'
import TipoClienteLookUp from '../../../../../../../componentes/tipoCliente'
import TipoEstadoLookUp from '../../../../../../../componentes/tipoEstado'
import { generosGrupos, realacionadoGrupos } from '../..'
import {
  AsyncRule,
  EmailRule,
  PatternRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/validator'
import {
  MessagesKeys,
  lh,
} from '../../../../../../../../helpers/localizationHelper'
import { GeneralService } from '../../../../../../../../services/general.service'
import { ClientesService } from '../../../../../../../ventas/services/clientes.service'
import ZonasLookUp from '../../../../../../../componentes/zonasLookUp'
import {
  setTabDatosGenerales,
  setTabPerson,
} from '../../../../store/editDataReducer'
import { UrlHelper } from '../../../../../../../../helpers/urlHelper'
import { utilidades } from '../../../../../../../../helpers/utilidades'
import { ECountry } from '../../../../../../../../store/enum/countries'
import { ETipoIdCodTributario } from '../../../../../../../../services/itemEnum/enumSV'
import { ToastTypes } from '../../../../../../../../store/types'

interface IDatosGeneralesProps {
  tabId: string
  refForm: any
  setToast?: (sms: any, tipo: ToastTypes) => void
}

const DatosGenerales: React.FunctionComponent<IDatosGeneralesProps> = (
  props,
) => {
  const { setToast, tabId } = props
  const dispatch = useDispatch()
  const urls = UrlHelper.getUrls()

  const [loadPerson, setLoadPerson] = React.useState<{
    load: boolean
    data: any
    esNuevo: boolean
  }>({ load: false, data: null, esNuevo: false })
  const nombre = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.contribuyente
      ?.nombre
  })
  const razonComercial = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.contribuyente
      ?.razonComercial
  })
  const datosGeneralesState = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.generales
  })
  const datosContribuyenteState = useSelector((state: RootState) => {
    return state.clientes.clientes.editData[props.tabId]?.tabs?.contribuyente
  })
  const empresaState = useSelector((state: RootState) => {
    return state.global.session.empresa
  })

  const isElSalvador = empresaState?.codigoPais === ECountry.ElSalvador
  const [element] = React.useState('1')
  const [esNuevo, setEsNuevo] = React.useState<boolean>(false) // tab.editStatus === StatesEdition.new ? true : false

  const nrcRegex = '^[0-9]{1,8}$'

  const onMessageValidation = React.useCallback(
    (message: MessagesKeys, replace) => {
      let sms_replace: string = ''
      if (message.includes('@dinamic_var@') && replace['label'] !== undefined) {
        sms_replace = message.replaceAll(
          '@dinamic_var@',
          replace['label'] ?? 's/n',
        )
      }
      if (message.includes('@min_char@') && replace['min_char'] !== undefined) {
        sms_replace = message.replaceAll(
          '@min_char@',
          replace['min_char'] ?? 's/n',
        )
      }
      if (message.includes('@min_char@') && replace['max_char'] !== undefined) {
        sms_replace = message.replaceAll(
          '@max_char@',
          replace['max_char'] ?? 's/n',
        )
      }
      return sms_replace
    },
    [],
  )

  const onChangeIdentificacion = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            identificacion: data,
          },
        }),
      )
    },
    [dispatch, tabId, datosGeneralesState],
  )

  const onChangeGenero = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            genero: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeDiscapacidad = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            discapacidad: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeDireccion = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            direccion: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeReferencia = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            referenciaDireccion: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeEmail = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            email: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeContactos = React.useCallback(
    (data) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            telefonos: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeLocalidad = React.useCallback(
    (data) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            ciudad: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeParroquia = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            codigoParroquia: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeZona = React.useCallback(
    (data) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            ruta: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeTipoAgente = React.useCallback(
    (data) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            tipoAgente: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeTipoCliente = React.useCallback(
    (data) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            tipoCliente: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeEstado = React.useCallback(
    (data) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            estado: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeContribuyente = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            contribuyente: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const onChangeRelacionado = React.useCallback(
    (data: any) => {
      if (data.value !== null) {
        dispatch(
          setTabDatosGenerales({
            key: tabId,
            data: {
              ...datosGeneralesState,
              parteRelacionada: data,
            },
          }),
        )
      }
    },
    [dispatch, tabId, datosGeneralesState],
  )

  const onChangeNrc = React.useCallback(
    async (event: any) => {
      const nrc = utilidades.eliminarGuiones(event)
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            nrc: nrc,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const getDataPerson = React.useCallback(
    async (infoPerson) => {
      const actualizarPersona: TDatosPersona = {
        direccion: datosGeneralesState?.direccion ?? '',
        email: datosGeneralesState?.email ?? '',
        nombre: datosContribuyenteState?.nombre ?? '',
        razonComercial: datosContribuyenteState?.razonComercial ?? '',
        telefono: datosGeneralesState?.telefonos ?? [],
        nrc: datosGeneralesState?.nrc ?? '',
      }
      if (infoPerson.isValid === true) {
        if (nombre === '' && infoPerson?.nombre !== undefined) {
          actualizarPersona.nombre = infoPerson?.nombre
        }
        if (razonComercial === '' && infoPerson?.razonComercial !== undefined) {
          actualizarPersona.razonComercial = infoPerson?.razonComercial
        }
        if (
          datosGeneralesState.email === '' &&
          infoPerson?.email !== undefined
        ) {
          actualizarPersona.email = infoPerson?.email
        }
        if (
          datosGeneralesState.direccion === '' &&
          infoPerson?.direccion !== undefined
        ) {
          actualizarPersona.direccion = infoPerson?.direccion
        }

        if (datosGeneralesState.nrc === null && infoPerson?.nrc !== '') {
          actualizarPersona.nrc = infoPerson?.nrc
        }

        // datosGeneralesState?.telefonos

        if (
          datosGeneralesState?.telefonos &&
          datosGeneralesState?.telefonos.length > 0
        ) {
          const contacto: any = await datosGeneralesState?.telefonos.findIndex(
            (x) => {
              return String(x.numero) === String(infoPerson?.telefono)
            },
          )
          const contatosAgregados: TelefonoCliente[] =
            datosGeneralesState?.telefonos.slice()
          if (contacto < 0) {
            contatosAgregados.push({
              descripcion: 'CELULAR',
              numero: infoPerson.telefono,
              tipo: 'celular',
              principal: 1,
            })
            actualizarPersona.telefono = contatosAgregados
          } else {
            actualizarPersona.telefono = [
              {
                descripcion: 'CELULAR',
                numero: infoPerson.telefono,
                tipo: 'celular',
                principal: 1,
              },
            ]
          }
        } else {
          if (infoPerson.telefono) {
            actualizarPersona.telefono = [
              {
                descripcion: 'CELULAR',
                numero: infoPerson.telefono,
                tipo: 'celular',
                principal: 1,
              },
            ]
          }
        }

        dispatch(
          setTabPerson({
            key: tabId,
            datosPersona: actualizarPersona,
          }),
        )
      }
    },
    [
      dispatch,
      tabId,
      datosContribuyenteState,
      datosGeneralesState,
      razonComercial,
      nombre,
    ],
  )

  const onChangeIntegrantes = React.useCallback(
    (data: any) => {
      dispatch(
        setTabDatosGenerales({
          key: tabId,
          data: {
            ...datosGeneralesState,
            numeroIntegrantesFamilia: data,
          },
        }),
      )
    },
    [dispatch, datosGeneralesState, tabId],
  )

  const validateId = React.useCallback(
    async (params) => {
      const validacionIdentificacion =
        await GeneralService.validarIndentificacion(params.value ?? '')
      if (validacionIdentificacion.isValid === true) {
        setLoadPerson({
          load: true,
          data: validacionIdentificacion,
          esNuevo: esNuevo,
        })
        if (esNuevo) {
          const clientes = await ClientesService.consultarClientes({
            nombre: '',
            identificacion: params.value ?? '',
          })
          if (clientes.length > 0)
            return Promise.reject(
              `El cliente con cedula ${
                params.value ?? ''
              } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
            )
        }
        return Promise.resolve()
      } else {
        return Promise.reject(
          validacionIdentificacion.motivo ?? 'Error al validar identificación',
        )
      }
    },
    [esNuevo],
  )

  const validateAsyncSelect = React.useCallback(
    (datoSeleccion: any, message: string) => {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    },
    [],
  )

  const validateAsyncSelectCiudad = React.useCallback(
    (datoSeleccion: any) => {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(
          isElSalvador
            ? 'Municipio: Debe seleccionar una opción'
            : 'Ciudad: Debe seleccionar una opción',
        )
      }
    },
    [isElSalvador],
  )

  const validateAsyncSeleccionTipoCliente = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Tipo de Cliente: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const validateAsyncSeleccionTipoId = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Tipo de Identificación: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const validateAsyncContactos = React.useCallback(() => {
    if (datosGeneralesState.telefonos.length > 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(`Contactos: Debe tener al menos un contacto`)
    }
  }, [datosGeneralesState])

  React.useEffect(() => {
    if (loadPerson.load === true) {
      setLoadPerson({ load: false, data: null, esNuevo: false })
      getDataPerson(loadPerson.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPerson])

  const onChangeTipoIdentificacion = React.useCallback(
    (data) => {
      if (data !== null) {
        dispatch(
          setTabDatosGenerales({
            key: tabId,
            data: {
              ...datosGeneralesState,
              tipoIdentificacion: data,
              identificacion: '',
            },
          }),
        )
      }
    },
    [dispatch, tabId, datosGeneralesState],
  )

  const validateIdCountry = React.useCallback(
    async (params) => {
      const validacionIdentificacion =
        await GeneralService.validarIndentificacion(params.value ?? '')
      if (validacionIdentificacion.isValid) {
        setLoadPerson({
          load: true,
          data: validacionIdentificacion,
          esNuevo: esNuevo,
        })
        if (esNuevo) {
          const sms: string = `El cliente con identificación ${
            params.value ?? ''
          } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`
          const clientes = await ClientesService.consultarClientes({
            nombre: '',
            identificacion: params.value ?? '',
          })
          if (clientes.length > 0) {
            setToast(sms, ToastTypes.Danger)
            return Promise.reject(sms)
          }
        }
      } else {
        return Promise.reject(
          validacionIdentificacion.motivo ?? 'Error al validar identificación',
        )
      }
      return Promise.resolve()
    },
    [esNuevo, setToast],
  )

  const inputIdentificacionCedula = React.useMemo(() => {
    return (
      <div key={'textCedula'}>
        <TextBox
          name="identificacionInput"
          placeholder="C.I. / Identificación"
          value={datosGeneralesState?.identificacion ?? ''}
          onValueChange={(e) => {
            onChangeIdentificacion(e)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionRequerido,
              )}
            />
            <StringLengthRule
              max="10"
              min="10"
              ignoreEmptyValue
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                '',
              )}
            />
            <AsyncRule
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                '',
              )}
              validationCallback={validateId}
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [
    element,
    onChangeIdentificacion,
    validateId,
    datosGeneralesState?.identificacion,
  ])

  const inputIdentificacionRuc = React.useMemo(() => {
    return (
      <div key={'textCedutextRucla'}>
        <TextBox
          name="identificacionInput"
          placeholder="R.U.C / Identificación"
          value={datosGeneralesState?.identificacion ?? ''}
          onValueChange={(e) => {
            onChangeIdentificacion(e)
          }}
          id={element}
        >
          <Validator>
            <PatternRule
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroRucIncorrecto,
                '',
              )}
              pattern="^[0-9]{5,}|^[0-9]{10}$|^[0-9]{13}$"
            />
            <AsyncRule
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroRucIncorrecto,
                '',
              )}
              validationCallback={validateId}
            />
            <StringLengthRule
              min="13"
              max="13"
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroRucIncorrecto,
                '',
              )}
              ignoreEmptyValue
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [
    element,
    onChangeIdentificacion,
    validateId,
    datosGeneralesState?.identificacion,
  ])

  const inputIdentificacionPasaporte = React.useMemo(() => {
    return (
      <div key="textPasaporte">
        <TextBox
          name="ruc"
          placeholder="Identificacion"
          value={datosGeneralesState?.identificacion ?? ''}
          onValueChange={(e) => {
            onChangeIdentificacion(e)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionRequerido,
                '',
              )}
            />
            <StringLengthRule
              max="15"
              message={lh
                .getMessage(MessagesKeys.ClienteNumeroIdentificacionMax, '')
                .replace('@dinamic_var@', '15')}
              ignoreEmptyValue
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, onChangeIdentificacion, datosGeneralesState?.identificacion])

  const inputIdentificacionNIT = React.useMemo(() => {
    return (
      <div key={'textNIT'}>
        <TextBox
          name="identificacionInput"
          placeholder="NIT / Identificación"
          value={datosGeneralesState?.identificacion ?? ''}
          onValueChange={(e) => {
            onChangeIdentificacion(e)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionRequerido,
              )}
            />
            <StringLengthRule
              max="25"
              min="10"
              ignoreEmptyValue
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                '',
              )}
            />
            <PatternRule
              message="No tiene el formato correcto"
              pattern={'^(^[0-9]{14}$)$'}
            />

            <AsyncRule
              message={
                '  Ya existe un cliente registrado con esta identificación.'
              }
              reevaluate={true}
              validationCallback={validateIdCountry}
              ignoreEmptyValue={true}
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, onChangeIdentificacion, datosGeneralesState, validateIdCountry])

  const inputIdentificacionDUI = React.useMemo(() => {
    return (
      <div key={'textDUI'}>
        <TextBox
          name="identificacionInput"
          placeholder="DUI / Identificación"
          value={datosGeneralesState?.identificacion ?? ''}
          onValueChange={(e) => {
            onChangeIdentificacion(e)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionRequerido,
              )}
            />
            <StringLengthRule
              max="10"
              min="9"
              ignoreEmptyValue
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                '',
              )}
            />
            <PatternRule
              message="No tiene el formato correcto"
              pattern={'^([0-9]{8}[0-9]{1})$'}
            />

            <AsyncRule
              message={
                '  Ya existe un cliente registrado con esta identificación.'
              }
              reevaluate={true}
              validationCallback={validateIdCountry}
              ignoreEmptyValue={true}
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, onChangeIdentificacion, datosGeneralesState, validateIdCountry])

  const validateIdCliente = React.useMemo(() => {
    if (
      datosGeneralesState?.tipoIdentificacion &&
      datosGeneralesState?.tipoIdentificacion?.codigo === '05'
    ) {
      return inputIdentificacionCedula
    } else if (
      datosGeneralesState?.tipoIdentificacion &&
      datosGeneralesState?.tipoIdentificacion?.codigo === '04'
    ) {
      return inputIdentificacionRuc
    } else if (
      datosGeneralesState?.tipoIdentificacion &&
      datosGeneralesState?.tipoIdentificacion?.codigo === '12'
    ) {
      return inputIdentificacionNIT
    } else if (
      datosGeneralesState?.tipoIdentificacion &&
      datosGeneralesState?.tipoIdentificacion?.codigo === '13'
    ) {
      return inputIdentificacionDUI
    } else {
      return inputIdentificacionPasaporte
    }
  }, [
    inputIdentificacionCedula,
    inputIdentificacionRuc,
    inputIdentificacionPasaporte,
    inputIdentificacionNIT,
    inputIdentificacionDUI,
    datosGeneralesState?.tipoIdentificacion,
  ])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Tipo Identificación:">
            <TipoIdentificacionLookUp
              onChanged={(data) => {
                onChangeTipoIdentificacion(data)
              }}
              transaccion={TipoTransaccion.venta}
              disabled={false}
              selected={datosGeneralesState?.tipoIdentificacion ?? null}
              provider={datosGeneralesState?.tiposIdentificacion ?? []}
              id="lookUpTipoIdDGC"
            >
              <Validator>
                <RequiredRule
                  message={
                    'Tipo de Identificación: Debe seleccionar una opción'
                  }
                />
                <AsyncRule
                  message={
                    'Tipo de Identificación: Debe seleccionar una opción'
                  }
                  validationCallback={validateAsyncSeleccionTipoId}
                />
              </Validator>
            </TipoIdentificacionLookUp>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label={isElSalvador ? 'Identificación' : 'Cédula/RUC:'}>
            {' '}
            {validateIdCliente}
          </Labeled>
        </CustomCol>
        {empresaState.codigoPais === ECountry.ElSalvador && (
          <CustomCol xs="12" md="2">
            <Labeled label="Nrc">
              <TextBox
                name="nrc"
                placeholder=""
                value={datosGeneralesState.nrc ?? null}
                onValueChange={onChangeNrc}
                maxLength={14}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              >
                <Validator>
                  <PatternRule
                    message="No tiene el formato correcto"
                    pattern={nrcRegex}
                  />
                  <StringLengthRule
                    max={11}
                    message={'Este campo no puede tener mas de 11 caracteres'}
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
        )}
        <CustomCol xs="12" md="3">
          <Labeled label="Género">
            <RadioGroup
              name="generos"
              layout="horizontal"
              displayExpr="label"
              dataSource={generosGrupos}
              value={datosGeneralesState?.genero ?? null}
              onValueChange={(e) => {
                onChangeGenero(e)
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Capacidades Especiales">
            <Switch
              value={datosGeneralesState?.discapacidad ?? null}
              onValueChange={(e) => {
                onChangeDiscapacidad(e)
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Direccion:">
            <TextBox
              value={datosGeneralesState?.direccion ?? ''}
              onValueChange={(e) => {
                onChangeDireccion(e)
              }}
            >
              <Validator>
                <StringLengthRule
                  max="250"
                  min="5"
                  message={utilidades.getMessageValidation(
                    lh.getMessage(MessagesKeys.ClienteLongitudCampoIncorrecto),
                    { label: 'Dirección', minChar: 5, maxChar: 250 },
                  )}
                  ignoreEmptyValue
                />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Referencia:">
            <TextBox
              value={datosGeneralesState?.referenciaDireccion ?? ''}
              onValueChange={(e) => {
                onChangeReferencia(e)
              }}
            >
              <Validator>
                <StringLengthRule
                  max="250"
                  message={utilidades.getMessageValidation(
                    lh.getMessage(MessagesKeys.ClienteLongitudCampoIncorrecto),
                    { label: 'Referencia', minChar: 5, maxChar: 250 },
                  )}
                  ignoreEmptyValue
                />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Email">
            <TextBox
              value={datosGeneralesState?.email ?? ''}
              onValueChange={(e) => {
                onChangeEmail(e)
              }}
            >
              <Validator>
                <RequiredRule message={'Email: Formato de email incorrecto.'} />
                <EmailRule message={'Email: Formato de email incorrecto.'} />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="12" className="mt-2 mb-2">
          <CCard>
            <CCardBody>
              <TabContactos
                tipoAsociado={ETipoAsociado.cliente}
                data={datosGeneralesState?.telefonos ?? []}
                actionSelect={(data) => console.log('data', data)}
                tiposOperadoras={[]}
                returnContactos={(contactos) => {
                  if (contactos.length > 0) {
                    onChangeContactos(contactos)
                  }
                }}
              >
                <Validator>
                  <AsyncRule
                    message={'Contactos: Debe tener al menos un contacto'}
                    validationCallback={validateAsyncContactos}
                  />
                </Validator>
              </TabContactos>
            </CCardBody>
          </CCard>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Ciudad:">
            <LocalidadesLookUp
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangeLocalidad(e)
                }
              }}
              onChangedOptions={() => {}}
              selected={datosGeneralesState?.ciudad ?? null}
              allowEdit
              allowClear
            >
              <Validator>
                <AsyncRule
                  message={'Ciudad: Debe seleccionar una opción'}
                  validationCallback={validateAsyncSelectCiudad}
                />
                <RequiredRule message={'Ciudad: Debe seleccionar una opción'} />
              </Validator>
            </LocalidadesLookUp>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Cod. Parroquia:">
            <CRow>
              <CCol md="6" xs="8">
                <TextBox
                  value={datosGeneralesState?.codigoParroquia ?? ''}
                  onValueChange={(e) => {
                    onChangeParroquia(e)
                  }}
                >
                  <Validator>
                    <StringLengthRule
                      max="2"
                      message={utilidades.getMessageValidation(
                        lh.getMessage(
                          MessagesKeys.ClienteLongitudCampoIncorrecto,
                        ),
                        { label: 'Cod. Parroquia', maxChar: 2 },
                      )}
                    />
                  </Validator>
                </TextBox>
              </CCol>
              <CCol md="6" xs="4">
                <CLink
                  href={
                    urls.documentServerBasePath +
                    '/SolucionEmpresarial/SigNum/bin/modelos/dpa_inec.pdf'
                  }
                  target="_blank"
                >
                  {' Ver Códigos'}
                </CLink>
              </CCol>
            </CRow>
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Ruta / Localidad:">
            <ZonasLookUp
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangeZona(e)
                }
              }}
              selected={datosGeneralesState?.ruta ?? null}
              provider={datosGeneralesState?.rutas ?? []}
              id={'lookUpZonasG'}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Tipo de Agente:">
            <TipoAgenteLookUp
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangeTipoAgente(e)
                }
              }}
              selected={datosGeneralesState?.tipoAgente ?? null}
              provider={datosGeneralesState?.tiposAgente ?? []}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Tipo de Cliente:">
            <TipoClienteLookUp
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangeTipoCliente(e)
                }
              }}
              selected={datosGeneralesState?.tipoCliente ?? null}
              provider={datosGeneralesState?.tiposCliente ?? []}
            >
              <Validator>
                <RequiredRule
                  message={'Tipo de Cliente: Debe seleccionar una opción'}
                />
                <AsyncRule
                  message={'Tipo de Cliente: Debe seleccionar una opción'}
                  validationCallback={validateAsyncSeleccionTipoCliente}
                />
              </Validator>
            </TipoClienteLookUp>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Estado:">
            <TipoEstadoLookUp
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangeEstado(e)
                }
              }}
              selected={datosGeneralesState?.estado ?? null}
              provider={datosGeneralesState?.estados ?? []}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="4">
          <Labeled label="Nro. Contribuyente:">
            <TextBox
              value={datosGeneralesState?.contribuyente ?? ''}
              onValueChange={(e) => {
                onChangeContribuyente(e)
              }}
              readOnly={datosGeneralesState?.tipoAgente?.codigo !== 2}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Parte Relacionada:">
            <RadioGroup
              name="relacionado"
              layout="horizontal"
              displayExpr="label"
              dataSource={realacionadoGrupos}
              value={datosGeneralesState?.parteRelacionada ?? ''}
              onValueChange={(e) => {
                onChangeRelacionado(e)
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Número de integrantes:">
            <NumberBox
              value={datosGeneralesState?.numeroIntegrantesFamilia ?? 0}
              onValueChange={(e) => {
                onChangeIntegrantes(e)
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(DatosGenerales)

export const defaultDatosEdicionDatosGenerales: DatosEdicionDatosGenerales = {
  tipoIdentificacion: null,
  tiposIdentificacion: [],
  identificacion: '',
  direccion: '',
  referenciaDireccion: '',
  telefonos: [],
  discapacidad: false,
  genero: null, // generosGrupos[0]
  email: '',
  ciudad: null,
  codigoParroquia: '', // ejem 01,02 etc
  ruta: null,
  rutas: [],
  tipoAgente: null,
  tiposAgente: [],
  tipoCliente: null,
  tiposCliente: [],
  contribuyente: '',
  estado: null,
  estados: [],
  parteRelacionada: null, // realacionadoGrupos[1]
  numeroIntegrantesFamilia: 1,
  nrc: null,
}
