import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TMenu } from './types'
import { TConfigSearchState } from './types'

const initialState: TConfigSearchState = {
  filter: {},
  mensaje: '',
  error: false,
  allModulesMenus: [],
  selectedModuleMenu: [],
  allUnAssignedButtons: [],
  allAssignedButtons: [],
}

const searchConfSlice = createSlice({
  name: 'MenuBotonesSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    setCleanAllModulesMenu(state) {
      state.allModulesMenus = []
    },
    setAllModulesMenu(state, action: PayloadAction<Array<TMenu> | []>) {
      state.allModulesMenus = action.payload
    },
    setCleanSelectedModuleMenu(state) {
      state.selectedModuleMenu = []
    },
    setSelectedModuleMenu(state, action: PayloadAction<Array<TMenu> | []>) {
      state.selectedModuleMenu = action.payload
    },
    setCleanAllUnAssignedButtons(state) {
      state.allUnAssignedButtons = []
    },
    setAllUnAssignedButtons(state, action: PayloadAction<Array<TMenu> | []>) {
      state.allUnAssignedButtons = action.payload
    },
    setCleanAllAssignedButtons(state) {
      state.allAssignedButtons = []
    },
    setAllAssignedButtons(state, action: PayloadAction<Array<TMenu> | []>) {
      state.allAssignedButtons = action.payload
    },
  },
})

export const {
  resetState,
  setCleanAllModulesMenu,
  setAllModulesMenu,
  setCleanSelectedModuleMenu,
  setSelectedModuleMenu,
  setCleanAllUnAssignedButtons,
  setAllUnAssignedButtons,
  setCleanAllAssignedButtons,
  setAllAssignedButtons,
} = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
