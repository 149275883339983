import React from 'react'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  LoadPanel,
  Pager,
  Button as DatagridButton,
} from 'devextreme-react/data-grid'
import { VendedorBusqueda } from '../../../../shared/components/buscarVendedorLookMemoUp/type/types'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { DateRange } from '../../../../../store/types'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import { FiltroBusquedaOrdenProformaState } from '../../../../ventas/pages/proformas/type/types'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { ProformaService } from '../../services/proforma.service'

interface IResultadoBusquedaProps extends React.PropsWithChildren {
  funcion: string
  data: any
  dataSearch: TFindOrdenProforma
  resetFunction: () => void
  setDataItems: (data) => void
  onChanged: (any: VendedorBusqueda) => void
  playLoader?: () => void
  stopLoader?: () => void
}

type infoPaginationType = {
  desde: number
  hasta: number
  paginaActual: number
  totalPaginas: number
  totalRegistros: number
}

type TFindOrdenProforma = FiltroBusquedaOrdenProformaState & {}

const ResultadoBusqueda: React.FunctionComponent<IResultadoBusquedaProps> = (
  props,
) => {
  const {
    funcion,
    dataSearch,
    resetFunction,
    setDataItems,
    onChanged,
    playLoader,
    stopLoader,
  } = props

  const tablaRef = React.useRef<any>()
  const tablaRefPager = React.useRef<any>()

  const [data, setData] = React.useState<any>([])
  const [pageSize] = React.useState<number>(10)
  const [pageIndex, setPageIndex] = React.useState<number>(10)

  const onSearch = React.useCallback(
    async (dataSearch: TFindOrdenProforma) => {
      try {
        playLoader()

        const rangofechas: DateRange = {
          inicio: DateUtils.pickersDateToApiDate(dataSearch.fechaInicio),
          fin: DateUtils.pickersDateToApiDate(dataSearch.fechaFin),
        }

        const resultado = await ProformaService.getOrdenes(
          rangofechas,
          dataSearch.numero ? dataSearch.numero : '',
          dataSearch,
        )
        stopLoader()

        setData(resultado)
        setDataItems(resultado)
      } catch {
        stopLoader()
      }
    },
    [playLoader, setDataItems, stopLoader],
  )

  const onResetTab = React.useCallback(() => {
    setPageIndex(0)
  }, [])

  const onRowDblClick = React.useCallback(
    (selec) => {
      if (selec.data) {
        onChanged(selec['data'])
      }
    },
    [onChanged],
  )

  React.useEffect(() => {
    if (funcion === ButtonTypes.find) {
      onResetTab()
      resetFunction()
      onSearch(dataSearch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  React.useEffect(() => {
    setData(props.data)
  }, [props.data])

  return (
    <>
      <RowContainer className="mt-2">
        <DataGrid
          ref={tablaRef}
          keyExpr="codigo"
          dataSource={data ?? []}
          selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          showBorders={true}
          showRowLines={true}
          allowColumnReordering={true}
          allowColumnResizing
          columnResizingMode="widget"
          columnAutoWidth={true}
          activeStateEnabled={true}
          remoteOperations={true}
          onRowDblClick={(d) => {
            onRowDblClick(d)
          }}
          width={'100%'}
        >
          <Paging
            pageIndex={pageIndex}
            enabled={true}
            defaultPageSize={10}
            pageSize={pageSize}
          />
          <Pager
            ref={tablaRefPager}
            showPageSizeSelector={true}
            allowedPageSizes={[8, 12, 20]}
            showNavigationButtons={true}
            showInfo={true}
            infoText="Page #{0}. Total: {1} ({2} items)"
          />
          <LoadPanel enabled={true} />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Column type="buttons" width={'auto'}>
            <DatagridButton
              icon="arrowleft"
              visible={true}
              hint="Seleccionar"
              onClick={(e) => {
                onChanged(e.row.data)
              }}
            />
          </Column>
          <Column
            dataField="codigo"
            caption="# orden"
            width="auto"
            allowEditing={false}
            visible={true}
            allowSearch={false}
            allowFiltering={false}
            alignment={'center'}
          />
          <Column
            dataField="fecha"
            caption="Fecha"
            width="auto"
            allowEditing={false}
            visible={true}
            allowSearch={false}
            allowFiltering={false}
            alignment={'center'}
          />
          <Column
            dataField="clienteNombre"
            caption="Cliente"
            minWidth="200px"
            width="auto"
            alignment={'center'}
          />
          <Column
            dataField="clienteIdentificacion"
            caption="Identificación"
            minWidth="200px"
            width="auto"
            alignment={'center'}
          />
          <Column
            dataField="localNombre"
            caption="Local"
            width="auto"
            allowEditing={false}
            visible={true}
            allowSearch={false}
            allowFiltering={true}
            alignment={'center'}
          />
          <Column
            dataField="total"
            caption="Total"
            alignment="right"
            width="auto"
            allowFiltering={false}
          />
        </DataGrid>
      </RowContainer>
    </>
  )
}
export default ResultadoBusqueda
