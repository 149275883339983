import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils'
import {
  AbonoComprasCuadresCajaListado,
  AbonosCuadresCajaListado,
  AnticiposCuadresCajaListado,
  ChequesCuadresCajaListado,
  ComprasCuadresCajaListado,
  ComprobantesCuadresCajaListado,
  LetrasCuadresCajaListado,
} from '../types/types'
import { CuadresService } from './cuadres.service'

export async function getDatosFacturas(
  fechaCierre,
  local,
  puntoVenta,
  venInicial1,
  venFinal1,
) {
  const dpfacturasCuadre: Array<ComprobantesCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpfacturasCuadre: any = await CuadresService.getCierreCaja2(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial1,
    venFinal1,
  )
  if (resDpfacturasCuadre.auto && resDpfacturasCuadre.auto.length > 0) {
    for (const cuadreFactura of resDpfacturasCuadre.auto) {
      dpfacturasCuadre.push({
        clienteNombre: String(cuadreFactura.clienteNombre) ?? '',
        fecha: String(cuadreFactura.fecha) ?? '',
        saldo: Number(cuadreFactura.saldo) ?? 0,
        valor: Number(cuadreFactura.valor) ?? 0,
        ventaCodigo: Number(cuadreFactura.ventaCodigo) ?? 0,
        ventaEstado: String(cuadreFactura.ventaEstado) ?? '',
        ventaFormaPago: Number(cuadreFactura.ventaFormaPago) ?? 0,
        ventaFormaPagoDescripcion:
          String(cuadreFactura.ventaFormaPagoDescripcion) ?? '',
        ventaNumero: String(cuadreFactura.ventaNumero) ?? '',
        ventaTipoDocumentoDescripcion:
          String(cuadreFactura.ventaTipoDocumentoDescripcion) ?? '',
        ventaTotal: Number(cuadreFactura.ventaTotal) ?? 0,
      })
    }
  }
  return dpfacturasCuadre
}

export async function getCierreCuadreActicipos(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const dpCierreRet: Array<AnticiposCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpCierreRet: any = await CuadresService.getCierreCuadreActicipos(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial,
    venFinal,
  )

  if (resDpCierreRet.auto && resDpCierreRet.auto.length > 0) {
    for (const cuadreFactura of resDpCierreRet.auto) {
      dpCierreRet.push({
        codigo: Number(cuadreFactura.codigo) ?? 0, //   ANT_CODIGO;
        numero: String(cuadreFactura.numero) ?? '', //  ANT_NUMERO;
        pertenece: Number(cuadreFactura.pertenece) ?? 0, //   ANT_PERTENECE;
        tipo: String(cuadreFactura.tipo) ?? '', //  ANT_TIPO;
        asociado: Number(cuadreFactura.asociado) ?? 0, //   ANT_ASOCIADO;
        formaPago: Number(cuadreFactura.formaPago) ?? 0, //   ANT_FPAGO;
        formaPagoDescripcion: String(cuadreFactura.formaPagoDescripcion) ?? '', //  ANT_FPAGO_DES;
        concepto: String(cuadreFactura.concepto) ?? '', //  ANT_CONCEPTO;
        registro: String(cuadreFactura.registro) ?? '', //  ANT_REGISTRO;
        documento: String(cuadreFactura.documento) ?? '', //  ANT_DOCUMENTO;
        valor: Number(cuadreFactura.valor) ?? 0, // (double)  ANT_VALOR;
        origen: String(cuadreFactura.origen) ?? '', //  ANT_ORIGEN;
        origenAsociado: Number(cuadreFactura.origenAsociado) ?? 0, //   ANT_OASOCIADO;
        puntoVentaCodigo: Number(cuadreFactura.puntoVentaCodigo) ?? 0, //   PTO_CODIGO;
        puntoVentaHost: String(cuadreFactura.puntoVentaHost) ?? '', //  PTO_HOST;
        devengado: String(cuadreFactura.devengado) ?? '', //  ANT_DEVENGADO;
        saldo: Number(cuadreFactura.saldo) ?? 0, // (double)  ANT_SALDO;
        saldoReal: String(cuadreFactura.saldoReal) ?? '', //  ANT_SALDOREAL;
        asociadoIdentificacion:
          String(cuadreFactura.asociadoIdentificacion) ?? '', //  ANT_ASOCIADO_ID;
        asociadoDescripcion: String(cuadreFactura.asociadoDescripcion) ?? '', //  ANT_ASOCIADO_DES;
        asociadoCodigo: String(cuadreFactura.asociadoCodigo) ?? '', //  ASO_CODIGO;
        asientoNumero: String(cuadreFactura.asientoNumero) ?? '', //  ASI_NUMERO;
        asientoNumeroTipo: String(cuadreFactura.asientoNumeroTipo) ?? '', //  ASI_NROTIPO;
        localNombre: String(cuadreFactura.localNombre) ?? '', //  LOC_NOMBRE;
        estado: String(cuadreFactura.estado) ?? '', //  ANT_ESTADO;
      })
    }
  }
  return dpCierreRet
}

export async function getAbonosCuadres(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const cuadre: Array<AbonosCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpCierreRet: any = await CuadresService.getAbonosCuadres(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial,
    venFinal,
  )
  if (resDpCierreRet.auto && resDpCierreRet.auto.length > 0) {
    for (const cuadreItem of resDpCierreRet.auto) {
      cuadre.push({
        ventaCodigo: Number(cuadreItem.ventaCodigo) ?? 0, //  (int) $row['VEN_CODIGO'];
        ventaTipoDocumento: String(cuadreItem.ventaTipoDocumento) ?? '', //  $row['VEN_TIPODOC'];
        ventaTipoDocumentoDescripcion:
          String(cuadreItem.ventaTipoDocumentoDescripcion) ?? '', //  $row['VEN_TIPODOC_DES'];
        comprobante: String(cuadreItem.comprobante) ?? '', //  $row['COMPROBANTE'];
        ventaNumero: String(cuadreItem.ventaNumero) ?? '', //  $row['VEN_NUMERO'];
        clienteIdentificacion: String(cuadreItem.clienteIdentificacion) ?? '', //  $row['CLI_IDENTIFICACION'];
        clienteNombre: String(cuadreItem.clienteNombre) ?? '', //  $row['CLI_NOMBRE'];
        clienteCodigo: Number(cuadreItem.clienteCodigo) ?? 0, //  $row['CLI_NOMBRE'];
        pagoVentaCuota: Number(cuadreItem.pagoVentaCuota) ?? 0, //  (double) $row['PVE_CUOTA'];
        abonoVentaValor: Number(cuadreItem.abonoVentaValor) ?? 0, //  (double) $row['ABV_VALOR'];
        abonoVentaFecha: String(cuadreItem.abonoVentaFecha) ?? '', //  $row['ABV_FECHA'];
        pagoVentaSaldo: Number(cuadreItem.pagoVentaSaldo) ?? 0, //  (double) $row['PVE_SALDO'];
        fecha: String(cuadreItem.fecha) ?? '', //  $row['FECHA'];
        pagoVentaVencimiento: String(cuadreItem.pagoVentaVencimiento) ?? '', //  $row['PVE_VENCIMIENTO'];
        ventaFecha: String(cuadreItem.ventaFecha) ?? '', //  $row['VEN_FECHA'];
        ingresoCodigo: Number(cuadreItem.ingresoCodigo) ?? 0, //  (int) $row['ING_CODIGO'];
        asientoNumeroTipo: Number(cuadreItem.asientoNumeroTipo) ?? 0, //  (int) $row['ASI_NROTIPO'];
        asientoNumero: Number(cuadreItem.asientoNumero) ?? 0, //  (int) $row['ASI_NUMERO'];
        localCodigo: Number(cuadreItem.localCodigo) ?? 0, //  (int) $row['LOC_CODIGO'];
        localNombre: String(cuadreItem.localNombre) ?? '', //  $row['LOC_NOMBRE'];
        ingresoConcepto: String(cuadreItem.ingresoConcepto) ?? '', //  $row['ING_CONCEPTO'];
        abonoVentaTotal: Number(cuadreItem.abonoVentaTotal) ?? 0, //  (double) $row['ABV_TOTAL'];
        abonoVentaTipoPago: Number(cuadreItem.abonoVentaTipoPago) ?? 0, //  (int) $row['ABV_TIPOPAGO'];
        abonoVentaTipoPagoDescripcion:
          String(cuadreItem.abonoVentaTipoPagoDescripcion) ?? '', //  $row['ABV_TIPOPAGO_DES'];
        tipoCodigo: String(cuadreItem.tipoCodigo) ?? '', //  $row['TIP_CODIGO'];
        abonoVentaTipoPagoDocumentoDescripcion:
          String(cuadreItem.abonoVentaTipoPagoDocumentoDescripcion) ?? '', //  $row['ABV_TIPOPAGODOC_DES'];
        abonoVentaOrigen: String(cuadreItem.abonoVentaOrigen) ?? '', //  $row['ABV_ORIGEN'];
        abonosVentasCodigoOrigen:
          String(cuadreItem.abonosVentasCodigoOrigen) ?? '', //  $row['ABV_FASOCIADO'];
        ingresoValor: String(cuadreItem.ingresoValor) ?? '', //  $row['ING_VALOR'];
        haber: Number(cuadreItem.haber) ?? 0, //  (double) $row['HABER'];
        debe: Number(cuadreItem.debe) ?? 0, //  (double) $row['DEBE'];
        numero: String(cuadreItem.numero) ?? '', //  $row['NUMERO'];
        puntoVentaCodigo: Number(cuadreItem.puntoVentaCodigo) ?? 0, //  (int) $row['PTO_CODIGO'];
        puntoVentaHost: String(cuadreItem.puntoVentaHost) ?? '', //  $row['PTO_HOST'];
        vendedorCompleto: String(cuadreItem.vendedorCompleto) ?? '', //  $row['VDR_COMPLETO'];
      })
    }
  }
  return cuadre
}

export async function getCierreCajaRet(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const dpCierreRet: Array<ComprobantesCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpCierreRet: any = await CuadresService.getCierreCajaRet(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial,
    venFinal,
  )
  if (resDpCierreRet.auto && resDpCierreRet.auto.length > 0) {
    for (const cuadreFactura of resDpCierreRet.auto) {
      dpCierreRet.push({
        clienteNombre: String(cuadreFactura.clienteNombre) ?? '',
        fecha: String(cuadreFactura.fecha) ?? '',
        saldo: Number(cuadreFactura.saldo) ?? 0,
        valor: Number(cuadreFactura.valor) ?? 0,
        ventaCodigo: Number(cuadreFactura.ventaCodigo) ?? 0,
        ventaEstado: String(cuadreFactura.ventaEstado) ?? '',
        ventaFormaPago: Number(cuadreFactura.ventaFormaPago) ?? 0,
        ventaFormaPagoDescripcion:
          String(cuadreFactura.ventaFormaPagoDescripcion) ?? '',
        ventaNumero: String(cuadreFactura.ventaNumero) ?? '',
        ventaTipoDocumentoDescripcion:
          String(cuadreFactura.ventaTipoDocumentoDescripcion) ?? '',
        ventaTotal: Number(cuadreFactura.ventaTotal) ?? 0,
      })
    }
  }
  return dpCierreRet
}

export async function getCierreCajaNCHelper(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const dpCierreNC: Array<ComprobantesCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpCierre: any = await CuadresService.getCierreCajaNC(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial,
    venFinal,
  )
  if (resDpCierre.auto && resDpCierre.auto.length > 0) {
    for (const cuadreFactura of resDpCierre.auto) {
      dpCierreNC.push({
        clienteNombre: String(cuadreFactura.clienteNombre) ?? '',
        fecha: String(cuadreFactura.fecha) ?? '',
        saldo: Number(cuadreFactura.saldo) ?? 0,
        valor: Number(cuadreFactura.valor) ?? 0,
        ventaCodigo: Number(cuadreFactura.ventaCodigo) ?? 0,
        ventaEstado: String(cuadreFactura.ventaEstado) ?? '',
        ventaFormaPago: Number(cuadreFactura.ventaFormaPago) ?? 0,
        ventaFormaPagoDescripcion:
          String(cuadreFactura.ventaFormaPagoDescripcion) ?? '',
        ventaNumero: String(cuadreFactura.ventaNumero) ?? '',
        ventaTipoDocumentoDescripcion:
          String(cuadreFactura.ventaTipoDocumentoDescripcion) ?? '',
        ventaTotal: Number(cuadreFactura.ventaTotal) ?? 0,
      })
    }
  }
  return dpCierreNC
}

export async function getAbonosComprasCHelper(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const cuadre: Array<ComprasCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpCierreRet: any = await CuadresService.getAbonosCompras(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial,
    venFinal,
  )
  if (resDpCierreRet.auto && resDpCierreRet.auto.length > 0) {
    for (const cuadreItem of resDpCierreRet.auto) {
      cuadre.push({
        compraCodigo: Number(cuadreItem.compraCodigo) ?? 0, // COM_CODIGO
        compraTipoDocumentoDescripcion:
          String(cuadreItem.compraTipoDocumentoDescripcion) ?? '', // COM_TIPODOC_DES
        compraNumero: String(cuadreItem.compraNumero) ?? '', // COM_NUMERO
        proveedorNombre: String(cuadreItem.proveedorNombre) ?? '', // PRV_NOMBRE
        formaPago: Number(cuadreItem.formaPago) ?? 0, // SUB_CODIGO
        compraFormaPagoDescripcion:
          String(cuadreItem.compraFormaPagoDescripcion) ?? '', // COM_FPAGO_DES
        compraTotal: Number(cuadreItem.compraTotal) ?? 0, // COM_TOTAL
        retencionCompraTotal: Number(cuadreItem.retencionCompraTotal) ?? 0, // REC_TOTAL
        saldo: Number(cuadreItem.saldo) ?? 0, // SALDO
      })
    }
  }
  return cuadre
}

export async function getPagosCuadresHelper(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const cuadre: Array<AbonoComprasCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpCierreRet: any = await CuadresService.getPagosCuadres(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial,
    venFinal,
  )
  if (resDpCierreRet.auto && resDpCierreRet.auto.length > 0) {
    for (const cuadreItem of resDpCierreRet.auto) {
      cuadre.push({
        egresoValor: String(cuadreItem.egresoValor) ?? '',
        compraCodigo: Number(cuadreItem.compraCodigo) ?? 0, //  COM_CODIGO
        compraFecha: String(cuadreItem.compraFecha) ?? '', //  COM_FECHA
        proveedorNombre: String(cuadreItem.proveedorNombre) ?? '', // PRV_NOMBRE
        vencimiento: String(cuadreItem.vencimiento) ?? '', // PCO_VENCIMIENTO
        abonoFecha: String(cuadreItem.abonoFecha) ?? '', // ABC_FECHA
        compraTipoDocumentoCodigo:
          Number(cuadreItem.compraTipoDocumentoCodigo) ?? 0, // ABC_TIPOPAGO
        compraTipoDocumentoDescripcion:
          String(cuadreItem.compraTipoDocumentoDescripcion) ?? '', // COM_TIPODOC_DES
        puntoVentaHost: String(cuadreItem.puntoVentaHost) ?? '', // PTO_HOST
        compraNumero: String(cuadreItem.compraNumero) ?? '', // COM_NUMERO
        compraFormaPagoDescripcion:
          String(cuadreItem.compraFormaPagoDescripcion) ?? '', // ABC_TIPOPAGO_DES
        abonoCompraTipoPagoDocumentoDescripcion:
          String(cuadreItem.abonoCompraTipoPagoDocumentoDescripcion) ?? '', //  ABC_TIPOPAGODOC_DES
        compraCuota: Number(cuadreItem.compraCuota) ?? 0, // PCO_CUOTA
        abonoCuotaValor: Number(cuadreItem.abonoCuotaValor) ?? 0, // ABC_VALOR
        pagoCuotaSaldo: Number(cuadreItem.pagoCuotaSaldo) ?? 0, // PCO_SALDO
      })
    }
  }
  return cuadre
}

export async function getCierreCuadreActiciposProveedoresHelper(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const cuadre: Array<AnticiposCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpCierreRet: any =
    await CuadresService.getCierreCuadreActiciposProveedores(
      fechaFilter,
      local?.codigo ?? -1,
      puntoVenta?.codigoPuntoVenta ?? -1,
      venInicial,
      venFinal,
    )
  if (resDpCierreRet.auto && resDpCierreRet.auto.length > 0) {
    for (const cuadreFactura of resDpCierreRet.auto) {
      cuadre.push({
        codigo: Number(cuadreFactura.codigo) ?? 0, //   ANT_CODIGO;
        numero: String(cuadreFactura.numero) ?? '', //  ANT_NUMERO;
        pertenece: Number(cuadreFactura.pertenece) ?? 0, //   ANT_PERTENECE;
        tipo: String(cuadreFactura.tipo) ?? '', //  ANT_TIPO;
        asociado: Number(cuadreFactura.asociado) ?? 0, //   ANT_ASOCIADO;
        formaPago: Number(cuadreFactura.formaPago) ?? 0, //   ANT_FPAGO;
        formaPagoDescripcion: String(cuadreFactura.formaPagoDescripcion) ?? '', //  ANT_FPAGO_DES;
        concepto: String(cuadreFactura.concepto) ?? '', //  ANT_CONCEPTO;
        registro: String(cuadreFactura.registro) ?? '', //  ANT_REGISTRO;
        documento: String(cuadreFactura.documento) ?? '', //  ANT_DOCUMENTO;
        valor: Number(cuadreFactura.valor) ?? 0, // (double)  ANT_VALOR;
        origen: String(cuadreFactura.origen) ?? '', //  ANT_ORIGEN;
        origenAsociado: Number(cuadreFactura.origenAsociado) ?? 0, //   ANT_OASOCIADO;
        puntoVentaCodigo: Number(cuadreFactura.puntoVentaCodigo) ?? 0, //   PTO_CODIGO;
        puntoVentaHost: String(cuadreFactura.puntoVentaHost) ?? '', //  PTO_HOST;
        devengado: String(cuadreFactura.devengado) ?? '', //  ANT_DEVENGADO;
        saldo: Number(cuadreFactura.saldo) ?? 0, // (double)  ANT_SALDO;
        saldoReal: String(cuadreFactura.saldoReal) ?? '', //  ANT_SALDOREAL;
        asociadoIdentificacion:
          String(cuadreFactura.asociadoIdentificacion) ?? '', //  ANT_ASOCIADO_ID;
        asociadoDescripcion: String(cuadreFactura.asociadoDescripcion) ?? '', //  ANT_ASOCIADO_DES;
        asociadoCodigo: String(cuadreFactura.asociadoCodigo) ?? '', //  ASO_CODIGO;
        asientoNumero: String(cuadreFactura.asientoNumero) ?? '', //  ASI_NUMERO;
        asientoNumeroTipo: String(cuadreFactura.asientoNumeroTipo) ?? '', //  ASI_NROTIPO;
        localNombre: String(cuadreFactura.localNombre) ?? '', //  LOC_NOMBRE;
        estado: String(cuadreFactura.estado) ?? '', //  ANT_ESTADO;
      })
    }
  }
  return cuadre
}

export async function getChequesCuadresHelper(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const cuadre: Array<ChequesCuadresCajaListado> = []
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const resDpCierreRet: any = await CuadresService.getChequesCuadres(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial,
    venFinal,
  )
  if (resDpCierreRet.auto && resDpCierreRet.auto.length > 0) {
    for (const cuadreItem of resDpCierreRet.auto) {
      cuadre.push({
        chequeCodigo: Number(cuadreItem.chequeCodigo) ?? 0, // CHE_CODIGO;
        chequeNumero: String(cuadreItem.chequeNumero) ?? '', //CHE_NUMERO;
        chequeFechaRegistro: String(cuadreItem.chequeFechaRegistro) ?? '', //CHE_FREGISTRO;
        cuentaBancoCodigo: Number(cuadreItem.cuentaBancoCodigo) ?? 0, // CBA_CODIGO;
        cuentaBancoNumero: String(cuadreItem.cuentaBancoNumero) ?? '', //CBA_NUMERO;
        bancoNombre: String(cuadreItem.bancoNombre) ?? '', //BAN_NOMBRE;
        chequeFormaPago: Number(cuadreItem.chequeFormaPago) ?? 0, // CHE_FPAGO;
        cuentaBancoPerteneceDescripcion:
          String(cuadreItem.cuentaBancoPerteneceDescripcion) ?? '', //CBA_PERTENECE_DES;
        chequeTransaccion: String(cuadreItem.chequeTransaccion) ?? '', //CHE_TRANSACCION;
        chequeAFavor: String(cuadreItem.chequeAFavor) ?? '', //CHE_AFAVOR;
        chequeValor: Number(cuadreItem.chequeValor) ?? 0, //  CHE_VALOR;
        chequeEstado: String(cuadreItem.chequeEstado) ?? '', //CHE_ESTADO;
        chequeEstadoDescripcion:
          String(cuadreItem.chequeEstadoDescripcion) ?? '', //CHE_ESTADO_DES;
      })
    }
  }
  return cuadre
}

export async function getLetrasCuadresHelper(
  fechaCierre,
  local,
  puntoVenta,
  venInicial,
  venFinal,
) {
  const fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi)
  const cuadre: Array<LetrasCuadresCajaListado> = []
  const resDpCierreRet: any = await CuadresService.getLetrasCuadres(
    fechaFilter,
    local?.codigo ?? -1,
    puntoVenta?.codigoPuntoVenta ?? -1,
    venInicial,
    venFinal,
  )
  if (resDpCierreRet.auto && resDpCierreRet.auto.length > 0) {
    for (const cuadreItem of resDpCierreRet.auto) {
      cuadre.push({
        codigo: Number(cuadreItem.codigo) ?? 0, //  PVE_CODIGO;
        cuota: Number(cuadreItem.cuota) ?? 0, //  PVE_CUOTA;
        vencimiento: String(cuadreItem.vencimiento) ?? '', //  PVE_VENCIMIENTO;
        interes: Number(cuadreItem.interes) ?? 0, //  PVE_INTERES;
        saldo: Number(cuadreItem.saldo) ?? 0, //  PVE_SALDO;
        letra: String(cuadreItem.letra) ?? '', //  PVE_LETRA;
        estado: String(cuadreItem.estado) ?? '', //  PVE_ESTADO;
        estadoDescripcion: String(cuadreItem.estadoDescripcion) ?? '', //  PVE_ESTADO_DES;
      })
    }
  }
  return cuadre
}
