import { RequestHelper } from '../../../../../helpers/requestHelper'
import { SesionService } from '../../../../../services/sesion.service'
import { EFormasPago } from '../../../../../store/enum/formasPago'
import { DateRange, Puntoventa } from '../../../../../store/types'
import {
  CuadresCajaListado,
  EgresosBovedaListado,
  formasPago,
  LocalOption,
  ComprasCuadresCajaListado,
  VendedoresListado,
  BovedaCuadresCajaListado,
  IngresosCuadresCajaListado,
  TCuadreCajaIngresar,
  DenominacionListado,
  formasPagoCuadreDetalle,
  TIngresoCaja,
} from '../types/types'

export const CuadresService = {
  getCuadreCajaDetalleByCode,
  getCuadreCajaByCode,
  getAllCuadresCaja,
  getPuntosVentas,
  getLocales,
  getEspecial,
  geIngresosBoveda,
  getCierreCaja2,
  getComboTipoPago,
  getEgresosBoveda,
  getCierreCajaNC,
  getCierreCajaRet,
  getCierreCuadreActicipos,
  getAbonosCuadres,
  getAbonosCompras,
  getPagosTesoreria,
  getPagosCuadres,
  getChequesCuadres,
  getVendedores,
  getLetrasCuadres,
  getBovedaCuadre,
  getDeleteCuadre,
  ingresarCuadre,
  getAllCuadresView,
  getCuadreCajaReportTotales,
  getCuadreCajaReportDetalles,
  getCuadreCajaReportResumen,
  getCierreCuadreActiciposProveedores,
  ingresarCaja,
  getCargarEspecial,
  getDeleteIngresoCaja,
  getSecuancialCuadres,
}

async function getCuadreCajaReportResumen(find: any) {
  console.log('getCuadreCajaReportResumen', find)
  const queryData: any = {}
  queryData.ccaCod1 = find.ccaCod1
  queryData.ccaCod2 = find.ccaCod2
  queryData.ccaCod3 = find.ccaCod3
  queryData.puntoVentaCodigo = Number(find.puntoVentaCodigo)
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/cuadres',
    'cuadreCaja/cuadreCajaResumen',
    '',
    queryData,
  )
  return apiData
}

async function getCuadreCajaReportDetalles(find: any) {
  console.log('getCuadreCajaReportDetalles', find)
  const queryData: any = {}
  queryData.ccaCod1 = find.ccaCod1
  queryData.vendedorCodigo = Number(find.vendedorCodigo)
  queryData.localCodigo = Number(find.localCodigo)
  queryData.puntoVentaCodigo = Number(find.puntoVentaCodigo)
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/cuadres',
    'cuadreCaja/detalleCuadreCaja',
    '',
    queryData,
  )
  return apiData
}

async function getCuadreCajaReportTotales(find: any) {
  const queryData: any = {}
  queryData.ccaCod1 = find.ccaCod1
  queryData.ccaCod7 = find.ccaCod7
  queryData.ccaCod8 = find.ccaCod8
  queryData.ccaCod10 = find.ccaCod10
  queryData.vendedorCodigo = Number(find.vendedorCodigo)
  queryData.localCodigo = Number(find.localCodigo)
  queryData.puntoVentaCodigo = Number(find.puntoVentaCodigo)
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/cuadres',
    'cuadreCaja/detalleCuadreCajaTotales',
    '',
    queryData,
  )
  return apiData
}

async function getAllCuadresView(
  fechaCierre: string,
  localCodigo: number = -1,
  puntoVentaCodigo: number = -1,
) {
  const queryData: any = {
    puntoCodigo: puntoVentaCodigo,
    localCodigo: localCodigo,
    cuadreFechaCierre: fechaCierre,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'cuadrecaja',
    'allSee',
    '',
    queryData,
  )
  const cuadreCaja: Array<CuadresCajaListado> = []
  for (const cuadreItem of apiData) {
    const itemDenominacion: Array<DenominacionListado> = []
    cuadreItem.cuadreCajaDenominacion.forEach(function (det) {
      itemDenominacion.push({
        descripcion: String(det?.descripcion) ?? '',
        valor: Number(det?.valor) ?? 0,
        grupo: Number(det?.valor) ?? 0,
      })
    })

    cuadreCaja.push({
      cuadreCodigo: Number(cuadreItem.cuadreCajaCodigo) ?? 0,
      cuadreFechaCierre: String(cuadreItem.cuadreCajaFechaCierre) ?? '',
      cuadreFechaHoraCierre: String(cuadreItem.cuadreCajaHoraCierre) ?? '',
      puntoVentaCodigo: Number(cuadreItem.puntoVentaCodigo) ?? 0,
      puntoVentaHost: String(cuadreItem.puntoVentaHost) ?? '',
      usuarioCodigo: Number(cuadreItem.usuarioCodigo) ?? 0,
      usuarioNombre: String(cuadreItem.usuarioNombre) ?? '',
      cuadreTotal: Number(cuadreItem.cuadreCajaTotal) ?? 0,
      cuadreNumero: Number(cuadreItem.cuadreCajaNumero) ?? 0,
      localCodigo: Number(cuadreItem.localCodigo) ?? 0,
      localNombre: String(cuadreItem.localNombre) ?? '',
      ventaInicial: Number(cuadreItem.ventaInicial) ?? 0,
      ventaFinal: Number(cuadreItem.ventaFinal) ?? 0,
      tipoCuadreCajaCodigo: Number(cuadreItem.tipoCuadreCajaCodigo) ?? 0,
      cuadreDenominacion: itemDenominacion ?? [],
    })
  }
  return cuadreCaja
}

async function getCuadreCajaDetalleByCode(find: any, documento: string) {
  const queryData: any = {
    documento: documento,
  }
  if (find.ccaCodigo) {
    queryData.cuadreCajaCodigo = find.ccaCodigo
  }
  if (find.ccaCod1) {
    queryData.ccaCod1 = find.ccaCod1
  }
  if (find.ccaCod2) {
    queryData.ccaCod2 = find.ccaCod2
  }
  if (find.ccaCod3) {
    queryData.ccaCod3 = find.ccaCod3
  }

  if (find.ccaCod7) {
    queryData.ccaCod7 = find.ccaCod7
  }

  if (find.ccaCod8) {
    queryData.ccaCod8 = find.ccaCod8
  }

  if (find.ccaCod9) {
    queryData.ccaCod9 = find.ccaCod9
  }

  if (find.ccaCod10) {
    queryData.ccaCod10 = find.ccaCod10
  }

  if (find.ccaCod11) {
    queryData.ccaCod11 = find.ccaCod11
  }

  if (find.borigen) {
    queryData.borigen = find.borigen
  }

  if (find.fechaci) {
    queryData.fechaci = find.fechaci
  }
  if (find.fechacf) {
    queryData.fechacf = find.fechacf
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/cuadres',
    'cuadreCaja/detalleFormasPago',
    '',
    queryData,
  )
  const cuadreCaja: Array<formasPagoCuadreDetalle> = []
  for (const cuadreItem of apiData) {
    cuadreCaja.push({
      formaPago: String(cuadreItem.formaPago) ?? '',
      total: Number(cuadreItem.total) ?? 0,
    })
  }
  return cuadreCaja
}

async function getCuadreCajaByCode(codCuadre: number) {
  const queryData: any = {
    cuadreCajaCodigo: codCuadre,
  }
  const apiData = await RequestHelper.get<any>(
    'ventas/cuadres',
    'cuadreCaja/getByCode',
    '',
    queryData,
  )
  const cuadreCaja: any = {}
  if (apiData) {
    const itemDenominacion: Array<DenominacionListado> = []
    apiData.cuadreCajaDenominacion.forEach(function (det) {
      itemDenominacion.push({
        descripcion: String(det?.descripcion) ?? '',
        valor: Number(det?.valor) ?? 0,
        grupo: Number(det?.grupo) ?? 0,
      })
    })

    cuadreCaja.cuadreCodigo = Number(apiData.cuadreCajaCodigo) ?? 0
    cuadreCaja.cuadreFechaCierre = String(apiData.cuadreCajaFechaCierre) ?? ''
    cuadreCaja.cuadreFechaHoraCierre =
      String(apiData.cuadreCajaHoraCierre) ?? ''
    cuadreCaja.puntoCodigo = Number(apiData.puntoVentaCodigo) ?? 0
    cuadreCaja.puntoHost = String(apiData.puntoVentaHost) ?? ''
    cuadreCaja.usuarioCodigo = Number(apiData.usuarioCodigo) ?? 0
    cuadreCaja.usuarioNombre = String(apiData.usuarioNombre) ?? ''
    cuadreCaja.cuadrePunto = String(apiData.cuadreCajaPunto) ?? ''
    cuadreCaja.cuadreTotal = Number(apiData.cuadreCajaTotal) ?? ''
    cuadreCaja.localCodigo = Number(apiData.localCodigo) ?? 0
    cuadreCaja.localNombre = String(apiData.localNombre) ?? ''
    cuadreCaja.cuadreNumero = Number(apiData.cuadreCajaNumero) ?? 0
    cuadreCaja.ventaInicial = Number(apiData.ventaInicial) ?? ''
    cuadreCaja.ventaFinal = Number(apiData.ventaFinal) ?? ''
    cuadreCaja.tipoCuadreCajaCodigo = Number(apiData.tipoCuadreCajaCodigo) ?? 0
    cuadreCaja.cuadreDenominacion = itemDenominacion
  }
  return cuadreCaja
}

async function getAllCuadresCaja(
  rangoFechas: DateRange,
  puntoVenta: string = '',
) {
  const queryData: any = {
    desde: rangoFechas.inicio,
    hasta: rangoFechas.fin,
    cuadreCodigo: 0,
  }
  if (puntoVenta) queryData.puntoVenta = puntoVenta
  const apiData = await RequestHelper.get<Array<any>>(
    'cuadrecaja',
    'all',
    '',
    queryData,
  )
  const cuadreCaja: Array<CuadresCajaListado> = []
  for (const cuadreItem of apiData) {
    const itemDenominacion: Array<DenominacionListado> = []
    cuadreItem.cuadreCajaDenominacion.forEach(function (det) {
      itemDenominacion.push({
        descripcion: String(det?.descripcion) ?? '',
        valor: Number(det?.valor) ?? 0,
        grupo: Number(det?.grupo) ?? 0,
      })
    })

    cuadreCaja.push({
      cuadreCodigo: Number(cuadreItem?.cuadreCajaCodigo) ?? 0,
      cuadreFechaCierre: String(cuadreItem?.cuadreCajaFechaCierre) ?? '',
      cuadreFechaHoraCierre: String(cuadreItem?.cuadreCajaHoraCierre) ?? '',
      puntoVentaCodigo: Number(cuadreItem?.puntoVentaCodigo) ?? 0,
      puntoVentaHost: String(cuadreItem?.puntoVentaHost) ?? '',
      usuarioCodigo: Number(cuadreItem?.usuarioCodigo) ?? 0,
      usuarioNombre: String(cuadreItem?.usuarioNombre) ?? '',
      cuadreTotal: Number(cuadreItem?.cuadreCajaTotal) ?? 0,
      localCodigo: Number(cuadreItem?.localCodigo) ?? 0,
      localNombre: String(cuadreItem?.localNombre) ?? '',
      cuadreNumero: Number(cuadreItem?.cuadreCajaNumero) ?? 0,
      tipoCuadreCajaCodigo: Number(cuadreItem?.tipoCuadreCajaCodigo) ?? 0,
      ventaInicial: Number(cuadreItem?.ventaInicial) ?? 0,
      ventaFinal: Number(cuadreItem?.ventaFinal) ?? 0,
      cuadreDenominacion: itemDenominacion ?? [],
    })
  }
  return cuadreCaja
}

async function getPuntosVentas(find): Promise<Array<Puntoventa>> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    codigoLocal: sesion.local.codigo,
    ...find,
  }

  const dataResAPi = await RequestHelper.get<Array<any>>(
    'generales',
    'pointSale/comboLoad',
    '',
    data,
  )

  let puntosVenta: Array<Puntoventa> = []
  puntosVenta = dataResAPi.map(function (key: Puntoventa) {
    return {
      codigoPuntoVenta: Number(key['codigoPuntoVenta']),
      host: String(key['host']),
      codigoLocal: Number(key['codigoLocal']),
      nombreLocal: String(key['nombreLocal']),
      especial: String(key['especial']),
      mac: String(key['mac']),
      uso: String(key['uso']),
      restaurante: Number(key['restaurante']),
      toOrden: Number(key['toOrden']),
      emula: Number(key['emula']),
      estado: Number(key['estado']),
      nombreDocumento: String(key['nombreDocumento']),
    }
  })

  return puntosVenta
}

async function getLocales(find): Promise<Array<LocalOption>> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    codigoLocal: sesion.local.codigo,
    codigoempresa: sesion.empresa.codigo,
    ...find,
  }

  const dataResApi = await RequestHelper.get<Array<any>>(
    'local',
    'cboxLoad',
    '',
    data,
  )

  let localApi: Array<LocalOption> = []
  localApi = dataResApi.map(function (key: LocalOption) {
    return {
      codigo: Number(key['localCodigo']) ?? -1,
      nombre: String(key['localNombre']) ?? '',
      siglas: String(key['localSiglas']) ?? '',
      proyectoCodigo: Number(key['localTipo']) ?? -1,
      proyectoNombre: String(key['localTipoDescripcion']) ?? '',
    }
  })

  return localApi
}

async function getEspecial(fecha: string, tipoDocumento: number) {
  const queryData: any = {
    fecha: fecha,
    tipoDocumento: tipoDocumento,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'generales',
    'sales/getSpecial',
    '',
    queryData,
  )
  const cuadreCaja: Array<IngresosCuadresCajaListado> = []
  for (const cuadreItem of apiData) {
    cuadreCaja.push({
      ventaCodigo: Number(cuadreItem.ventaCodigo) ?? 0, //VEN_CODIGO;
      ventaTipoDocumento: String(cuadreItem.ventaTipoDocumento) ?? '', // VEN_TIPODOC;
      ventaTipoDocumentoDescripcion:
        String(cuadreItem.ventaTipoDocumentoDescripcion) ?? '', // VEN_TIPODOC_DES;
      ventaNumero: String(cuadreItem.ventaNumero) ?? '', // VEN_NUMERO;
      ventaAutorizacion: String(cuadreItem.ventaAutorizacion) ?? '', // VEN_AUTORIZACION;
      ventaEstablecimiento: String(cuadreItem.ventaEstablecimiento) ?? '', // VEN_ESTABLECIMIENTO;
      ventaPuntoEmision: String(cuadreItem.ventaPuntoEmision) ?? '', // VEN_PTOEMISION;
      ventaFecha: String(cuadreItem.ventaFecha) ?? '', // VEN_FECHA;
      ventaGuia: String(cuadreItem.ventaGuia) ?? '', // VEN_GUIA;
      ventaSubtotal12: Number(cuadreItem.ventaSubtotal12) ?? 0, // VEN_SUBTOTAL12;
      ventaSubtotal0: Number(cuadreItem.ventaSubtotal0) ?? 0, // VEN_SUBTOTAL0;
      ventaSubtotalNoIVA: Number(cuadreItem.ventaSubtotalNoIVA) ?? 0, // VEN_SUBTOTALNOIVA;
      ventaDescuento: Number(cuadreItem.ventaDescuento) ?? 0, // VEN_DESCUENTO;
      ventaSubtotal: Number(cuadreItem.ventaSubtotal) ?? 0, // VEN_SUBTOTAL;
      ventaIVA: Number(cuadreItem.ventaIVA) ?? 0, // VEN_IVA;
      ventaICE: Number(cuadreItem.ventaICE) ?? 0, // VEN_ICE;
      ventaTotal: Number(cuadreItem.ventaTotal) ?? 0, // VEN_TOTAL;
      ventaFormaPago: Number(cuadreItem.ventaFormaPago) ?? 0, //VEN_FPAGO;
      ventaFormaPagoDescripcion:
        String(cuadreItem.ventaFormaPagoDescripcion) ?? '', // VEN_FPAGO_DES;
      asientoNumero: String(cuadreItem.asientoNumero) ?? '', // ASI_NUMERO;
      clienteCodigo: Number(cuadreItem.clienteCodigo) ?? 0, //CLI_CODIGO;
      clienteIdentificacion: String(cuadreItem.clienteIdentificacion) ?? '', // CLI_IDENTIFICACION;
      clienteNombre: String(cuadreItem.clienteNombre) ?? '', // CLI_NOMBRE;
      clienteRazonComercial: String(cuadreItem.clienteRazonComercial) ?? '', // CLI_RCOMERCIAL;
      clienteDireccion: String(cuadreItem.clienteDireccion) ?? '', // CLI_DIRECCION;
      clienteTelefono: String(cuadreItem.clienteTelefono) ?? '', // CLI_TELEFONO;
      clienteTipoIdentificacion:
        String(cuadreItem.clienteTipoIdentificacion) ?? '', // CLI_TIPOIDE;
      clienteEmail: String(cuadreItem.clienteEmail) ?? '', // CLI_EMAIL;
      clienteImpresion: String(cuadreItem.clienteImpresion) ?? '', // CLI_IMPRESION;
      usuarioCodigo: Number(cuadreItem.usuarioCodigo) ?? 0, //USU_CODIGO;
      empresaCodigo: Number(cuadreItem.empresaCodigo) ?? 0, //EMP_CODIGO;
      localCodigo: Number(cuadreItem.localCodigo) ?? 0, //LOC_CODIGO;
      puntoVenta: Number(cuadreItem.puntoVenta) ?? 0, //PTO_CODIGO;
      puntoVentaHost: String(cuadreItem.puntoVentaHost) ?? '', // PTO_HOST;
      puntoVentaEspecial: String(cuadreItem.puntoVentaEspecial) ?? '', // PTO_ESPECIAL;
      ventaImpreso: Number(cuadreItem.ventaImpreso) ?? 0, //VEN_IMPRESO;
      ventaObservaciones: String(cuadreItem.ventaObservaciones) ?? '', // VEN_OBSERVACIONES;
      vendedorCodigo: String(cuadreItem.vendedorCodigo) ?? '', // VDR_CODIGO;
      ventaServicios: Number(cuadreItem.ventaServicios) ?? 0, // VEN_SERVICIOS;
      sucursalCodigo: Number(cuadreItem.sucursalCodigo) ?? 0, //SUC_CODIGO;
      ventaEstado: String(cuadreItem.ventaEstado) ?? '', // VEN_ESTADO;
      ventaEstadoDescripcion: String(cuadreItem.ventaEstadoDescripcion) ?? '', // VEN_ESTADO_DES;
      retencionVentaTotal: Number(cuadreItem.retencionVentaTotal) ?? 0, // REV_TOTAL;
      retencionVentaIVA: Number(cuadreItem.retencionVentaIVA) ?? 0, // REV_IVA;
      retencionVentaRenta: Number(cuadreItem.retencionVentaRenta) ?? 0, // REV_RENTA;
      saldo: Number(cuadreItem.saldo) ?? 0, // SALDO;
      claveClave: String(cuadreItem.claveClave) ?? '', // CLA_CLAVE;
      claveFecha: String(cuadreItem.claveFecha) ?? '', // CLA_FECHA;
      ventaCosteoNotasCredito: Number(cuadreItem.ventaCosteoNotasCredito) ?? 0, // VEN_COSTEONC;
      ventaDescuentoGlobal: Number(cuadreItem.ventaDescuentoGlobal) ?? 0, // VEN_DESCUENTOG;
      ventaFechaNegociacion: String(cuadreItem.ventaFechaNegociacion) ?? '', // VEN_FECHAN;
      ventaImpuesto: String(cuadreItem.ventaImpuesto) ?? '', // VEN_IMPUESTO;
      totalActivoBIVA: Number(cuadreItem.totalActivoBIVA) ?? 0, // TOTALACTIVOB_IVA;
      totalActivoIIVA: Number(cuadreItem.totalActivoIIVA) ?? 0, // TOTALACTIVOI_IVA;
      totalActivoB0: Number(cuadreItem.totalActivoB0) ?? 0, // TOTALACTIVOB_0;
      totalActivoI0: Number(cuadreItem.totalActivoI0) ?? 0, // TOTALACTIVOI_0;
      totalBienesBIVA: Number(cuadreItem.totalBienesBIVA) ?? 0, // TOTALBIENESB_IVA;
      totalBienesIIVA: Number(cuadreItem.totalBienesIIVA) ?? 0, // TOTALBIENESI_IVA;
      totalServicioBIVA: Number(cuadreItem.totalServicioBIVA) ?? 0, // TOTALSERVICIOB_IVA;
      totalServicioIIVA: Number(cuadreItem.totalServicioIIVA) ?? 0, // TOTALSERVICIOI_IVA;
      totalBienesB0: Number(cuadreItem.totalBienesB0) ?? 0, // TOTALBIENESB_0;
      totalBienesI0: Number(cuadreItem.totalBienesI0) ?? 0, // TOTALBIENESI_0;
      totalServiciosB0: Number(cuadreItem.totalServiciosB0) ?? 0, // TOTALSERVICIOSB_0;
      totalServiciosI0: Number(cuadreItem.totalServiciosI0) ?? 0, // TOTALSERVICIOSI_0;
      clienteRemitente: Number(cuadreItem.clienteRemitente) ?? 0, // CLI_REMITENTE;
      clienteIdentificacionRemitente:
        String(cuadreItem.clienteIdentificacionRemitente) ?? '', // CLI_IDENTIFICACIONRE;
      clienteNombreRemitente: String(cuadreItem.clienteNombreRemitente) ?? '', // CLI_NOMBRERE;
      clienteDireccionRemitente:
        String(cuadreItem.clienteDireccionRemitente) ?? '', // CLI_DIRECCIONRE;
      clienteTelefonoRemitente:
        String(cuadreItem.clienteTelefonoRemitente) ?? '', // CLI_TELEFONORE;
      clienteDestinatario: String(cuadreItem.clienteDestinatario) ?? '', // CLI_DESTINATARIO;
      clienteIdentificacionDestinatario:
        String(cuadreItem.clienteIdentificacionDestinatario) ?? '', // CLI_IDENTIFICACIONDE;
      clienteNombreDestinatario:
        String(cuadreItem.clienteNombreDestinatario) ?? '', // CLI_NOMBREDE;
      clienteDireccionDestinatario:
        String(cuadreItem.clienteDireccionDestinatario) ?? '', // CLI_DIRECCIONDE;
      clienteTelefonoDestinatario:
        String(cuadreItem.clienteTelefonoDestinatario) ?? '', // CLI_TELEFONODE;
      vehiculoCodigo: Number(cuadreItem.vehiculoCodigo) ?? 0, //VEH_CODIGO;
      vehiculoDisco: String(cuadreItem.vehiculoDisco) ?? '', // VEH_DISCO;
      vehiculoPlaca: String(cuadreItem.vehiculoPlaca) ?? '', // VEH_PLACA;
      proveedorSocio: String(cuadreItem.proveedorSocio) ?? '', // PRV_SOCIO;
      proveedorChofer: String(cuadreItem.proveedorChofer) ?? '', // PRV_CHOFER;
      ventaTipoDestino: String(cuadreItem.ventaTipoDestino) ?? '', // VEN_TIPODESTINO;
      ventaDestinoCiudad: String(cuadreItem.ventaDestinoCiudad) ?? '', // VEN_DESTINOCIU;
      ventaDestinoCiudadNombre:
        String(cuadreItem.ventaDestinoCiudadNombre) ?? '', // VEN_DESTINOCIUNOM;
      ventaPasajeros: String(cuadreItem.ventaPasajeros) ?? '', // VEN_PASAJEROS;
      ventaRentaInicio: String(cuadreItem.ventaRentaInicio) ?? '', // VEN_RENINICIO;
      ventaRentaFin: String(cuadreItem.ventaRentaFin) ?? '', // VEN_RENFIN;
      dias: String(cuadreItem.dias) ?? '', // DIAS;
      ventaViene: String(cuadreItem.ventaViene) ?? '', // VEN_VIENE;
      ventaVieneNumero: String(cuadreItem.ventaVieneNumero) ?? '', // VEN_VIENENUM;
      color: String(cuadreItem.color) ?? '', // COLOR;
      ventaOrigen: String(cuadreItem.ventaOrigen) ?? '', // VEN_ORIGEN;
      nuevoInicio: String(cuadreItem.nuevoInicio) ?? '', // NUEVOINICIO;
      nuevoDias: String(cuadreItem.nuevoDias) ?? '', // NUEVODIAS;
      movimientoCabeceraCodigo:
        Number(cuadreItem.movimientoCabeceraCodigo) ?? 0, //MCA_CODIGO;
      estado: String(cuadreItem.estado) ?? '', // ESTADO;
      proyectoCodigo: Number(cuadreItem.proyectoCodigo) ?? 0, //PRO_CODIGO;
      ventaMulta: String(cuadreItem.ventaMulta) ?? '', // VEN_MULTA;
      ventaObservacionMulta: String(cuadreItem.ventaObservacionMulta) ?? '', // VEN_OBSMULTA;
      comisionVentaCodigo: String(cuadreItem.comisionVentaCodigo) ?? '', // COV_CODIGO;
      ventaIRBPNR: Number(cuadreItem.ventaIRBPNR) ?? 0, // VEN_IRBPNR;
    })
  }
  return apiData
}

async function geIngresosBoveda(puntoVentaCodigo: number, fechaHasta: string) {
  const queryData: any = {
    puntoVentaCodigo: puntoVentaCodigo,
    fechaHasta: fechaHasta,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/cuadres/',
    'cuadreCaja/incomeBowel',
    '',
    queryData,
  )
  // const cuadreCaja: Array<CuadresCajaListado> = [];
  // for (const cuadreItem of apiData) {
  //   cuadreCaja.push({
  //     cuadreCodigo: Number(cuadreItem.cuadreCajaCodigo) ?? 0,
  //     cuadreFechaCierre: String(cuadreItem.cuadreCajaFechaCierre) ?? "",
  //     cuadreFechaHoraCierre: String(cuadreItem.cuadreCajaFechaHoraCierre) ?? "",
  //     puntoCodigo: Number(cuadreItem.puntoCodigo) ?? 0,
  //     puntoHost: String(cuadreItem.puntoHost) ?? "",
  //     usuarioCodigo: Number(cuadreItem.usuarioCodigo) ?? 0,
  //     usuarioNombre: String(cuadreItem.usuarioNombre) ?? "",
  //     cuadrePunto: String(cuadreItem.cuadreCajaPunto) ?? "",
  //     cuadreTotal: Number(cuadreItem.cuadreCajaTotal) ?? "",
  //     localCodigo: Number(cuadreItem.localCodigo) ?? 0,
  //     localNombre: String(cuadreItem.localNombre) ?? "",
  //     cuadreNumero: Number(cuadreItem.cuadreCajaNumero) ?? 0,
  //     cuadreDenominacion: String(cuadreItem.cuadreCajaDenominacion) ?? "",
  //   });
  // }
  return apiData
}

async function getEgresosBoveda(puntoVentaCodigo: number, fechaHasta: string) {
  const queryData: any = {
    puntoVentaCodigo: puntoVentaCodigo,
    fechaHasta: fechaHasta,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/cuadres',
    'cuadreCaja/egresssBowel',
    '',
    queryData,
  )
  const cuadreEgresoBoveda: Array<EgresosBovedaListado> = []
  for (const itemEgreso of apiData) {
    cuadreEgresoBoveda.push({
      egresoCodigo: Number(itemEgreso.egresoCodigo) ?? 0, // EGR_CODIGO;
      bovedaOrigen: Number(itemEgreso.bovedaOrigen) ?? 0, // BOV_ORIGEN;
      bovedaOrigenNombre: String(itemEgreso.bovedaOrigenNombre) ?? '', // BOV_ORIGEN_NOM;
      bovedaDestino: Number(itemEgreso.bovedaDestino) ?? 0, // BOV_DESTINO;
      bovedaDestinoNombre: String(itemEgreso.bovedaDestinoNombre) ?? '', // BOV_DESTINO_NOM;
      boveda100: Number(itemEgreso.boveda100) ?? 0, // BOV_100;
      boveda50: Number(itemEgreso.boveda50) ?? 0, // BOV_50;
      boveda20: Number(itemEgreso.boveda20) ?? 0, // BOV_20;
      boveda10: Number(itemEgreso.boveda10) ?? 0, // BOV_10;
      boveda5: Number(itemEgreso.boveda5) ?? 0, // BOV_5;
      boveda1: Number(itemEgreso.boveda1) ?? 0, // BOV_1;
      boveda050: Number(itemEgreso.boveda050) ?? 0, // BOV_050;
      boveda025: Number(itemEgreso.boveda025) ?? 0, // BOV_025;
      boveda010: Number(itemEgreso.boveda010) ?? 0, // BOV_010;
      boveda005: Number(itemEgreso.boveda005) ?? 0, // BOV_005;
      boveda001: Number(itemEgreso.boveda001) ?? 0, // BOV_001;
      bovedaEstado: String(itemEgreso.bovedaEstado) ?? '', // BOV_ESTADO;
      egresoTipo: String(itemEgreso.egresoTipo) ?? '', // EGR_TIPO;
      egresoFecha: String(itemEgreso.egresoFecha) ?? '', // EGR_FECHA;
      fecha: String(itemEgreso.fecha) ?? '', // FECHA;
      egresoAceptado: String(itemEgreso.egresoAceptado) ?? '', // EGR_ACEPTADO;
      egresoConcepto: String(itemEgreso.egresoConcepto) ?? '', // EGR_CONCEPTO;
      egresoValor: Number(itemEgreso.egresoValor) ?? 0, //  EGR_VALOR;
    })
  }
  return cuadreEgresoBoveda
}

async function getCierreCaja2(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fecha: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData: any = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/closeBox2',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getComboTipoPago() {
  const queryData: any = {
    grupo: 3,
    all: true,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'generales',
    'subtypes/cboxLoadFP',
    '',
    queryData,
  )
  const formas: Array<formasPago> = []
  for (const cuadreFactura of apiData) {
    if (Number(cuadreFactura.codigo) !== EFormasPago.otros) {
      formas.push({
        codigo: Number(cuadreFactura.codigo) ?? -1,
        descripcion: String(cuadreFactura.descripcion) ?? '',
        grupo: String(cuadreFactura.grupo) ?? '',
      })
    }
  }
  return formas
}

async function getCierreCajaNC(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fecha: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/boxClaspNC',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getCierreCajaRet(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/boxClasp',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getCierreCuadreActicipos(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/advances/cashBox',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getAbonosCuadres(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/sales/seePaymentCashes',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getAbonosCompras(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/sales/cashBox',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getPagosTesoreria(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
) {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/cuadres',
    'cuadreCaja/sales/cashBox',
    '',
    queryData,
  )
  const cuadre: Array<ComprasCuadresCajaListado> = []
  for (const cuadreItem of apiData) {
    cuadre.push({
      compraCodigo: Number(cuadreItem.compraCodigo) ?? 0, // COM_CODIGO
      compraTipoDocumentoDescripcion:
        String(cuadreItem.compraTipoDocumentoDescripcion) ?? '', // COM_TIPODOC_DES
      compraNumero: String(cuadreItem.compraNumero) ?? '', // COM_NUMERO
      proveedorNombre: String(cuadreItem.proveedorNombre) ?? '', // PRV_NOMBRE
      formaPago: Number(cuadreItem.formaPago) ?? 0, // SUB_CODIGO
      compraFormaPagoDescripcion:
        String(cuadreItem.compraFormaPagoDescripcion) ?? '', // COM_FPAGO_DES
      compraTotal: Number(cuadreItem.compraTotal) ?? 0, // COM_TOTAL
      retencionCompraTotal: Number(cuadreItem.retencionCompraTotal) ?? 0, // REC_TOTAL
      saldo: Number(cuadreItem.saldo) ?? 0, // SALDO
    })
  }
  return cuadre
}

async function getPagosCuadres(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/shopping/viewPayCashBox',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getChequesCuadres(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/checks/cashBox',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getVendedores(find: any) {
  const sesion = SesionService.getCurrentSesion()
  const queryData: any = {
    codigoLocal: sesion.local.codigo,
    mensaje: find?.mensaje ?? 'Elija un vendedor',
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/ventas',
    'sellers/cboxLoad',
    '',
    queryData,
  )
  const cuadre: Array<VendedoresListado> = []
  for (const cuadreItem of apiData) {
    cuadre.push({
      vendedorCodigo: Number(cuadreItem.vendedorCodigo) ?? 0, //  VDR_CODIGO;
      usuarioCodigo: Number(cuadreItem.usuarioCodigo) ?? 0, //  USU_CODIGO;
      comision: Number(cuadreItem.comision) ?? 0, //   VDR_COMISION;
      vendedorZona: String(cuadreItem.vendedorZona) ?? '', //  VDR_ZONA;
      usuarioNombres: String(cuadreItem.usuarioNombres) ?? '', //  USU_NOMBRES;
      usuarioApellidos: String(cuadreItem.usuarioApellidos) ?? '', //  USU_APELLIDOS;
      completo: String(cuadreItem.completo) ?? '', //  VDR_COMPLETO;
      siglas: String(cuadreItem.siglas) ?? '', //  VDR_SIGLAS;
      estado: String(cuadreItem.estado) ?? '', //  VDR_ESTADO;
    })
  }
  return cuadre
}

async function getLetrasCuadres(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/cashBox',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getBovedaCuadre(
  localCodigo: number,
  puntoVentaCodigo: number,
  fechaInicial: string,
  fechaFinal: string,
) {
  const queryData: any = {
    puntoVentaCodigo: puntoVentaCodigo,
    localCodigo: localCodigo,
    fechaDesde: fechaInicial,
    fechaHasta: fechaFinal,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'ventas/cuadres',
    'cuadreCaja/incomeBowel',
    '',
    queryData,
  )
  const cuadre: Array<BovedaCuadresCajaListado> = []
  for (const cuadreItem of apiData) {
    cuadre.push({
      egresoCodigo: Number(cuadreItem.egresoCodigo) ?? 0, // '] = (int) $row -> EGR_CODIGO;
      bovedaOrigen: Number(cuadreItem.bovedaOrigen) ?? 0, // '] = (int) $row -> BOV_ORIGEN;
      bovedaOrigenNombre: String(cuadreItem.bovedaOrigenNombre) ?? '', // '] = $row -> BOV_ORIGEN_NOM;
      bovedaDestino: Number(cuadreItem.bovedaDestino) ?? 0, // '] = (int) $row -> BOV_DESTINO;
      bovedaDestinoNombre: String(cuadreItem.bovedaDestinoNombre) ?? '', // '] = $row -> BOV_DESTINO_NOM;
      boveda100: Number(cuadreItem.boveda100) ?? 0, // '] = (int) $row -> BOV_100;
      boveda50: Number(cuadreItem.boveda50) ?? 0, // '] = (int) $row -> BOV_50;
      boveda20: Number(cuadreItem.boveda20) ?? 0, // '] = (int) $row -> BOV_20;
      boveda10: Number(cuadreItem.boveda10) ?? 0, // '] = (int) $row -> BOV_10;
      boveda5: Number(cuadreItem.boveda5) ?? 0, // '] = (int) $row -> BOV_5;
      boveda1: Number(cuadreItem.boveda1) ?? 0, // '] = (int) $row -> BOV_1;
      boveda050: Number(cuadreItem.boveda050) ?? 0, // '] = (int) $row -> BOV_050;
      boveda025: Number(cuadreItem.boveda025) ?? 0, // '] = (int) $row -> BOV_025;
      boveda010: Number(cuadreItem.boveda010) ?? 0, // '] = (int) $row -> BOV_010;
      boveda005: Number(cuadreItem.boveda005) ?? 0, // '] = (int) $row -> BOV_005;
      boveda001: Number(cuadreItem.boveda001) ?? 0, // '] = (int) $row -> BOV_001;
      bovedaEstado: String(cuadreItem.bovedaEstado) ?? '', // '] = $row -> BOV_ESTADO;
      egresoTipo: String(cuadreItem.egresoTipo) ?? '', // '] = $row -> EGR_TIPO;
      egresoFecha: String(cuadreItem.egresoFecha) ?? '', // '] = $row -> EGR_FECHA;
      fecha: String(cuadreItem.fecha) ?? '', // '] = $row -> FECHA;
      egresoAceptado: String(cuadreItem.egresoAceptado) ?? '', // '] = $row -> EGR_ACEPTADO;
      egresoConcepto: String(cuadreItem.egresoConcepto) ?? '', // '] = $row -> EGR_CONCEPTO;
      egresoValor: Number(cuadreItem.egresoValor) ?? 0, // '] = (double) $row -> EGR_VALOR;
    })
  }
  return cuadre
}

async function getDeleteCuadre(objDetele) {
  try {
    const queryData: any = {
      ...objDetele,
    }
    const apiData = await RequestHelper.deleteRequestAll<any>(
      'ventas/cuadres',
      'cuadreCaja/delete',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function ingresarCuadre(
  cuadreCaja: TCuadreCajaIngresar,
): Promise<Promise<any>> {
  const dataSave = {
    infoRegistro: {
      ...cuadreCaja,
    },
  }
  const creada = await RequestHelper.post<any>(
    'ventas/cuadres',
    'cuadreCaja/save',
    dataSave,
  )
  return creada
}

async function getCierreCuadreActiciposProveedores(
  fecha: string,
  localCodigo: number,
  puntoVentaCodigo: number,
  ventaInicial: number,
  ventaFinal: number,
): Promise<any> {
  const queryData: any = {
    fechaHasta: fecha,
    localCodigo: localCodigo,
    puntoVentaCodigo: puntoVentaCodigo,
    ventaInicial: ventaInicial,
    ventaFinal: ventaFinal,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'ventas/cuadres',
      'cuadreCaja/advances/cashBoxSupplier',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function ingresarCaja(cuadreCaja: TIngresoCaja): Promise<Promise<any>> {
  try {
    const dataSave = {
      infoRegistro: {
        ...cuadreCaja,
      },
    }
    const creada = await RequestHelper.postAll<any>(
      'ventas/cuadres',
      'cuadreCaja/sales/save',
      dataSave,
    )
    return creada
  } catch (error) {
    return error
  }
}

async function getCargarEspecial(
  fecha: string,
  tipoDocumento: number,
): Promise<any> {
  const queryData: any = {
    fecha: fecha,
    tipoDocumento: tipoDocumento,
  }
  try {
    const apiData = await RequestHelper.getAll<any>(
      'generales/sales',
      'getSpecial',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getDeleteIngresoCaja(objDetele) {
  try {
    const queryData: any = {
      ventaCodigo: objDetele?.ventaCodigo ?? 0,
    }
    const apiData = await RequestHelper.deleteRequestAll<any>(
      'ventas/cuadres/cuadreCaja',
      'sales/deleteSpecial',
      '',
      queryData,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getSecuancialCuadres(): Promise<number> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    localCodigo: sesion.local.codigo,
  }
  const res = await RequestHelper.get<number>(
    'ventas/cuadres',
    'cuadreCaja/sequential',
    '',
    data,
  )
  return res
}
