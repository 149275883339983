import React from 'react'
import { DataGrid } from 'devextreme-react/data-grid'
import { ClientesService } from '../../../../clientes/pages/clientes/services/clientes.service'
import { utilidades } from '../../../../../helpers/utilidades'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import { CBadge, CCard, CCardBody } from '@coreui/react-pro'
import {
  Button,
  TextBox,
  Validator,
  ValidationGroup,
  ValidationSummary,
} from 'devextreme-react'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import TextArea from 'devextreme-react/text-area'
import {
  AsyncRule,
  Column,
  EmailRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/data-grid'
import LocalidadesLookUp from '../../../../componentes/localidadesLookUp/localidadesLookUp'
import {
  DatosEdicionSucursal,
  DatosSucursales,
} from '../../../../clientes/pages/clientes/types/types'
import { TDetalleContactosSucursales } from '../../../../clientes/pages/clientes/components/documento/componentes/sucursales/contactosLookUp/types/type'
import { getZonas } from '../../../../clientes/pages/clientes/helper/helper'
import { SelectBoxDE } from '../../../../../views/componentes/selectBox-DE/selectBox-DE'
import { ToastTypes } from '../../../../../store/types'
interface ISucursalesProps {
  clienteCodigo: number
  setToast: (sms: string, type: ToastTypes) => void
  onChange: (data: Array<DatosSucursales> | []) => void
}
const CSucursales: React.FunctionComponent<ISucursalesProps> = (props) => {
  const { clienteCodigo, setToast, onChange } = props
  const formSucursal = React.useRef<any>()

  const [sucursal, setSucursal] = React.useState<any>({
    ...defaultDatosEdicionSucursalCliente,
  })
  const [sucursales, setSucursales] = React.useState<
    Array<DatosSucursales> | []
  >([])
  const [seleccionarDato, setSeleccionarDato] = React.useState<any | null>(null)

  const onChangedDireccion = React.useCallback(
    (data) => {
      setSucursal({
        ...sucursal,
        direccion: data,
      })
    },
    [sucursal],
  )

  const onChangedReference = React.useCallback(
    (data) => {
      setSucursal({
        ...sucursal,
        referencia: data,
      })
    },
    [sucursal],
  )

  const onChangedEmail = React.useCallback(
    (data) => {
      setSucursal({
        ...sucursal,
        email: data,
      })
    },
    [sucursal],
  )

  const onChangedCiudad = React.useCallback(
    (data) => {
      setSucursal({
        ...sucursal,
        ciudad: data,
      })
    },
    [sucursal],
  )

  const onChangeZonas = React.useCallback(
    (data) => {
      setSucursal({
        ...sucursal,
        zona: data,
      })
    },
    [sucursal],
  )

  const onChangedContacto = React.useCallback(
    (data) => {
      setSucursal({
        ...sucursal,
        telefono: data,
      })
    },
    [sucursal],
  )

  const validateAsyncSelect = React.useCallback(
    (datoSeleccion: any, message: string) => {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    },
    [],
  )

  const validateAsyncSeleccionCiudad = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Ciudad: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const onSelectionChanged = React.useCallback((evt) => {
    if (evt['selectedRowsData'].length > 0) {
      const data = evt['selectedRowsData'][0]
      setSeleccionarDato({
        index: 1,
        row: data,
      })
    }
  }, [])

  const validateAsyncSeleccionZona = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Zona: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const validateAsyncSeleccionContacto = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `Contacto: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const onAddSucursal = React.useCallback(() => {
    const init = { ...sucursal }
    const result = formSucursal.current.instance.validate()
    if (result.isValid === false) {
      setToast(
        'No se pudo registrar la sucursal, por favor revise las advertencias.',
        ToastTypes.Danger,
      )
      return false
    } else {
      let zonaCodigo = null
      let contactoCodigo = null
      let contactoNumero = null
      let ciudadCodigo = null
      if (sucursal.telefono == null || sucursal.telefono == undefined) {
        setToast(
          'Es necesario tener seleccionado un contacto telefónico.',
          ToastTypes.Danger,
        )
        return false
      } else {
        contactoCodigo = sucursal.telefono.codigo
        contactoNumero = sucursal.telefono.numero
      }
      if (sucursal.zona == null || sucursal.zona == undefined) {
        setToast('Es necesario tener seleccionado una zona.', ToastTypes.Danger)
        return false
      } else {
        zonaCodigo = sucursal.zona.codigo
      }
      if (sucursal.ciudad == null || sucursal.ciudad == undefined) {
        setToast(
          'Es necesario tener seleccionado una ciudad.',
          ToastTypes.Danger,
        )
        return false
      } else {
        ciudadCodigo = sucursal.ciudad.codigo
      }
      const rows = sucursales.slice()
      const newSucursal: DatosSucursales = {
        codigo: 0,
        clienteCodigo: clienteCodigo,
        direccion: sucursal.direccion,
        referencia: sucursal.referencia,
        email: sucursal.email,
        contacto: contactoNumero ?? null,
        codContacto: contactoCodigo ?? null,
        uuid: utilidades.getUUID(),
        ciudadCodigo: ciudadCodigo ?? null,
        ciudadDescripcion: '',
        zonaCodigo: zonaCodigo ?? null,
        zonaDescripcion: '',
      }
      if (sucursal.zona) {
        newSucursal.zonaCodigo = sucursal.zona.codigo
        newSucursal.zonaDescripcion = sucursal.zona.descripcion
      }
      if (sucursal.ciudad) {
        newSucursal.ciudadCodigo = sucursal.ciudad.codigo
        newSucursal.ciudadDescripcion = sucursal.ciudad.nombre
      }
      rows.push(newSucursal)
      setSucursal({
        ...init,
        direccion: '',
        referencia: '',
        telefono: null,
        email: '',
        ciudad: null,
        zona: null,
        sucursales: rows,
      })
      setSucursales(rows)
      formSucursal.current.instance.reset()
    }
  }, [sucursal, sucursales, setToast, clienteCodigo])

  const onRemove = React.useCallback(() => {
    if (seleccionarDato === null) {
      setToast(
        'No se encuentra seleccionado un registro para eliminarlo.',
        ToastTypes.Info,
      )
      return false
    } else {
      const filtrado = sucursal?.sucursales.findIndex((item: any) => {
        return item.key === seleccionarDato['row']['key']
      })
      if (filtrado > -1) {
        const rowsBefore = sucursales.slice()
        rowsBefore.splice(filtrado, 1)
        setSeleccionarDato(null)
        setToast('Removido correctamente.', ToastTypes.Success)
        setSucursales(rowsBefore)
      }
    }
  }, [sucursal, sucursales, setToast, seleccionarDato])

  const setData = React.useCallback(
    async (clienteCodigo) => {
      const init = { ...sucursal }
      const clienteContactos = await ClientesService.getDetailsCustomer(
        clienteCodigo,
      )
      let contactosSucursales: Array<TDetalleContactosSucursales> = []
      contactosSucursales = clienteContactos.map((x) => {
        return {
          codigo: x?.codigo,
          descripcion: x?.descripcion ?? '',
          numero: x?.numero ?? '',
          tipo: x?.tipoDescripcion ?? '',
          tipoDescripcion: x?.tipoDescripcion ?? '',
          estado: x?.estado ?? 0,
          asociadoCodigo: x?.asociadoCodigo ?? 0,
          asociadoTipo: x?.asociadoTipo ?? 0,
          principal: x?.principal ?? 0,
        }
      })
      const clienteSucursales = await ClientesService.getSucursales(
        clienteCodigo,
      )
      if (contactosSucursales.length > 0) {
        init.telefonos = contactosSucursales
        init.telefono = contactosSucursales[0]
      }
      const sucursalesCliente: any = clienteSucursales.map((x) => {
        return {
          codigo: x.codigo,
          clienteCodigo: x.codigoCliente,
          direccion: x.direccion,
          referencia: x.referencia,
          email: x.email,
          contacto: x.telefonoNumero,
          codContacto: x.telefonoCodigo,
          uuid: utilidades.getUUID(),
          ciudadCodigo: x.codigoCiudad,
          ciudadDescripcion: x.ciudadNombre,
          zonaCodigo: x.codigoZona,
          zonaDescripcion: x.descripcionZona,
        }
      })
      if (sucursalesCliente.length > 0) {
        init.sucursales = sucursalesCliente
      }
      const optionZonas = await getZonas()
      if (optionZonas.length > 0) {
        init.zona =
          optionZonas.find((x) => x.codigo === clienteCodigo) ?? optionZonas[0]
        init.zonas = optionZonas
      }
      setSucursal(init)
      setSucursales(init?.sucursales ?? [])
    },
    [sucursal],
  )

  React.useEffect(() => {
    setData(clienteCodigo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteCodigo])

  React.useEffect(() => {
    onChange(sucursales ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sucursales])

  return (
    <CCard>
      <CCardBody>
        <ValidationGroup
          key={'valitadionCliSucursales'}
          id={'valitadionCliSucursales'}
          ref={formSucursal}
        >
          <RowContainer>
            <CustomCol xs="12" md="12" className="mt-1 mb-1">
              <ValidationSummary id="summary" />
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label="Dirección:">
                <TextArea
                  value={sucursal?.direccion ?? ''}
                  onValueChange={(data) => {
                    onChangedDireccion(data)
                  }}
                >
                  <Validator>
                    <RequiredRule
                      message={'Dirección: Debe tener al menos 5 caracteres.'}
                    />
                    <StringLengthRule min={5} />
                  </Validator>
                </TextArea>
              </Labeled>
            </CustomCol>

            <CustomCol xs="12" md="6">
              <Labeled label="Referencia:">
                <TextArea
                  value={sucursal?.referencia ?? ''}
                  onValueChange={(data) => {
                    onChangedReference(data)
                  }}
                >
                  <Validator>
                    <RequiredRule
                      message={'Referencia: Debe tener al menos 5 caracteres.'}
                    />
                    <StringLengthRule
                      min={5}
                      message={'Referencia: Debe tener al menos 5 caracteres.'}
                    />
                  </Validator>
                </TextArea>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="6">
              <Labeled label="Email:">
                <TextBox
                  value={sucursal?.email ?? ''}
                  onValueChange={(data) => {
                    onChangedEmail(data)
                  }}
                >
                  <Validator>
                    <RequiredRule
                      message={'Email: Formato de email incorrecto.'}
                    />
                    <EmailRule
                      message={'Email: Formato de email incorrecto.'}
                    />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="6">
              <Labeled label="Teléfono:">
                <SelectBoxDE
                  items={sucursal?.telefonos ?? []}
                  value={sucursal?.telefono ?? null}
                  displayExpr="numero"
                  searchEnabled={true}
                  onValueChange={(d) => {
                    onChangedContacto(d)
                  }}
                  id="contactosSelect"
                  key="contactosSelect"
                  inputAttr={{
                    autocomplete: 'nope',
                  }}
                >
                  <Validator>
                    <RequiredRule
                      message={'Teléfono: Debe seleccionar una opción'}
                    />
                    <AsyncRule
                      message={'Teléfono: Debe seleccionar una opción'}
                      validationCallback={(e) =>
                        validateAsyncSeleccionContacto(e)
                      }
                    />
                  </Validator>
                </SelectBoxDE>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" md="6">
              <Labeled label="Municipio:">
                <LocalidadesLookUp
                  onChange={(data) => {
                    onChangedCiudad(data)
                  }}
                  onChangedOptions={() => {}}
                  selected={sucursal?.ciudad ?? null}
                  allowEdit
                  allowClear
                >
                  <Validator>
                    <RequiredRule
                      message={'Municipio: Debe seleccionar una opción'}
                    />
                    <AsyncRule
                      message={'Municipio: Debe seleccionar una opción'}
                      validationCallback={(e) =>
                        validateAsyncSeleccionCiudad(e)
                      }
                    />
                  </Validator>
                </LocalidadesLookUp>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" md="6">
              <Labeled label="Ruta:">
                <RowContainer>
                  <CustomCol md="8" xs="8">
                    <SelectBoxDE
                      items={sucursal?.zonas ?? []}
                      value={sucursal?.zona ?? null}
                      displayExpr="descripcion"
                      searchEnabled={true}
                      onValueChange={(d) => {
                        onChangeZonas(d)
                      }}
                      id="zonasSelect"
                      key="zonasSelect"
                      inputAttr={{
                        autocomplete: 'nope',
                      }}
                    >
                      <Validator>
                        <RequiredRule
                          message={'Zona: Debe seleccionar una opción'}
                        />
                        <AsyncRule
                          message={'Zona: Debe seleccionar una opción'}
                          validationCallback={(e) =>
                            validateAsyncSeleccionZona(e)
                          }
                        />
                      </Validator>
                    </SelectBoxDE>
                  </CustomCol>
                  <CustomCol md="4" xs="4">
                    <Button
                      id="btnAdd"
                      className="me-1"
                      icon="add"
                      stylingMode="contained"
                      type="default"
                      onClick={() => onAddSucursal()}
                    />
                    <Button
                      id="btnRemove"
                      icon="trash"
                      stylingMode="contained"
                      type="danger"
                      onClick={() => onRemove()}
                    />
                  </CustomCol>
                </RowContainer>
              </Labeled>
            </CustomCol>
          </RowContainer>
        </ValidationGroup>

        <RowContainer>
          <CustomCol xs="12" className="mt-2 mb-2">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <div>
                <CBadge color="info">{`${
                  sucursales && sucursales.length
                } REGISTROS`}</CBadge>
              </div>
            </div>
          </CustomCol>
        </RowContainer>
        <RowContainer className="mt-2">
          <CustomCol xs="12" md="12">
            <DataGrid
              keyExpr="uuid"
              repaintChangesOnly
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              dataSource={sucursales ?? []}
              showBorders={true}
              showRowLines={true}
              onSelectionChanged={(e) => onSelectionChanged(e)}
            >
              <Column
                dataType="string"
                dataField="ciudadDescripcion"
                caption="Ciudad"
                width="30%"
              />
              <Column
                dataType="string"
                dataField="direccion"
                caption="Dirección"
                width="60%"
              />
              <Column
                dataType="string"
                dataField="email"
                caption="Email"
                width="35%"
              />
              <Column dataField="zonaDescripcion" caption="Ruta" width="60%" />
              <Column dataField="contacto" caption="Telefono" width="30%" />
            </DataGrid>
          </CustomCol>
        </RowContainer>
      </CCardBody>
    </CCard>
  )
}
export default React.memo(CSucursales)

export const defaultDatosEdicionSucursalCliente: DatosEdicionSucursal = {
  direccion: '',
  referencia: '',
  telefono: null,
  telefonos: [],
  email: '',
  ciudad: null,
  zonas: [],
  zona: null,
  sucursales: [],
}
