//#region imports
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import {
  CTabContent,
  CTabPane,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CAlert,
} from '@coreui/react-pro'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../../../../../../../../store/store'
import { DocumentInfoCuadre } from '../../types/types'
import {
  CuadresCajaDatosEdicion,
  CuadresCajaListado,
  formasPago,
  LocalOption,
  TabStateCuadre,
  TCuadreCajaIngresar,
  DetalleCuadreRegistro,
  formasPagoCuadre,
  DenominacionListado,
} from '../../../../types/types'
import LoadingIndicator from '../../../../../../../../views/componentes/loadingindicator/loadingindicator'
import VisualizaError from '../../../../../shared/visualizaError/visualizaError'
import {
  setDatosEdicion,
  setMuestraError,
  updatePuntoVentaOnly,
  updateMinutoCierreOnly,
  updateHoraCierreOnly,
  updateFechaCierreOnly,
  updateLocalOnly,
  updateCcaCodigoOnly,
  updateUsuarioNombreOnly,
  updateUsuarioCodigoOnly,
  updateCuadreTotalOnly,
  updateGenerarCuadreOnly,
  updateDpFormasPagoOnly,
} from '../../store/editDataReducer'
import { StatesEdition } from '../../../../../../types/enums'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import SelectBox from '../../../../../../../../views/componentes/selectBox/selectBox'
import {
  buttonClick,
  changeEditStatus,
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import {
  DateUtils,
  formatoFechasApi,
  formatoFechasDatePickers,
} from '../../../../../../../../helpers/dateUtils'
import { CuadresService } from '../../../../services/cuadres.service'
import { Puntoventa, ToastTypes } from '../../../../../../../../store/types'
import { NumberBox } from 'devextreme-react/number-box'
import Denominacion, {
  objetoBilletes,
  objetoMonedas,
} from '../tabs/denominacion/denominacion'
import Facturas from '../tabs/facturas/facturas'
import NotasCredito from '../tabs/notasCredito/notasCredito'
import Retenciones from '../tabs/retenciones/retenciones'
import Anticipos from '../tabs/anticipos/anticipos'
import CobrosCartera from '../tabs/cobrosCartera/cobrosCartera'
import ComprasCuadres from '../tabs/compras/comprasCuadres'
import PagosTesoreria from '../tabs/pagosTesoreria/pagosTesoreria'
import DateBox from 'devextreme-react/date-box'
import { CustomButtons } from '../../../../../../types/generics'
import Totales from '../tabs/totales/totales'
import Cheques from '../tabs/cheques/cheques'
import Letras from '../tabs/letras/letras'
import { ButtonTypes } from '../../../../../../../../views/componentes/globalMenu/types'
import { addToast } from '../../../../../../../../store/toasterReducer'
import { ReportWithLocalDataModal } from '../../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { seleccionarCuadre } from '../../store/configuracionesComandaReducer'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import ModalListadoComprobantes from '../../../../../../../componentes/modalListadoComprobantes'
import Dialog from '../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../../../../views/componentes/librerias/block-ui'
import { listadoComprobantesService } from '../../../../../../../componentes/modalListadoComprobantes/service/modalListadoComprobantes.services'
import AnticiposProveedores from '../tabs/anticiposProveedores/anticipos'
import {
  getAbonosComprasCHelper,
  getAbonosCuadres,
  getChequesCuadresHelper,
  getCierreCajaNCHelper,
  getCierreCajaRet,
  getCierreCuadreActicipos,
  getCierreCuadreActiciposProveedoresHelper,
  getDatosFacturas,
  getLetrasCuadresHelper,
  getPagosCuadresHelper,
} from '../../../../services/helper.cuadreCaja'
import { UrlHelper } from '../../../../../../../../helpers/urlHelper'
import { EFormasPago } from '../../../../../../../../store/enum/formasPago'
import { ECountry } from '../../../../../../../../store/enum/countries'

interface ICuadreCajaProps extends React.PropsWithChildren {
  info: DocumentInfoCuadre<CuadresCajaListado>
  tabId: string
  tab: TabStateCuadre<CuadresCajaListado>
  templateMode?: boolean
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
  modulo: number
}

enum TabCuadres {
  Denominacion = 'Denominación',
  Facturas = 'Facturas',
  AnticiposClientes = 'Anticipos Clientes',
  CobrosCartera = 'Cobros de cartera',
  Retenciones = 'Retenciones',
  NotasCredito = 'Notas de Credito',
  Compras = 'Compras',
  PagosTesoreria = 'Pagos Tesoreria',
  AnticiposProveedores = 'Anticipos Proveedores',
  Totales = 'Totales',
  Cheques = 'Cheques',
  Letras = 'Letras',
  // Boveda = 'Boveda',
  // EgresosBoveda = 'Egresos a Boveda',
}

const planes651Ec = [6, 5, 1]
const planes234Ec = [2, 3, 4]
const tabCuadresArray = Object.values(TabCuadres)

type TotalesResumen = {
  facturas: Array<any>
  notasCredito: Array<any>
  anticipo: Array<any>
  abonos: Array<any>
  pagos: Array<any>
}

type TotalesDetalle = {
  facturas: Array<any>
  notasCredito: Array<any>
  depositosGarantia: Array<any>
  ventasPorLinea: Array<any>
}

type ItemTab = {
  tabTitle: string
  isActive: boolean
}

enum PLANTILLA_IMPRIMIR {
  DENOMINACION_TICKET = 'StaticDenominacionTicketera',
  DENOMINACION_STARDAR = 'StaticDenominacionStandar',
  COBORSCARTERA_TICKETERA = 'StaticCobrosCarteraTicketera',
  COBORSCARTERA_STARDAR = 'StaticCobrosCarteraStardar',
  TOTALES_TOTALES = 'StaticTotales',
  TOTALES_RESUMEN = 'StaticTotalesResumen',
  TOTALES_DETALLE = 'StaticTotalesDetalle',
}

enum MODAL_DOCUMENTOS {
  CHEQUES = 'cheques',
  CUOTAS = 'cuotas',
  TARJETAS = 'tarjetas',
}

const availablesTabs = [
  'Facturas',
  'Notas de Credito',
  'Retenciones',
  'Compras',
  'Totales',
]

type TDataReport = {
  cabecera?: any
  detalle?: any
  totales?: any
  resumen?: any
}

const CuadreCaja: React.FunctionComponent<ICuadreCajaProps> = (props) => {
  const { tabId, info, tab, setToast, playLoader, stopLoader, modulo } = props

  const dispatch = useDispatch()

  const usuario = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  //const currentTab = useSelector((state: RootState) => { return state.ventas.ventas.tabs.tabs[props.tabIndex] });
  const puntoVentaGlobal = useSelector((state: RootState) => {
    return state.global.puntoVenta
  })
  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId]
  })
  const loading = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loading
  })
  const puntoVenta = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].puntoVenta
  })
  const fechaCierre = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre
  })
  const horaCierre = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].horaCierre
  })
  const minutoCierre = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].minutoCierre
  })
  const segundosCierre = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].segundosCierre
  })
  const local = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].local
  })
  const formEdition = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId]
  })
  const denominacion = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].denominacion
  })
  const fecha = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fecha
  })
  const dptotales1 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales1
  })
  const dptotales2 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales2
  })
  const dptotales3 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales3
  })
  const dptotales7 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales7
  })
  const dptotales8 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales8
  })
  const dptotales9 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales9
  })
  const dptotales10 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales10
  })
  const dptotales11 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales11
  })
  const ccaCod1 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod1
  })
  const venInicial1 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial1
  })
  const venFinal1 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal1
  })
  const ccaCod2 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod2
  })
  const venInicial2 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial2
  })
  const venFinal2 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal2
  })
  const ccaCod3 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod3
  })
  const venInicial3 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial3
  })
  const venFinal3 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal3
  })
  const ccaCod4 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod4
  })
  const venInicial4 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial4
  })
  const venFinal4 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal4
  })
  const ccaCod5 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod5
  })
  const venInicial5 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial5
  })
  const venFinal5 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal5
  })
  const ccaCod6 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod6
  })
  const venInicial6 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial6
  })
  const venFinal6 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal6
  })
  const ccaCod7 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod7
  })
  const venInicial7 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial7
  })
  const venFinal7 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal7
  })
  const ccaCod8 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod8
  })
  const venInicial8 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial8
  })
  const venFinal8 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal8
  })
  const ccaCod9 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod9
  })
  const venInicial9 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial9
  })
  const venFinal9 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal9
  })
  const ccaCod10 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod10
  })
  const ccaCod11 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod11
  })
  const venInicial10 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial10
  })
  const venFinal10 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal10
  })
  const venInicial11 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial11
  })
  const venFinal11 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal11
  })
  const imprimir = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].imprimir
  })
  const dpcierre1 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre1
  })
  const dpcierre2 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre2
  })
  const dpcierre3 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre3
  })
  const dpcierre4 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre4
  })
  const dpcierre5 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre5
  })
  const dpcierre7 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre7
  })
  const dpcierre8 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre8
  })
  const dpcierre9 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre9
  })
  const dpcierre10 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre10
  })
  const dptotales = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales
  })
  const dpcierre11 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre11
  })
  const ccaCodigo = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCodigo
  })
  const contratoEmpresaState = useSelector((state: RootState) => {
    return state.pagosSuscripcion.contratoEmpresa
  })
  const { dptotalresumen } = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId]
  })

  const urls = UrlHelper.getUrls()

  const [showTab, setShowTab] = React.useState<string>(tabCuadresArray[0])
  const [tabsCuadre, setTabsCuadre] = React.useState<Array<any>>([])

  const dialogRefCuadre = React.useRef<any>(null)

  const [puntosVenta, setPuntosVenta] = React.useState<Array<Puntoventa> | []>(
    [],
  )
  const [locales, setLocales] = React.useState<Array<LocalOption> | []>([])
  const [currentTabCuadreIndex, setCurrentTabCuadreIndex] =
    React.useState<TabCuadres>(TabCuadres.Denominacion)
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )

  const [datosExportar, setDatosExportar] = React.useState<TDataReport | []>([])
  const [plantillaNombre, setPlantillaNombre] =
    React.useState<PLANTILLA_IMPRIMIR>(PLANTILLA_IMPRIMIR.DENOMINACION_STARDAR)
  const [openModal, setOpenModal] = React.useState<MODAL_DOCUMENTOS | null>(
    null,
  )
  const [cheques, setCheques] = React.useState<Array<any>>([])
  const [cuotas, setCuotas] = React.useState<Array<any>>([])
  const [tarjetas, setTarjetas] = React.useState<Array<any>>([])

  const [loadedTabDenominacion, setLoadedTabDenominacion] =
    React.useState<boolean>(false)
  const [loadedTabFacturas, setLoadedTabFacturas] =
    React.useState<boolean>(false)
  const [loadedTabAnticiposClientes, setLoadedTabAnticiposClientes] =
    React.useState<boolean>(false)
  const [loadedTabCobrosCartera, setLoadedTabCobrosCartera] =
    React.useState<boolean>(false)
  const [loadedTabRetenciones, setLoadedTabRetenciones] =
    React.useState<boolean>(false)
  const [loadedTabNotasCredito, setLoadedTabNotasCredito] =
    React.useState<boolean>(false)
  const [loadedTabCompras, setLoadedTabCompras] = React.useState<boolean>(false)
  const [loadedTabPagosTesoreria, setLoadedTabPagosTesoreria] =
    React.useState<boolean>(false)
  const [loadedTabAnticiposProveedores, setLoadedTabAnticiposProveedores] =
    React.useState<boolean>(false)
  const [loadedTabTotales, setLoadedTabTotales] = React.useState<boolean>(false)
  const [loadedTabCheques, setLoadedTabCheques] = React.useState<boolean>(false)
  const [loadedTabLetras, setLoadedTabLetras] = React.useState<boolean>(false)
  const [habilitarDisenador, setHabilitarDisenador] =
    React.useState<boolean>(true)

  const getVerificaPath = React.useCallback(async () => {
    if (urls.origin.includes('acatha.io')) {
      setHabilitarDisenador(false)
    } else {
      setHabilitarDisenador(true)
    }
  }, [urls])

  const parseInfoAllCuadres = React.useCallback(
    async (
      data: CuadresCajaDatosEdicion,
      dataAPiCuadres: Array<CuadresCajaListado>,
    ) => {
      for (let i: number = 0; i < dataAPiCuadres.length; i++) {
        switch (dataAPiCuadres[i].tipoCuadreCajaCodigo) {
          case 1:
            data.venInicial1 = dataAPiCuadres[i].ventaInicial
            data.venFinal1 = dataAPiCuadres[i].ventaFinal
            data.ccaCod1 = dataAPiCuadres[i].cuadreCodigo
            break
          case 2:
            data.venInicial2 = dataAPiCuadres[i].ventaInicial
            data.venFinal2 = dataAPiCuadres[i].ventaFinal
            data.ccaCod2 = dataAPiCuadres[i].cuadreCodigo
            break
          case 3:
            data.venInicial3 = dataAPiCuadres[i].ventaInicial
            data.venFinal3 = dataAPiCuadres[i].ventaFinal
            data.ccaCod3 = dataAPiCuadres[i].cuadreCodigo
            break
          case 4:
            data.venInicial4 = dataAPiCuadres[i].ventaInicial
            data.venFinal4 = dataAPiCuadres[i].ventaFinal
            data.ccaCod4 = dataAPiCuadres[i].cuadreCodigo
            break
          case 5:
            data.venInicial5 = dataAPiCuadres[i].ventaInicial
            data.venFinal5 = dataAPiCuadres[i].ventaFinal
            data.ccaCod5 = dataAPiCuadres[i].cuadreCodigo
            break
          case 6:
            data.venInicial6 = dataAPiCuadres[i].ventaInicial
            data.venFinal6 = dataAPiCuadres[i].ventaFinal
            data.ccaCod6 = dataAPiCuadres[i].cuadreCodigo
            break
          case 7:
            data.venInicial7 = dataAPiCuadres[i].ventaInicial
            data.venFinal7 = dataAPiCuadres[i].ventaFinal
            data.ccaCod7 = dataAPiCuadres[i].cuadreCodigo
            break
          case 8:
            data.venInicial8 = dataAPiCuadres[i].ventaInicial
            data.venFinal8 = dataAPiCuadres[i].ventaFinal
            data.ccaCod8 = dataAPiCuadres[i].cuadreCodigo
            break
          case 9:
            data.venInicial9 = dataAPiCuadres[i].ventaInicial
            data.venFinal9 = dataAPiCuadres[i].ventaFinal
            data.ccaCod9 = dataAPiCuadres[i].cuadreCodigo
            break
          case 10:
            data.venInicial10 = dataAPiCuadres[i].ventaInicial
            data.venFinal10 = dataAPiCuadres[i].ventaFinal
            data.ccaCod10 = dataAPiCuadres[i].cuadreCodigo
            break
          case 11:
            data.venInicial11 = dataAPiCuadres[i].ventaInicial
            data.venFinal11 = dataAPiCuadres[i].ventaFinal
            data.ccaCod11 = dataAPiCuadres[i].cuadreCodigo
            break
        }
      }
      return await data
    },
    [],
  )

  const parseInfoCuadre = React.useCallback(
    async (dataAPiCuadre) => {
      const cuadreRegistrado = dataAPiCuadre
      const itemDenominacion: Array<DenominacionListado> = []
      cuadreRegistrado.cuadreDenominacion.forEach(function (det) {
        itemDenominacion.push({
          descripcion: String(det?.descripcion) ?? '',
          valor: Number(det?.valor) ?? 0,
          grupo: Number(det?.grupo) ?? 0,
        })
      })

      const returnObject = {
        cuadreCodigo: Number(cuadreRegistrado?.cuadreCodigo) ?? 0,
        cuadreFechaCierre: String(cuadreRegistrado?.cuadreFechaCierre) ?? '',
        cuadreFechaHoraCierre:
          String(cuadreRegistrado?.cuadreFechaHoraCierre) ?? '',
        puntoVentaCodigo: Number(cuadreRegistrado?.puntoCodigo) ?? -1,
        puntoVentaHost: String(cuadreRegistrado?.puntoHost) ?? '',
        usuarioCodigo: Number(cuadreRegistrado?.usuarioCodigo) ?? 0,
        usuarioNombre: String(cuadreRegistrado?.usuarioNombre) ?? '',
        cuadreTotal: Number(cuadreRegistrado?.cuadreTotal) ?? 0,
        localCodigo: Number(cuadreRegistrado?.localCodigo) ?? 0,
        localNombre: String(cuadreRegistrado?.localNombre) ?? '',
        cuadreNumero: Number(cuadreRegistrado?.cuadreNumero) ?? 0,
        ventaInicial: Number(cuadreRegistrado?.ventaInicial) ?? 0,
        ventaFinal: Number(cuadreRegistrado?.ventaFinal) ?? 0,
        tipoCuadreCajaCodigo:
          Number(cuadreRegistrado?.tipoCuadreCajaCodigo) ?? 0,
        cuadreDenominacion: itemDenominacion,
      }
      dispatch(
        updateCcaCodigoOnly({
          ccaCodigo: Number(cuadreRegistrado?.cuadreCodigo) ?? 0,
          key: tabId,
        }),
      )
      dispatch(
        updateUsuarioNombreOnly({
          usuarioNombre: String(cuadreRegistrado?.usuarioNombre) ?? '',
          key: tabId,
        }),
      )
      dispatch(
        updateUsuarioCodigoOnly({
          usuarioCodigo: Number(cuadreRegistrado?.usuarioCodigo) ?? 0,
          key: tabId,
        }),
      )
      dispatch(
        updateCuadreTotalOnly({
          cuadreTotal: Number(cuadreRegistrado?.cuadreTotal) ?? 0,
          key: tabId,
        }),
      )
      return returnObject
    },
    [tabId, dispatch],
  )

  const onTotalizaCantidadComprobantes = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
      const cantidad = {
        facturas: 0,
        notasCredito: 0,
        retenciones: 0,
        anticipos: 0,
        anticiposp: 0,
        cobrosCartera: 0,
        compras: 0,
        pagosTesoreria: 0,
        cheques: 0,
        credito: 0,
        totalFacturasAnuladas: 0,
        letrasCambio: 0,
      }

      let total: number = 0
      let aux: number = 0
      let validas: number = 0
      let k: number = 0
      let totalFacturasAnuladas: number = 0
      let totalFacturas: number = 0
      let totalNotasCredito: number = 0
      let totalRetenciones: number = 0
      let totalCheques: number = 0
      let totalLetrasCambio: number = 0
      let totalAnticiposClientes: number = 0
      let totalAnticiposProveedores: number = 0
      let totalCobros: number = 0
      let totalCompras: number = 0
      let totalTesoreria: number = 0

      // TOTALIZADO GENERAL

      for (k = 0; k < dpcierre1.length; k++) {
        if (dpcierre1[k].ventaEstado === '*') {
          total++
        }
        if (aux !== Number(dpcierre1[k].ventaCodigo)) {
          validas++
        }
        aux = Number(dpcierre1[k].ventaCodigo)
      }
      totalFacturasAnuladas = total
      totalFacturas = validas - total

      total = 0
      for (k = 0; k < dpcierre2.length; k++) {
        if (dpcierre2[k].ventaEstado === '*') {
          total++
        }
      }
      totalNotasCredito = dpcierre2.length - total

      total = 0
      for (k = 0; k < dpcierre3.length; k++) {
        if (dpcierre3[k].ventaEstado === '*') {
          total++
        }
      }

      if (
        tipoImpresion === PLANTILLA_IMPRIMIR.DENOMINACION_TICKET ||
        tipoImpresion === PLANTILLA_IMPRIMIR.DENOMINACION_STARDAR
      ) {
        totalRetenciones = dpcierre3.length - total
        totalCheques = dpcierre3.length - total
        totalLetrasCambio = dpcierre5.length
        totalAnticiposClientes = dpcierre7.length
        totalAnticiposProveedores = dpcierre11.length
        totalCobros = dpcierre8.length
        totalCompras = dpcierre9.length
        totalTesoreria = dpcierre10.length
      } else if (
        tipoImpresion === PLANTILLA_IMPRIMIR.COBORSCARTERA_TICKETERA ||
        tipoImpresion === PLANTILLA_IMPRIMIR.COBORSCARTERA_STARDAR
      ) {
        totalRetenciones = dpcierre3.length - total
        totalCheques = dpcierre4.length - total
        totalLetrasCambio = dpcierre5.length
        totalAnticiposClientes = dpcierre7.length
        totalCobros = dpcierre8.length
        totalCompras = dpcierre9.length
        totalTesoreria = dpcierre10.length
        totalAnticiposProveedores = dpcierre11.length
      }

      cantidad.facturas = totalFacturas
      cantidad.notasCredito = totalNotasCredito
      cantidad.retenciones = totalRetenciones
      cantidad.anticipos = totalAnticiposClientes
      cantidad.anticiposp = totalAnticiposProveedores
      cantidad.cobrosCartera = totalCobros
      cantidad.compras = totalCompras
      cantidad.pagosTesoreria = totalTesoreria
      cantidad.cheques = totalCheques
      cantidad.credito = totalCompras
      cantidad.totalFacturasAnuladas = totalFacturasAnuladas
      cantidad.letrasCambio = totalLetrasCambio

      return await cantidad
    },
    [
      dpcierre1,
      dpcierre2,
      dpcierre3,
      dpcierre5,
      dpcierre7,
      dpcierre8,
      dpcierre9,
      dpcierre10,
      dpcierre4,
      dpcierre11,
    ],
  )

  const onParseInfoReporteTotales = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
      console.log('onParseInfoReporteTotales')
      let resCargaCuadre: any = null
      playLoader()
      const findDetall: any = {
        ccaCod1: formEdition.ccaCod1,
        ccaCod7: formEdition.ccaCod7,
        ccaCod8: formEdition.ccaCod8,
        ccaCod10: formEdition.ccaCod10,
        vendedorCodigo: formEdition?.vendedor?.vendedorCodigo ?? 0,
        localCodigo: formEdition?.local?.codigo ?? 0,
        puntoVentaCodigo: formEdition?.puntoVenta?.codigoPuntoVenta ?? -1,
      }

      try {
        resCargaCuadre = await CuadresService.getCuadreCajaReportTotales(
          findDetall,
        )
        console.log('resCargaCuadre', resCargaCuadre)

        stopLoader()

        const resumenTotales: TotalesResumen = {
          facturas: [],
          notasCredito: [],
          anticipo: [],
          abonos: [],
          pagos: [],
        }

        if (resCargaCuadre) {
          const resCargaCuadreFacturas: Array<any> = []
          resCargaCuadre.facturas.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaCuadreFacturas.push({
              cheque: String(det?.cheque) ?? '',
              descripcion: String(det?.descripcion) ?? '',
              fecha: String(det?.fecha) ?? '',
              numero: String(det?.numero) ?? '',
              identificacion: String(det?.identificacion) ?? '',
              nombre: String(det?.nombre) ?? '',
              abono: Number(det?.abono ?? 0),
              total: total,
              anulado: String(det?.anulado) ?? '',
            })
          })
          resumenTotales.facturas = resCargaCuadreFacturas

          const resCargaCuadreNotasCredito: Array<any> = []
          resCargaCuadre.notasCredito.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaCuadreNotasCredito.push({
              descripcion: String(det?.descripcion) ?? '',
              fecha: String(det?.fecha) ?? '',
              numero: String(det?.numero) ?? '',
              identificacion: String(det?.identificacion) ?? '',
              nombre: String(det?.nombre) ?? '',
              abono: Number(det?.abono ?? 0),
              total: total,
              anulado: String(det?.anulado) ?? '',
            })
          })
          resumenTotales.notasCredito = resCargaCuadreNotasCredito

          const resCargaCuadreAnticipos: Array<any> = []
          resCargaCuadre.anticipo.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaCuadreAnticipos.push({
              fecha: String(det?.registro) ?? '',
              descripcion: String(det?.descripcion) ?? '',
              numero: String(det?.numero) ?? '',
              identificacion: String(det?.identificacion) ?? '',
              nombre: String(det?.nombre) ?? '',
              total: total,
            })
          })
          resumenTotales.anticipo = resCargaCuadreAnticipos

          const resCargaCuadreAbonos: Array<any> = []
          resCargaCuadre.abonos.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaCuadreAbonos.push({
              descripcion: String(det?.descripcion) ?? '',
              identificacion: String(det?.identificacion) ?? '',
              nombre: String(det?.nombre) ?? '',
              numero: String(det?.numero) ?? '',
              fecha: String(det?.registro) ?? '',
              tipo: Number(det?.tipo) ?? 0,
              total: total,
            })
          })
          resumenTotales.abonos = resCargaCuadreAbonos

          const resCargaCuadrePagos: Array<any> = []
          resCargaCuadre.pagos.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaCuadrePagos.push({
              fecha: String(det?.registro) ?? '',
              descripcion: String(det?.descripcion) ?? '',
              tipo: Number(det?.tipo) ?? 0,
              numero: String(det?.numero) ?? '',
              identificacion: String(det?.identificacion) ?? '',
              nombre: String(det?.nombre) ?? '',
              total: total,
            })
          })
          resumenTotales.pagos = resCargaCuadrePagos
        }

        const hora: string = `${formEdition?.horaCierre}${':'}${
          formEdition.minutoCierre
        }`

        const objetoExportar: TDataReport = {
          cabecera: {
            cuadreCodigo: formEdition?.ccaCodigo ?? 0,
            fechaCierre: formEdition?.fechaCierre ?? '',
            horaCierre: hora,
            puntoVentaCodigo: formEdition?.puntoVenta?.codigoLocal ?? 0,
            puntoVentaDescripcion: formEdition?.puntoVenta?.host ?? '',
            localNombre: formEdition?.local?.codigo ?? 0,
            localCodigo: formEdition?.local?.nombre ?? 0,
            cuadreNumero: formEdition?.numero ?? 0,
            usuarioNombre: formEdition?.usuarioNombre ?? '',
            usuarioCodigo: formEdition?.usuarioCodigo ?? 0,
            cuadreTotal: formEdition?.cuadreTotal ?? 0,
          },
          detalle: resumenTotales,
        }

        setDatosExportar(objetoExportar)

        return true
      } catch (error) {
        stopLoader()
        console.error(error)
        return false
      }
    },
    [formEdition, playLoader, stopLoader],
  )

  const onParseInfoReporteDetalle = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
      let resCargaCuadre: any = null
      playLoader()
      const findDetall: any = {
        ccaCod1: formEdition.ccaCod1,
        vendedorCodigo: formEdition?.vendedor?.vendedorCodigo ?? 0,
        localCodigo: formEdition?.local?.codigo ?? 0,
        puntoVentaCodigo: formEdition?.puntoVenta?.codigoPuntoVenta ?? -1,
      }

      try {
        resCargaCuadre = await CuadresService.getCuadreCajaReportDetalles(
          findDetall,
        )
        stopLoader()

        const resumenTotales: TotalesDetalle = {
          facturas: [],
          notasCredito: [],
          depositosGarantia: [],
          ventasPorLinea: [],
        }

        if (resCargaCuadre) {
          const resCargaCuadreFacturas: Array<any> = []
          resCargaCuadre.facturas.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaCuadreFacturas.push({
              cheque: String(det?.cheque) ?? '',
              descripcion: String(det?.descripcion) ?? '',
              fecha: String(det?.fecha) ?? '',
              numero: String(det?.numero) ?? '',
              identificacion: String(det?.identificacion) ?? '',
              nombre: String(det?.nombre) ?? '',
              abono: String(det?.abono) ?? '',
              total: total,
              anulado: String(det?.anulado) ?? '',
            })
          })
          resumenTotales.facturas = resCargaCuadreFacturas

          const resCargaCuadreNotasCredito: Array<any> = []
          resCargaCuadre.notasCredito.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaCuadreNotasCredito.push({
              descripcion: String(det?.descripcion) ?? '',
              fecha: String(det?.fecha) ?? '',
              numero: String(det?.numero) ?? '',
              identificacion: String(det?.identificacion) ?? '',
              nombre: String(det?.nombre) ?? '',
              abono: String(det?.abono) ?? '',
              total: total,
              anulado: String(det?.anulado) ?? '',
            })
          })
          resumenTotales.notasCredito = resCargaCuadreNotasCredito

          const resCargaDepositosPorGarantia: Array<any> = []
          resCargaCuadre.depositosGarantia.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaDepositosPorGarantia.push({
              descripcion: String(det?.descripcion) ?? '',
              identificacion: String(det?.identificacion) ?? '',
              nombre: String(det?.nombre) ?? '',
              numero: String(det?.numero) ?? '',
              fecha: String(det?.registro) ?? '',
              tipo: Number(det?.tipo) ?? 0,
              total: total,
            })
          })
          resumenTotales.depositosGarantia = resCargaDepositosPorGarantia

          const resCargaVentasPorLinea: Array<any> = []
          resCargaCuadre.ventasPorLinea.forEach(function (det) {
            let total = 0
            if (
              det?.total !== null ||
              det?.total !== '' ||
              det?.total !== undefined
            ) {
              total = parseFloat(det?.total.replace(/,/g, ''))
            }
            resCargaVentasPorLinea.push({
              descripcion: String(det?.descripcion) ?? '',
              total: total,
            })
          })
          resumenTotales.ventasPorLinea = resCargaVentasPorLinea
        }

        const hora: string = `${formEdition?.horaCierre}${':'}${
          formEdition.minutoCierre
        }`

        const objetoExportar = {
          cabecera: {
            cuadreCodigo: formEdition?.ccaCodigo ?? 0,
            fechaCierre: formEdition?.fechaCierre ?? '',
            horaCierre: hora,
            puntoVentaCodigo: formEdition?.puntoVenta?.codigoLocal ?? 0,
            puntoVentaDescripcion: formEdition?.puntoVenta?.host ?? '',
            localNombre: formEdition?.local?.codigo ?? 0,
            localCodigo: formEdition?.local?.nombre ?? 0,
            cuadreNumero: formEdition?.numero ?? 0,
            usuarioNombre: formEdition?.usuarioNombre ?? '',
            usuarioCodigo: formEdition?.usuarioCodigo ?? 0,
            cuadreTotal: formEdition?.cuadreTotal ?? 0,
          },
          detalle: resumenTotales,
        }

        setDatosExportar(objetoExportar)
        return true
      } catch (error) {
        stopLoader()
        console.error(error)
        return false
      }
    },
    [formEdition, playLoader, stopLoader],
  )

  const onParseInfoReporteResumen = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
      let resCargaCuadre: any = null
      playLoader()
      const findDetall: any = {
        ccaCod1: formEdition.ccaCod1,
        ccaCod2: formEdition.ccaCod2,
        ccaCod3: formEdition.ccaCod3,
        puntoVentaCodigo: formEdition?.puntoVenta?.codigoPuntoVenta ?? -1,
      }

      try {
        resCargaCuadre = await CuadresService.getCuadreCajaReportResumen(
          findDetall,
        )

        stopLoader()

        const resumenTotales: Array<any> = []

        if (resCargaCuadre) {
          // resCargaCuadre.forEach(function (det) {
          //   resumenTotales.push({
          //     abono: Number(det?.abono) ?? 0,
          //     comprobante: String(det?.comprobante) ?? "",
          //     estado: String(det?.estado) ?? "",
          //     fecha: String(det?.fecha) ?? "",
          //     fpago: String(det?.fpago) ?? "",
          //     nombre: String(det?.nombre) ?? "",
          //     numero: String(det?.numero) ?? "",
          //     total: Number(det?.total) ?? 0,
          //     saldo: Number(det?.saldo) ?? 0,
          //   })
          // })

          const hora: string = `${formEdition?.horaCierre}${':'}${
            formEdition.minutoCierre
          }`

          const objetoExportar = {
            cabecera: {
              cuadreCodigo: formEdition?.ccaCodigo ?? 0,
              fechaCierre: formEdition?.fechaCierre ?? '',
              horaCierre: hora,
              puntoVentaCodigo: formEdition?.puntoVenta?.codigoLocal ?? 0,
              puntoVentaDescripcion: formEdition?.puntoVenta?.host ?? '',
              localNombre: formEdition?.local?.codigo ?? 0,
              localCodigo: formEdition?.local?.nombre ?? 0,
              cuadreNumero: formEdition?.numero ?? 0,
              usuarioNombre: formEdition?.usuarioNombre ?? '',
              usuarioCodigo: formEdition?.usuarioCodigo ?? 0,
              cuadreTotal: formEdition?.cuadreTotal ?? 0,
            },
            detalle: resCargaCuadre,
          }

          setDatosExportar(objetoExportar)
        }

        return true
      } catch (error) {
        stopLoader()
        console.error(error)
        return false
      }
    },
    [formEdition, playLoader, stopLoader],
  )

  const onParseInfoReporte = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
      let dataPrint: TDataReport = {}
      let resCargaCuadre: any = null
      const resumen: any = {
        descuadre: formEdition?.descuadre_ti ?? 0,
        totalEgreso: formEdition?.totalegreso_ti ?? 0,
        totalVentas: 0,
        totalEfectivo: 0,
      }
      try {
        console.log('onParseInfoReporte')
        console.log('resCargaCuadre', resCargaCuadre)
        console.log(formEdition.fecha)
        // total efectivo init
        let valorBilletes: number = 0
        let valorMonedas: number = 0
        formEdition?.objetoBilletes.forEach(function (item: any) {
          valorBilletes = valorBilletes + item.total
        })
        formEdition?.objetoMonedas.forEach(function (item: any) {
          valorMonedas = valorMonedas + item.total
        })
        resumen.totalEfectivo = Number(valorBilletes) + Number(valorMonedas)
        // total efectivo end

        // total ventas init

        const totalventas_ti: number = formEdition?.totalventas_ti
        if (dptotalresumen.length > 0) {
          const dptotalresumenEfectivo = dptotalresumen.find(
            //EFECTIVO
            (x: any) => String(x?.valor) === 'EFECTIVO',
          )

          if (
            dptotalresumenEfectivo !== undefined &&
            dptotalresumenEfectivo?.total
          ) {
            resumen.totalVentas =
              Number(totalventas_ti) + Number(dptotalresumenEfectivo?.total)
          }
        }
        // total ventas end

        const fecha = formEdition.fecha
        let fechaInicial = ''
        let hora: string = ''

        if (fecha.includes(' ')) {
          const fechaPos = fecha.split(' ')
          fechaInicial = DateUtils.convertirFecha(fechaPos[0])
          hora = `${formEdition?.horaCierre}${':'}${formEdition.minutoCierre}`
        }

        const cantidades = await onTotalizaCantidadComprobantes(tipoImpresion)

        dataPrint = {
          cabecera: {
            cuadreCodigo: formEdition?.ccaCodigo ?? 0,
            fechaCierre: formEdition?.fechaCierre ?? '',
            horaCierre: hora,
            puntoVentaCodigo: formEdition?.puntoVenta?.codigoLocal ?? 0,
            puntoVentaDescripcion: formEdition?.puntoVenta?.host ?? '',
            localNombre: formEdition?.local?.codigo ?? 0,
            localCodigo: formEdition?.local?.nombre ?? 0,
            cuadreNumero: formEdition?.numero ?? 0,
            usuarioNombre: formEdition?.usuarioNombre ?? '',
            usuarioCodigo: formEdition?.usuarioCodigo ?? 0,
            cuadreTotal: formEdition?.cuadreTotal ?? 0,
          },
          detalle: resCargaCuadre,
          totales: {
            facturas: cantidades?.facturas ?? 0,
            notasCredito: cantidades?.notasCredito ?? 0,
            retenciones: cantidades?.retenciones ?? 0,
            anticiposClientes: cantidades?.anticipos ?? 0,
            anticiposProveedores: cantidades?.anticiposp ?? 0,
            cobrosCartera: cantidades?.cobrosCartera ?? 0,
            compras: cantidades?.compras ?? 0,
            pagosTesoreria: cantidades?.pagosTesoreria ?? 0,
            cheques: cantidades?.cheques ?? 0,
            credito: cantidades?.credito ?? 0,
            letrasCambio: cantidades?.letrasCambio ?? 0,
            totalFacturasAnuladas: cantidades?.totalFacturasAnuladas ?? 0,
            descuadre: formEdition?.descuadre_ti ?? 0,
          },
          resumen: resumen,
        }

        if (
          tipoImpresion === PLANTILLA_IMPRIMIR.DENOMINACION_TICKET ||
          tipoImpresion === PLANTILLA_IMPRIMIR.DENOMINACION_STARDAR
        ) {
          playLoader()
          resCargaCuadre = []
          console.log('resCargaCuadre 1', resCargaCuadre)

          dptotalresumen.map(function (item) {
            console.log('resCargaCuadre item', item)
            resCargaCuadre.push({
              formaPago: item?.valor ?? '',
              total: item?.total ?? 0,
            })
          })
          console.log('resCargaCuadre', resCargaCuadre)
          dataPrint['detalle'] = resCargaCuadre
          stopLoader()
        } else if (
          tipoImpresion === PLANTILLA_IMPRIMIR.COBORSCARTERA_TICKETERA ||
          tipoImpresion === PLANTILLA_IMPRIMIR.COBORSCARTERA_STARDAR
        ) {
          playLoader()
          const findDetall: any = {
            documento: 'Cobros de cartera',
            ccaCod1: formEdition.ccaCod1,
            ccaCod2: formEdition.ccaCod2,
            ccaCod3: formEdition.ccaCod3,
            ccaCod7: formEdition.ccaCod7,
            ccaCod8: formEdition.ccaCod8,
            ccaCod9: formEdition.ccaCod9,
            ccaCod10: formEdition.ccaCod10,
            ccaCod11: formEdition.ccaCod11,
            borigen: formEdition.puntoVenta?.codigoLocal,
            fechaci: fechaInicial,
            fechacf: formEdition.fechaCierre,
          }
          resCargaCuadre = await CuadresService.getCuadreCajaDetalleByCode(
            findDetall,
            currentTabCuadreIndex,
          )
          dataPrint['detalle'] = resCargaCuadre

          stopLoader()
        }
        console.log('dataPrint', dataPrint)

        setDatosExportar(dataPrint)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
        stopLoader()
      } finally {
        stopLoader()
      }
    },
    [
      formEdition,
      playLoader,
      stopLoader,
      currentTabCuadreIndex,
      onTotalizaCantidadComprobantes,
      dptotalresumen,
    ],
  )

  const printTicketera = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
      setPlantillaNombre(tipoImpresion)
      await onParseInfoReporte(tipoImpresion)
      setReporte(design === true ? 'Designer' : 'Viewer')
    },
    [onParseInfoReporte],
  )

  const printComun = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
      setPlantillaNombre(tipoImpresion)
      await onParseInfoReporte(tipoImpresion)
      setReporte(design === true ? 'Designer' : 'Viewer')
    },
    [onParseInfoReporte],
  )

  const printCuadreCajaTotales = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
      setPlantillaNombre(tipoImpresion)
      const open = await onParseInfoReporteTotales(tipoImpresion)
      if (open) setReporte(design === true ? 'Designer' : 'Viewer')
    },
    [onParseInfoReporteTotales],
  )

  const printCuadreCajaDetalles = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
      setPlantillaNombre(tipoImpresion)
      const open = await onParseInfoReporteDetalle(tipoImpresion)
      if (open) setReporte(design === true ? 'Designer' : 'Viewer')
    },
    [onParseInfoReporteDetalle],
  )

  const printCuadreCajaResumen = React.useCallback(
    async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
      setPlantillaNombre(tipoImpresion)
      const open = await onParseInfoReporteResumen(tipoImpresion)
      if (open) setReporte(design === true ? 'Designer' : 'Viewer')
    },
    [onParseInfoReporteResumen],
  )

  const onConfirmarPrint = React.useCallback(
    (sms, design: boolean) => {
      let action: any = null
      if (
        currentTabCuadreIndex === TabCuadres.Denominacion ||
        currentTabCuadreIndex === TabCuadres.Facturas
      ) {
        action = [
          Dialog.Action(
            <span>
              <u>{'S'}</u>
              {'i'}
            </span>,
            (dialog) => {
              dialog.hide()
              printTicketera(PLANTILLA_IMPRIMIR.DENOMINACION_TICKET, design)
            },
            'btn-success',
            'n',
          ),
          Dialog.Action(
            <span>
              <u>{'N'}</u>
              {'o'}
            </span>,
            (dialog) => {
              dialog.hide()
              printComun(PLANTILLA_IMPRIMIR.DENOMINACION_STARDAR, design)
            },
            'btn-danger',
            'c',
          ),
        ]
      } else if (currentTabCuadreIndex === TabCuadres.CobrosCartera) {
        // tab 5
        action = [
          Dialog.Action(
            <span>
              <u>{'S'}</u>
              {'i'}
            </span>,
            (dialog) => {
              dialog.hide()
              printTicketera(PLANTILLA_IMPRIMIR.COBORSCARTERA_TICKETERA, design)
            },
            'btn-success',
            'n',
          ),
          Dialog.Action(
            <span>
              <u>{'N'}</u>
              {'o'}
            </span>,
            (dialog) => {
              dialog.hide()
              printComun(PLANTILLA_IMPRIMIR.COBORSCARTERA_STARDAR, design)
            },
            'btn-danger',
            'c',
          ),
        ]
      } else if (currentTabCuadreIndex === TabCuadres.Totales) {
        // tab 8

        action = [
          Dialog.Action(
            <span>
              <u>{'T'}</u>
              {'otal'}
            </span>,
            (dialog) => {
              dialog.hide() // cuadrecajaDetalle
              printCuadreCajaTotales(PLANTILLA_IMPRIMIR.TOTALES_TOTALES, design)
            },
            'btn-success',
            'n',
          ),
          Dialog.Action(
            <span>
              <u>{'R'}</u>
              {'esumen'}
            </span>,
            (dialog) => {
              dialog.hide() // cuadrecaja
              printCuadreCajaResumen(PLANTILLA_IMPRIMIR.TOTALES_RESUMEN, design)
            },
            'btn-danger',
            'c',
          ),
          Dialog.Action(
            <span>
              <u>{'D'}</u>
              {'etalle'}
            </span>,
            (dialog) => {
              dialog.hide() // cuadrecajaDetalleCorpo
              printCuadreCajaDetalles(
                PLANTILLA_IMPRIMIR.TOTALES_DETALLE,
                design,
              )
            },
            'btn-primary',
            'c',
          ),
        ]
      } else if (currentTabCuadreIndex === TabCuadres.Letras) {
        // tab 10
      }
      dialogRefCuadre.current.show({
        title: (
          <>
            <strong>{'Ácatha'}</strong>
          </>
        ),
        body: (
          <label style={{ fontSize: '14px', fontWeight: 600 }}>{sms}</label>
        ),
        actions: action,
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
          dispatch(clearButtonClick(tabId))
          setReporte(null)
          Dialog.resetOptions()
        },
      })
      return
    },
    [
      printComun,
      printTicketera,
      currentTabCuadreIndex,
      tabId,
      dispatch,
      printCuadreCajaDetalles,
      printCuadreCajaResumen,
      printCuadreCajaTotales,
    ],
  )

  const onLoadedTabs = React.useCallback(
    (loaded: any) => {
      const loadedT = {
        loadedTabDenominacion: loaded['loadedTabDenominacion'],
        loadedTabFacturas: loaded['loadedTabFacturas'],
        loadedTabAnticiposClientes: loaded['loadedTabAnticiposClientes'],
        loadedTabCobrosCartera: loaded['loadedTabCobrosCartera'],
        loadedTabRetenciones: loaded['loadedTabRetenciones'],
        loadedTabNotasCredito: loaded['loadedTabNotasCredito'],
        loadedTabCompras: loaded['loadedTabCompras'],
        loadedTabPagosTesoreria: loaded['loadedTabPagosTesoreria'],
        loadedTabAnticiposProveedores: loaded['loadedTabAnticiposProveedores'],
        loadedTabTotales: loaded['loadedTabTotales'],
        loadedTabCheques: loaded['loadedTabCheques'],
        loadedTabLetras: loaded['loadedTabLetras'],
      }
      let cont: number = 0
      Object.values(loadedT).forEach(function (det) {
        if (det === true) {
          cont = cont + 1
        }
      })

      if (cont === 12) {
        dispatch(
          setDatosEdicion({
            data: {
              ...datosEdicion,
              loader: {
                show: false,
                mensaje: '',
              },
            },
            key: tabId,
          }),
        )
        dispatch(
          updateGenerarCuadreOnly({
            generarCuadre: false,
            key: tabId,
          }),
        )
      }
    },
    [datosEdicion, tabId, dispatch],
  )

  const putTabLoaded = React.useCallback(
    async (key: string, value: boolean) => {
      switch (key) {
        case 'denominacion':
          setLoadedTabDenominacion(value)
          break
        case 'facturas':
          setLoadedTabFacturas(value)
          break
        case 'anticiposClientes':
          setLoadedTabAnticiposClientes(value)
          break
        case 'cobrosCartera':
          setLoadedTabCobrosCartera(value)
          break
        case 'retenciones':
          setLoadedTabRetenciones(value)
          break
        case 'notasCredito':
          setLoadedTabNotasCredito(value)
          break
        case 'compras':
          setLoadedTabCompras(value)
          break
        case 'pagosTesoreria':
          setLoadedTabPagosTesoreria(value)
          break
        case 'anticiposProveedores':
          setLoadedTabAnticiposProveedores(value)
          break
        case 'totales':
          setLoadedTabTotales(value)
          break
        case 'cheques':
          setLoadedTabCheques(value)
          break
        case 'letras':
          setLoadedTabLetras(value)
          break

        default:
          break
      }
    },
    [],
  )

  const handlePrint = React.useCallback(
    (design: boolean) => {
      Dialog.resetOptions()

      if (
        currentTabCuadreIndex === TabCuadres.Denominacion ||
        currentTabCuadreIndex === TabCuadres.Facturas
      ) {
        onConfirmarPrint('Desea imprimir en la ticketera?', design)
      } else if (currentTabCuadreIndex === TabCuadres.CobrosCartera) {
        // tab 5
        onConfirmarPrint('Desea imprimir en la ticketera principal?', design)
      } else if (currentTabCuadreIndex === TabCuadres.Totales) {
        // tab 8
        onConfirmarPrint('Desea imprimir?', design)
      } else if (currentTabCuadreIndex === TabCuadres.Letras) {
        // tab 10
        onConfirmarPrint('Desea imprimir en la ticketera?', design)
      }
    },
    [onConfirmarPrint, currentTabCuadreIndex],
  )

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    Dialog.resetOptions()
    dialogRefCuadre.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px' }}>{tittle}</strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const onConfirmarRegistro = React.useCallback((sms, method) => {
    dialogRefCuadre.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: <label style={{ fontSize: '14px', fontWeight: 600 }}>{sms}</label>,
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            method()
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const onFindListadoComprobantes = React.useCallback(
    async (puntoVenta: Puntoventa) => {
      const cuotasCount =
        await listadoComprobantesService.verificarSinCuotasPunto(
          'd',
          puntoVenta?.codigoPuntoVenta ?? -1,
        )
      if (cuotasCount.auto && cuotasCount.auto.length > 0) {
        setCuotas(cuotasCount?.auto ?? [])
      } else {
        setCuotas([])
      }
      const chequesCount =
        await listadoComprobantesService.verificarSinChequesPunto(
          'd',
          puntoVenta?.codigoPuntoVenta ?? -1,
        )
      if (chequesCount.auto && chequesCount.auto.length > 0) {
        setCheques(chequesCount?.auto ?? [])
      } else {
        setCheques([])
      }
      const tarjetasCount =
        await listadoComprobantesService.verificarSinTarjetasPunto(
          'd',
          puntoVenta?.codigoPuntoVenta ?? -1,
        )
      if (tarjetasCount.auto && tarjetasCount.auto.length > 0) {
        setTarjetas(tarjetasCount?.auto ?? [])
      } else {
        setTarjetas([])
      }
    },
    [],
  )

  const onMarcaLocal = React.useCallback(
    async (newValue: Puntoventa) => {
      const filtrado = locales.findIndex((item: any) => {
        return Number(item.codigo) === Number(newValue.codigoLocal)
      })
      if (filtrado > -1) {
        dispatch(
          updateLocalOnly({
            local: locales[filtrado],
            key: tabId,
          }),
        )
      }
      await onFindListadoComprobantes(newValue)
    },
    [tabId, dispatch, locales, onFindListadoComprobantes],
  )

  const onLocalChanged = React.useCallback(
    (data: LocalOption) => {
      if (data !== null || data !== undefined) {
        dispatch(
          updateLocalOnly({
            local: data,
            key: tabId,
          }),
        )
      }
    },
    [tabId, dispatch],
  )

  const onPuntoVentaChanged = React.useCallback(
    (data: Puntoventa) => {
      if (data !== null || data !== undefined) {
        if (data !== null) {
          dispatch(
            updatePuntoVentaOnly({
              puntoventa: data,
              key: tabId,
            }),
          )
          onMarcaLocal(data)
        } else {
          dispatch(
            updatePuntoVentaOnly({
              puntoventa: puntosVenta[0],
              key: tabId,
            }),
          )
          onMarcaLocal(puntosVenta[0])
        }
        setCurrentTabCuadreIndex(TabCuadres.Denominacion)
      }
    },
    [tabId, dispatch, onMarcaLocal, puntosVenta],
  )

  const onChangeDateCierre = React.useCallback(
    (data) => {
      if (data?.event !== undefined) {
        dispatch(
          updateFechaCierreOnly({
            fechaCierre: data.value,
            key: tabId,
          }),
        )
      }
    },
    [tabId, dispatch],
  )

  const onChangeHora = React.useCallback(
    (data) => {
      if (data?.event !== undefined) {
        dispatch(
          updateHoraCierreOnly({
            horaCierre: data.value,
            key: tabId,
          }),
        )
      }
    },
    [tabId, dispatch],
  )

  const onChangeMinutos = React.useCallback(
    (data) => {
      if (data?.event !== undefined) {
        dispatch(
          updateMinutoCierreOnly({
            minutoCierre: data.value,
            key: tabId,
          }),
        )
      }
    },
    [tabId, dispatch],
  )

  const cargarDenominacion = React.useCallback(
    async (denominacionApi: Array<DenominacionListado> | []) => {
      const denominacion: Array<any> = []

      denominacionApi.forEach(function (det: any) {
        if (det.grupo === 0) {
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'CIEN'
          ) {
            denominacion.push({
              grupo: 0,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              billeteValor: 100,
              cantidad: Number(det.valor) / 100,
              tipo: 'billete',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'CINCUENTA'
          ) {
            denominacion.push({
              grupo: 0,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              billeteValor: 50,
              cantidad: Number(det.valor) / 50,
              tipo: 'billete',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'VEINTE'
          ) {
            denominacion.push({
              grupo: 0,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              billeteValor: 20,
              cantidad: Number(det.valor) / 20,
              tipo: 'billete',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'DIEZ'
          ) {
            denominacion.push({
              grupo: 0,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              billeteValor: 10,
              cantidad: Number(det.valor) / 10,
              tipo: 'billete',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'CINCO'
          ) {
            denominacion.push({
              grupo: 0,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              billeteValor: 5,
              cantidad: Number(det.valor) / 5,
              tipo: 'billete',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'UNO'
          ) {
            denominacion.push({
              grupo: 0,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              billeteValor: 1,
              cantidad: Number(det.valor) / 1,
              tipo: 'billete',
            })
          }
        }
      })

      denominacionApi.forEach(function (det: any) {
        if (det.grupo === 1) {
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'UND'
          ) {
            denominacion.push({
              grupo: 1,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              monedaValor: 1,
              cantidad: Number(det.valor) / 1,
              tipo: 'moneda',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'CINCUENTA'
          ) {
            denominacion.push({
              grupo: 1,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              monedaValor: 0.5,
              cantidad: Number(det.valor) / 0.5,
              tipo: 'moneda',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'VEINTECINCO'
          ) {
            denominacion.push({
              grupo: 1,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              monedaValor: 0.25,
              cantidad: Number(det.valor) / 0.25,
              tipo: 'moneda',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'DIEZ'
          ) {
            denominacion.push({
              grupo: 1,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              monedaValor: 0.1,
              cantidad: Number(det.valor) / 0.1,
              tipo: 'moneda',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'CINCO'
          ) {
            denominacion.push({
              grupo: 1,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              monedaValor: 0.05,
              cantidad: Number(det.valor) / 0.05,
              tipo: 'moneda',
            })
          }
          if (
            det.descripcion &&
            String(det.descripcion).toUpperCase() === 'UNC'
          ) {
            denominacion.push({
              grupo: 1,
              descripcion: det.descripcion,
              total: Number(det.valor),
              valor: Number(det.valor),
              monedaValor: 0.01,
              cantidad: Number(det.valor) / 0.01,
              tipo: 'moneda',
            })
          }
        }
      })
      return await denominacion
    },
    [],
  )

  const cargarCuadre = React.useCallback(
    async (cuadre: any, setInitData: boolean) => {
      const datacuadre: CuadresCajaListado = cuadre.info

      const data = { ...defaultDatosEdicionCuadreCaja }
      try {
        if (setInitData)
          dispatch(
            setDatosEdicion({
              data: { ...data },
              key: tabId,
            }),
          )
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.find,
          }),
        )
        const findPuntosVenta = {
          mensaje: 'Todos',
        }
        const puntosVentaApi = await CuadresService.getPuntosVentas(
          findPuntosVenta,
        )

        const getCuadre = await CuadresService.getCuadreCajaByCode(
          datacuadre.cuadreCodigo,
        )

        if (getCuadre) {
          const parseCuadre: CuadresCajaListado = await parseInfoCuadre(
            getCuadre,
          )

          data.puntosVenta = puntosVentaApi
          if (puntosVentaApi) {
            setPuntosVenta(puntosVentaApi)
            if (parseCuadre.puntoVentaCodigo) {
              const puntoVenta = puntosVentaApi.find(
                (x) =>
                  Number(x.codigoPuntoVenta) ===
                  Number(parseCuadre.puntoVentaCodigo),
              )
              if (puntoVenta) {
                data.puntoVenta = puntoVenta
              }
            } else {
              data.puntoVenta = puntosVentaApi[0]
            }
          }

          const findLocales = {
            mensaje: 'Elija una opción',
          }
          const locales = await CuadresService.getLocales(findLocales)
          if (locales) {
            setLocales(locales)
            if (parseCuadre.localCodigo) {
              const localFind = locales.find(
                (x) => x.codigo === parseCuadre.localCodigo,
              )
              if (localFind) {
                data.local = localFind
              }
            } else {
              data.local = locales[0]
            }
          }

          const formasPg = await CuadresService.getComboTipoPago()
          if (formasPg.length > 0) {
            const formas: Array<formasPago> = []
            for (const fpg of formasPg) {
              if (fpg.codigo !== EFormasPago.otros) {
                formas.push({
                  codigo: Number(fpg.codigo) ?? -1,
                  descripcion: String(fpg.descripcion) ?? '',
                  grupo: String(fpg.grupo) ?? '',
                })
              }
            }
            data.dpfpagos = formas
          }

          dispatch(
            updateDpFormasPagoOnly({
              formasPago: data.dpfpagos ?? [],
              key: tabId,
            }),
          )

          const findVendedores = {
            mensaje: 'Todos',
          }
          const vendedoresOptions = await CuadresService.getVendedores(
            findVendedores,
          )
          if (vendedoresOptions) {
            data.dpvendedores = vendedoresOptions
            data.vendedor = vendedoresOptions[0]
          }

          let hora: number = 0
          let min: number = 0
          let sec: string = '00'

          const descHoraCierre = parseCuadre.cuadreFechaHoraCierre.split(':')
          if (descHoraCierre.length) {
            hora = Number(descHoraCierre[0])
            min = Number(descHoraCierre[1])
            sec = String(descHoraCierre[2])
          }

          data.ccaCodigo = Number(parseCuadre?.cuadreCodigo) ?? 0
          data.fechaCierre =
            DateUtils.apiDateToPickersDate(parseCuadre?.cuadreFechaCierre) ?? '' // String() ?? "";
          data.horaCierre = hora
          data.minutoCierre = min
          data.segundosCierre = sec

          data.usuarioCodigo = Number(parseCuadre?.usuarioCodigo) ?? 0
          data.usuarioNombre = String(parseCuadre?.usuarioNombre) ?? ''

          data.numero = Number(parseCuadre?.cuadreNumero) ?? 0
          data.denominacion = await cargarDenominacion(
            parseCuadre?.cuadreDenominacion ?? [],
          )
        }
        const getAllCuadresView = await CuadresService.getAllCuadresView(
          datacuadre.cuadreFechaCierre + ' ' + datacuadre.cuadreFechaHoraCierre,
          datacuadre.localCodigo,
          datacuadre.puntoVentaCodigo,
        )
        if (getAllCuadresView) {
          const parseCuadre = await parseInfoAllCuadres(data, getAllCuadresView)

          data.venInicial1 = parseCuadre.venInicial1
          data.venFinal1 = parseCuadre.venFinal1
          data.ccaCod1 = parseCuadre.ccaCod1
          data.dpcierre1 =
            (await getDatosFacturas(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial1,
              data.venFinal1,
            )) ?? []
          data.loaddpcierre1 = true

          data.venInicial2 = parseCuadre.venInicial2
          data.venFinal2 = parseCuadre.venFinal2
          data.ccaCod2 = parseCuadre.ccaCod2
          data.dpcierre2 =
            (await getCierreCajaNCHelper(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial2,
              data.venFinal2,
            )) ?? []
          data.loaddpcierre2 = true

          data.venInicial3 = parseCuadre.venInicial3
          data.venFinal3 = parseCuadre.venFinal3
          data.ccaCod3 = parseCuadre.ccaCod3
          data.dpcierre3 =
            (await getCierreCajaRet(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial3,
              data.venFinal3,
            )) ?? []
          data.loaddpcierre3 = true

          data.venInicial4 = parseCuadre.venInicial4
          data.venFinal4 = parseCuadre.venFinal4
          data.ccaCod4 = parseCuadre.ccaCod4
          data.dpcierre4 =
            (await getChequesCuadresHelper(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial4,
              data.venFinal4,
            )) ?? []
          data.loaddpcierre4 = true

          data.venInicial5 = parseCuadre.venInicial5
          data.venFinal5 = parseCuadre.venFinal5
          data.ccaCod5 = parseCuadre.ccaCod5
          data.dpcierre5 =
            (await getLetrasCuadresHelper(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial5,
              data.venFinal5,
            )) ?? []
          data.loaddpcierre5 = true

          data.venInicial6 = parseCuadre.venInicial6
          data.venFinal6 = parseCuadre.venFinal6
          data.ccaCod6 = parseCuadre.ccaCod6

          data.venInicial7 = parseCuadre.venInicial7
          data.venFinal7 = parseCuadre.venFinal7
          data.ccaCod7 = parseCuadre.ccaCod7
          data.dpcierre7 =
            (await getCierreCuadreActicipos(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial7,
              data.venFinal7,
            )) ?? []
          data.loaddpcierre7 = true

          data.venInicial8 = parseCuadre.venInicial8
          data.venFinal8 = parseCuadre.venFinal8
          data.ccaCod8 = parseCuadre.ccaCod8
          data.dpcierre8 =
            (await getAbonosCuadres(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial8,
              data.venFinal8,
            )) ?? []
          data.loaddpcierre8 = true

          data.venInicial9 = parseCuadre.venInicial9
          data.venFinal9 = parseCuadre.venFinal9
          data.ccaCod9 = parseCuadre.ccaCod9
          data.dpcierre9 =
            (await getAbonosComprasCHelper(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial9,
              data.venFinal9,
            )) ?? []
          data.loaddpcierre9 = true

          data.venInicial10 = parseCuadre.venInicial10
          data.venFinal10 = parseCuadre.venFinal10
          data.ccaCod10 = parseCuadre.ccaCod10
          data.dpcierre10 =
            (await getPagosCuadresHelper(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial10,
              data.venFinal10,
            )) ?? []
          data.loaddpcierre10 = true

          data.venInicial11 = parseCuadre.venInicial11
          data.venFinal11 = parseCuadre.venFinal11
          data.ccaCod11 = parseCuadre.ccaCod11
          data.dpcierre11 =
            (await getCierreCuadreActiciposProveedoresHelper(
              data.fechaCierre,
              data.local,
              data.puntoVenta,
              data.venInicial11,
              data.venFinal11,
            )) ?? []
          data.loaddpcierre11 = true
        }

        data.loader = {
          mensaje: '',
          show: false,
        }
        data.loading = false
        console.log('cargarCuadre data: ', data)

        dispatch(
          setDatosEdicion({
            data: data,
            key: tabId,
          }),
        )

        dispatch(
          seleccionarCuadre({
            cuadreCodigo: getCuadre?.ccaCodigo,
            cuadreFechaCierre: getCuadre?.cuadreFechaCierre,
            cuadreFechaHoraCierre: getCuadre?.cuadreFechaHoraCierre,
            localCodigo: getCuadre?.localCodigo,
            puntoVentaCodigo: getCuadre?.puntoCodigo,
          }),
        )

        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...CuadresCajaButtons,
              Eliminar: true,
              Guardar: false,
              Deshacer: false,
              Descuento: false,
            },
          }),
        )

        dispatch(
          updateGenerarCuadreOnly({
            generarCuadre: true,
            key: tabId,
          }),
        )

        //   }
      } catch (error) {
        console.error(error)
        data.tieneError = true
        data.mensajeError = JSON.stringify(error)
        data.loading = false
        dispatch(
          setDatosEdicion({
            data: data,
            key: tabId,
          }),
        )
      }
    },
    [dispatch, tabId, cargarDenominacion, parseInfoCuadre, parseInfoAllCuadres],
  )

  const modoNuevo = React.useCallback(
    async (puntoVenta: string, template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      const data = { ...defaultDatosEdicionCuadreCaja }
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...CuadresCajaButtons,
            Editar: false,
            Imprimir: false,
            Exportar: false,
            Autorizar: false,
            Enviar: false,
            Guardar: true,
          },
        }),
      )
      try {
        const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers) //  moment().format('yyyy-MM-DD');

        if (template > 0) {
          // const venta = await VentasService.getVenta(template); //VentasService.getVentas(0, "", "", "", "", "", "", undefined, clave_acceso, undefined, undefined, undefined, TiposComprobantesSri.Factura);
          // if (venta) {
          //   const detalles = await VentasService.getDetalleVenta(template, TiposComprobantesSri.Factura);
          //   const cliente = await ClientesService.getCliente(venta?.clienteIdentificacion);
          //   const tmpDet2 = VentaHelper.detallesToDetallesVenta(detalles);
          //   data.detalles = tmpDet2;
          //   data.cliente = cliente;
          //   data.observaciones = venta.observaciones;
          //   const formasPago = await VentasService.getFormasPago(cliente.identificacion);
          //   data.formasPago = formasPago;
          //   const fp = formasPago.find(fp => fp.codigo === venta.formaPago);
          //   if (fp) {
          //     data.formaPago = [fp];
          //   }
          //   data.desdePlantilla = true;
          // }
        } else {
          const findPuntosVenta = {
            mensaje: 'Todos',
          }
          const puntosVenta = await CuadresService.getPuntosVentas(
            findPuntosVenta,
          )

          data.puntosVenta = puntosVenta
          if (puntosVenta) {
            data.puntoVenta = puntosVenta[0]
            setPuntosVenta(puntosVenta)
          }

          await onFindListadoComprobantes(data?.puntoVenta)

          const findLocales = {
            mensaje: 'Elija una opción',
          }
          const locales = await CuadresService.getLocales(findLocales)
          if (locales) {
            data.local = locales[0]
            setLocales(locales)
          }

          const formasPg = await CuadresService.getComboTipoPago()
          if (formasPg.length > 0) {
            const formas: Array<formasPago> = []
            for (const fpg of formasPg) {
              if (fpg.codigo !== EFormasPago.otros) {
                formas.push({
                  codigo: Number(fpg.codigo) ?? -1,
                  descripcion: String(fpg.descripcion) ?? '',
                  grupo: String(fpg.grupo) ?? '',
                })
              }
            }
            data.dpfpagos = formas
          }

          const findVendedores = {
            mensaje: 'Todos',
          }
          const vendedoresOptions = await CuadresService.getVendedores(
            findVendedores,
          )
          if (vendedoresOptions) {
            data.dpvendedores = vendedoresOptions
            data.vendedor = vendedoresOptions[0]
          }
        }
        const fechaActual = new Date()
        const hours = fechaActual.getHours()
        const mins = fechaActual.getMinutes()
        data.numero = 0
        data.fechaCierre = fecha
        data.horaCierre = hours
        data.minutoCierre = mins
        data.segundosCierre = '00'
        data.identificadorTransaccion = uuidv4()
        data.loading = false
        data.loader = {
          mensaje: '',
          show: false,
        }
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError =
          typeof error == 'string' ? error : JSON.stringify(error)
        data.loader = {
          mensaje: '',
          show: false,
        }
        setToast(data.mensajeError, 'danger')
      }
      dispatch(
        setDatosEdicion({
          data: data,
          key: tabId,
        }),
      )
    },
    [loading, tabId, dispatch, setToast, onFindListadoComprobantes],
  )

  const validarFormulario = React.useCallback(async () => {
    const errors: any = []
    if (denominacion.length === 0) {
      errors.push('No existe registro de efectivo.')
    }
    if (dptotalresumen.length === 0) {
      errors.push('No existe información a registrar.')
    }
    if (
      (puntoVenta && puntoVenta.codigoPuntoVenta < 0) ||
      (local && local.codigo < 0)
    ) {
      errors.push('Debe elegir un local o punto de venta.')
    }
    if (fechaCierre === null || fechaCierre === '') {
      errors.push('Ingrese la fecha del cierre.')
    }
    if (minutoCierre === null || horaCierre === null) {
      errors.push('Ingrese la hora y minuto(s) del cuadre.')
    }
    let fecha1: string = ''

    if (minutoCierre === 0 || horaCierre === 0) {
      fecha1 = fechaCierre + ' 00:00:00'
    } else {
      fecha1 = fechaCierre + ' ' + minutoCierre + ':' + minutoCierre + ':00'
    }
    if (fecha1 === fecha) {
      errors.push('Ya existe un Cuadre con la misma fecha y hora.\n')
    }
    if (errors.length > 0) {
      return {
        errors: true,
        messages: errors,
      }
    } else {
      return {
        errors: false,
        messages: errors,
      }
    }
  }, [
    puntoVenta,
    local,
    denominacion,
    minutoCierre,
    horaCierre,
    fechaCierre,
    fecha,
    dptotalresumen,
  ])

  const guardar = React.useCallback(async () => {
    if (usuario == null || empresa == null) {
      return
    }
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined,
      }),
    )
    dispatch(
      buttonClick({
        tabKey: tabId,
        button: ButtonTypes.none,
      }),
    )
    const res = await validarFormulario()
    if (res.errors === true) {
      onErrorConfirm(res.messages, 'Antes de continuar revise lo siguiente:')
      return
    }
    try {
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: true,
              mensaje: 'Guardando Cuadre de Caja...',
            },
          },
          key: tabId,
        }),
      )

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.save,
        }),
      )

      let denominacion_: any = {}
      const objetoBilletes: Array<any> = []
      const objetoMonedas: Array<any> = []
      const objetoKeysDenominacion = denominacion

      for (const key in objetoKeysDenominacion) {
        if (objetoKeysDenominacion[key]['grupo'] === 1) {
          const name = objetoKeysDenominacion[key]['descripcion']
          const valor = objetoKeysDenominacion[key]['valor']
          objetoBilletes.push({
            [name]: valor,
            grupo: objetoKeysDenominacion[key].grupo,
          })
        } else if (objetoKeysDenominacion[key]['grupo'] === 2) {
          const name = objetoKeysDenominacion[key]['descripcion']
          const valor = objetoKeysDenominacion[key]['valor']
          objetoMonedas.push({
            [name]: valor,
            grupo: objetoKeysDenominacion[key].grupo,
          })
        }
      }
      denominacion_ = {
        0: objetoBilletes,
        1: objetoMonedas,
      }
      const detalle1_: Array<DetalleCuadreRegistro> = []
      const detalle2_: Array<DetalleCuadreRegistro> = []
      const detalle3_: Array<DetalleCuadreRegistro> = []
      const detalle7_: Array<DetalleCuadreRegistro> = []
      const detalle8_: Array<DetalleCuadreRegistro> = []
      const detalle9_: Array<DetalleCuadreRegistro> = []
      const detalle10_: Array<DetalleCuadreRegistro> = []
      const detalle11_: Array<DetalleCuadreRegistro> = []

      // CCD_VALOR, SUB_CODIGO, SUB_VALOR
      dptotales1.forEach(function (det: formasPagoCuadre) {
        detalle1_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString(),
        })
      })

      dptotales2.forEach(function (det: formasPagoCuadre) {
        detalle2_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString(),
        })
      })

      dptotales3.forEach(function (det: formasPagoCuadre) {
        detalle3_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString(),
        })
      })

      dptotales7.forEach(function (det: formasPagoCuadre) {
        detalle7_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString(),
        })
      })

      dptotales8.forEach(function (det: formasPagoCuadre) {
        detalle8_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString(),
        })
      })

      dptotales9.forEach(function (det: formasPagoCuadre) {
        detalle9_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString(),
        })
      })

      dptotales10.forEach(function (det: formasPagoCuadre) {
        detalle10_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString(),
        })
      })

      dptotales11.forEach(function (det: formasPagoCuadre) {
        detalle11_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString(),
        })
      })

      const cuadreCaja: TCuadreCajaIngresar = {
        cuadreCajaFechaCierre: DateUtils.format(fechaCierre, formatoFechasApi),
        localCodigo: local?.codigo ?? -1,
        puntoVentaCodigo: puntoVenta?.codigoPuntoVenta ?? -1,
        usuario: usuario.codigo,
        hora: horaCierre.toString().padStart(2, '0') ?? '00',
        minuto: minutoCierre.toString().padStart(2, '0') ?? '00',
        denominacion: denominacion_,
        1: {
          cuadreCajaCodigo: ccaCod1,
          ventaFinal: venFinal1,
          ventaInicial: venInicial1,
          tipoCuadreCajaCodigo: 1,
        },
        2: {
          cuadreCajaCodigo: ccaCod2,
          ventaFinal: venFinal2,
          ventaInicial: venInicial2,
          tipoCuadreCajaCodigo: 2,
        },
        3: {
          cuadreCajaCodigo: ccaCod3,
          ventaFinal: venFinal3,
          ventaInicial: venInicial3,
          tipoCuadreCajaCodigo: 3,
        },
        4: {
          cuadreCajaCodigo: ccaCod4,
          ventaFinal: venFinal4,
          ventaInicial: venInicial4,
          tipoCuadreCajaCodigo: 4,
        },
        5: {
          cuadreCajaCodigo: ccaCod5,
          ventaFinal: venFinal5,
          ventaInicial: venInicial5,
          tipoCuadreCajaCodigo: 5,
        },
        6: {
          cuadreCajaCodigo: ccaCod6,
          ventaFinal: venFinal6,
          ventaInicial: venInicial6,
          tipoCuadreCajaCodigo: 6,
        },
        7: {
          cuadreCajaCodigo: ccaCod7,
          ventaFinal: venFinal7,
          ventaInicial: venInicial7,
          tipoCuadreCajaCodigo: 7,
        },
        8: {
          cuadreCajaCodigo: ccaCod8,
          ventaFinal: venFinal8,
          ventaInicial: venInicial8,
          tipoCuadreCajaCodigo: 8,
        },
        9: {
          cuadreCajaCodigo: ccaCod9,
          ventaFinal: venFinal9,
          ventaInicial: venInicial9,
          tipoCuadreCajaCodigo: 9,
        },
        10: {
          cuadreCajaCodigo: ccaCod10,
          ventaFinal: venFinal10,
          ventaInicial: venInicial10,
          tipoCuadreCajaCodigo: 10,
        },
        11: {
          cuadreCajaCodigo: ccaCod11,
          ventaFinal: venFinal11,
          ventaInicial: venInicial11,
          tipoCuadreCajaCodigo: 11,
        },
        detalle1: detalle1_,
        detalle2: detalle2_,
        detalle3: detalle3_,
        detalle7: detalle7_,
        detalle8: detalle8_,
        detalle9: detalle9_,
        detalle10: detalle10_,
        detalle11: detalle11_,
      }
      const resultado = await CuadresService.ingresarCuadre(cuadreCaja)
      const resCargaCuadre = await CuadresService.getCuadreCajaByCode(
        resultado?.['1'],
      )
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            ccaCod1: Number(resultado?.['1']) ?? 0,
            ccaCod2: Number(resultado?.['2']) ?? 0,
            ccaCod3: Number(resultado?.['3']) ?? 0,
            ccaCod4: Number(resultado?.['4']) ?? 0,
            ccaCod5: Number(resultado?.['5']) ?? 0,
            ccaCod6: Number(resultado?.['6']) ?? 0,
            ccaCod7: Number(resultado?.['7']) ?? 0,
            ccaCod8: Number(resultado?.['8']) ?? 0,
            ccaCod9: Number(resultado?.['9']) ?? 0,
            ccaCod10: Number(resultado?.['10']) ?? 0,
            ccaCod11: Number(resultado?.['11']) ?? 0,
            fecha: fechaCierre,
            loader: {
              show: false,
              mensaje: '',
            },
            imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false,
          },
          key: tabId,
        }),
      )

      const parseCuadre: CuadresCajaListado = await parseInfoCuadre(
        resCargaCuadre,
      )

      dispatch(
        changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          info: parseCuadre,
          buttons: {
            ...CuadresCajaButtons,
            Guardar: false,
            Editar: true,
            Deshacer: false,
            Imprimir: true,
          },
        }),
      )
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Registro Satisfactorio.',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Success,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    } catch (error) {
      console.error(error)
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al guardar el cuadre de caja ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      return
    }
  }, [
    empresa,
    local,
    onErrorConfirm,
    puntoVenta,
    usuario,
    validarFormulario,
    denominacion,
    datosEdicion,
    dispatch,
    tabId,
    dptotales1,
    dptotales2,
    dptotales3,
    dptotales7,
    dptotales8,
    dptotales9,
    dptotales10,
    dptotales11,
    ccaCod1,
    ccaCod10,
    ccaCod11,
    ccaCod2,
    ccaCod3,
    ccaCod4,
    ccaCod5,
    ccaCod6,
    ccaCod7,
    ccaCod8,
    ccaCod9,
    fechaCierre,
    venFinal1,
    venFinal10,
    venFinal11,
    venFinal2,
    venFinal3,
    venFinal4,
    venFinal5,
    venFinal6,
    venFinal7,
    venFinal8,
    venFinal9,
    venInicial1,
    venInicial11,
    venInicial10,
    venInicial2,
    venInicial3,
    venInicial4,
    venInicial5,
    venInicial6,
    venInicial7,
    venInicial8,
    venInicial9,
    parseInfoCuadre,
    horaCierre,
    minutoCierre,
  ])

  const modoEdicion = React.useCallback(() => {
    if (ccaCodigo === 0) {
      dispatch(
        addToast({
          autoHide: 5000,
          content: 'El cuadre no puede ser editado.',
          fade: true,
          id: '',
          title: 'Editar Cuadre',
          type: ToastTypes.Info,
        }),
      )
    }
  }, [dispatch, ccaCodigo])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.save:
          guardar()
          break
        case ButtonTypes.edit:
          modoEdicion()
          break
        case ButtonTypes.print:
          handlePrint(false)
          break
        case ButtonTypes.print_design:
          handlePrint(true)
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, guardar, tabId, modoEdicion, handlePrint],
  )

  const onDataTab = React.useCallback(async () => {
    const tabsActives: Array<ItemTab> = []

    for (const itemTab of tabCuadresArray) {
      if (empresa?.codigoPais === ECountry.ElSalvador) {
        if (planes651Ec.includes(contratoEmpresaState?.codigoPlanVigente)) {
          if (
            contratoEmpresaState?.codigoPlanVigente !== undefined &&
            [
              'Denominación',
              'Facturas',
              'Notas de Credito',
              'Retenciones',
              'Compras',
              'Totales',
            ].includes(itemTab)
          ) {
            tabsActives.push({
              tabTitle: itemTab,
              isActive: true,
            })
          }
        } else if (
          planes234Ec.includes(contratoEmpresaState?.codigoPlanVigente)
        ) {
          if (
            contratoEmpresaState?.codigoPlanVigente !== undefined &&
            [
              'Denominación',
              'Facturas',
              'Anticipos Clientes',
              'Cobros de cartera',
              'Retenciones',
              'Notas de Credito',
              'Compras',
              'Pagos Tesoreria',
              'Anticipos Proveedores',
              'Totales',
              'Cheques',
              'Letras',
            ].includes(itemTab)
          ) {
            tabsActives.push({
              tabTitle: itemTab,
              isActive: true,
            })
          }
        }

        tabsActives.push({
          tabTitle: itemTab,
          isActive: true,
        })
      } else {
        if (planes651Ec.includes(contratoEmpresaState?.codigoPlanVigente)) {
          if (
            contratoEmpresaState?.codigoPlanVigente !== undefined &&
            [
              'Denominación',
              'Facturas',
              'Notas de Credito',
              'Retenciones',
              'Compras',
              'Totales',
            ].includes(itemTab)
          ) {
            tabsActives.push({
              tabTitle: itemTab,
              isActive: true,
            })
          }
        } else if (
          planes234Ec.includes(contratoEmpresaState?.codigoPlanVigente)
        ) {
          if (
            contratoEmpresaState?.codigoPlanVigente !== undefined &&
            [
              'Denominación',
              'Facturas',
              'Anticipos Clientes',
              'Cobros de cartera',
              'Retenciones',
              'Notas de Credito',
              'Compras',
              'Pagos Tesoreria',
              'Anticipos Proveedores',
              'Totales',
              'Cheques',
              'Letras',
            ].includes(itemTab)
          ) {
            tabsActives.push({
              tabTitle: itemTab,
              isActive: true,
            })
          }
        }
      }
    }

    setTabsCuadre(tabsActives)
  }, [contratoEmpresaState, empresa])

  const onCurrentTab = React.useCallback((tab) => {
    setShowTab(tab.tabTitle)
    switch (tab.tabTitle) {
      case 'Denominación':
        setCurrentTabCuadreIndex(TabCuadres.Denominacion)
        break
      case 'Facturas':
        setCurrentTabCuadreIndex(TabCuadres.Facturas)
        break
      case 'Totales':
        setCurrentTabCuadreIndex(TabCuadres.Totales)
        break
      default:
        setCurrentTabCuadreIndex(TabCuadres.Denominacion)
        break
    }
  }, [])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVentaGlobal ?? '', tab.info?.codigo ?? 0)
    } else if (tab.editStatus === StatesEdition.save) {
      cargarCuadre(info, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    if (imprimir) {
      handlePrint(false)
    }
  }, [imprimir, handlePrint, tabId, dispatch])

  React.useEffect(() => {
    onLoadedTabs({
      loadedTabDenominacion,
      loadedTabFacturas,
      loadedTabAnticiposClientes,
      loadedTabCobrosCartera,
      loadedTabRetenciones,
      loadedTabNotasCredito,
      loadedTabCompras,
      loadedTabPagosTesoreria,
      loadedTabAnticiposProveedores,
      loadedTabTotales,
      loadedTabCheques,
      loadedTabLetras,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadedTabDenominacion,
    loadedTabFacturas,
    loadedTabAnticiposClientes,
    loadedTabCobrosCartera,
    loadedTabRetenciones,
    loadedTabNotasCredito,
    loadedTabCompras,
    loadedTabPagosTesoreria,
    loadedTabAnticiposProveedores,
    loadedTabTotales,
    loadedTabCheques,
    loadedTabLetras,
  ])

  React.useEffect(() => {
    getVerificaPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (contratoEmpresaState !== null && tabsCuadre.length === 0) {
      onDataTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} className="mb-4">
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={datosEdicion.loader.show}
        message={datosEdicion.loader.mensaje}
      >
        {openModal !== null && (
          <ModalListadoComprobantes
            show={openModal === null ? false : true}
            onClose={() => {
              setOpenModal(null)
            }}
            cargarComprobantes={() => {}}
            tipo={openModal}
            modulo={modulo}
            puntoVenta={puntoVenta}
          />
        )}
        {reporte !== null && (
          <ReportWithLocalDataModal
            show={reporte !== null}
            onClose={() => setReporte(null)}
            data={datosExportar as any}
            fileName={plantillaNombre}
            mode={habilitarDisenador ? 'Designer' : 'Viewer'}
            parameters={{ Reporte_Filtro: '' }}
            template={plantillaNombre}
            key="reportDesignerCuadre"
          />
        )}
        {datosEdicion?.tieneError && (
          <VisualizaError
            titulo="Error en el cuadre"
            mensaje={datosEdicion.mensajeError}
            onOk={() => {
              dispatch(
                setMuestraError({
                  key: props.tabId,
                  tieneError: false,
                  mensajeError: '',
                }),
              )
            }}
          />
        )}
        <Dialog ref={dialogRefCuadre} />
        <fieldset disabled={tab.editStatus === StatesEdition.save}>
          <div>
            <RowContainer gutters={true}>
              <CustomCol xs="12" md="3">
                <div>
                  <RowContainer gutters={true}>
                    <CustomCol xs="12">
                      <Labeled label="Punto de Venta ">
                        <SelectBox
                          id="selectPuntoVenta"
                          displayExpr="host"
                          keyExpr="codigoPuntoVenta"
                          options={puntosVenta}
                          selected={puntoVenta}
                          placeholder=""
                          multiple={false}
                          onChange={onPuntoVentaChanged}
                          disabled={datosEdicion.ccaCodigo > 0}
                          clearButton={true}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </div>
              </CustomCol>
              <CustomCol xs="12" md="4">
                <div>
                  <RowContainer>
                    <CustomCol xs="12" md="5">
                      <Labeled label="Fecha Cierre">
                        <DateBox
                          value={fechaCierre}
                          onValueChanged={onChangeDateCierre}
                          disabled={datosEdicion.ccaCodigo > 0}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </div>
              </CustomCol>
              <CustomCol xs="4" md="5">
                <Labeled label="Hora">
                  <RowContainer className={'d-flex align-items-center'}>
                    <CCol xs="12" md="3">
                      <NumberBox
                        disabled={datosEdicion.ccaCodigo > 0}
                        format="#00"
                        min={0}
                        max={24}
                        placeholder=""
                        value={horaCierre}
                        showSpinButtons={true}
                        onValueChanged={onChangeHora}
                        width="100%"
                      />
                    </CCol>
                    <CCol xs="12" md="1">
                      {': '}
                    </CCol>
                    <CCol xs="12" md="3">
                      <NumberBox
                        disabled={datosEdicion.ccaCodigo > 0}
                        format="#00"
                        min={0}
                        max={59}
                        placeholder=""
                        value={minutoCierre}
                        showSpinButtons={true}
                        onValueChanged={onChangeMinutos}
                        width="100%"
                      />
                    </CCol>
                    <CCol xs="12" md="3">
                      {': ' + segundosCierre}
                    </CCol>
                  </RowContainer>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer gutters={true}>
              <CustomCol xs="12" md="3">
                <div>
                  <RowContainer gutters={true}>
                    <CustomCol xs="12">
                      <Labeled label="Local">
                        <SelectBox
                          id="selectLocal"
                          displayExpr="nombre"
                          keyExpr="codigo"
                          options={locales}
                          selected={local}
                          placeholder=""
                          multiple={false}
                          onChange={onLocalChanged}
                          disabled={datosEdicion.ccaCodigo > 0}
                          clearButton={true}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </div>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Sin Cuotas">
                  <NumberBox
                    value={cuotas.length ?? 0}
                    placeholder=""
                    showClearButton={true}
                    width="100%"
                  >
                    <TextBoxButton
                      name="sinCuotas"
                      location="after"
                      options={{
                        type: 'normal',
                        stylingMode: 'contained',
                        icon: 'plus',
                        onClick: () => {
                          setOpenModal(MODAL_DOCUMENTOS.CUOTAS)
                        },
                      }}
                    />
                  </NumberBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Sin Cheque">
                  <NumberBox
                    value={cheques.length ?? 0}
                    placeholder=""
                    showClearButton={true}
                    width="100%"
                  >
                    <TextBoxButton
                      name="sinCheque"
                      location="after"
                      options={{
                        type: 'normal',
                        stylingMode: 'contained',
                        icon: 'plus',
                        onClick: () => {
                          setOpenModal(MODAL_DOCUMENTOS.CHEQUES)
                        },
                      }}
                    />
                  </NumberBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="2">
                <Labeled label="Sin Tarjetas">
                  <NumberBox
                    value={tarjetas.length ?? 0}
                    placeholder=""
                    showClearButton={true}
                    width="100%"
                  >
                    <TextBoxButton
                      name="sinTarjetas"
                      location="after"
                      options={{
                        type: 'normal',
                        stylingMode: 'contained',
                        icon: 'plus',
                        onClick: () => {
                          setOpenModal(MODAL_DOCUMENTOS.TARJETAS)
                        },
                      }}
                    />
                  </NumberBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer className="mt-2 mb-2">
              <CustomCol xs="12" md="12">
                <CNav variant="tabs" role="tablist">
                  {tabsCuadre.map((tab, index) => {
                    return (
                      <CNavItem key={`${index}-${tab.tabTitle}`}>
                        <CNavLink
                          onClick={() => {
                            onCurrentTab(tab)
                          }}
                          active={showTab === tab.tabTitle}
                        >
                          {tab.tabTitle}
                        </CNavLink>
                      </CNavItem>
                    )
                  })}
                </CNav>
                <CTabContent style={{ overflowY: 'unset' }}>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[0]}-tab`}
                    visible={showTab === tabCuadresArray[0]}
                  >
                    <Denominacion
                      tab={tab}
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('denominacion', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[1]}-tab`}
                    visible={showTab === tabCuadresArray[1]}
                  >
                    <Facturas
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('facturas', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[2]}-tab`}
                    visible={showTab === tabCuadresArray[2]}
                  >
                    <Anticipos
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('anticiposClientes', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[3]}-tab`}
                    visible={showTab === tabCuadresArray[3]}
                  >
                    <CobrosCartera
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('cobrosCartera', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[4]}-tab`}
                    visible={showTab === tabCuadresArray[4]}
                  >
                    <Retenciones
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('retenciones', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[5]}-tab`}
                    visible={showTab === tabCuadresArray[5]}
                  >
                    <NotasCredito
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('notasCredito', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[6]}-tab`}
                    visible={showTab === tabCuadresArray[6]}
                  >
                    <ComprasCuadres
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('compras', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[7]}-tab`}
                    visible={showTab === tabCuadresArray[7]}
                  >
                    <PagosTesoreria
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('pagosTesoreria', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[8]}-tab`}
                    visible={showTab === tabCuadresArray[8]}
                  >
                    <AnticiposProveedores
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('anticiposProveedores', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[9]}-tab`}
                    visible={showTab === tabCuadresArray[9]}
                  >
                    <Totales
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('totales', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[10]}-tab`}
                    visible={showTab === tabCuadresArray[10]}
                  >
                    <Cheques
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('cheques', loadTab)
                      }}
                    />
                  </CTabPane>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby={`${tabCuadresArray[11]}-tab`}
                    visible={showTab === tabCuadresArray[11]}
                  >
                    <Letras
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                      statusLoaded={(loadTab) => {
                        putTabLoaded('letras', loadTab)
                      }}
                    />
                  </CTabPane>
                  {/* <CTabPane role="tabpanel" aria-labelledby={`${tabCuadresArray[12]}-tab`} visible={showTab === tabCuadresArray[12]}>
                    <Boveda
                      setToast={setToast}
                      playLoader={playLoader}
                      stopLoader={stopLoader}
                      tabId={tabId}
                      onErrorConfirm={onErrorConfirm}
                      onConfirmarRegistro={onConfirmarRegistro}
                    />
                  </CTabPane>
                  <CTabPane role="tabpanel" aria-labelledby={`${tabCuadresArray[13]}-tab`} visible={showTab === tabCuadresArray[13]}>
                    Egresos a Boveda
                  </CTabPane> */}
                </CTabContent>
              </CustomCol>
            </RowContainer>
            <div id="detalleCuadreyTotales">
              {isMobile && (
                <>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      {/* <small>{datosEdicion.totales.subtotal.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal 0%</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      {/* <small>{datosEdicion.totales.subtotal0.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>ICE</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      {/* <small>{datosEdicion.totales.ice.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal IVA</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      {/* <small>{datosEdicion.totales.subtotalIva.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Impuestos</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      {/* <small>{datosEdicion.totales.iva.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Descuento Items</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      {/* <small>{datosEdicion.totales.descuento.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Total</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      {/* <small>{datosEdicion.totales.total.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                </>
              )}
            </div>
          </div>
        </fieldset>
        {isMobile && <div style={{ height: '175px' }} />}
      </BlockUi>
    </div>
  )
}

export const defaultDatosEdicionCuadreCaja: CuadresCajaDatosEdicion = {
  ccaCodigo: 0,
  usuarioNombre: '',
  usuarioCodigo: 0,
  cuadreTotal: 0,
  identificadorTransaccion: '',
  numero: 0,
  fecha: '',
  fechaCierre: '',
  puntosVenta: [],
  puntoVenta: null,
  horaCierre: 0,
  minutoCierre: 0,
  segundosCierre: '00',
  local: null,
  tieneError: false,
  mensajeError: '',
  loader: {
    mensaje: 'Cargando...',
    show: true,
  },
  totales: {
    totalEfectivo: 0,
    totalEgresos: 0,
    descuadre: 0,
  },
  imprimir: false,
  loading: true,
  totalinicialb_ti: 0, //
  totalinicial_ti: 0,
  totalesaldosi_ti: 0,
  totalventas_ti: 0,

  totalcajab_ti: 0,
  totalegreso_ti: 0,
  descuadre_ti: 0,
  descuadredes_ti: '',
  totalegresosb_ti: 0,
  valortotalb_ti: 0,
  anuladascierre1_ti: 0,
  total1_ti: 0,
  dptotales1: [],

  dptotales: [],
  dptotalesFacturas: [],
  dptotalesAnticipos: [],
  dptotalesCobrosCartera: [],
  dptotalesRetenciones: [],
  dptotalesComprasCaja: [],
  dptotalesNotasCredito: [],
  dptotalesPagosTesoreria: [],
  dptotalesAnticiposProveedores: [],

  dpfpagos: [],
  dptotalresumen: [],

  totalcaja_ti: 0,
  totalegresos_ti: 0,
  valortotal_ti: 0,
  dpegresosboveda: [],

  generarCuadre: false,
  ccaCod1: 0,
  ccaCod2: 0,
  ccaCod3: 0,
  ccaCod4: 0,
  ccaCod5: 0,
  ccaCod6: 0,
  ccaCod7: 0,
  ccaCod8: 0,
  ccaCod9: 0,
  ccaCod10: 0,
  ccaCod11: 0,

  venInicial1: 0,
  venFinal1: 0,
  venInicial2: 0,
  venFinal2: 0,
  venInicial3: 0,
  venFinal3: 0,
  venInicial4: 0,
  venFinal4: 0,
  venInicial5: 0,
  venFinal5: 0,
  venInicial6: 0,
  venFinal6: 0,

  venInicial7: 0,
  venFinal7: 0,

  venInicial8: 0,
  venFinal8: 0,
  venInicial9: 0,
  venFinal9: 0,
  venInicial10: 0,
  venFinal10: 0,
  venInicial11: 0,
  venFinal11: 0,

  dpcierre1: [],
  loaddpcierre1: false,

  dpcierre2: [],
  loaddpcierre2: false,
  dptotales2: [],
  anuladascierre2_ti: 0,
  total2_ti: 0,

  dpcierre4: [],
  loaddpcierre4: false,

  dpcierre5: [],
  loaddpcierre5: false,

  dpcierre3: [],
  dptotales3: [],
  anuladascierre3_ti: 0,
  total3_ti: 0,
  loaddpcierre3: false,

  dpcierre7: [],
  dptotales7: [],
  total7_ti: 0,
  loaddpcierre7: false,

  dpcierre8: [],
  dptotales8: [],
  total8_ti: 0,
  loaddpcierre8: false,

  dpcierre9: [],
  dptotales9: [],
  totalcom_ti: 0,
  loaddpcierre9: false,

  dpcierre10: [],
  dptotales10: [],
  totaltesoreria_ti: 0,
  loaddpcierre10: false,

  dpcierre11: [],
  dptotales11: [],
  total11_ti: 0,
  loaddpcierre11: false,

  dpvendedores: [],
  vendedor: null,
  dpingresosboveda: [],
  totalsaldoi_ti: 0,
  totalbovedai_ti: 0,
  totalingresosb_ti: 0,
  valortotalboveda_ti: 0,

  dpingresos: [],
  denominacion: [],

  objetoBilletes: objetoBilletes,
  objetoMonedas: objetoMonedas,
  editableInput: false,
}

export const CuadresCajaButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Ingresos_de_caja: true,
}

export default React.memo(CuadreCaja)
