import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import config from '../../../config/config'
import { AccionMenu, ToastTypes } from '../../../store/types'
import { changeLoader } from '../../../store/reducers'
import { addToast } from '../../../store/toasterReducer'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { GlobalMenu } from '../../../views/componentes/globalMenu/globalMenu'
import { ButtonTypes } from '../../../views/componentes/globalMenu/types'
import Buscar from './components/buscar/index'
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import LoadPanel from 'devextreme-react/load-panel'
import Dialog from '../../../views/componentes/librerias/bootstrap-dialog'
import Modalform from '../../../views/componentes/modalform'
import { OrdenProformaListado } from './store/types'

interface IPropsModalProformas extends React.PropsWithChildren {
  orden?: OrdenProformaListado | null
  onChanged: (params: any) => void
  onCancel: () => void
  show: boolean
}

const ModalProformas: React.FunctionComponent<IPropsModalProformas> = (
  props,
) => {
  const { show, onCancel, onChanged, orden } = props

  const dispatch = useDispatch()
  const dialogRef = React.useRef<any>(null)

  const loader = useSelector((state: RootState) => state.global.loader)

  const botones = config['menuDefecto']
    .filter((acc) => acc.type === ButtonTypes.find)
    .map((acc) => {
      const boton: AccionMenu = {
        nombre: acc.id,
        icon: acc.icon,
        actionType: acc.type,
        main: false,
        modal: false,
        shortcut: null,
      }
      return boton
    })

  const [acciones] = React.useState<AccionMenu[]>(botones)
  const [currentTab, setCurrentTab] = React.useState<any>('Buscar')
  const [funcion, setFuncion] = React.useState<string>('')
  const [seleccion, setSeleccion] = React.useState<any>(null)

  const playLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(async () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      dispatch(
        addToast({
          id: '',
          autoHide: 5000,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const returnAction = (boton: AccionMenu) => {
    switch (boton.actionType) {
      case ButtonTypes.find:
        setSeleccion(null)
        if (currentTab == 'Buscar') {
          setFuncion(ButtonTypes.find)
          return false
        }
        break
      default:
        break
    }
  }

  const getButtonDisabled = React.useCallback((button: AccionMenu) => {
    switch (button.actionType) {
      case ButtonTypes.find:
        return false
      case ButtonTypes.undo:
        return true
      case ButtonTypes.new:
        return true
      case ButtonTypes.delete:
      case ButtonTypes.edit:
        return true
      case ButtonTypes.save:
        return true
      default:
        return true
    }
  }, [])

  React.useEffect(() => {
    if (funcion !== '') {
      setFuncion('')
    }
  }, [funcion])

  React.useEffect(() => {
    if (show) {
      if (orden === null) {
        setSeleccion(null)
        setCurrentTab('Buscar')
        setFuncion('')
      } else if (orden) {
        setSeleccion(orden)
        setCurrentTab('Buscar')
        setFuncion('')
      }
    }
  }, [show, orden])

  const botonesModal = () => {
    return (
      <RowContainer>
        <CustomCol xs="12" md="4">
          <GlobalMenu
            acciones={acciones}
            onClick={returnAction}
            getButtonDisabled={getButtonDisabled}
          />
        </CustomCol>
      </RowContainer>
    )
  }

  const bodyModal = () => {
    return (
      <RowContainer>
        <CustomCol xs="12" md="12">
          {currentTab === 'Buscar' && (
            <Buscar
              funcion={funcion}
              resetFunction={() => setFuncion('')}
              onChanged={(data) => {
                setSeleccion(data)
                onChanged(data)
              }}
              onEdit={(data) => {
                setSeleccion(data)
              }}
              ejecutarBusqueda={() => setFuncion('Buscar')}
              currentTab={currentTab}
              seleccion={seleccion}
              setToast={setToast}
              playLoader={playLoader}
              stopLoader={stopLoader}
            />
          )}
        </CustomCol>
      </RowContainer>
    )
  }

  const renderContent = () => {
    return (
      <CCard>
        <CCardHeader>{botonesModal()}</CCardHeader>
        <CCardBody>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={'center'}
            visible={loader.show}
            showIndicator={true}
            shading={true}
            showPane={true}
            message={loader.mensaje}
          />
          {bodyModal()}
        </CCardBody>
      </CCard>
    )
  }

  return (
    <>
      <div>
        <Dialog ref={dialogRef} />
      </div>
      <Modalform
        name="popupProformas"
        headerTitle={'Proformas'}
        childrenBody={renderContent()}
        closeOnBackdrop={false}
        show={show}
        onClose={onCancel}
        centered={true}
        size="lg"
      />
    </>
  )
}

export default ModalProformas
