import React from 'react';
import { BuscarVendedorLookUp } from './buscarVendedorLookUp';
import { VendedorBusqueda } from './type/types';

interface IBuscarVendedorLookUpMemoProps extends React.PropsWithChildren {
  vendedor: VendedorBusqueda | null,
  tab: any,
  cambiarVendedorSeleccionado: any,
  disabled?: boolean,
  allowAdd?: boolean,
  allowClear?: boolean,
  allowEdit?: boolean,
}

const BuscarVendedorLookMemoUp: React.FC<IBuscarVendedorLookUpMemoProps> = (props) => {

  const { disabled, vendedor, tab, cambiarVendedorSeleccionado, allowAdd, allowEdit, allowClear } = props;

  return (
    <BuscarVendedorLookUp
      selected={vendedor}
      allowEdit={allowEdit}
      allowAdd={allowAdd}
      allowClear={allowClear}
      onChanged={cambiarVendedorSeleccionado}
      disabled={disabled}
    />
  )
}

export default React.memo(BuscarVendedorLookMemoUp);
