import {
  DatosEdicionAdicionales,
  DatosEdicionCompra,
  DatosEdicionContribuyente,
  DatosEdicionGenerales,
  DatosEdicionHistorico,
  ProveedoresDatosEdicion,
  ProveedoresListado,
  TMainEdition,
} from '../../types/types'
import { defaultDatosGenerales } from './components/generales'
import { defaultDatosCompra } from './components/compras'
import { defaultDatosHistorico } from './components/historicos'
import { defaultDatosAditionales, diasVisista } from './components/adicionales'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import { isMobile } from 'react-device-detect'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { StatesEdition } from '../../../../../ventas/types/enums'
import { RootState } from '../../../../../../store/store'
import { ValidationGroup, ValidationSummary } from 'devextreme-react'
import TabsEdition from './components/tabsEdition/tabsEdition'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import { useDispatch, useSelector } from 'react-redux'
import { defaultDatosContribuyente } from './components/main'
import { UrlHelper } from '../../../../../../helpers/urlHelper'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../../store/tabsReducer'
import {
  setDatosEdicion,
  setMuestraErrores,
  setTabAdicionales,
  setTabCompra,
  setTabContribuyente,
  setTabGenerales,
  setTabHistorico,
  updateLoader,
} from '../../store/editDataReducer'
import { realacionadoGrupos } from '../../../../../clientes/pages/clientes/components/documento'
import {
  getClasificacion,
  getDpEspecialidades,
  getDpRegimenEmpresa,
  getEstados,
  getSustentosTributarios,
  getTipoIdetificacion,
  getTipoProveedor,
} from '../../../../../clientes/pages/clientes/helper/helper'
import { TabState } from '../../../../../../store/genericTabTypes'
import { chequesService } from '../../../../../bancos/pages/cheques/services/ChequesServices'
import { tipoIdentificacionService } from '../../../../../componentes/tipoIdentificacion/service/tipoId.service'
import { TipoTransaccion } from '../../../../../componentes/tipoIdentificacion/store/types'
import { ProveedoresService } from '../../../../services/proveedores.service'
import { localidadesService } from '../../../../../../services/localidades.service'
import { tipoProveedorService } from '../../../../../componentes/tipoProveedor/service/tiposProveedor.service'
import { clasificacionesService } from '../../../../../componentes/lookUpClasificacionAdicional/service/clasificaciones.service'
import { tipoEstadoService } from '../../../../../componentes/tipoEstado/service/tipoEstado.service'
import { sustentoTributarioServices } from '../../../../../componentes/sustentosTributarioLookUp/services/sustentoTributario.service'
import { ClientesService } from '../../../../../clientes/pages/clientes/services/clientes.service'
import { proveedorService } from '../../services/ProveedoresService'
import { estadosService } from '../../../../../componentes/tiposEmpresasLookUp/service/estados.service'
import { specialityServices } from '../../../../../componentes/especialidadProveedor/service/especialidadproveedor.service'
import { asignacionesService } from '../../../../../contabilidad/pages/asignaciones/services/asignaciones.service'
import { Proveedor as proveedorTipo } from '../../../../store/types'
import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { EFormasPago } from '../../../../../../store/enum/formasPago'
import { ECountry } from '../../../../../../store/enum/countries'
import { consoleService } from '../../../../../../services/console.service'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { CAlert } from '@coreui/react-pro'
import { getTypeAgent } from '../../../../../../views/pages/componentes/wizardRegistro/helper/helper'

interface IProveedorProps {
  info: DocumentInfo<ProveedoresListado>
  tabId: string
  tab: TabState<ProveedoresListado>
  templateMode?: boolean
}

const Proveedor: FunctionComponent<IProveedorProps> = (props) => {
  const { tab, tabId, info } = props
  const dispatch = useDispatch()

  const validationGroupRef = useRef<any>()
  const urls = UrlHelper.getUrls()
  const usuario = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const local = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  const loading = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId].loading
  })
  const dataSupplier = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]
  })
  const stateEditHistoricos = useSelector(
    (state: RootState) =>
      state.proveedores.proveedores.editData[tabId]?.tabs?.historicos,
  )
  const datosEdicion = useSelector(
    (state: RootState) => state.proveedores.proveedores.editData[tabId],
  )
  const empresaState = useSelector((state: RootState) => {
    return state.global.session.empresa
  })

  const isElSalvador = empresaState?.codigoPais === ECountry.ElSalvador

  const [, setHabilitarDisenador] = useState<boolean>(false)
  const [verPdf, setVerPdf] = useState<{ design: boolean }>()
  const dpIva = []
  const dpRenta = []
  const formasPago = []

  const getVerificaPath = useCallback(async () => {
    if (urls.origin.includes('acatha.io')) {
      setHabilitarDisenador(false)
    } else {
      setHabilitarDisenador(true)
    }
  }, [urls])

  const setToast = useCallback(
    (texto: string, type: ToastTypes) => {
      let time: number = 3000
      time = texto.length < 50 ? 5000 : 10500
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const modoNuevo = useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      dispatch(
        updateLoader({
          key: tabId,
          loader: { mensaje: 'Cargando', show: true },
        }),
      )
      const data = { ...defaultDatosEdicionProveedor }

      try {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...ModuleButtons,
              Editar: false,
              Imprimir: false,
              Exportar: false,
              Deshacer: true,
              Limpiar: true,
              Guardar: true,
            },
          }),
        )

        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.new,
          }),
        )

        const defaultDatosContribuyenteUpdate: DatosEdicionContribuyente =
          defaultDatosContribuyente
        const defaultDatosGeneralesUpdate: DatosEdicionGenerales =
          defaultDatosGenerales
        const defaultDatosCompraUpdate: DatosEdicionCompra = defaultDatosCompra
        const defaultDatosHistoricoUpdate: DatosEdicionHistorico =
          defaultDatosHistorico
        const defaultDatosAditionalesUpdate: DatosEdicionAdicionales =
          defaultDatosAditionales

        const tab: any = {}

        const optionTiposIdetificacion = await getTipoIdetificacion()
        const optionTipoProveedor = await getTipoProveedor()
        const optionClasificacion = await getClasificacion()
        const optionEstados = await getEstados()
        const optionSustentosTributarios = await getSustentosTributarios()
        const optionTiposEmpresas = await getDpRegimenEmpresa()
        const optionEspecialidades = await getDpEspecialidades()
        const optionTiposAgente = await getTypeAgent(empresa.codigoPais)

        if (template > 0) {
        } else {
          if (optionTiposAgente.length > 0) {
            defaultDatosGeneralesUpdate.tiposAgente = optionTiposAgente

            if (empresa.codigoPais === ECountry.ElSalvador) {
              const findTipoContr = optionTiposIdetificacion.find((item) => {
                return String(item?.descripcion)
                  .toLocaleUpperCase()
                  .includes('MEDIAN')
              })

              if (findTipoContr) {
                defaultDatosGeneralesUpdate.tipoAgente = findTipoContr
              }
            }
          }

          if (optionTiposIdetificacion.length > 0) {
            defaultDatosGeneralesUpdate.tipoIdentificacion =
              optionTiposIdetificacion[1]
            defaultDatosGeneralesUpdate.tiposIdentificacion =
              optionTiposIdetificacion

            console.log('optionTiposIdetificacion', optionTiposIdetificacion)

            if (empresa.codigoPais === ECountry.ElSalvador) {
              const findTipo = optionTiposIdetificacion.find((item) => {
                console.log('item', item)

                return String(item?.descripcion)
                  .toLocaleUpperCase()
                  .includes('NIT')
              })
              console.log('findTipo', findTipo)

              if (findTipo) {
                defaultDatosGeneralesUpdate.tipoIdentificacion = findTipo
              }
            }
          }
          console.log(
            'defaultDatosGeneralesUpdate',
            defaultDatosGeneralesUpdate,
          )
          if (optionTipoProveedor.length > 0) {
            defaultDatosGeneralesUpdate.tipoProveedor = optionTipoProveedor[0]
            defaultDatosGeneralesUpdate.tiposProveedor = optionTipoProveedor
          }
          if (optionClasificacion.length > 0) {
            defaultDatosGeneralesUpdate.clasificacion = optionClasificacion[0]
            defaultDatosGeneralesUpdate.clasificacionOptions =
              optionClasificacion
          }
          if (optionEstados.length > 0) {
            defaultDatosGeneralesUpdate.estado = optionEstados[1]
            defaultDatosGeneralesUpdate.estados = optionEstados
          }
          if (optionSustentosTributarios.length > 0) {
            defaultDatosCompraUpdate.sustentoTri = optionSustentosTributarios[0]
            defaultDatosCompraUpdate.sustentoTriOptions =
              optionSustentosTributarios
          }
          if (optionTiposEmpresas.length > 0) {
            defaultDatosAditionalesUpdate.tipoEmpresa = optionTiposEmpresas[0]
            defaultDatosAditionalesUpdate.tiposEmpresaDp = optionTiposEmpresas
          }
          if (optionEspecialidades.length > 0) {
            defaultDatosAditionalesUpdate.especialidad = optionEspecialidades[0]
            defaultDatosAditionalesUpdate.especialidadOptions =
              optionEspecialidades
          }

          defaultDatosGeneralesUpdate.parteRelacionada = realacionadoGrupos[1]

          tab.contribuyente = {
            ...defaultDatosContribuyenteUpdate,
          }
          tab.generales = {
            ...defaultDatosGeneralesUpdate,
          }
          tab.compras = {
            ...defaultDatosCompraUpdate,
          }
          tab.historicos = {
            ...defaultDatosHistoricoUpdate,
          }
          tab.adicionales = {
            ...defaultDatosAditionalesUpdate,
          }
          data.tabs = tab
          data.desdePlantilla = false

          data.loading = false
          data.loader = {
            mensaje: '',
            show: false,
          }
        }

        dispatch(
          updateLoader({
            key: tabId,
            loader: { mensaje: '', show: false },
          }),
        )

        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
      } catch (err) {
        data.tieneError = true
        data.mensajeError = err
        data.loading = false

        dispatch(
          addToast({
            content: data.mensajeError,
            type: ToastTypes.Danger,
          }),
        )

        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
      }

      dispatch(
        setDatosEdicion({
          data: data,
          key: tabId,
        }),
      )
    },
    [loading, dispatch, tabId, empresa],
  )

  const obtenerTipoIdentificacion = useCallback(
    async (tipoIdentificacion: string) => {
      try {
        const tipos = await tipoIdentificacionService.getTipoIdetificacion(
          TipoTransaccion.compra,
        )
        if (tipos !== undefined && tipos !== null && tipos['error'] === false) {
          const prvTipoId = tipos['auto'].find(
            (item) => item.codigo === tipoIdentificacion,
          )
          return { id: prvTipoId, tipos }
        }
        return null
      } catch (error) {
        return null
      }
    },
    [],
  )

  const obtenerCiudad = useCallback(async (ciudad: string) => {
    try {
      const data = await localidadesService.getDpCiudades(
        -1,
        'Elija una opción',
      )
      if (data !== null && data !== undefined && data.length > 0) {
        const selectCiudad = data.find((item) => item.nombre === ciudad)
        return selectCiudad
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const obtenerEstado = useCallback(async (codigo: number) => {
    try {
      const data = await tipoEstadoService.getTipoEstado('Elija un estado')
      if (data !== null && data !== undefined && data['error'] === false) {
        const selectEstado = data['auto'].find((item) => item.codigo === codigo)
        return {
          estado: selectEstado,
          estados: data['auto'],
        }
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const obtenerTipoProveedor = useCallback(async (tipoProveedor) => {
    try {
      const data = await tipoProveedorService.getTiposProveedor(
        'Elige una opción',
      )
      if (data !== null && data !== undefined && data['error'] === false) {
        const tipProveedor = data['auto'].find(
          (item) => item.codigo === tipoProveedor,
        )
        return {
          tProveedor: tipProveedor,
          tProveedores: data['auto'],
        }
      }
      return null
    } catch (error) {
      return null
    }
  }, [])
  const obtenerTipoEmpresa = useCallback(async (tipoEmpresa) => {
    try {
      let codigo = -1
      if (tipoEmpresa !== 0) {
        codigo = tipoEmpresa
      }
      const data = await estadosService.getEstados('Elija una opción')
      if (data !== null && data !== undefined && data['error'] === false) {
        const tipEmpresa = data['auto'].find((item) => item.codigo === codigo)
        return {
          tEmpresar: tipEmpresa,
          todos: data['auto'],
        }
      }
      return null
    } catch (error) {
      return null
    }
  }, [])
  const obtenerEspecialidad = useCallback(async (especialidadC) => {
    try {
      let codigo = 0
      if (especialidadC === 0) {
        codigo = -1
      } else {
        codigo = especialidadC
      }
      const data = await specialityServices.getSpeciality('Elija una opción')
      if (data !== null && data !== undefined && data['error'] === false) {
        const especialidad = data['auto'].find((item) => item.codigo === codigo)
        return {
          especialidad,
          todos: data['auto'],
        }
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const obtenerClasificacion = useCallback(async (clasificacion: number) => {
    try {
      const data = await clasificacionesService.getClasificacionesAdicionales(
        'Elija una opción',
      )
      if (data !== null && data !== undefined && data['error'] === false) {
        const clasificacionProv = data['auto'].find(
          (item) => item.codigo === clasificacion,
        )
        return {
          clasificacion: clasificacionProv,
          todos: data['auto'],
        }
      }
      return null
    } catch (error) {
      return null
    }
  }, [])
  const obtenerSustentoTri = useCallback(async (susTri: string) => {
    try {
      let susTributario = ''
      if (susTri === '') {
        susTributario = '00'
      } else {
        susTributario = susTri
      }
      const data = await sustentoTributarioServices.getTipos()
      if (data !== null && data !== undefined && data['error'] === false) {
        const selectSusTri = data['auto'].find(
          (item) => item.codigo === susTributario,
        )
        return {
          sustentoTributarioSelect: selectSusTri,
          sustentosTributarios: data['auto'],
        }
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const modoEdicion = useCallback(
    async (proveedor) => {
      if (proveedor.info === null || proveedor.info === undefined) {
        return
      }

      dispatch(
        updateLoader({
          key: tabId,
          loader: { mensaje: 'Cargando', show: true },
        }),
      )
      dispatch(
        setTabContribuyente({
          key: tabId,
          data: {
            codigo: proveedor.info?.codigo ?? 0,
            nombre: proveedor.info?.nombre ?? '',
            razonComercial: proveedor.info?.razonComercial ?? '',
          },
        }),
      )

      let tipo = null
      let tipos = []

      const provTipoId = await obtenerTipoIdentificacion(
        proveedor.info?.tipoIdentificacion,
      )
      const ciudades = await obtenerCiudad(proveedor.info?.ciudadNombre)

      if (provTipoId !== null && provTipoId !== undefined) {
        tipo = provTipoId.id ?? null
        tipos = provTipoId.tipos?.auto ?? []
      }

      try {
        const identificacion = proveedor.info?.identificacion ?? ''
        const cargarProveedor = await chequesService.obtenerProveedor(
          identificacion,
        )
        const provDetalle = await ProveedoresService.getDetalleProveedor(
          proveedor.info?.codigo,
          '',
        )

        const renta = await ClientesService.getRetenciones(
          cargarProveedor?.codigo,
          56,
          65,
        )
        const iva = await ClientesService.getRetenciones(
          cargarProveedor?.codigo,
          56,
          66,
        )

        if (renta !== null && renta !== undefined && renta['error'] === false) {
          if (renta['auto'].length > 0) {
            renta['auto'].map((item) => {
              if (item.impuesto === 66) {
                dpIva.push(item)
              } else {
                dpRenta.push(item)
              }
            })
          }
        }
        if (iva !== null && iva !== undefined && iva['error'] === false) {
          if (iva['auto'].length > 0) {
            iva['auto'].map((item) => {
              if (item.impuesto === 66) {
                dpIva.push(item)
              } else {
                dpRenta.push(item)
              }
            })
          }
        }

        const clasificacionProv = await obtenerClasificacion(
          cargarProveedor?.clasificacion,
        )
        const tipsProveedore = await obtenerTipoProveedor(
          cargarProveedor?.tipoProvedorCodigo,
        )
        const provEstado = await obtenerEstado(
          parseInt(cargarProveedor?.estado),
        )
        const tipoEmpresa = await obtenerTipoEmpresa(
          cargarProveedor?.tipoEmpresa,
        )
        const especialidad = await obtenerEspecialidad(
          cargarProveedor?.especialidad,
        )
        const susTriData = await obtenerSustentoTri(cargarProveedor?.tributario)
        const fpData = await asignacionesService.obtenerFormasPagoCompra(
          'C',
          '',
          'Formas de Pago',
        )
        if (fpData !== null && fpData !== undefined && fpData.length > 0) {
          fpData.map((item) => {
            if (
              item.descripcion !== 'Formas de Pago' &&
              item.codigo !== EFormasPago.otros
            ) {
              formasPago.push({
                codigoFormaPago: item.codigo,
                descripcionFormaPago: item.descripcion,
              })
            }
          })
        }

        const proveedorAsignacion =
          await proveedorService.obtenerAsignacionesProveedores(
            proveedor.info?.codigo,
          )
        if (
          proveedorAsignacion !== null &&
          proveedorAsignacion !== undefined &&
          proveedorAsignacion['error'] === false
        ) {
          if (proveedorAsignacion['auto'].length > 0) {
            proveedorAsignacion['auto'].map((item, i) => {
              if (item.asignacionTransaccion === '' && item.enUso === 0) {
                formasPago[i].cuentaCodigo = item.planCuentasCodigo
                formasPago[i].cuentaNumero = item.planCuentasNumero
                formasPago[i].cuentaDescripcion = item.planCuentasDescripcion
                formasPago[i].transaccion = item.asignacionTransaccion
                formasPago[i].enUso = item.enUso
              } else {
                if (
                  formasPago[i].descripcionFormaPago ===
                  item.asignacionTransaccion
                ) {
                  formasPago[i].cuentaCodigo = item.planCuentasCodigo
                  formasPago[i].cuentaNumero = item.planCuentasNumero
                  formasPago[i].cuentaDescripcion = item.planCuentasDescripcion
                  formasPago[i].transaccion = item.asignacionTransaccion
                  formasPago[i].enUso = item.enUso
                }
              }
            })
          }
        }

        const optionTiposAgente = await getTypeAgent(empresa.codigoPais)

        let tiposAgente = []
        let tipoAgente = null

        if (optionTiposAgente.length > 0) {
          tiposAgente = optionTiposAgente
          if (empresa.codigoPais === ECountry.ElSalvador) {
            const findTipoContr = optionTiposAgente.find((item) => {
              return (
                Number(item?.codigo) ===
                Number(cargarProveedor?.tipoAgenteCodigo)
              )
            })

            if (findTipoContr) {
              tipoAgente = findTipoContr
            } else {
              // marcar por defecto
              const findTipoContr = optionTiposAgente.find((item) => {
                return String(item?.descripcion)
                  .toLocaleUpperCase()
                  .includes('MEDIAN')
              })
              if (findTipoContr) {
                tipoAgente = findTipoContr
              }
            }
          }
        }

        const telefonos = []
        if (
          provDetalle !== null &&
          provDetalle !== undefined &&
          provDetalle.length > 0
        ) {
          provDetalle.map((item) => {
            telefonos.push(item)
          })
        }

        const ultimoPago: any = { fecha: '', documento: '', monto: 0 }
        const ultimoCompra: any = { fecha: '', documento: '', monto: 0 }

        if (cargarProveedor?.documentoUltimoPago !== 0) {
          ultimoPago.fecha = cargarProveedor?.fechaUltimoPago ?? ''
          ultimoPago.documento = cargarProveedor?.documentoUltimoPago ?? ''
          ultimoPago.monto = cargarProveedor?.montoPago ?? 0
        }

        if (cargarProveedor?.documentoUltimaCompra !== null) {
          ultimoCompra.fecha = cargarProveedor?.fechaUltimaCompra ?? ''
          ultimoCompra.documento = cargarProveedor?.documentoUltimaCompra ?? ''
          ultimoCompra.monto = cargarProveedor?.montoCompra ?? 0
        }

        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              tiposAgente: tiposAgente,
              tipoAgente: tipoAgente,
              tipoIdentificacion: tipo,
              tiposIdentificacion: tipos,
              identificacion: cargarProveedor?.identificacion ?? '',
              dui: cargarProveedor?.dui ?? '',
              proveedorSeguro: cargarProveedor?.seguros === 0 ? false : true,
              artesanoCalificado:
                cargarProveedor?.artesanos === 0 ? false : true,
              direccion: cargarProveedor?.direccion ?? '',
              email: cargarProveedor?.email ?? '',
              estado: provEstado?.estado ?? null,
              estados: provEstado?.estados ?? [],
              telefonos: telefonos,
              ciudad: {
                codigoPais: ciudades?.paisCodigo ?? 0,
                nombrePais: ciudades?.paisNombre ?? '',
                codigoProvincia: ciudades?.provinciaCodigo ?? 0,
                nombreProvincia: ciudades?.provinciaNombre ?? '',
                codigo: ciudades?.codigo ?? 0,
                nombre: ciudades?.nombre ?? '',
              },
              tipoProveedor: tipsProveedore?.tProveedor ?? null,
              tiposProveedor: tipsProveedore?.tProveedores ?? [],
              clasificacion: clasificacionProv?.clasificacion ?? null,
              clasificacionOptions: clasificacionProv?.todos ?? [],
              parteRelacionada:
                cargarProveedor?.relacionado === realacionadoGrupos[1].label
                  ? realacionadoGrupos[1]
                  : realacionadoGrupos[0],
              transportista:
                cargarProveedor?.transportista === '0' ? false : true,
              nrc: cargarProveedor?.nrc ?? '',
              actividadEconomica: cargarProveedor?.tipoActividad ?? null,
            },
          }),
        )

        dispatch(
          setTabCompra({
            key: tabId,
            data: {
              diasCredito: cargarProveedor?.diasCredito ?? 0,
              limiteCredito: cargarProveedor?.limiteCredito ?? 0,
              descuento: cargarProveedor?.descuento ?? 0,
              saldo: cargarProveedor?.saldo ?? 0,
              sustentoTri: susTriData?.sustentoTributarioSelect ?? null,
              sustentoTriOptions: susTriData?.sustentosTributarios ?? [],
              atencionCompras: cargarProveedor?.personaAtencionCompras ?? '',
              atencionPagos: cargarProveedor?.personaAtencionPagos ?? '',
              retenciones: dpIva,
              formasPago: formasPago,
            },
          }),
        )

        dispatch(
          setTabHistorico({
            key: tabId,
            data: {
              ...stateEditHistoricos,
              comprasAnuales: cargarProveedor?.comprasAnuales,
              ultimoPago: ultimoPago,
              ultimaCompra: ultimoCompra,
            },
          }),
        )

        const dia = diasVisista.find(
          (item) => item.value === cargarProveedor?.diaReposicion,
        )

        dispatch(
          setTabAdicionales({
            key: tabId,
            data: {
              observaciones: cargarProveedor?.observacion ?? '',
              sitioweb: cargarProveedor?.estadoDescripcion ?? '',
              tipoEmpresa: tipoEmpresa?.tEmpresar ?? null,
              tiposEmpresaDp: tipoEmpresa?.todos ?? [],
              especialidad: especialidad?.especialidad ?? null,
              especialidadOptions: especialidad?.todos ?? [],
              reposicion: cargarProveedor?.intervalo ?? 0,
              diaVisita: dia ?? null,
            },
          }),
        )

        dispatch(
          updateLoader({
            key: tabId,
            loader: { mensaje: '', show: false },
          }),
        )
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
        dispatch(
          updateLoader({
            key: tabId,
            loader: { mensaje: '', show: false },
          }),
        )
      }
    },
    [
      dispatch,
      tabId,
      obtenerTipoIdentificacion,
      obtenerCiudad,
      obtenerClasificacion,
      obtenerTipoProveedor,
      obtenerEstado,
      obtenerSustentoTri,
      setToast,
      obtenerTipoEmpresa,
      obtenerEspecialidad,
      stateEditHistoricos,
      dpIva,
      dpRenta,
      formasPago,
      empresa,
    ],
  )

  const guardar = useCallback(async () => {
    if (usuario == null || empresa == null || local == null) {
      return
    }

    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save,
      }),
    )

    let mensajes: Array<string> = []

    const resultado = validationGroupRef.current.instance.validate()

    if (resultado.isValid === false) {
      if (resultado.brokenRules) {
        mensajes = resultado.brokenRules.map((x) => {
          return x.message
        })
      }

      dispatch(
        setMuestraErrores({
          key: tabId,
          tieneError: true,
          mensajesErrors: mensajes ?? [],
        }),
      )

      dispatch(
        addToast({
          content: 'Tiene errores por favor verifíquelos.',
          type: ToastTypes.Danger,
        }),
      )

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      return false
    } else {
      if (
        dataSupplier?.tabs?.generales?.telefonos &&
        dataSupplier?.tabs?.generales?.telefonos.length === 0
      ) {
        mensajes.push('Se requiere al menos un numero de contacto.')
      }
      if (!dataSupplier?.tabs?.generales?.ciudad) {
        mensajes.push('Se requiere que la ciudad/municipio este seleccionada.')
      }

      if (mensajes.length > 0) {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        dispatch(
          setMuestraErrores({
            key: tabId,
            tieneError: true,
            mensajesErrors: mensajes ?? [],
          }),
        )
        return false
      }

      const telefonos = dataSupplier.tabs.generales.telefonos
      const data = dataSupplier.tabs
      try {
        const proveedor: proveedorTipo = {
          codigo: data.contribuyente?.codigo ?? 0,
          tipoIdentificacion: data.generales?.tipoIdentificacion.codigo ?? '',
          tipoIdentificacionDescripcion:
            data.generales?.tipoIdentificacion.descripcion ?? '',
          identificacion: data.generales.identificacion ?? '',
          identificacionString: data.generales.identificacion ?? '',
          tributario: data.compras?.sustentoTri.codigo ?? '',
          // fechaRegitro: "",
          nombre: data.contribuyente.nombre ?? '',
          razonComercial: data.contribuyente.razonComercial ?? '',
          direccion: data.generales.direccion ?? '',
          telefono: data.generales.telefonos[0].numero ?? '',
          // fax: "",
          celular: data.generales.telefonos[0].numero ?? '',
          email: data.generales.email ?? '',
          ubicacion: data.generales.ciudad.codigo ?? 0,
          ciudadNombre: data.generales.ciudad.nombre ?? '',
          clasificacion: data.generales.clasificacion.codigo ?? 0,
          diasCredito: data.compras.diasCredito ?? 0,
          limiteCredito: data.compras.limiteCredito ?? 0,
          descuento: data.compras.descuento ?? 0,
          personaAtencionCompras: data.compras.atencionCompras ?? '',
          personaAtencionPagos: data.compras.atencionCompras ?? '',
          saldo: data.compras.saldo ?? 0,
          comprasAnuales: data.historicos.comprasAnuales ?? 0,
          fechaUltimoPago: data.historicos.ultimoPago.fecha ?? '',
          documentoUltimoPago: data.historicos.ultimoPago.documento ?? '',
          montoPago: data.historicos.ultimoPago.monto ?? 0,
          // fechaUltimaCompra: data.historicos.ultimaCompra.fecha ?? 0,
          documentoUltimaCompra: data.historicos.ultimaCompra.documento ?? '',
          montoCompra: data.historicos.ultimaCompra.monto ?? 0,
          observaciones: data.adicionales.observaciones ?? '',
          web: data.adicionales.sitioweb ?? '',
          tipoEmpresa: data.adicionales.tipoEmpresa.codigo ?? 0,
          especialidad: data.adicionales.especialidad.codigo ?? 0,
          tipoAgenteCodigo: data.generales.tipoAgente.codigo ?? 0,
          seguros: data.generales.proveedorSeguro ? 1 : 0,
          artesanos: data.generales.artesanoCalificado ? 1 : 0,
          tipoProveedorCodigo:
            String(data.generales.tipoProveedor.codigo) ?? '',
          relacionado: data.generales.parteRelacionada.label ?? '',
          estado: data.generales.estado.codigo.toString() ?? '',
          intervalo: data.adicionales.reposicion ?? 0,
          diaReposicion:
            data.adicionales.diaVisita !== null
              ? data.adicionales.diaVisita.label
              : 'Elija una opcion',
          transportista: data.generales.transportista ? 'true' : 'false',
          paisCodigo: data.generales.ciudad.codigoPais ?? 0,
          paisCescripcion: data.generales.ciudad.nombrePais ?? '',
          provinciaCodigo: data.generales.ciudad.codigoProvincia ?? 0,
          provinciaDescripcion: data.generales.ciudad.nombreProvincia ?? '',
          ciudadCodigo: data.generales.ciudad.codigo ?? 0,
          ciudadDescripcion: data.generales.ciudad.nombre ?? '',
          placas: '',
        }

        if (empresa.codigoPais === ECountry.ElSalvador) {
          proveedor.nit = data?.generales?.identificacion ?? ''
          proveedor.dui = data?.generales?.dui ?? ''
          proveedor.nrc = data?.generales?.nrc ?? ''
          proveedor.actividadCodigo =
            data?.generales?.actividadEconomica?.codigo ?? -1
        }

        dispatch(
          updateLoader({
            key: tabId,
            loader: { mensaje: 'Cargando', show: true },
          }),
        )
        const respuesta: any = await ProveedoresService.modificarProveedor(
          proveedor,
          telefonos,
        )
        dispatch(
          updateLoader({
            key: tabId,
            loader: { mensaje: '', show: false },
          }),
        )
        if (
          respuesta !== null &&
          respuesta !== undefined &&
          !isNaN(parseInt(respuesta))
        ) {
          setToast('Registro guardado exitosamente', ToastTypes.Success)
          const datos = []
          formasPago.map((item) => {
            if (item.cuentaCodigo && item.enUso && item.enUso === 0) {
              datos.push({
                codigo: 0,
                vinculacion: respuesta,
                tipo: 'PROVEEDORES',
                planCuentasCodigo: item?.cuentaCodigo ?? 0,
                transaccion: item?.transaccion ?? '',
              })
            }
          })
          try {
            await asignacionesService.registrarAsignacion(datos)
            const obj_request: any = {
              asignaciones: dpRenta,
              tipo: 56,
              id: parseInt(respuesta),
              impuesto: 65,
            }
            await proveedorService.guardarRetProCli(obj_request)
            obj_request.impuesto = 66
            obj_request.asignaciones = dpIva
            await proveedorService.guardarRetProCli(obj_request)
          } catch (error) {
            setToast(error, ToastTypes.Danger)
          }

          dispatch(
            setNameTab({
              tabKey: tabId,
              nombre: proveedor.razonComercial ?? '',
              identificacion: proveedor.identificacion ?? '',
              codigo: respuesta ?? 0,
            }),
          )
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ModuleButtons,
                Editar: false,
                Imprimir: false,
                Exportar: false,
                Deshacer: false,
                Limpiar: false,
                Guardar: false,
              },
            }),
          )
        } else if (
          respuesta !== null &&
          respuesta !== undefined &&
          respuesta === 'EXISTE'
        ) {
          setToast(
            `Proveedor con identificación ${data.generales?.tipoIdentificacion.descripcion} ya EXISTE`,
            ToastTypes.Danger,
          )
        } else if (
          respuesta !== null &&
          respuesta !== undefined &&
          respuesta === 'COMPRAS'
        ) {
          setToast(
            'El proveedor cuenta con COMPRAS ingresadas no puede ser inactivado, si desea hacerlo primero elimine los documentos asociados',
            ToastTypes.Danger,
          )
        } else {
          setToast(respuesta, ToastTypes.Danger)
        }
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
      } catch (error) {
        consoleService.error(error)

        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )

        dispatch(
          updateLoader({
            key: tabId,
            loader: { mensaje: '', show: false },
          }),
        )
        setToast(error, ToastTypes.Danger)
      }
    }
  }, [
    dataSupplier,
    setToast,
    dispatch,
    tabId,
    dpRenta,
    dpIva,
    formasPago,
    empresa,
    local,
    usuario,
  ])

  const deshacer = useCallback(() => {}, [])

  const handlePrint = useCallback((design: boolean) => {
    setVerPdf({ design: design })
  }, [])

  const handleButtonClick = useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.edit:
          modoEdicion(info)
          break
        case ButtonTypes.save: {
          guardar()
          break
        }
        case ButtonTypes.undo:
          if (tab.info?.codigo > 0) {
            modoEdicion(info)
          } else {
            modoNuevo(tab.info?.codigo, true)
          }
          break
        case ButtonTypes.print:
          handlePrint(false)
          break
        case ButtonTypes.print_design:
          handlePrint(true)
          break
        case ButtonTypes.broom:
          modoNuevo(tab.info?.codigo, true)
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [tabId, tab, info, modoNuevo, dispatch, guardar, handlePrint, modoEdicion],
  )

  useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  useEffect(() => {
    getVerificaPath()
  }, [getVerificaPath])

  const generaDetalleErrores = useCallback(
    (item: any) => {
      if (item.length > 0) {
        return (
          <CAlert color="danger" id={'alert-' + tabId}>
            <ul className="errorList" id={tabId + 'errorList'}>
              {item.map(function (item, id) {
                return <li key={id + '-' + tabId}>{item}</li>
              })}
            </ul>
          </CAlert>
        )
      } else {
        return <></>
      }
    },
    [tabId],
  )

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} className="mb-4">
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={dataSupplier.loader.show}
        message={dataSupplier.loader.mensaje}
      >
        {verPdf !== undefined && (
          <ReportWithLocalDataModal
            show={verPdf !== null}
            onClose={() => setVerPdf(null)}
            data={null}
            fileName="StaticProveedor"
            mode={verPdf?.design === true ? 'Designer' : 'Viewer'}
            parameters={{ Reporte_Filtro: [] }}
            template="StaticProveedor"
            key="reportDesigner"
          />
        )}
        {datosEdicion.tieneError && (
          <VisualizaError
            titulo="Acatha"
            mensaje={datosEdicion.mensajeError}
            content={generaDetalleErrores(datosEdicion.mensajesErrors)}
            onOk={() => {
              dispatch(
                setMuestraErrores({
                  key: props.tabId,
                  tieneError: false,
                  mensajesErrors: [],
                }),
              )
            }}
          />
        )}
        <ValidationGroup id={'formProveedor'} ref={validationGroupRef}>
          <ValidationSummary />
          <TabsEdition
            tabId={tabId}
            tab={tab}
            setToast={setToast}
            refForm={validationGroupRef}
          />
        </ValidationGroup>
        {isMobile && <div style={{ height: '175px' }} />}
      </BlockUi>
    </div>
  )
}

export const defaultDatosEdicionConfig: TMainEdition = {
  imprimir: false,
  loader: {
    mensaje: 'Cargando...',
    show: false,
  },
  mensajeError: '',
  mensajesErrors: [],
  tieneError: false,
  loading: true,
  desdePlantilla: false,
  codigoProveedor: 0,
}

export const defaultDatosEdicionProveedor: ProveedoresDatosEdicion = {
  ...defaultDatosEdicionConfig,
  tabs: {
    contribuyente: { ...defaultDatosContribuyente },
    generales: { ...defaultDatosGenerales },
    compras: { ...defaultDatosCompra },
    historicos: { ...defaultDatosHistorico },
    adicionales: { ...defaultDatosAditionales },
  },
}

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Deshacer: true,
  Limpiar: true,
}

export default memo(Proveedor)
