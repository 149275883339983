import { TPlanesDatosEdicion } from './types'

export const defaultDatosEdicionPlanes: TPlanesDatosEdicion = {
  id: 0,
  nombre: '',
  precio: 0,
  popular: 0,
  estado: 1,
  mobilesPermitidos: 0,
  pcPermitidos: 0,
  tabletsPermitidos: 0,
  codigoBase: 0,
  nombreWeb: '',
  usuariosBase: 0,
  usuariosExtra: 0,
  codigoItem: '',
  codigoUsuario: '',
  codigoComprobantes: '',
  usuariosMaximo: 0,
  numeroEmpresas: 0,
  numeroLocales: 0,
  multisesion: false,
  masIva: false,
  comprobantesMaximo: 0,
  tituloAlternativo: '',
  ordenPlan: 0,
  tituloDetalle: '',
  visibleWeb: false,
  categoria: {
    codigo: 1,
    descripcion: '',
    estado: true,
  },
  asistencias: 0,
  periodoCodigo: 0,
  pais: null,
  detalle: [],
  edition: false,
  loader: {
    mensaje: 'Espere, por favor...',
    show: true,
  },
  loading: true,
  tieneError: false,
  mensajeError: '',
}
