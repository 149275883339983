import { LibroTransaccionalState } from '../pages/anexoTransaccional/pages/libroTransacciones/types/types'
import { AnexoTransaccionalState } from '../pages/anexoTransaccional/types/types'

export type ModuloImpuestosSVState = {
  anexoTransaccional: AnexoTransaccionalState
  libroTransaccional: LibroTransaccionalState
}

export interface CustomDictionaryImpuestos<T> {
  [key: string]: T
}

// generar un enumerador
export enum ETipoAnexo {
  compras = '3',
  noContribuyente = '2',
  contribuyente = '1',
  anulados = '3',
  retencion = '7',
  sujetoExcluido = '5',
}
