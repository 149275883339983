import { CCol, CRow } from '@coreui/react-pro'
import React from 'react'
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  update8VenInicialFinal,
  updateDptotales8Only,
  updateDptotalesCobrosCarteraOnly,
  updateLoadDpCierre8Only,
  updateTotal8_tiOnly,
} from '../../../store/editDataReducer'
import DataGrid, {
  Column,
  HeaderFilter,
  Paging,
  Pager,
  Grouping,
  GroupPanel,
  ColumnChooser,
  Export,
} from 'devextreme-react/data-grid'
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../../views/componentes/colContainer'
import { utilidades } from '../../../../../../../../../helpers/utilidades'
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize'
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper'
import {
  AbonosCuadresCajaListado,
  formasPagoCuadre,
} from '../../../../../types/types'
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago'
import { GlobalLabel } from '../../../../../../../../../views/componentes/globalLabel/globalLabel'
import { exportFormats } from '../facturas/facturas'
import { CCDETALLE } from '../../../types/clases/ccdetalles'

interface ICobrosCarteraProps extends React.PropsWithChildren {
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void
  onErrorConfirm: (sms, title) => void
  onConfirmarRegistro: (sms, method) => void
  tabId: string
  statusLoaded: (load) => void
}

const CobrosCartera: React.FunctionComponent<ICobrosCarteraProps> = (props) => {
  const { tabId, statusLoaded } = props
  const dataGrid = React.useRef<any>()

  const dispatch = useDispatch()
  const [heigth] = useGridResultadosSize()

  const generarCuadre = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre
  })
  const loaddpcierre8 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loaddpcierre8
  })

  const total8_ti = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].total8_ti
  })
  const dptotales8 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales8
  })
  const dpfpagos = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos
  })
  const dpcierre8 = useSelector((state: RootState) => {
    return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre8
  })

  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showPageSizeSelector] = React.useState<boolean>(true)

  const resultadoCuadresAbonos = React.useCallback(
    async (dpCierreFacturasVar: Array<AbonosCuadresCajaListado> | []) => {
      let valor: number = 0
      const dptotales8Local: Array<formasPagoCuadre> = []
      if (dpCierreFacturasVar.length === 0) {
        for (const fpg of dpfpagos) {
          dptotales8Local.push({
            subCodigo: Number(fpg.codigo) ?? -1,
            subValor: String(fpg.descripcion) ?? '',
            ccdValor: valor,
          })
        }

        dispatch(
          updateDptotales8Only({
            dptotales8: dptotales8Local,
            key: tabId,
          }),
        )
      } else {
        let i = 0

        for (let j = 0; j <= dpfpagos.length - 1; j++) {
          valor = 0
          for (i = 0; i < dpCierreFacturasVar.length; i++) {
            if (
              dpCierreFacturasVar[i].abonoVentaTipoPago === dpfpagos[j].codigo
            ) {
              valor =
                valor +
                Number(
                  Number(dpCierreFacturasVar[i].abonoVentaValor).toFixed(2),
                )
            }
          }

          dptotales8Local.push({
            subCodigo: Number(dpfpagos[j].codigo),
            subValor: String(dpfpagos[j].descripcion),
            ccdValor: Number(Number(valor).toFixed(2)),
          })
        }

        const VEN_INICIAL8 = dpCierreFacturasVar[0].ingresoCodigo
        const VEN_FINAL8 =
          dpCierreFacturasVar[dpCierreFacturasVar.length - 1].ingresoCodigo

        dispatch(
          update8VenInicialFinal({
            rango: {
              inicial: VEN_INICIAL8,
              final: VEN_FINAL8,
            },
            key: tabId,
          }),
        )

        dispatch(
          updateDptotales8Only({
            dptotales8: dptotales8Local,
            key: tabId,
          }),
        )

        let k = 0

        let total: number = 0
        for (k = 0; k < dptotales8Local.length; k++) {
          total = total + dptotales8Local[k].ccdValor
        }
        total = Number(Number(total).toFixed(2))
        dispatch(
          updateTotal8_tiOnly({
            total8_ti: total,
            key: tabId,
          }),
        )

        const dptotalesCobrosCartera: CCDETALLE[] = []

        for (i = 0; i < dpfpagos.length; i++) {
          let x: CCDETALLE | null = null
          try {
            x = dptotales8Local[i] as CCDETALLE
          } catch (ex) {
            x = null
          }
          if (x == null) {
            const detalle = new CCDETALLE()
            detalle.ccdValor = Number(
              Number(dptotales8Local[i].ccdValor).toFixed(2),
            )
            detalle.subCodigo = dpfpagos[i].codigo
            detalle.subValor = dpfpagos[i].descripcion
            dptotalesCobrosCartera.push(detalle)
          } else {
            const y: number =
              Number(x?.ccdValor ?? 0) +
              Number(dptotales8Local[i]?.ccdValor ?? 0)
            const nuevoDetalle = new CCDETALLE(
              x?.ccdCodigo ?? 0,
              x?.ccaCodigo ?? '',
              x?.subValor ?? '',
              y,
              x?.subCodigo ?? 0,
            )
            dptotalesCobrosCartera[i] = nuevoDetalle
          }
        }
        const detallesTotales = []
        dptotalesCobrosCartera.forEach((item) => {
          detallesTotales.push({ ...item })
        })

        dispatch(
          updateDptotalesCobrosCarteraOnly({
            dptotalesCobrosCartera: dptotalesCobrosCartera,
            key: tabId,
          }),
        )
      }
    },
    [dispatch, tabId, dpfpagos],
  )

  const getCuadresAbonos = React.useCallback(async () => {
    let dpCierreRet = []
    if (dpcierre8) {
      dpCierreRet = dpcierre8
    }
    await resultadoCuadresAbonos(dpCierreRet)
    dispatch(
      updateLoadDpCierre8Only({
        loaddpcierre8: false,
        key: tabId,
      }),
    )
  }, [dispatch, tabId, dpcierre8, resultadoCuadresAbonos])

  const onLoadClose = React.useCallback(async () => {
    statusLoaded(true)
  }, [statusLoaded])

  React.useEffect(() => {
    if (loaddpcierre8 === true) {
      getCuadresAbonos()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaddpcierre8])

  React.useEffect(() => {
    if (generarCuadre === true) {
      onLoadClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generarCuadre])
  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">
          <RowContainer>
            <CustomCol xs="12" md="12">
              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr="ventaCodigo"
                dataSource={dpcierre8}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode="widget"
                height={heigth}
                ref={dataGrid}
              >
                <Export enabled={true} formats={exportFormats} />
                <GroupPanel visible={true} />
                <Grouping autoExpandAll={false} />
                <Paging defaultPageSize={20} />
                <Pager
                  visible={dpcierre8.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre8)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} mode="select" />
                <Column
                  dataField="ingresoValor"
                  caption=""
                  width="20%"
                  allowEditing={false}
                  groupIndex={0}
                />
                <Column
                  dataField="ventaFecha"
                  caption="Fecha de Registro"
                  width="20%"
                  allowEditing={false}
                />
                <Column
                  dataField="clienteNombre"
                  caption="Cliente"
                  width="20%"
                  allowEditing={false}
                />
                <Column
                  dataField="pagoVentaVencimiento"
                  caption="Vencimiento"
                  width="15%"
                  allowEditing={false}
                />
                <Column
                  dataField="abonoVentaFecha"
                  caption="Fecha Abono"
                  allowEditing={false}
                  width={'15%'}
                />
                <Column
                  dataField="ventaTipoDocumentoDescripcion"
                  caption="Comprobante"
                  width="15%"
                  allowEditing={false}
                  visible={false}
                />
                <Column
                  dataField="puntoVentaHost"
                  caption="POS"
                  width="15%"
                  allowEditing={false}
                />
                <Column
                  dataField="vendedorCompleto"
                  caption="Vendedor(AC)"
                  width="20%"
                  allowEditing={false}
                  visible={false}
                />
                <Column
                  dataField="ventaNumero"
                  caption="Número"
                  width="20%"
                  allowEditing={false}
                  visible={false}
                />
                <Column
                  dataField="abonoVentaTipoPagoDescripcion"
                  caption="Forma Pago"
                  width="20%"
                  allowEditing={false}
                  visible={false}
                />
                <Column
                  dataField="abonoVentaTipoPagoDocumentoDescripcion"
                  caption="Doc."
                  width="20%"
                  allowEditing={false}
                  visible={false}
                />
                <Column
                  dataField="pagoVentaCuota"
                  caption="Cuota"
                  width="20%"
                  allowEditing={false}
                />
                <Column
                  dataField="abonoVentaValor"
                  caption="Abono"
                  width="20%"
                  allowEditing={false}
                />
                <Column
                  dataField="pagoVentaSaldo"
                  caption="Saldo"
                  width="20%"
                  allowEditing={false}
                />
              </DataGrid>
            </CustomCol>
          </RowContainer>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol
          lg="12"
          md="12"
          sm="12"
          className="d-flex justify-content-end  align-items-center"
        >
          <CCol
            lg="3"
            md="3"
            sm="12"
            className="d-flex justify-content-end  align-items-center"
          >
            <TabFormasPago tabId={tabId} dptotales={dptotales8} />
          </CCol>
        </CCol>
      </CRow>
      <CRow className="m-2 row">
        <CCol
          lg="12"
          md="12"
          sm="12"
          className="d-flex justify-content-end  align-items-center"
        >
          <CCol lg="2" md="2" xs="12">
            <>
              <GlobalLabel htmlFor="total" className="col-form-label-md">
                <strong> {'Total'} </strong>
              </GlobalLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={total8_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </>
          </CCol>
        </CCol>
      </CRow>
    </>
  )
}
export default CobrosCartera
