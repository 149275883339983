import React from 'react'
import { useGridResultadosSize } from '../../../../../../../../../../hooks/useGridResultadosSize'
import DataGrid, {
  Column,
  HeaderFilter,
  Paging,
  Scrolling,
} from 'devextreme-react/data-grid'
import { utilidades } from '../../../../../../../../../../helpers/utilidades'
import { formasPagoCuadre } from '../../../../../../types/types'

interface ITabFormasPagoProps extends React.PropsWithChildren {
  dptotales: Array<formasPagoCuadre> | []
  tabId: string
}

const TabFormasPago: React.FunctionComponent<ITabFormasPagoProps> = (props) => {
  const { tabId, dptotales } = props

  const dataGridFp = React.useRef<any>()

  const [heigth] = useGridResultadosSize()
  const [data, setData] = React.useState<Array<formasPagoCuadre> | []>([])

  const editCellRender = (cell) => {
    return (
      <>
        <label>{utilidades.formatDecimal(cell.value, 2)}</label>
      </>
    )
  }

  const clearFilter = () => {
    dataGridFp.current.instance.clearSorting()
    dataGridFp.current.instance.clearFilter()
  }

  const pobladoTabla = React.useCallback((data) => {
    clearFilter()
    setData(data)
  }, [])

  React.useEffect(() => {
    pobladoTabla(dptotales)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dptotales])

  return (
    <>
      <DataGrid
        selection={{ mode: 'single' }}
        focusedRowEnabled={true}
        keyExpr="subCodigo"
        dataSource={data}
        showBorders={true}
        width={utilidades.getGridFullWidth()}
        allowColumnResizing
        columnResizingMode="widget"
        height={180}
        ref={dataGridFp}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <Column
          dataField="subValor"
          dataType="string"
          caption="F. PAGO"
          width="60%"
          allowEditing={false}
          allowSearch={false}
          allowFiltering
        />
        <Column
          dataField="ccdValor"
          caption="TOTAL"
          width="40%"
          allowEditing={false}
          editCellRender={editCellRender}
        />
      </DataGrid>
    </>
  )
}
export default TabFormasPago
