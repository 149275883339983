import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { localesService } from './service/locales.service';
import { LocalOption } from './store/type';
import { v4 as uuidv4 } from 'uuid'
import { adminService } from '../../admin/cuenta/usuario/services/adminService';
interface LocalesLookUpProps extends React.PropsWithChildren {
  selected: LocalOption | null | Pick<LocalOption, 'codigo'>
  // eslint-disable-next-line no-unused-vars
  onChanged: (newValue: LocalOption | null) => void
  // eslint-disable-next-line no-unused-vars
  onChangedOptions?: (newValue: Array<LocalOption> | []) => void
  disabled?: boolean
  provider?: Array<LocalOption> | []
  id?: string
  message?: string
  defecto?: boolean
}

const LocalesLookUp: React.FC<LocalesLookUpProps> = (props) => {
  const { id, provider, selected, onChanged, disabled, onChangedOptions, defecto = true } =
    props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<LocalOption> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      let marcarOption: LocalOption = provider[0]
      const selectedIndex = provider.findIndex((select: LocalOption) => {
        return select.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        marcarOption = provider[selectedIndex] ?? provider[0]
      }
      onChanged(marcarOption)
    } else {
      const message = props.message ? props.message : 'Elija una opción'
      let data = null
      if (defecto) {
        data = await localesService.getLocales(message)
      } else {
        data = await adminService.obtenerLocalesUsuario("Elija un local", true)
      }
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto ?? []
        }
        setOptions(opt)
        if (onChangedOptions) {
          onChangedOptions(opt)
        }
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }
  }, [provider, onChanged, selected?.codigo, props.message, onChangedOptions, defecto])

  const getItemDisplayExpr = React.useCallback((item: LocalOption) => {
    return item ? `${item.nombre}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      onChanged(evt)
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: LocalOption) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
    selectRef.current.instance.repaint()
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="localSelect"
      key={id ?? uuidv4()}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['nombre']}
      searchTimeout={500}
      onEnterKey={() => { }}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(LocalesLookUp);
