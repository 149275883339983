import React from 'react';
import { Cliente } from '../../../../../../../types/types';
import { ItemProforma } from '../../../type/types';
import { BuscarItemPedidoLookUp } from './BuscarItemsPedidoSelectBox';
import { EModulosAcatha } from '../../../../../../../../../store/enumsAplication';

interface IBuscarItemPedidoMemoLookUp {
  selected: ItemProforma | null,
  onChanged: (newValue: ItemProforma | null) => void,
  fecha: string,
  cliente: Cliente | null,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean,
  disabled?: boolean,
  onEnterKey?: () => void
  modulo?: EModulosAcatha
}

const BuscarItemPedidoMemoLookUp: React.FC<IBuscarItemPedidoMemoLookUp> = (props) => {

  const { onEnterKey, selected, onChanged, allowAdd, allowClear, allowEdit, disabled, cliente, fecha } = props;

  return (
    <BuscarItemPedidoLookUp
      selected={selected}
      onChanged={onChanged}
      fecha={fecha}
      cliente={cliente}
      allowEdit={allowEdit}
      allowClear={allowClear}
      allowAdd={allowAdd}
      disabled={disabled}
      onEnterKey={onEnterKey}
    />
  )
}

export default React.memo(BuscarItemPedidoMemoLookUp);
