import { TBotonesDatosEdicion } from './types'

export const defaultDatosEdicionBotones: TBotonesDatosEdicion = {
  codigo: 0,
  descripcion: '',
  atajo: '',
  imagen: '',
  edition: false,
  loader: {
    mensaje: 'Espere, por favor...',
    show: true,
  },
  loading: true,
  tieneError: false,
  mensajeError: '',
}
