import React, { useEffect, useRef } from 'react'
import { Ciudad } from '../../store/types'
import { Cliente, TipoCliente } from '../../types/types'
import { useDispatch } from 'react-redux'
import { localidadesService } from '../../../../services/localidades.service'
import { ClientesService } from '../../services/clientes.service'
import { addToast } from '../../../../store/toasterReducer'
import {
  TipoNegocio,
  TiposComprobantesSLV,
  ToastTypes,
} from '../../../../store/types'
import {
  CAlert,
  CTooltip,
  CCard,
  CCardBody,
  CCardHeader,
  CLink,
  CCollapse,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'

import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../views/componentes/colContainer'
import Labeled from '../../../../views/componentes/labeledInput/labeledInput'
import SelectBox from '../../../../views/componentes/selectBox/selectBox'
import TextBox from 'devextreme-react/text-box'
import {
  Validator,
  RequiredRule,
  PatternRule,
  AsyncRule,
  StringLengthRule,
} from 'devextreme-react/validator'
import { ValidationGroup } from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import { lh, MessagesKeys } from '../../../../helpers/localizationHelper'
import CustomModalDevx from '../../../../views/componentes/modal/Modal'
import { isMobile } from 'react-device-detect'
import TextArea from 'devextreme-react/text-area'
import { GeneralService } from '../../../../services/general.service'
import { LoadPanel } from 'devextreme-react/load-panel'
import { PopupContent } from '../../../../views/componentes/popupContent'
import { utilidades } from '../../../../helpers/utilidades'
import { useModalConvertSize } from '../../../../hooks/useModalSize'
import { isMobileOnly } from 'react-device-detect'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import { ECountry } from '../../../../store/enum/countries'
import { tipoIdentificacionService } from '../../../componentes/tipoIdentificacion/service/tipoId.service'
import {
  TipoIdentificacion,
  TipoTransaccion,
} from '../../../componentes/tipoIdentificacion/store/types'
import { consoleService } from '../../../../services/console.service'
import TipoIdentificacionLookUp from '../../../componentes/tipoIdentificacion/tipoIdentificacion'
import { Lookup } from 'devextreme-react'
import { ETipoIdCodTributario } from '../../../../services/itemEnum/enumSV'
import { sidebarService } from '../../../../services/sidebar.service'
import LocalidadesLookUp from '../../../componentes/localidadesLookUp/localidadesLookUp'
import { OptionCiudad } from '../../../../containers/component/formLocal/types/types'
import { regexPatterns } from '../../../../helpers/sv/patternValidations'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'
import CSucursales from './sucursales/sucursales'
import { DatosSucursales } from '../../../clientes/pages/clientes/types/types'
import Button from 'devextreme-react/button'
import QrPopover from '../../../../views/componentes/qrPopover/qrPopover'

export interface IModificarClienteProps extends React.PropsWithChildren {
  identificacion: string
  // eslint-disable-next-line no-unused-vars
  onChanged: (newData: Cliente, isNew: boolean) => void
  onCancel: () => void
  //tabId: number,
  show: boolean
  tipoComprobante: TiposComprobantesSLV
}

type DatosModificarCliente = {
  tipoCliente: TipoCliente | null
  tipoIdentificacion?: TipoIdentificacion | null
  ruc?: string
  nombres?: string | null
  nombreComercial?: string | null
  email?: string | null
  telefono?: string | null
  telefonoBackup?: string | null
  direccion?: string
  ciudad: Ciudad | OptionCiudad | null
  nit?: string | null
  nrc?: string | null
  dui?: string | null
}

export const emailsRegex = new RegExp(
  "^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]{1,}[\\;]?)+){1,}$",
)
export const emailPatern =
  /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\\;]?)+){1,}$/

type ErrorInfo = {
  tieneError: boolean
  mensaje?: string
}

const ModificarClienteSV: React.FunctionComponent<IModificarClienteProps> = (
  props,
) => {
  const { show, identificacion, onCancel, onChanged, tipoComprobante } = props
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const local = useSelector((state: RootState) => {
    return state.global.session?.local
  })

  const nitRegex = useRef(regexPatterns.nit)
  const srcRegex = useRef(regexPatterns.nrc)
  const duiRegex = useRef(regexPatterns.dui)
  const nitHomRegex = useRef(/^[0-9]{8}[0-9]{1}$/)

  const dispatch = useDispatch()
  const [modalSize] = useModalConvertSize('lg')
  const [tiposIdentificacion, setTiposIdentificacion] = React.useState<
    Array<TipoIdentificacion>
  >([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [codigo, setCodigo] = React.useState<number>(0)
  const [element] = React.useState('1')
  const [ruc, setRuc] = React.useState('')
  const [nombres, setNombres] = React.useState('')
  const [nombreComercial, setNombreComercial] = React.useState<string | null>(
    '',
  )
  const [tipoCliente, setTipocliente] = React.useState<TipoCliente | null>(null)
  const [tipoIdentificacion, setTipoIdentificacion] =
    React.useState<TipoIdentificacion | null>(null)
  const [tiposClientes, setTiposClientes] = React.useState<Array<TipoCliente>>(
    [],
  )
  const [email, setEmail] = React.useState<string>()
  const [telefonoBackup, setTelefonoBackup] = React.useState<string>('')
  const [telefono, setTelefono] = React.useState<string>('')
  const [direccion, setDireccion] = React.useState('')
  const [ciudad, setCiudad] = React.useState<Ciudad | OptionCiudad | null>(null)
  const [esNuevo, setEsnuevo] = React.useState(false)
  const [errorInfo, seterrorInfo] = React.useState<ErrorInfo>({
    tieneError: false,
  })

  const validationGroupRef = React.useRef<any>()
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loadPerson, setLoadPerson] = React.useState<{
    load: boolean
    data: any
  }>({ load: false, data: null })
  const [nit, setNit] = React.useState<string>('')
  const [nrc, setNrc] = React.useState<string>('')
  const [dui, setDui] = React.useState<string>('')
  const [actividad, setActividad] = React.useState<TipoNegocio | null>(null)
  const [actividades, setActividades] = React.useState<Array<TipoNegocio> | []>(
    [],
  )
  const [collapsed, setCollapsed] = React.useState(false)
  const [sucursales, setSucursales] = React.useState<
    Array<DatosSucursales> | []
  >([])
  const [showQr, setShowQr] = React.useState<boolean>(false)

  const onInitFiltro = React.useCallback(async () => {
    const activities = await sidebarService.tiponegocioListar()
    if (activities.length) {
      setActividades(activities ?? [])
    }
  }, [])

  const setToast = React.useCallback(
    (texto, type) => {
      let tipo: ToastTypes = ToastTypes.Info
      if (type === 'warning') {
        tipo = ToastTypes.Warning
      } else if (type === 'success') {
        tipo = ToastTypes.Success
      }
      if (type === 'info') {
        tipo = ToastTypes.Info
      }
      if (type === 'danger') {
        tipo = ToastTypes.Danger
      }
      dispatch(
        addToast({
          id: '',
          autoHide: 3500,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onChangeCity = React.useCallback((e) => {
    setCiudad(e)
  }, [])

  const onChangeActivitie = React.useCallback((data) => {
    let activitie = null
    if (data) {
      activitie = data
    }
    setActividad(activitie)
  }, [])

  const resetForm = React.useCallback(async () => {
    setEsnuevo(true)
    setCodigo(0)
    setRuc('')
    setNombres('')
    setNombreComercial('')
    setTipoIdentificacion(null)
    setEmail('')
    setTelefono('')
    setTelefonoBackup('')
    setDireccion('')
    setDui('')
    setNrc('')
    setNit('')
    setActividad(null)
    seterrorInfo({ tieneError: false })
    let ciudad = null
    if (local && local.ciudad) {
      const ciudadLocal = await localidadesService.getCiudad(
        local?.ciudad?.codigo,
      )
      if (ciudadLocal) {
        ciudad = ciudadLocal
      }
    }
    setCiudad(ciudad)
  }, [local])

  const validate = React.useCallback(async () => {
    let resolved = false
    let status = false
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(50)
      }
      resolve(resolved)
    })
    const validationResult = validationGroupRef.current.instance.validate()
    consoleService.log('validationResult', validationResult)
    const errors = []
    if (tipoIdentificacion?.codigo && Number(tipoIdentificacion?.codigo) < 0) {
      errors.push({ type: 'required', message: 'Tipo Documento: es requerido' })
    }
    if (
      validationResult?.brokenRules &&
      validationResult?.brokenRules.length > 0
    ) {
      const errorsForm = []
      validationResult?.brokenRules.map(function (key) {
        errorsForm.push({ type: key.type, message: key.message })
      })

      setShowErrorMessages([...errors, ...errorsForm])
      return false
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async (resolve) => {
        await resolve
        status = resolve.isValid
        resolved = true
      })
      await p
      return status
    }
    return true
  }, [tipoIdentificacion])

  const getDataPerson = React.useCallback(
    async (infoPerson) => {
      console.log('getDataPerson infoPerson', infoPerson)

      if (infoPerson.nombre && nombres === '') {
        setNombres(infoPerson.nombre)
      }
      if (infoPerson.razonComercial && nombreComercial === '') {
        setNombreComercial(infoPerson.razonComercial)
      }
      if (infoPerson.email && email === '') {
        setEmail(infoPerson.email)
      }
      if (infoPerson.direccion && direccion === '') {
        setDireccion(infoPerson.direccion)
      }
      if (infoPerson.telefono && telefono === '') {
        setTelefono(infoPerson.telefono)
      }
      if (infoPerson.nrc && nrc === '') {
        setNrc(infoPerson.nrc)
      }
      if (infoPerson.actividadEconomica && actividad === null) {
        const activities = await sidebarService.tiponegocioListar()

        setActividad(
          activities.find(
            (x) =>
              String(x.codigoTributario) ===
              String(infoPerson.actividadEconomica),
          ) ?? null,
        )
      }
      setLoadPerson({ load: false, data: null })
    },
    [nombres, email, direccion, telefono, nrc, nombreComercial, actividad],
  )

  const validateId = React.useCallback(
    async (params) => {
      const validacionIdentificacion =
        await GeneralService.validarIndentificacion(params.value ?? '')

      if (validacionIdentificacion.isValid) {
        setLoadPerson({ load: true, data: validacionIdentificacion })
        if (esNuevo) {
          const sms: string = `El cliente con cedula ${
            params.value ?? ''
          } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`
          const clientes = await ClientesService.consultarClientes({
            nombre: '',
            identificacion: params.value ?? '',
          })
          if (clientes.length > 0) {
            setToast([sms], 'danger')
            return Promise.reject(sms)
          }
        }
        return Promise.resolve()
      } else {
        return Promise.reject(
          validacionIdentificacion.motivo ?? 'Error al validar identificación',
        )
      }
    },
    [esNuevo, setToast],
  )

  const validateIdCountry = React.useCallback(
    async (params) => {
      const validacionIdentificacion =
        await GeneralService.validarIndentificacion(params.value ?? '')
      if (validacionIdentificacion.isValid) {
        setLoadPerson({ load: true, data: validacionIdentificacion })
        if (esNuevo) {
          const sms: string = `El cliente con identificación ${
            params.value ?? ''
          } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`
          const clientes = await ClientesService.consultarClientes({
            nombre: '',
            identificacion: params.value ?? '',
          })
          if (clientes.length > 0) {
            setToast([sms], 'danger')
            return Promise.reject(sms)
          }
        }
      } else {
        return Promise.reject(
          validacionIdentificacion.motivo ?? 'Error al validar identificación',
        )
      }
      return Promise.resolve()
    },
    [esNuevo, setToast],
  )

  const handleSubmit = React.useCallback(
    async (values: DatosModificarCliente, verificarNuevo: boolean) => {
      setLoading(true)

      const validationResult = await validate()

      if (!validationResult) {
        setShowErrorPopup(true)
        setLoading(false)
        return false
      }
      if (verificarNuevo) {
        let cliente = null
        if (tipoComprobante !== TiposComprobantesSLV.Factura) {
          cliente = await ClientesService.consultarClientes({
            nombre: '',
            identificacion: values.nit ?? '',
          })
        }
        setLoading(false)
        if (cliente && cliente.length > 0) {
          dispatch(
            addToast({
              title: 'Crear cliente',
              content: `El cliente con cedula ${
                values.ruc ?? ''
              } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
              type: ToastTypes.Danger,
              autoHide: 3500,
            }),
          )
          return
        }
      }
      if (values.telefono.includes(';')) {
        const controlar = values.telefono.split(';')
        let numeroError: string = ''
        controlar.map(function (key) {
          if (key.length > 20) {
            numeroError = key
            return false
          }
        })
        if (numeroError !== '') {
          setLoading(false)
          dispatch(
            addToast({
              title: 'Contactos cliente',
              content: `El numero ${
                numeroError ?? ''
              } no tiene el formato permitido, por favor vuelva a ingresar el contacto correcto.`,
              type: ToastTypes.Danger,
              autoHide: 7500,
            }),
          )
          return false
        }
      }
      if (
        values?.tipoIdentificacion &&
        values?.tipoIdentificacion?.codigo === '-1'
      ) {
        setLoading(false)
        dispatch(
          addToast({
            title: 'Cliente',
            content: `El tipo de identificación seleccionado no es el correcto.`,
            type: ToastTypes.Danger,
            autoHide: 7500,
          }),
        )
        return false
      }

      let contactos: string = ''
      if (values.telefono !== '') {
        contactos = values.telefono
        if (values.telefonoBackup !== '') {
          if (contactos !== values.telefonoBackup) {
            contactos = contactos + '||' + values.telefonoBackup
          }
        }
      } else {
        contactos = values.telefono
      }
      let id: string = ''
      if (empresa.codigoPais === ECountry.ElSalvador) {
        if (values?.nit) {
          id = values.nit
        }
      } else if (empresa.codigoPais === ECountry.Ecuador) {
        id = values.ruc
      }
      const tipoIdentificacion = values.tipoIdentificacion?.codigo ?? '01'

      const saveSucursales =
        sucursales
          .filter((c: any) => c.codigo === 0)
          .map((c: any) => {
            return {
              codigo: parseInt(c?.codigo),
              codigoCliente: parseInt(c?.clienteCodigo),
              codigoTelefono: parseInt(c?.codContacto),
              numeroTelefono: c?.contacto,
              codigoCiudad: parseInt(c?.ciudadCodigo),
              codigoZona: parseInt(c?.zonaCodigo),
              direccion: c?.direccion.toString(),
              email: c?.email.toString(),
              referencia: c?.referencia.toString(),
            }
          }) ?? []

      const cliente: Cliente = {
        codigo: verificarNuevo ? 0 : codigo,
        identificacion: id ?? '',
        nombres: values.nombres ?? '',
        nombreComercial: values.nombreComercial ?? '',
        direccion: values.direccion ?? '',
        telefono: contactos ?? '',
        email: values?.email ?? '',
        descuento: 0,
        ciudad: values.ciudad?.nombre ?? '',
        ciudadCodigo: values.ciudad?.codigo ?? 0,
        tipoCliente: values.tipoCliente?.codigo ?? 0,
        tipoIdentificacion: tipoIdentificacion,
        nrc: values?.nrc ?? '',
        dui: values?.dui ?? '',
      }
      if (saveSucursales.length > 0) {
        cliente.sucursales = saveSucursales ?? []
      }
      if (actividad !== null) {
        cliente.tipoNegocio = actividad?.codigo ?? -1
      }

      try {
        const respuesta = await ClientesService.modificarCliente(cliente)

        setLoading(false)
        if (respuesta['error'] === false) {
          setToast(respuesta['message'], 'success')
          onChanged(
            {
              ...cliente,
              codigo: parseInt(respuesta['auto'].codigo as any),
              telefono: values.telefono || null,
            },
            verificarNuevo,
          )
        } else {
          seterrorInfo({
            tieneError: true,
            mensaje: 'Error al guardar el cliente, \n' + respuesta['message'],
          })
          setToast(respuesta['message'], 'danger')
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        setLoading(false)
        seterrorInfo({
          tieneError: true,
          mensaje: 'Error al guardar el cliente, \n' + error,
        })
      }
    },
    [
      validate,
      onChanged,
      codigo,
      dispatch,
      setToast,
      empresa,
      actividad,
      tipoComprobante,
      sucursales,
    ],
  )

  const cargarCliente = React.useCallback(
    async (identificacion: string) => {
      consoleService.log('cargarCliente')

      try {
        setLoading(true)
        const tipos = await ClientesService.getTiposClienntes()
        const tiposIdentification =
          await tipoIdentificacionService.getTipoIdetificacion(
            TipoTransaccion.venta,
            empresa?.codigoPais,
          )
        let tiposId = []
        if (tiposIdentification.error === false) {
          let opt = []
          if (tiposIdentification.auto.length > 0) {
            opt = tiposIdentification.auto.map((x) => {
              const item: TipoIdentificacion = {
                codigo: x.codigo ?? 0,
                descripcion: x.descripcion ?? '',
                codTributario: x.codTributario ?? '',
              }
              return item
            })
          }
          tiposId = opt
          setTiposIdentificacion(opt)
        }

        const busqueda =
          identificacion === ''
            ? null
            : await ClientesService.getCliente(identificacion)

        const activities = await sidebarService.tiponegocioListar()

        if (activities.length) {
          setActividades(activities ?? [])
        }

        setLoading(false)
        if (!busqueda) {
          setEsnuevo(true)
          setCodigo(0)
          setRuc(identificacion)
          setNombres('')
          setNombreComercial('')
          setTipocliente(tipos[0])
          if (empresa.codigoPais === ECountry.ElSalvador) {
            setTipoIdentificacion(
              tiposId.find((x) => Number(x.codigo) === 12) ?? null,
            )
          } else if (empresa.codigoPais === ECountry.Ecuador) {
            setTipoIdentificacion(
              identificacion.length === 13 ? tiposId[0] : tiposId[1],
            )
          }
          setEmail('')
          setTelefono('')
          setTelefonoBackup('')
          setDireccion('')
          const ciudadLocal = localidadesService.getCiudadLocalActual()
          if (ciudadLocal) {
            setCiudad(ciudadLocal)
          }
        } else {
          const cliente = busqueda
          const ciudad = await localidadesService.getCiudadByCode(
            cliente.ciudadCodigo,
          )
          setEsnuevo(false)
          setCodigo(cliente.codigo)
          setRuc(cliente.identificacion)
          setNombres(cliente.nombres)
          setNombreComercial(cliente.nombreComercial)
          setEmail(cliente.email ?? undefined)
          setTelefono(cliente.telefono ?? '')
          setTelefonoBackup(cliente.telefono ?? '')
          setDireccion(cliente.direccion)
          setDui(cliente.dui)
          setNrc(cliente.nrc)
          setNit(cliente.identificacion)
          setCiudad(ciudad)
          setTipocliente(
            tipos.find((x) => x.codigo === cliente.tipoCliente) ?? null,
          )
          if (tiposId.length > 0 && cliente.identificacion !== null) {
            const findIdTipo = tiposId.find(
              (x) => Number(x.codigo) === Number(cliente.tipoIdentificacion),
            )

            setTipoIdentificacion(findIdTipo ?? null)
          }
          if (activities.length > 0 && cliente.tipoNegocio !== null) {
            setActividad(
              activities.find(
                (x) => Number(x.codigo) === Number(cliente.tipoNegocio),
              ) ?? null,
            )
          }
        }
        setTiposClientes(tipos)
      } catch (error) {
        consoleService.error(error)
        setLoading(false)
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: 'Error al cargar cliente. Error:\n' + error,
            fade: true,
            title: 'Clientes',
            type: ToastTypes.Danger,
          }),
        )
      }
      setLoading(false)
    },
    [dispatch, empresa],
  )

  const getMessage = (mensaje, replace) => {
    const message = mensaje.replace('@dinamic_var@', replace)
    return message
  }

  const datasource = React.useMemo(() => {
    return localidadesService.getCiudadesDatasource()
  }, [])

  const getCiudadDisplayExpr = React.useCallback((ciudad: Ciudad) => {
    return ciudad ? `${ciudad.nombre}` : ''
  }, [])

  const isCountryLocation = React.useCallback((value) => {
    consoleService.log(value)
    return new Promise((resolve, reject) => {
      if (value?.value?.codigoPais === ECountry.ElSalvador) {
        resolve(true)
      } else {
        reject()
      }
    })
  }, [])

  const isTipoId = React.useCallback((value) => {
    return new Promise((resolve, reject) => {
      if (value?.value?.codigo > 0) {
        resolve(true)
      } else {
        reject()
      }
    })
  }, [])

  const inputIdentificacionPasaporte = () => {
    return (
      <div key="textPasaporte">
        <TextBox
          name="ruc"
          placeholder="R.U.C / Identificación"
          value={ruc}
          onValueChange={(evt) => {
            setRuc(evt)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroPasaporteRequerido,
                '',
              )}
            />
            <StringLengthRule
              max="15"
              message={getMessage(
                lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionMax, ''),
                15,
              )}
              ignoreEmptyValue
            />
          </Validator>
        </TextBox>
      </div>
    )
  }

  const inputIdentificacionCedula = () => {
    return (
      <div key={'textCedula'}>
        <TextBox
          name="ruc"
          placeholder="C.I. / Identificación"
          value={ruc}
          onValueChange={(evt) => {
            setRuc(evt)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionRequerido,
              )}
            />
            <StringLengthRule
              max="10"
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                '',
              )}
            />
            <AsyncRule
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                '',
              )}
              reevaluate={false}
              validationCallback={validateId}
            />
          </Validator>
        </TextBox>
      </div>
    )
  }

  const loadPanelShow = () => {
    return (
      <div>
        <LoadPanel
          id="loadingPanelDevx"
          shadingColor="rgba(0,0,0,0.4)"
          visible={loading}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </div>
    )
  }

  function Item(data) {
    return (
      <CTooltip
        key={'tool-tipoNegocioOption'}
        placement="top"
        content={data?.descripcion ?? ''}
      >
        <div className="custom-item">
          <div className="product-name">{data.descripcion} </div>
        </div>
      </CTooltip>
    )
  }

  const fieldTipoNeg = React.useCallback((data) => {
    if (data && data?.data !== null) {
      if (data?.data?.descripcion && data?.data?.descripcion !== null) {
        return (
          <CTooltip
            key={'tool-tipoNegocio'}
            placement="top"
            content={data?.data?.descripcion ?? ''}
          >
            <div className="custom-item">
              <div className="tipoNegocio-name">
                {data?.data?.descripcion ?? ''}{' '}
              </div>
            </div>
          </CTooltip>
        )
      }
    }
  }, [])

  React.useEffect(() => {
    if (loadPerson.load === true) {
      getDataPerson(loadPerson.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPerson])

  React.useEffect(() => {
    if (show) {
      cargarCliente(identificacion)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificacion])

  useEffect(() => {
    resetForm()
  }, [resetForm, show])

  const elementMunicipioExportacion = () => {
    return (
      <LocalidadesLookUp
        onChanged={(data) => {
          onChangeCity(data)
        }}
        onChangedOptions={() => {}}
        selected={(ciudad as OptionCiudad) ?? null}
        allowEdit
        allowClear
      >
        <Validator>
          <RequiredRule message={'Municipio: Este campo es requerido'} />
        </Validator>
      </LocalidadesLookUp>
    )
  }

  const elementMunicipioGeneral = () => {
    return (
      <Lookup
        width="100%"
        dataSource={datasource}
        searchEnabled={true}
        searchMode={'contains'}
        searchExpr={'descripcion'}
        displayExpr={getCiudadDisplayExpr}
        searchTimeout={200}
        minSearchLength={3}
        showDataBeforeSearch={true}
        placeholder="Seleccionar"
        showClearButton={true}
        value={ciudad ?? null}
        onValueChange={onChangeCity}
      >
        <Validator>
          <RequiredRule message={'Municipio: Este campo es requerido'} />
          {tipoComprobante !== TiposComprobantesSLV.FacturaExportacion && (
            <AsyncRule
              message={
                'Municipio: La ubicación seleccionada no corresponde al país emisor de documento.'
              }
              validationCallback={isCountryLocation}
              ignoreEmptyValue
            />
          )}
        </Validator>
      </Lookup>
    )
  }

  const elementMunicipio = () => {
    if (tipoComprobante === TiposComprobantesSLV.FacturaExportacion) {
      return <>{elementMunicipioExportacion()}</>
    } else {
      return <>{elementMunicipioGeneral()}</>
    }
  }

  const getInputIdentification = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled label="Tipo Documento">
              <TipoIdentificacionLookUp
                onChanged={(data) => {
                  if (data !== null) {
                    setTipoIdentificacion(data)
                  }
                }}
                transaccion={TipoTransaccion.venta}
                disabled={false}
                selected={tipoIdentificacion}
                id="lookUpTipoIdMC"
                codigoPais={empresa?.codigoPais}
              >
                <Validator>
                  {tipoComprobante !== TiposComprobantesSLV.Factura && (
                    <>
                      <RequiredRule message={'Tipo Documento: es requerido'} />
                      <AsyncRule
                        message={'Tipo Documento: es requerido'}
                        validationCallback={isTipoId}
                        reevaluate={true}
                      />
                    </>
                  )}
                </Validator>
              </TipoIdentificacionLookUp>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="4">
            <Labeled
              label={
                tipoIdentificacion?.codigo === '-1'
                  ? 'Identificacion'
                  : tipoIdentificacion?.descripcion
              }
            >
              <TextBox
                name="nit"
                placeholder={
                  tipoIdentificacion?.codigo === '-1'
                    ? 'Identificacion'
                    : tipoIdentificacion?.descripcion
                }
                value={nit}
                onValueChange={(evt) => {
                  setNit(utilidades.eliminarGuiones(evt))
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              >
                <Validator>
                  {tipoComprobante !== TiposComprobantesSLV.Factura && (
                    <RequiredRule
                      message={
                        (tipoIdentificacion?.codigo === '-1'
                          ? 'Identificacion'
                          : tipoIdentificacion?.descripcion) +
                        ': Este campo es requerido'
                      }
                    />
                  )}
                  {tipoIdentificacion?.codTributario ===
                    ETipoIdCodTributario.nit && (
                    <PatternRule
                      message="El NIT no tiene el formato correcto"
                      pattern={
                        nit.length === 9
                          ? nitHomRegex.current
                          : nitRegex.current
                      }
                    />
                  )}
                  {tipoIdentificacion?.codTributario ===
                    ETipoIdCodTributario.dui && (
                    <PatternRule
                      message="El DUI no tiene el formato correcto"
                      pattern={
                        nit.length === 9
                          ? nitHomRegex.current
                          : nitRegex.current
                      }
                    />
                  )}
                  {tipoIdentificacion?.codTributario ===
                    ETipoIdCodTributario.dui ||
                    (tipoIdentificacion?.codTributario ===
                      ETipoIdCodTributario.nit && (
                      <AsyncRule
                        message={
                          '  Ya existe un cliente registrado con esta identificación.'
                        }
                        reevaluate={true}
                        validationCallback={validateIdCountry}
                        ignoreEmptyValue={true}
                      />
                    ))}
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="8">
            <Labeled label="Nrc">
              <TextBox
                name="nrc"
                placeholder=""
                value={nrc}
                onValueChange={(evt) => {
                  setNrc(utilidades.eliminarGuiones(evt))
                }}
                maxLength={14}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              >
                <Validator>
                  <PatternRule
                    message="Contribuyente: El contribuyente no tiene el formato correcto"
                    pattern={srcRegex.current}
                  />
                  <StringLengthRule
                    max={11}
                    message={
                      'Contribuyente: Este campo no puede tener mas de ' +
                      11 +
                      ' caracteres'
                    }
                  />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  React.useEffect(() => {
    onInitFiltro()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  if (!show) {
    return null
  }

  return (
    <>
      {loading && loadPanelShow()}
      <CustomModalDevx
        size={modalSize}
        id="sectionTutRegistraCliente"
        show={show}
        title="Administración de cliente"
        onClose={onCancel}
        toolbarItems={[
          {
            options: {
              accessKey: 'btnAceptarCliente',
              id: 'btnAceptarCliente',
              text: 'Aceptar',
              stylingMode: 'contained',
              type: 'default',
              icon: 'check',
              onClick: () =>
                handleSubmit(
                  {
                    tipoIdentificacion: tipoIdentificacion,
                    tipoCliente: tipoCliente,
                    ciudad: ciudad,
                    direccion: direccion,
                    email: email,
                    nombreComercial: nombreComercial,
                    nombres: nombres,
                    ruc: ruc,
                    telefono: telefono,
                    telefonoBackup: telefonoBackup,
                    nrc: nrc,
                    nit: nit,
                    dui: dui,
                  },
                  esNuevo,
                ),
            },
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after',
          },
          {
            options: {
              id: 'btnCancelarCliente',
              text: 'Cancelar',
              stylingMode: 'contained',
              type: 'danger',
              icon: 'remove',
              onClick: onCancel,
            },
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after',
          },
        ]}
        height={!isMobileOnly ? 480 : undefined}
      >
        <fieldset>
          <ValidationGroup
            id={`valGroupModificarCliente${props.identificacion}`}
            ref={validationGroupRef}
          >
            <RowContainer>
              <CustomCol xl="2">
                <Button
                  stylingMode="contained"
                  type="normal"
                  text="QR Registro"
                  onClick={() => {
                    setShowQr(true)
                  }}
                  elementAttr={{
                    id: 'btnAddClient',
                  }}
                />
              </CustomCol>
            </RowContainer>
            {showQr && (
              <QrPopover
                showEnlace={showQr}
                texto={empresa?.configuracion?.configApp?.registroPersona ?? ''}
                onClose={() => {
                  setShowQr(false)
                }}
              />
            )}
            {errorInfo.tieneError && (
              <CAlert
                key="modificaClienteErrorAlert"
                color="danger"
                visible={true}
                onChange={() => {
                  seterrorInfo({ tieneError: false })
                }}
              >
                {errorInfo.mensaje}
              </CAlert>
            )}
            {getInputIdentification()}
            <RowContainer>
              <CustomCol xs="12" md="8">
                <Labeled label="Nombres">
                  <TextBox
                    name="nombres"
                    placeholder="Nombres"
                    value={nombres}
                    onValueChange={(evt) => {
                      setNombres(evt)
                    }}
                    inputAttr={{
                      autocomplete: 'nope',
                    }}
                  >
                    <Validator>
                      <RequiredRule
                        trim
                        message={lh.getMessage(
                          MessagesKeys.GlobalCampoRequerido,
                          'nombre',
                        )}
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4">
                <Labeled label="Municipio">{elementMunicipio()}</Labeled>
              </CustomCol>
            </RowContainer>

            <RowContainer>
              <CustomCol xs="12" md="8">
                <Labeled label="Nombre Comercial">
                  <TextBox
                    name="nombresComercial"
                    placeholder="Nombre Comercial"
                    value={nombreComercial}
                    onValueChange={(evt) => {
                      setNombreComercial(evt)
                    }}
                    inputAttr={{
                      autocomplete: 'nope',
                    }}
                  >
                    <Validator>
                      {tipoComprobante ===
                        TiposComprobantesSLV.CreditoFiscal && (
                        <RequiredRule
                          trim
                          message={lh.getMessage(
                            MessagesKeys.GlobalCampoRequerido,
                            'Nombre Comercial',
                          )}
                        />
                      )}
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4">
                <Labeled label="Actividad Económica:">
                  <Lookup
                    width="100%"
                    dataSource={actividades ?? []}
                    displayExpr="descripcion"
                    searchEnabled={true}
                    searchMode={'contains'}
                    searchExpr={'descripcion'}
                    searchTimeout={200}
                    minSearchLength={3}
                    showDataBeforeSearch={true}
                    placeholder="Seleccionar"
                    showClearButton={true}
                    value={actividad ?? null}
                    onValueChange={onChangeActivitie}
                    itemRender={Item}
                    fieldComponent={fieldTipoNeg}
                  >
                    {tipoComprobante !== TiposComprobantesSLV.Factura &&
                      tipoComprobante !==
                        TiposComprobantesSLV.FacturaExportacion && (
                        <Validator>
                          <RequiredRule
                            message={
                              'Actividad Económica: Este campo es requerido'
                            }
                          />
                        </Validator>
                      )}
                  </Lookup>
                </Labeled>
              </CustomCol>
            </RowContainer>

            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Teléfono">
                  <TextBox
                    value={telefono}
                    onValueChange={(evt) => {
                      setTelefono(evt)
                    }}
                    inputAttr={{
                      autocomplete: 'nope',
                    }}
                    placeholder="0992702599;0992702588"
                  >
                    <Validator>
                      {tipoComprobante !== TiposComprobantesSLV.Factura && (
                        <RequiredRule
                          message={lh.getMessage(
                            MessagesKeys.GlobalCampoRequerido,
                            'Teléfono',
                          )}
                        />
                      )}
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="4">
                <Labeled label="Email">
                  <TextBox
                    value={email}
                    onValueChange={(evt) => {
                      setEmail(String(evt).trim())
                    }}
                    inputAttr={{
                      autocomplete: 'nope',
                    }}
                  >
                    <Validator>
                      {tipoComprobante !== TiposComprobantesSLV.Factura && (
                        <RequiredRule
                          message={lh.getMessage(
                            MessagesKeys.GlobalCampoRequerido,
                            'Email',
                          )}
                        />
                      )}
                      <PatternRule
                        pattern={emailPatern}
                        message={lh.getMessage(
                          MessagesKeys.GlobalFormatoCampoIncorrecto,
                          'Email',
                        )}
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12">
                <Labeled label="Dirección">
                  {isMobile && (
                    <TextArea
                      value={direccion}
                      onValueChange={(evt) => {
                        setDireccion(evt)
                      }}
                      height={90}
                      inputAttr={{
                        autocomplete: 'nope',
                      }}
                    >
                      <Validator>
                        {tipoComprobante !== TiposComprobantesSLV.Factura && (
                          <RequiredRule
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Dirección',
                            )}
                          />
                        )}
                      </Validator>
                    </TextArea>
                  )}
                  {!isMobile && (
                    <TextBox
                      value={direccion}
                      onValueChange={(evt) => {
                        setDireccion(evt)
                      }}
                      inputAttr={{
                        autocomplete: 'nope',
                      }}
                    >
                      <Validator>
                        {tipoComprobante !== TiposComprobantesSLV.Factura && (
                          <RequiredRule
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Dirección',
                            )}
                          />
                        )}
                      </Validator>
                    </TextBox>
                  )}
                </Labeled>
              </CustomCol>
            </RowContainer>
            {codigo > 0 && (
              <RowContainer>
                <CustomCol xs="12">
                  <CCard className="mt-2 ">
                    <CCardHeader
                      onClick={() => setCollapsed(!collapsed)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <strong> {'Sucursales'} </strong>
                      <CLink className="card-header-action">
                        <CIcon
                          icon={collapsed ? cilChevronBottom : cilChevronTop}
                        />
                      </CLink>
                    </CCardHeader>
                  </CCard>
                  <CCollapse visible={collapsed}>
                    <CCardBody>
                      <CSucursales
                        clienteCodigo={codigo}
                        setToast={setToast}
                        onChange={(d) => {
                          setSucursales(d)
                        }}
                      />
                    </CCardBody>
                  </CCollapse>
                </CustomCol>
              </RowContainer>
            )}
            <RowContainer className="mb-2 mp-2">
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
            {showErrorPopup && showErrorMessages.length > 0 && (
              <PopupContent
                show={showErrorPopup}
                title={'Acatha'}
                subTitle={'Por favor revise las siguientes advertencias:'}
                onClose={() => {
                  setShowErrorPopup(false)
                  setShowErrorMessages([])
                }}
                size={'sm'}
                canClose={true}
              >
                <CAlert color="danger">
                  <ul className="errorList">
                    {showErrorMessages.map(function (item, id) {
                      return <li key={id}>{item['message']}</li>
                    })}
                  </ul>
                </CAlert>
              </PopupContent>
            )}
          </ValidationGroup>
        </fieldset>
      </CustomModalDevx>
    </>
  )
}

export default React.memo(ModificarClienteSV)
